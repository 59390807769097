import React from 'react';
import classnames from 'classnames';
import { usePagination, DOTS } from './usePagination';

const Pagination = (props:any) => {
  const {
    onPageChange,
    totalCount,
    siblingCount = 1,
    currentPage,
    pageSize,
    className,
  } = props;

  const paginationRange = usePagination({
    currentPage,
    totalCount,
    siblingCount,
    pageSize,
  });

  if (currentPage === 0 || paginationRange.length < 2) {
    return null;
  }

  const onNext = () => {
    onPageChange(currentPage + 1);
  };

  const onPrevious = () => {
    onPageChange(currentPage - 1);
  };

  let lastPage = paginationRange[paginationRange.length - 1];
  return (
    <ul
      className={classnames('pagination-container', { [className]: className })}
    >
      <li className={classnames('pagination-item pagesize')}>
            {currentPage}-{pageSize} of total {totalCount} record(s)
      </li>
      <li className={classnames('pagination-item currentpage')}>
            {currentPage}/{Math.ceil(totalCount/pageSize)}
      </li>
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === 1,
        })}
        onClick={onPrevious}
      >
        <div className="arrow left" >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 22.75C6.07 22.75 1.25 17.93 1.25 12C1.25 6.07 6.07 1.25 12 1.25C17.93 1.25 22.75 6.07 22.75 12C22.75 17.93 17.93 22.75 12 22.75ZM12 2.75C6.9 2.75 2.75 6.9 2.75 12C2.75 17.1 6.9 21.25 12 21.25C17.1 21.25 21.25 17.1 21.25 12C21.25 6.9 17.1 2.75 12 2.75Z" fill="#616972"/>
          <path d="M13.2599 16.28C13.0699 16.28 12.8799 16.21 12.7299 16.06L9.19988 12.53C9.0604 12.3889 8.98218 12.1984 8.98218 12C8.98218 11.8016 9.0604 11.6111 9.19988 11.47L12.7299 7.94C13.0199 7.65 13.4999 7.65 13.7899 7.94C14.0799 8.23 14.0799 8.71 13.7899 9L10.7899 12L13.7899 15C14.0799 15.29 14.0799 15.77 13.7899 16.06C13.7217 16.1315 13.6394 16.1881 13.5481 16.2259C13.4568 16.2638 13.3587 16.2822 13.2599 16.28Z" fill="#616972"/>
        </svg>
        </div>
      </li>
      
      <li
        className={classnames('pagination-item', {
          disabled: currentPage === lastPage,
        })}
        onClick={onNext}
      >
        <div className="arrow right" >
        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
          <path d="M12 22.75C17.93 22.75 22.75 17.93 22.75 12C22.75 6.07 17.93 1.25 12 1.25C6.07 1.25 1.25 6.07 1.25 12C1.25 17.93 6.07 22.75 12 22.75ZM12 2.75C17.1 2.75 21.25 6.9 21.25 12C21.25 17.1 17.1 21.25 12 21.25C6.9 21.25 2.75 17.1 2.75 12C2.75 6.9 6.9 2.75 12 2.75Z" fill="#616972"/>
          <path d="M10.7401 16.28C10.9301 16.28 11.1201 16.21 11.2701 16.06L14.8001 12.53C14.9396 12.3889 15.0178 12.1984 15.0178 12C15.0178 11.8016 14.9396 11.6111 14.8001 11.47L11.2701 7.94C10.9801 7.65 10.5001 7.65 10.2101 7.94C9.92012 8.23 9.92012 8.71 10.2101 9L13.2101 12L10.2101 15C9.92012 15.29 9.92012 15.77 10.2101 16.06C10.2783 16.1315 10.3606 16.1881 10.4519 16.2259C10.5432 16.2638 10.6413 16.2822 10.7401 16.28Z" fill="#616972"/>
        </svg>
        </div>
      </li>
    </ul>
  );
};

export default Pagination;
