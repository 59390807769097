import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { styled } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Card from '@mui/material/Card';
// Components
import GameCard from './GameCard';
import GameProviderHeader from './GameProviderHeader';
// Models
import GameModel from '../@types/models/GameModel';
// SVG
import {
  EGamesViewSvg,
  ArrowRightSvg,
  WEGamesSvg,
  WEGamesViewSvg,
  RealTimeGamViewsSvg,
} from '@/shared/svg';

// TODO move this to MUI-Theme
const themeColor = process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';

const BorderCard = styled(Card)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  margin: 0,
  backgroundColor: 'transparent',
  // backgroundImage: 'url("https://arionplay-media.s3.ap-southeast-1.amazonaws.com/backgrounds/slots_640.jpg")',
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  borderRadius: '10px',
  border: `1px solid ${themeColor}`,
  // '&:hover': {
  //     backdropFilter: 'blur(5px)',
  //   '.MuiPaper-root': {
  //   },
  // },
}));

const StyledStack = styled(Stack)(({ theme }) => ({
  paddingBottom: 1,
  overflow: 'auto',
  flexWrap: 'nowrap',
  [theme.breakpoints.up('md')]: {
    flexWrap: 'wrap',
  },
}));

type Props = {
  title: string,
  games: null | Array<GameModel>,
  onClick?: (gameId: string) => void,
};

const GamesCard = ({title, games, onClick}: Props) => {
  const imgSize = 100;
  return (
    <BorderCard sx={{ p: 2 }}>
      <Typography variant="subtitle1" paragraph={true} style={{ color: '#A3ADC2'}}>
        {title}
      </Typography>
      {
        (games && games.length) && (
          <StyledStack direction="row" spacing={0} sx={{ gap: 1 }}>
            {games.map((gameModel: GameModel, index: number) => (
              <div key={`${gameModel.id}-${index}`} style={{ width: imgSize+2 }}>
                <GameCard
                  gameModel={gameModel}
                  size={imgSize}
                  onClick={onClick}
                  displayName={false}
                  displayCategory={false}
                />
              </div>
            ))}
          </StyledStack>
        )
      }
    </BorderCard>
  );
};

export default GamesCard;
