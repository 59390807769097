import React, { useEffect, useState } from 'react';
import ForgotPasswordForm from './forgotform';
import ForgotOtp from './forgototp';
import ForgotSuccess from './forgotsuccess';

interface ChildProps {
  parentModalHandlerFunction: any;
  closePopup: any;
}

const ForgotForm = (props: ChildProps) => {
  const [displayStep, setDisplayStep] = useState('forgotform');
  const changeSteps = (moduleName?: string) => {
    if (moduleName === 'login') {
      props.parentModalHandlerFunction('login');
      return;
    }
    setDisplayStep(moduleName);
  };
  useEffect(() => {
    setDisplayStep('forgotform');
  }, []);
  const closePopup = () => {
    props.closePopup('close');
  };
  return (
    <>
      {displayStep === 'forgotform' && (
        <ForgotPasswordForm changeSteps={changeSteps} />
      )}
      {displayStep === 'forgototp' && <ForgotOtp changeSteps={changeSteps} />}
      {displayStep === 'forgotsuccess' && (
        <ForgotSuccess changeSteps={changeSteps} closePopup={closePopup} />
      )}
    </>
  );
};

export default ForgotForm;
