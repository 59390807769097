import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import AuthService from '@/services/AuthService';

const initialState: IRegisterOTP = {
  regOTPData: undefined,
  isRegOTPLoading: false,
  isRegOTPSuccess: undefined,
  isRegOTPError: undefined,
  regErrorMessage: undefined,
};

export const getOtp = createAsyncThunk(
  'otp',
  async (userData: any, thunkAPI) => {
    try {
      const response = await AuthService.getOTP(userData);
      if (!response.status) return thunkAPI.rejectWithValue(response.data);
      else return response;
    } catch (error) {
      return thunkAPI.rejectWithValue(error);
    }
  }
);

const registerOTPSlice = createSlice({
  name: 'regotp',
  initialState,
  reducers: {
    setRegOTPDefaultData: state => {
      state.isRegOTPSuccess = undefined;
    },
    resetRegOTP: state => {
      Object.assign(state, initialState);
    },
  },
  extraReducers(builder): void {
    builder.addCase(getOtp.pending, state => {
      state.isRegOTPLoading = true;
    });
    builder.addCase(getOtp.fulfilled, (state, action) => {
      state.isRegOTPSuccess = true;
      state.isRegOTPLoading = true;
      state.regOTPData = action.payload.data;
    });
    builder.addCase(getOtp.rejected, (state, action: any) => {
      state.isRegOTPLoading = false;
      state.isRegOTPError = true;
      state.regErrorMessage = action.payload;
    });
  },
});
export const { setRegOTPDefaultData, resetRegOTP } = registerOTPSlice.actions;
export const registerOTPReducer = registerOTPSlice.reducer;
