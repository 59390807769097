import React, { useState, useEffect } from 'react';
import {
  StyledAllSelect,
  StyledBoxSelect,
  StyledContainerToteHead,
  StyledContainerToteHeader,
  StyledContainerTotepools,
  StyledToteMultLeg,
  StyledToteSelectAll,
  StledHorseDetails,
  StyledBoxSelectSno,
  StyledAllSelectWin,
  StyledAllSelectPlc,
} from '@/pages/betting/style';

// import { useAppDispatch } from '@/hooks/useAppDispatch';
// import { useAppSelector } from '@/hooks/useAppSelector';
// import RacingService from '@/services/RacingService';
import { Checkbox } from '@/components';

type RunnerHeader = {
  poolsActive: any;
  selectedPool: string;
  changeSelectedPool: Function;
  changeSelectedLeg: Function;
  selectedRaceNumber: number;
  selectedLeg: number;
  onClickBox: Function;
  onClickAll: Function;
  positions: number;
  boxOption: boolean;
  legs: number;
  betType: string;
};

const RunnerHeader: React.FC<RunnerHeader> = ({
  poolsActive,
  selectedPool,
  changeSelectedPool,
  selectedLeg,
  changeSelectedLeg,
  selectedRaceNumber,
  positions,
  onClickBox,
  onClickAll,
  boxOption,
  legs,
  betType
}) => {
  const [numberOfPositions, setNumberOfPositions] = useState(1);

  useEffect(() => {
    let pos = 1;
    if (!boxOption) pos = positions;
    setNumberOfPositions(pos);
  }, [positions, boxOption]);

  const onBoxClick = (e: { target: any }) => {
    onClickBox(e.target.checked);
  };

  const clickAllOption = (position: number, e: { target: any }) => {
    onClickAll(position, e.target.checked);
  };

  return (
    <>
      {betType === 'tote' && (
      <StyledContainerToteHeader>
        <ul>
          {Object.keys(poolsActive)
            .filter(index => poolsActive[index].type !== 'fixed')
            .map((index: any, pool: any) => {
              return (
                <StyledContainerTotepools key={index}>
                  <li
                    key={index}
                    onClick={() =>
                      changeSelectedPool(poolsActive[index].code.toLowerCase())
                    }
                    className={`${
                      selectedPool === poolsActive[index].code.toLowerCase()
                        ? 'selected'
                        : ''
                    }`}
                  >
                    <span className='clickspan'>
                      <span className='pool_name'>
                        {poolsActive[index].name}
                      </span>
                      <span className='pool_place'>
                        {poolsActive[index].shortDesc}
                      </span>
                    </span>
                  </li>
                </StyledContainerTotepools>
              );
            })}
        </ul>
      </StyledContainerToteHeader>
      )}
      <StyledContainerToteHead>
        {betType === 'tote' && legs > 1 && (
          <StyledToteMultLeg>
            <ul>
              {[...Array(legs)].map((x, i) => (
                <li
                  onClick={() => changeSelectedLeg(i + 1)}
                  key={i}
                  className={`${selectedLeg == i + 1 ? 'selected' : ''}`}
                >
                  <span>R{i + 1}</span>
                </li>
              ))}
            </ul>
          </StyledToteMultLeg>
        )}
        {betType === 'tote' && (
        <StyledToteSelectAll>
          <StyledBoxSelect>
            {positions !== 1 && (
              <Checkbox
                id='checkboxBox'
                name='checkboxBox'
                onChange={event => onBoxClick(event)}
                label='BOX'
              />
            )}
          </StyledBoxSelect>
          <StyledAllSelect>
            <div className='checkboxselect'>
            {[...Array(numberOfPositions)].map((x, i) => (
              <Checkbox
                id={`checkbox_${i}`}
                name={`checkbox_${i}`}
                label='All'
                onChange={event => clickAllOption(i, event)}
                key={i}
              />
            ))}
            </div>
          </StyledAllSelect>
        </StyledToteSelectAll>
        )}
         {betType === 'fixed' && (
            <StyledToteSelectAll>
              <StyledBoxSelectSno>
               S. No.
              </StyledBoxSelectSno>
              <StledHorseDetails>
                Horse Details
              </StledHorseDetails>
              <StyledAllSelectWin>
                <div className='fixedbetheader'>
                  <span>WIN</span>
                  <span>PLC</span>
                </div>
              </StyledAllSelectWin> 
              {/* <StyledAllSelectPlc>
                PLC
              </StyledAllSelectPlc>  */}
            </StyledToteSelectAll>
         )}
      </StyledContainerToteHead>
    </>
  );
};

export default RunnerHeader;
