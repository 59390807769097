import { forwardRef } from 'react';
import classnames from 'clsx';
import { StyledRadio, StyledRadioLabel } from './style';
import { StyledBoxWrap } from '@/components/forms/form-elements/style';
import Feedback from '@/components/forms/form-elements/feedback';
import { IInputProps } from '@/components/forms/form-elements/types';
import React from 'react';

interface IProps extends IInputProps {
  checked?: boolean;
  label: string;
}

const Radio = forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      disabled,
      feedbackText,
      id,
      name,
      label,
      state,
      checked,
      onChange,
      onClick,
      onBlur,
      value,
      ...restProps
    },
    ref
  ) => {
    return (
      <StyledBoxWrap
        className={classnames(className, 'custom-radio')}
        {...restProps}
      >
        <StyledRadio
          type='radio'
          disabled={disabled}
          id={id}
          name={name}
          checked={checked}
          value={value}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          ref={ref}
        />
        <StyledRadioLabel htmlFor={id}>{label}</StyledRadioLabel>
        {feedbackText && <Feedback state={state}>{feedbackText}</Feedback>}
      </StyledBoxWrap>
    );
  }
);

Radio.displayName = 'Radio';

export default Radio;
