import React, { useEffect, useState } from 'react';
import { Input, Label } from '@/components';
import {
  StyledPlaceBetView,
  StyledOverLay,
  StyledBetContaiiner,
  StyledBetSlipHead,
  StyledBetSlipButton,
  StyledBetSlipbtn,
  StyledButtonAmount,
  StyledCustomAmount,
} from './style';
import { CloseIconSvg } from '@/shared/svg/index';
import { useForm } from 'react-hook-form';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  parentHandlerFunction: Function;
  parentCloseHandlerFunction: Function;
  unitBet: number;
}

const BetAmount = (props: ChildProps) => {
  const [amount, setAmount] = useState(10);
  const [isError, setIsError] = useState(false);
  const { register, setValue } = useForm();

  const handleChange = (e: { target: any }) => {
    const remainder = e.target.value % props.unitBet;
    if (remainder === 0) setValue('custom_amount', e.target.value);
    else setIsError(false);
  };

  const handleAmount = () => {
    const remainder = amount % props.unitBet;
    console.log(amount+"=="+props.unitBet);
    props.parentHandlerFunction(amount);
    // if (remainder === 0) props.parentHandlerFunction(amount);
    // else setIsError(false);
  };
  useEffect(() => {
    setValue('custom_amount', 10);
  }, []);

  useEffect(() => {
    setValue('custom_amount', amount);
  }, [amount]);
  return (
    <StyledPlaceBetView>
      <StyledOverLay></StyledOverLay>
      <StyledBetContaiiner>
        <button
          className='closebtn'
          type='button'
          onClick={() => props.parentCloseHandlerFunction()}
          data-dismiss='modal'
          aria-label='Close'
        >
          <CloseIconSvg />
        </button>
        <StyledBetSlipHead>
          <h4>Bet Amount</h4>
          <StyledButtonAmount>
            <button
              className={`amounttext ${amount === 10 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(10)}
            >
              <span>&#8369;</span> 10
            </button>
            <button
              className={`amounttext ${amount === 20 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(20)}
            >
              <span>&#8369;</span> 20
            </button>
            <button
              className={`amounttext ${amount === 50 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(50)}
            >
              <span>&#8369;</span> 50
            </button>
            <button
              className={`amounttext ${amount === 100 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(100)}
            >
              <span>&#8369;</span> 100
            </button>
            <button
              className={`amounttext ${amount === 200 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(200)}
            >
              <span>&#8369;</span> 200
            </button>
            <button
              className={`amounttext ${amount === 500 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(500)}
            >
              <span>&#8369;</span> 500
            </button>
            <button
              className={`amounttext ${amount === 1000 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(1000)}
            >
              <span>&#8369;</span> 1000
            </button>
            <button
              className={`amounttext ${amount === 2000 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(2000)}
            >
              <span>&#8369;</span> 2000
            </button>
            <button
              className={`amounttext ${amount === 5000 ? 'active' : ''}`}
              id=''
              onClick={() => setAmount(5000)}
            >
              <span>&#8369;</span> 5000
            </button>
          </StyledButtonAmount>
          <StyledCustomAmount>
            <Label display='block' mb='5px' htmlFor='email'>
              Custom Amount
            </Label>
            <Input
              className='mobilenumber'
              id='custom_amount'
              name='custom_amount'
              onChange={handleChange}
              {...register('custom_amount')}
              placeholder='Custom Amount'
            />
          </StyledCustomAmount>
        </StyledBetSlipHead>
        <StyledBetSlipButton>
          <StyledBetSlipbtn>
            {!isError && (
              <button
                className='yeare_button'
                id='age_consent'
                onClick={() => handleAmount()}
              >
                Continue
              </button>
            )}
          </StyledBetSlipbtn>
        </StyledBetSlipButton>
      </StyledBetContaiiner>
    </StyledPlaceBetView>
  );
};

export default BetAmount;
