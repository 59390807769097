import React from 'react';
import {
  StyledCard,
  StyledCardImgContainer,
  StyledCardBodyContainer,
  StyledCardBodyTitle,
  StyledCardBodyContent,
  StyledCardButtonContainer,
  StyledBardFooterContainer,
  StyledRtgHoverContainer,
} from './style';
import { PlaySvg,FlagImgIconSvg } from '@/shared/svg';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import CookieStorageService from '@/services/CookieStorageService';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
interface childProps {
  id: string;
  image: string;
  venueName: string;
  currentRaceNumber: string;
  totalEvents: string;
  mtp: number;
  desc: string;
  subtitle: string;
}
const TripleCrown = (props: childProps) => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const openInfoPopup = async (id: string, name: string) => {
    console.log(name);
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token === undefined || token == '') {
      dispatch(changeModule('login'));
      return false;
    }
    history(`racing/${id}`);
    // if (props.name.includes('Sports') && props.subtitle.includes('Tote'))
    //   history(`horseracingtote`);
    // else if (props.name.includes('Sports') && props.subtitle.includes('Fixed'))
    //   history(`/horseracingfixed`);
    // else history(`horseracingtote`);

    //else history(`game/we/${id}`);
    // if(props.name.includes('Sports') && props.subtitle.includes('Tote'))
    //   window.open(`horseracingtote`, '_self');
    // else if(props.name.includes('Sports') && props.subtitle.includes('Fixed'))
    //   window.open(`horseracingfixed`, '_self');
    // else
    //   window.open(`game/we/${id}`, '_self');
  };
  return (
    <StyledCard>
      <Link
        className='flex'
        to={''}
        //onClick={() => openInfoPopup(props.id, props.name)}
      >
        <StyledCardImgContainer>
          <img
            src={`${process.env.REACT_APP_GAME_S3_PATH}tri/noimage/tri-no-image.png`}
            alt=''
          />
        </StyledCardImgContainer>
        <StyledRtgHoverContainer>
          <span className='hoverview'>
            <button
              className='playnow_btn'
              type='button'
              onClick={() => openInfoPopup(props.id, props.venueName)}
            >
              <PlaySvg />
              <small>Play Now</small>
            </button>
          </span>
        </StyledRtgHoverContainer>
        <StyledCardBodyContainer>
          <StyledCardBodyTitle>
            <FlagImgIconSvg/>
            <p>{props.venueName}</p>
          </StyledCardBodyTitle>
        </StyledCardBodyContainer>
        <StyledBardFooterContainer>
          <p>Race {props.currentRaceNumber} / <span>{props.totalEvents}</span></p>
          <button className='time'>{props.mtp >0 ? props.mtp:'0'}<span>m</span></button>
        </StyledBardFooterContainer>
      </Link>
    </StyledCard>
  );
};

export default TripleCrown;
