/* eslint-disable no-useless-escape */
import {
  FormGroup,
  Label,
  Input,
  Button,
  Select,
  InputGroup,
  ButtonSpinner,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { hasKey } from '@/shared/methods';
import React, { useEffect, useRef, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledSteps,
  StyledPersonalContainer,
} from './style';
import { StarIconSvg } from '@/shared/svg';
//import { useDispatch } from 'react-redux';
//import { useAppSelector } from '@/hooks/useAppSelector';
import DatePicker from 'react-multi-date-picker';
import type { Value } from 'react-multi-date-picker';
import moment from 'moment/moment';
import AuthService from '@/services/AuthService';

interface ChildProps {
  changeSteps: any;
}

interface IDatePickerProps {
  onFocus: any;
  value: any;
  onChange: any;
}

function CustomInput(props: IDatePickerProps) {
  return (
    <input
      onFocus={props.onFocus}
      value={props.value}
      onChange={props.onChange}
      readOnly
      className={`dob_btn`}
      onKeyDown={e => e.preventDefault}
    />
  );
}

const PersonalForm = (props: ChildProps) => {
  //const dispatch = useDispatch();
  const [maxDate, setMaxDate] = useState('');
  const [maxValue, setMaxValue] = useState('');
  const [loading, setLoading] = useState(false);
  const [soureIncome, setSourceIncome] = useState('');
  const [occupation, setOccupation] = useState('');
  const [userInfo, setUserInfo] = useState<IUser>(null);
  //const { regData } = useAppSelector(state => state.register);
  const [nationality, setNationality] = useState('');
  const [income, setIncome] = useState('');
  const [dob, setDob] = useState<Value>('');
  const datePickerRef = useRef<any>();
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IUser>();

  const handleSoureIncomeValue = (e: any) => {
    setSourceIncome(e.target.value);
  };

  const handleNationalityValue = (e: any) => {
    setValue('nationality', e.target.value);
    setNationality(e.target.value);
  };

  const handleOccupationValue = (e: any) => {
    setOccupation(e.target.value);
  };

  const handleIncomeValue = (e: any) => {
    console.log(income);
    setIncome(e.target.value);
  };

  const handleDobValue = (date: any) => {
    const valueOfInput = date;

    const currentDate = new Date(valueOfInput.toString());
    // const timezoneOffsetMinutes = currentDate.getTimezoneOffset();
    // const timezoneOffsetHours = Math.abs(timezoneOffsetMinutes) / 60;
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
    const dob = new Date(formattedDate);
    setDob(dob);
  };

  const onSubmit: SubmitHandler<IUser> = async data => {
    setLoading(true);
    if (dob != undefined && dob != '') {
      data.dob = new Date(dob.toString());
    }

    if (data.sourceOfIncome === 'others') {
      data.sourceOfIncome = data.otherIncome;
      delete data.otherIncome;
    }
    const response = await AuthService.updateUserProfile(data);
    console.log(response);
    props.changeSteps('address');
  };

  const getMaxDate = (val: any) => {
    const start = moment().add(-21, 'y');
    console.log(start);
    const maxDate1 = moment(start).format('YYYY/MM/DD');
    const maxDate2 = moment(val, 'YYYY-MM-DD').format('YYYY/MM/DD');
    setMaxDate(maxDate1);
    setMaxValue(maxDate2);
  };
  const nationalityDropdownList = [
    'afghan',
    'albanian',
    'algerian',
    'american',
    'andorran',
    'angolan',
    'antiguans',
    'argentinean',
    'armenian',
    'australian',
    'austrian',
    'azerbaijani',
    'bahamian',
    'bahraini',
    'bangladeshi',
    'barbadian',
    'barbudans',
    'batswana',
    'belarusian',
    'belgian',
    'belizean',
    'beninese',
    'bhutanese',
    'bolivian',
    'bosnian',
    'brazilian',
    'british',
    'bruneian',
    'bulgarian',
    'burkinabe',
    'burmese',
    'burundian',
    'cambodian',
    'cameroonian',
    'canadian',
    'cape verdean',
    'central african',
    'chadian',
    'chilean',
    'chinese',
    'colombian',
    'comoran',
    'congolese',
    'costa rican',
    'croatian',
    'cuban',
    'cypriot',
    'czech',
    'danish',
    'djibouti',
    'dominican',
    'dutch',
    'east timorese',
    'ecuadorean',
    'egyptian',
    'emirian',
    'equatorial guinean',
    'eritrean',
    'estonian',
    'ethiopian',
    'fijian',
    'filipino',
    'finnish',
    'french',
    'gabonese',
    'gambian',
    'georgian',
    'german',
    'ghanaian',
    'greek',
    'grenadian',
    'guatemalan',
    'guinea-bissauan',
    'guinean',
    'guyanese',
    'haitian',
    'herzegovinian',
    'honduran',
    'hungarian',
    'icelander',
    'indian',
    'indonesian',
    'iranian',
    'iraqi',
    'irish',
    'israeli',
    'italian',
    'ivorian',
    'jamaican',
    'japanese',
    'jordanian',
    'kazakhstani',
    'kenyan',
    'kittian and nevisian',
    'kuwaiti',
    'kyrgyz',
    'laotian',
    'latvian',
    'lebanese',
    'liberian',
    'libyan',
    'liechtensteiner',
    'lithuanian',
    'luxembourger',
    'macedonian',
    'malagasy',
    'malawian',
    'malaysian',
    'maldivan',
    'malian',
    'maltese',
    'marshallese',
    'mauritanian',
    'mauritian',
    'mexican',
    'micronesian',
    'moldovan',
    'monacan',
    'mongolian',
    'moroccan',
    'mosotho',
    'motswana',
    'mozambican',
    'namibian',
    'nauruan',
    'nepalese',
    'new zealander',
    'ni-vanuatu',
    'nicaraguan',
    'nigerien',
    'north korean',
    'northern irish',
    'norwegian',
    'omani',
    'pakistani',
    'palauan',
    'panamanian',
    'papua new guinean',
    'paraguayan',
    'peruvian',
    'polish',
    'portuguese',
    'qatari',
    'romanian',
    'russian',
    'rwandan',
    'saint lucian',
    'salvadoran',
    'samoan',
    'san marinese',
    'sao tomean',
    'saudi',
    'scottish',
    'senegalese',
    'serbian',
    'seychellois',
    'sierra leonean',
    'singaporean',
    'slovakian',
    'slovenian',
    'solomon islander',
    'somali',
    'south african',
    'south korean',
    'spanish',
    'sri lankan',
    'sudanese',
    'surinamer',
    'swazi',
    'swedish',
    'swiss',
    'syrian',
    'taiwanese',
    'tajik',
    'tanzanian',
    'thai',
    'togolese',
    'tongan',
    'trinidadian or tobagonian',
    'tunisian',
    'turkish',
    'tuvaluan',
    'ugandan',
    'ukrainian',
    'uruguayan',
    'uzbekistani',
    'venezuelan',
    'vietnamese',
    'welsh',
    'yemenite',
    'zambian',
    'zimbabwean',
  ];

  const getUserInfo = async () => {
    const userData = await AuthService.getUserData();
    if (userData.status) {
      setUserInfo(userData.data);

      if (userData.data.nationality === undefined) {
        setValue('nationality', 'filipino');
        setNationality('filipino');
      } else {
        setValue('nationality', userData.data.nationality);
        setNationality(userData.data.nationality);
      }

      const {
        email,
        firstName,
        middleName,
        lastName,
        phone,
        occupation,
        income,
        sourceOfIncome,
        dob,
      } = userData.data;
      setValue('firstName', firstName);
      setValue('middleName', middleName);
      setValue('lastName', lastName);
      setValue('email', email);
      setValue('phone', phone);
      setValue('dob', dob);
      setValue('occupation', occupation);
      setValue('income', income);
      setValue('sourceOfIncome', sourceOfIncome);
      getMaxDate(userData.data.dob);
      if (
        userData.data.sourceOfIncome != undefined &&
        userData.data.sourceOfIncome != 'pension' &&
        userData.data.sourceOfIncome != 'business' &&
        userData.data.sourceOfIncome != ''
      ) {
        setSourceIncome('others');
        setValue('sourceOfIncome', 'others');
        setValue('otherIncome', userData.data.sourceOfIncome);
      }
      setOccupation(userData.data.occupation);
      setValue('otheroccupation', userData.data.otheroccupation);
    }
  };

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <StyledWrap>
      <StyledTitle>KYC Verification</StyledTitle>
      <StyledDesc>
        Please share the following details to verify your identity
      </StyledDesc>
      <StyledSteps>
        <h4>
          <span>Step 1/3</span>&nbsp;- Add your Personal Details
        </h4>
        <p>
          <StarIconSvg />
          Fields are mandatory
        </p>
        <h6>
          -Please ensure below details match those listed on your valid govt ID
        </h6>
      </StyledSteps>
      <form
        action='#'
        className='loginform personalform'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        
        <div className='scrollheight'>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  First Name<span>*</span>
                </Label>
                <InputGroup>
                  <Input
                    id='firstName'
                    type='text'
                    name='firstName'
                    placeholder='Enter your First Name'
                    feedbackText={errors?.firstName?.message}
                    state={hasKey(errors, 'firstName') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'firstName')}
                    {...register('firstName', {
                      required: 'First Name is required',
                      pattern: {
                        value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
                        message: 'Invalid name format',
                      },                      
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Middle Name
                </Label>
                <InputGroup>
                  <Input
                    id='middleName'
                    type='text'
                    name='middleName'
                    placeholder='Enter your Middle Name'
                    {...register('middleName')}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Last Name<span>*</span>
                </Label>
                <InputGroup>
                  <Input
                    id='lastName'
                    type='text'
                    name='lastName'
                    placeholder='Enter your Last Name'
                    feedbackText={errors?.lastName?.message}
                    state={hasKey(errors, 'lastName') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'lastName')}
                    {...register('lastName', {
                      required: 'Last Name is required',
                      pattern: {
                        value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
                        message: 'Invalid name format',
                      },                      
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Email Address<span>*</span>
                </Label>
                <InputGroup>
                  <Input
                    id='email'
                    type='text'
                    name='email'
                    placeholder='Enter your email'
                    feedbackText={errors?.email?.message}
                    state={hasKey(errors, 'email') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'email')}
                    {...register('email', {
                      required: 'Email is required',
                      pattern: {
                        value: /^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g,
                        message: 'Invalid email format',
                      },
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4} className='mobile_view'>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Phone Number<span>*</span>
                </Label>
                <InputGroup>
                  <Input
                    className='mobilenumber phonenonedit'
                    type='tel'
                    id='phone'
                    name='phone'
                    readonly
                    placeholder='Phone Number'
                    feedbackText={errors?.phone?.message}
                    state={hasKey(errors, 'phone') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'phone')}
                    {...register('phone')}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Date of Birth<span>*</span>
                </Label>
                <InputGroup>
                  <DatePicker
                    value={maxValue}
                    ref={datePickerRef}
                    format={'YYYY/MM/DD'}
                    maxDate={maxDate}
                    onChange={handleDobValue}
                    render={
                      <CustomInput
                        onFocus={undefined}
                        value={undefined}
                        onChange={undefined}
                      />
                    }
                  >
                    <button
                      className={`date_close`}
                      style={{ margin: '5px' }}
                      onClick={() => datePickerRef.current.closeCalendar()}
                    >
                      close
                    </button>
                  </DatePicker>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row mb='20px'>
            {/* <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Occupation<span>*</span>
                </Label>
                <InputGroup>
                  <Input
                    id='occupation'
                    type='text'
                    name='occupation'
                    placeholder='Enter your Occupation'
                    feedbackText={errors?.occupation?.message}
                    state={hasKey(errors, 'occupation') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'occupation')}
                    {...register('occupation', {
                      required: 'Occupation is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col> */}
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Occupation<span>*</span>
                </Label>
                <InputGroup>
                  <Select
                    id='occupation'
                    name='occupation'
                    className='select'
                    onChange={e => handleOccupationValue(e)}
                    feedbackText={errors?.occupation?.message}
                    state={hasKey(errors, 'occupation') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'occupation')}
                    {...register('occupation', {
                      required: 'Occupation is required',
                    })}
                  >
                    <option value=''>Select Occupation</option>
                    <option value='employee'>Employee</option>
                    <option value='self-employed'>Self-employed</option>
                    <option value='others'>Others</option>
                  </Select>
                  {occupation === 'others' && (
                    <Input
                      id='otheroccupation'
                      type='text'
                      name='otheroccupation'
                      className='otherincomeinput'
                      placeholder='Enter your Occupation'
                      feedbackText={errors?.otheroccupation?.message}
                      state={
                        hasKey(errors, 'otheroccupation') ? 'error' : 'success'
                      }
                      showState={!!hasKey(errors, 'otheroccupation')}
                      {...register('otheroccupation', {
                        required: 'Occupation is required',
                        pattern: {
                          value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
                          message: 'Only Alphabets allowed',
                        },
                      })}
                    />
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Source of income<span>*</span>
                </Label>
                <InputGroup>
                  <Select
                    id='sourceOfIncome'
                    name='sourceOfIncome'
                    className='select'
                    feedbackText={errors?.sourceOfIncome?.message}
                    state={
                      hasKey(errors, 'sourceOfIncome') ? 'error' : 'success'
                    }
                    showState={!!hasKey(errors, 'sourceOfIncome')}
                    {...register('sourceOfIncome', {
                      required: 'source Of Income is required',
                    })}
                    onChange={e => handleSoureIncomeValue(e)}
                  >
                    <option value=''>Select Source of Income</option>
                    <option value='salary'>Salary</option>
                    <option value='business'>Business</option>
                    <option value='pension'>Pension</option>
                    <option value='others'>others</option>
                  </Select>
                  {soureIncome === 'others' && (
                    <Input
                      id='otherIncome'
                      type='text'
                      name='otherIncome'
                      className='otherincomeinput'
                      placeholder='Enter your Source Of income'
                      feedbackText={errors?.otherIncome?.message}
                      state={
                        hasKey(errors, 'otherIncome') ? 'error' : 'success'
                      }
                      showState={!!hasKey(errors, 'otherIncome')}
                      {...register('otherIncome', {
                        required: 'Source Of Income is required',
                        pattern: {
                          value: /^[a-zA-Z]+(([',. -][a-zA-Z ])?[a-zA-Z]*)*$/g,
                          message: 'Only Alphabets allowed',
                        },
                      })}
                    />
                  )}
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Income<span>*</span>
                </Label>
                <InputGroup>
                  <Select
                    id='income'
                    name='income'
                    className='select'
                    feedbackText={errors?.income?.message}
                    state={hasKey(errors, 'income') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'income')}
                    {...register('income', {
                      required: 'Income is required',
                    })}
                    onChange={e => handleIncomeValue(e)}
                  >
                    <option value=''>Select Income</option>
                    <option value='Less than 11000 &#8369;'>
                      Less than 11,000 &#8369;
                    </option>
                    <option value='11000 &#8369; -21000 &#8369;'>
                      11,000 &#8369; - 21,000 &#8369;
                    </option>
                    <option value='21000 &#8369; -44000 &#8369;'>
                      21,000 &#8369; - 44,000 &#8369;
                    </option>
                    <option value='44000 &#8369; -76000 &#8369;'>
                      44,000 &#8369; - 76,000 &#8369;
                    </option>
                    <option value='76000 &#8369; -132000 &#8369;'>
                      76,000 &#8369; - 132,000 &#8369;
                    </option>
                    <option value='132000 &#8369; -220000 &#8369;'>
                      132,000 &#8369; - 220,000 &#8369;
                    </option>
                    <option value='Above 220000 &#8369;'>above 220,000 &#8369;</option>
                  </Select>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
          <Row>
            <Col md={4}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Nationality<span>*</span>
                </Label>
                <InputGroup>
                  <Select
                    id='nationality'
                    name='nationality'
                    value={nationality}
                    className='select'
                    onChange={e => handleNationalityValue(e)}
                    {...(register('nationality'),
                    {
                      required: 'Nationality is required',
                    })}
                  >
                    {nationalityDropdownList.map((item: any, index: number) => (
                      <option key={index} value={item}>
                        {item}
                      </option>
                    ))}
                  </Select>
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>
        </div>
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null} Continue
          </Button>
        </StyledPersonalContainer>
      </form>
    </StyledWrap>
  );
};

export default PersonalForm;
