/*
* Simple Search
*
* This component provide a functionality to input search phrase
* and display number of filtered items.
*/

import React from 'react';
// @mui
import { styled, alpha } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import InputAdornment from '@mui/material/InputAdornment';
// @mui icons
import SearchIcon from '@mui/icons-material/Search';

const SearchDiv = styled('div')(({ theme }) => ({
  width: '100%',
  position: 'relative',
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  marginLeft: 0,
  color: '#9cacc9',
  borderRadius: 8,
  border: '1px solid #9cacc933',
  backgroundColor: '#1b1f25',
  '&:hover': {
    // backgroundColor: alpha(theme.palette.common.white, 0.95),
    backgroundColor: '#2b2f35',
  },
  // borderRadius: theme.shape.borderRadius,
  // backgroundColor: alpha(theme.palette.common.white, 0.85),
  // [theme.breakpoints.up('sm')]: {
  //   marginLeft: theme.spacing(1),
  //   width: 'auto',
  // },
}));

const SearchIconWrapperDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    // width: '100%',
    // [theme.breakpoints.up('sm')]: {
    //   width: '12ch',
    //   '&:focus': {
    //     width: '20ch',
    //   },
    // },
    width: '14ch',
    '&:focus': {
      width: '20ch',
    },
  },
}));

const NumberResultWrapperDiv = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  right: 0,
  pointerEvents: 'none',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  paddingLeft: 12,
  borderLeft: '1px solid #9cacc922',
}));

type Props = {
  onFilter: (gameName: string) => void,
  size?: null | number,
};

const SimpleSearch = ({ onFilter, size }: Props) => {
  return (
    <SearchDiv>
      <SearchIconWrapperDiv>
        <SearchIcon />
      </SearchIconWrapperDiv>
      <StyledInputBase
        placeholder="Search"
        inputProps={{ 'aria-label': 'search' }}
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
          onFilter(event.target.value.toLowerCase().trim());
        }}
      />
      {size ?
        <NumberResultWrapperDiv>
          {size}
        </NumberResultWrapperDiv>:
        null
      }
    </SearchDiv>
  );
};

export default SimpleSearch;
