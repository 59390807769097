import React from 'react';
import {
  StyledAgeContainer,
  StyledAgeOverLay,
  StyledOverlayContainer,
  StyledOverlayHead,
  StyledOverlayBody,
  StyledOverlatTextView,
  StyledAgeButtonContainer,
} from './style';
import { AgeVerifySvg } from '@/shared/svg/index';
import { Link } from 'react-router-dom';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const AgeVerification = ({ onClick }: ChildProps) => {
  return (
    <StyledAgeContainer>
      <StyledAgeOverLay></StyledAgeOverLay>
      <StyledOverlayContainer>
        <StyledOverlayHead>
          <Link to=''>
            <img
              height="40px"
              src={process.env.REACT_APP_THEME_LOGO}
              alt=''
            />
          </Link>
          <Link to=''>
            <img
              className='agepopupimg'
              src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/PAGCORLogo.svg`}
              alt=''
            />
          </Link>
        </StyledOverlayHead>
        <StyledOverlayBody>
          <StyledOverlatTextView>
            <h4>Age Verification</h4>
            <AgeVerifySvg />
            <p>
              I hereby declare that I am 21 years of age and that I have read
              and accept the terms and condtions
            </p>
            {/* <p className='agree'>
              <Link to='/'>terms and condtions</Link>.
            </p> */}
            <StyledAgeButtonContainer>
              <button
                className='exit_button'
                id='exit_consent'
                onClick={onClick}
              >
                Decline
              </button>
              <button
                className='yeare_button'
                id='age_consent'
                onClick={onClick}
              >
                Accept
              </button>
            </StyledAgeButtonContainer>
          </StyledOverlatTextView>
        </StyledOverlayBody>
      </StyledOverlayContainer>
    </StyledAgeContainer>
  );
};

export default AgeVerification;
