import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import Marquee from 'react-fast-marquee';
// @mui
import SwipeableDrawer from '@mui/material/SwipeableDrawer';
import IconButton from '@mui/material/IconButton';
//
import { useAppSelector } from '@/hooks/useAppSelector';
import GameMenuHeader from './gamemenu';
import { PreLoginButtons } from './preloginbutton';
import { PostLoginButtons } from './postloginbutton';
import Dropdown from '@/components/ui/dropdown/dropdown';
import DropdownToggle from '@/components/ui/dropdown/dropdown-toggle';
import DropdownMenu from '@/components/ui/dropdown/dropdown-menu';
import DropdownItem from '@/components/ui/dropdown/dropdown-item';
import {
  WelcomeSvg,
  ClockSvg,
  LiveDealerSvg,
  SpecialgamesSvg,
  SportsBettingSvg,
  RealTimeGamesSvg,
  BetRulesSvg,
  TermsConditionsSvg,
  FAQsHelpSvg,
  NewsUpdatesSvg,
  HeaderPagcorSvg,
  HeaderDateIconSvg,
  UserLoginSvg,
  WEGamesSvg,
  EGamesViewSvg,
  EvolutionGamesSvg,
  MatchRacingSvg,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';
import {
  StyledHeaderWrapper,
  StyledHeaderContainer,
  StyledLogoContainer,
  StyledFlexContainer,
  StyledFlexContainer1,
  StyledWelcomeContainer,
  StyledMarqueeContainer,
  StyledParagraph,
  StyledLoginModalContainer,
  StyledKycDetails,
  StyledKycViewMobile,
  StyledHeaderMenu,
  StyledMenuView,
  StyledMenuHome,
  StyledMenuGames,
  StyledMenuPages,
  StyledDotSpace,
  StyledPagcorLog,
} from './style';

import Modal from '@/components/ui/modal/modal';
// Components
import ModalClose from '@/components/ui/modal/modal-close';
import ModalBody from '@/components/ui/modal/modal-body';
import AgeVerification from '@/components/AgeVerification';
import KycDetails from '@/components/KycDetails';
import MenuContext from '@/components/MenuContext'
//
import { changeModule as reducerChangeModule } from '@/redux/slices/module';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { HeaderMenuSvg, HomeiconMenuSvg } from '@/shared/svg';
import SigninForm from '@/pages/auth/signin';
import SignupForm from '@/pages/auth/signup';
import OTPForm from '@/pages/auth/otp';
import TermsForm from '@/pages/auth/terms';
import ForgotForm from '@/pages/auth/forgot';
import RegisterSuccess from '@/pages/auth/registersuccess';
import DepositForm from '@/pages/deposit';
import AgeSignup from '@/pages/auth/signup/age';
import KycForm from '@/pages/kyc';
import WithdrawForm from '@/pages/withdraw';
import Welcome from '@/pages/auth/signup/welcome';
import KYCVerified from '@/pages/auth/signup/kycverified';
import KYCReject from '@/pages/auth/signup/kycreject';
import KYCProgress from '@/pages/auth/signup/kycprogress';
import AuthService from '@/services/AuthService';
import moment from 'moment/moment';
import { StyledGameList } from '@/pages/home/style';
import GameService from '@/services/GameService';
//
const Header = () => {
  //
  const [openContextMenu, setOpenContextMenu] = useState<boolean>(false);
  //
  const [show, setShow] = useState(false);
  const [displayModule, setDisplayModule] = useState('');
  const [displayMenu, setDisplayMenu] = useState(false);
  const [currentDateTime, setCurrentDateTime] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoggedIn, setIsLoggedIn] = useState(false);
  const [password, setPassword] = useState('');
  const [screen, setScreen] = useState('');
  const [modalWidth, setModalWidth] = useState('lg');
  const [userInfo, setUserInfo] = useState<IUser>(null);
  const [gameList, SetGameList] = useState(null);
  const dispatch = useAppDispatch();
  const { isAuthenticated, isSuccess, isKYCStatus, isKYCPopup } =
    useAppSelector(state => state.auth);
  const { modalSize } = useAppSelector(state => state.setting);
  const { moduleName } = useAppSelector(state => state.module);

  const clickHandler1 = () => {
    console.log(isLoggedIn);
  };

  const clickHandler = (moduleName?: string) => {
    if (
      moduleName === 'login' ||
      moduleName === 'deposit' ||
      moduleName === 'withdraw' ||
      moduleName === 'kycwithdrawprocess' ||
      moduleName === 'kycsuccess' ||
      moduleName === 'defaultpageageconsent'
    )
      setModalWidth('md');
    if (
      moduleName === 'register' ||
      moduleName === 'kyc' ||
      moduleName === 'loginterms'
    )
      setModalWidth('lg');

    if (moduleName) {
      setDisplayModule(moduleName);
      setShow(prev => {
        if (prev) {
          dispatch(reducerChangeModule(null));
          document.body.classList.remove('doar-modal-open');
        } else {
          document.body.classList.add('doar-modal-open');
        }
        return !prev;
      });
    }
  };

  const changeModule = async (moduleName?: string) => {
    //const userData = await AuthService.getUserData();
    //alert('4');
    //setUserInfo(userData.data);

    setDisplayModule(moduleName);
    if (
      moduleName === 'signupageconsent' ||
      moduleName === 'signinageconsent' ||
      moduleName === 'defaultpageageconsent' ||
      moduleName === 'login' ||
      moduleName === 'kycsuccess' ||
      moduleName === 'kycreject' ||
      moduleName === 'kycprocess' ||
      moduleName === 'kycwithdrawprocess' ||
      moduleName === 'welcome' ||
      moduleName === 'kycverified'
    ) {
      setModalWidth('md');
    }
    if (moduleName === 'register') {
      setModalWidth('lg');
    }
  };

  const closePopup = () => {
    document.body.classList.remove('doar-modal-open');
    setShow(false);
  };

  const defaultAgeHandler = (e: { target: any }) => {
    console.log(e.target.id);
  };

  const signUpAgeHandler = (e: { target: any }) => {
    if (e.target.id === 'age_consent') changeModule('register');
    else setShow(false);
  };

  const signInAgeHandler = (e: { target: any }) => {
    if (e.target.id === 'age_consent') changeModule('login');
    else setShow(false);
  };

  const defaultPageAgeHandler = (e: { target: any }) => {
    if (e.target.id === 'age_consent') {
      CookieStorageService.setItem('accept_terms_age_consent', 'accept', 30);
      setShow(false);
      document.body.classList.remove('doar-modal-open');
    }
  };

  const openMenu = () => {
    setDisplayMenu(!displayMenu);
  };

  const getTimeAndZone = () => {
    const currentDate = new Date();

    const timezoneOffsetMinutes = currentDate.getTimezoneOffset();
    const timezoneOffsetHours = Math.abs(timezoneOffsetMinutes) / 60;
    const year = currentDate.getFullYear();
    const month = String(currentDate.getMonth() + 1).padStart(2, '0');
    const day = String(currentDate.getDate()).padStart(2, '0');
    const hours = String(currentDate.getUTCHours()).padStart(2, '0');
    const minutes = String(currentDate.getUTCMinutes()).padStart(2, '0');
    const seconds = String(currentDate.getUTCSeconds()).padStart(2, '0');

    const formattedDate = `${hours}:${minutes}:${seconds} (UTC+${timezoneOffsetHours})`;
    setCurrentDateTime(formattedDate);
  };

  const getGamesList = async () => {

    const response = await GameService.getGamesData();
    if (response.data.length > 0) {
      const groupByCategory = response.data
        .filter((game: any) => game.status === 'ACTIVE' && game.priority > 0)
        .sort((x: any, y: any) => {
          return y.priority - x.priority;
        })
        .reduce((group: any, game: any) => {
          const { providerId } = game;
          group[providerId] = group[providerId] ?? [];
          game.image = 'thumbnail_320x320.jpg';
          game.infoUrl = `${process.env.REACT_APP_CDN_IMAGES_PATH}/baccarat-factsheet.pdf`;
          group[providerId].push(game);
          return group;
        }, {});
      SetGameList(groupByCategory);
    }
  };

  const getGameName = (provider: string) => {
    const gameObj: any = GameService.getGameInfo(provider);
    return gameObj.name;
  };

  useEffect(() => {
    if (isSuccess) {
      clickHandler('popup');
    }
  }, [isSuccess]);

  useEffect(() => {
    if (isAuthenticated && isKYCStatus) {
      if (isKYCStatus === 'created') {
        setShow(true);
        changeModule('welcome');
      } else if (isKYCStatus === 'PENDING') {
        //setShow(true);
        //changeModule('kycprocess');
      } else if (isKYCStatus === 'DECLINED') {
        setShow(true);
        changeModule('kycreject');
      } else if (isKYCPopup) {
        setShow(false);
      } else if (!isKYCPopup) {
        setShow(true);
        changeModule('kycverified');
      }
    }
  }, [isAuthenticated, isKYCStatus]);

  useEffect(() => {
    if (isAuthenticated) {
      setIsLoggedIn(isAuthenticated);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (moduleName?.trim() !== '') {
      clickHandler(moduleName);
    }
  }, [moduleName]);

  useEffect(() => {
    // const interval = setInterval(() => {
    //   getTimeAndZone();
    // }, 1000);
    // return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    //setModalWidth(modalSize)
  }, [modalSize]);

  useEffect(() => {
    getGamesList();
  }, []);

  return (
    <>
      <StyledHeaderWrapper>
        {/* <GameMenuHeader /> */}
        {/* Do not take mobile space */}
        {/* <StyledKycViewMobile>
          <KycDetails />
        </StyledKycViewMobile> */}
        <StyledHeaderContainer>
          <StyledLogoContainer>

            <IconButton aria-label="menu" style={{ marginRight: 12 }} onClick={() => {
              setOpenContextMenu(true);
            }}>
              <HeaderMenuSvg />
            </IconButton>
            {/* <StyledHeaderMenu>
              <Dropdown>
                <DropdownToggle className='userloginbtn'>
                  <HeaderMenuSvg />
                </DropdownToggle>
                <DropdownMenu>
                  <StyledMenuView>
                    <StyledMenuHome>
                      <Link to=''>
                        <HomeiconMenuSvg />
                        <span>Home</span>
                      </Link>
                    </StyledMenuHome>
                    <StyledMenuGames>
                      <h4>GAMES</h4>
                      <ul>
                      <li>
                       <Link to='triplecrownview'>
                        <SportsBettingSvg/> Triple Crown
                       </Link>
                     </li>

                     <li>
                       <Link to=''>
                       <SpecialgamesSvg /> E-Games
                       </Link>
                     </li>
                     <li>
                       <Link to=''>
                        <SportsBettingSvg />
                         Sports Betting
                       </Link>
                     </li>
                     <li>
                       <Link to=''>
                        <RealTimeGamesSvg/>
                         Real Time Games
                       </Link>
                     </li>
                     {gameObjects[0]}


                        {gameList &&
                          Object.entries(gameList).map(
                            (gameObjects: any, index: number) => (
                              <li key={index}>
                                <Link to={`games/${gameObjects[0]}`}>
                                {gameObjects[0] == 'we' ? <WEGamesSvg /> : gameObjects[0] == 'evo' ? <EvolutionGamesSvg/> : gameObjects[0] == 'rtg' ? <RealTimeGamesSvg/> : ''}
                                  {getGameName(gameObjects[0])}
                                </Link>
                              </li>
                            )
                          )}
                      </ul>
                    </StyledMenuGames>
                    <StyledMenuPages>
                      <ul>
                        <li>
                          <Link to='/faqs'>
                            <FAQsHelpSvg /> FAQs & Help
                          </Link>
                        </li>
                        <li>
                          <Link to='/terms'>
                            <TermsConditionsSvg />
                            Terms & Conditions
                          </Link>
                        </li>
                        <li>
                          <Link to='/betrules'>
                            <BetRulesSvg />
                            Bet Rules
                          </Link>
                        </li>
                      </ul>
                    </StyledMenuPages>
                  </StyledMenuView>
                </DropdownMenu>
              </Dropdown>
            </StyledHeaderMenu> */}

            <Link to='/'>
              <strong className='desk_logo'>
                <img
                  height="40px"
                  src={process.env.REACT_APP_THEME_LOGO}
                  alt=''
                />
              </strong>
              <strong className='mobile_logo'>
                <img
                  src={process.env.REACT_APP_THEME_LOGO_MOBILE}
                  alt=''
                />
              </strong>
            </Link>
            <StyledDotSpace></StyledDotSpace>
            <StyledPagcorLog>
              <Link to=''>
                <HeaderPagcorSvg />
              </Link>
            </StyledPagcorLog>
            <StyledKycDetails>
              <KycDetails />
            </StyledKycDetails>
          </StyledLogoContainer>

          <StyledFlexContainer>
            {/* HERE SHALL BE HIS LAST WON OR THE BIGGEST ONE NOT A DUMMY DATE */}
            {/* <StyledWelcomeContainer>
              <StyledFlexContainer1>
                <StyledMarqueeContainer>
                  <p>{moment(new Date()).format('dddd, DD MMMM gggg')}</p>
                  <HeaderDateIconSvg />
                </StyledMarqueeContainer>
                <StyledParagraph>
                  <span className='js-clock'> {currentDateTime} </span>
                  <ClockSvg />
                </StyledParagraph>
              </StyledFlexContainer1>
            </StyledWelcomeContainer> */}
          </StyledFlexContainer>
          {isAuthenticated === false ? (
            <PreLoginButtons parentModalHandlerFunction={clickHandler} />
          ) : (
            <PostLoginButtons parentModalHandlerFunction={clickHandler} />
          )}
        </StyledHeaderContainer>
      </StyledHeaderWrapper>
      <StyledLoginModalContainer>
        <Modal
          show={show}
          onClose={clickHandler1}
          centered
          size={`${modalWidth === 'lg' ? 'lg' : 'md'}`}
        >
          <ModalBody
            className={`${displayModule === 'terms' ? 'termspage' : ''}`}
          >
            {displayModule !== 'deafultterms' &&
              displayModule !== 'defaultpageageconsent' && (
                <ModalClose onClose={clickHandler}>x</ModalClose>
              )}
            {displayModule === 'login' && (
              <SigninForm
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
              />
            )}
            {displayModule === 'register' && (
              <SignupForm
                parentModalHandlerFunction={changeModule}
                parentModalSizeHandler={setModalWidth}
                setPhoneNumber={setPhoneNumber}
                setPassword={setPassword}
                setScreen={setScreen}
                closePopup={closePopup}
              />
            )}
            {displayModule === 'terms' && (
              <TermsForm
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
                page='register'
              />
            )}
            {displayModule === 'loginterms' && (
              <TermsForm
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
                page='login'
              />
            )}
            {displayModule === 'deafultterms' && (
              <TermsForm
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
                page='defaultterms'
              />
            )}
            {displayModule === 'defaultageconsent' && (
              <AgeVerification onClick={defaultAgeHandler} />
            )}
            {displayModule === 'defaultpageageconsent' && (
              <AgeSignup
                onClick={defaultPageAgeHandler}
                closePopup={closePopup}
                page='default'
              />
            )}
            {displayModule === 'signinageconsent' && (
              <AgeSignup
                onClick={signInAgeHandler}
                closePopup={closePopup}
                page='login'
              />
            )}
            {displayModule === 'signupageconsent' && (
              <AgeSignup
                onClick={signUpAgeHandler}
                closePopup={closePopup}
                page='register'
              />
            )}
            {displayModule === 'otp' && (
              <OTPForm
                parentModalHandlerFunction={changeModule}
                phoneNumber={phoneNumber}
                password={password}
                screen={screen}
              />
            )}
            {displayModule === 'forgot' && (
              <ForgotForm
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
              />
            )}
            {displayModule === 'registersuccess' && (
              <RegisterSuccess
                parentModalHandlerFunction={changeModule}
                screen={screen}
              />
            )}
            {displayModule === 'deposit' && (
              <DepositForm parentModalHandlerFunction={changeModule} />
            )}
            {displayModule === 'kyc' && (
              <KycForm
                parentModalHandlerFunction={changeModule}
                parentModalSizeHandler={setModalWidth}
                closePopup={closePopup}
              />
            )}
            {displayModule === 'withdraw' && (
              <WithdrawForm parentModalHandlerFunction={changeModule} />
            )}
            {displayModule === 'welcome' && (
              <Welcome
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
              />
            )}
            {displayModule === 'kycreject' && (
              <KYCReject
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
              />
            )}
            {displayModule === 'kycprocess' && (
              <KYCProgress
                parentModalHandlerFunction={changeModule}
                message=''
                closePopup={closePopup}
              />
            )}
            {displayModule === 'kycwithdrawprocess' && (
              <KYCProgress
                parentModalHandlerFunction={changeModule}
                message='You will be able to withdraw money from wallet only after KYC verification is complete.'
                closePopup={closePopup}
              />
            )}
            {displayModule === 'kycverified' && (
              <KYCVerified
                parentModalHandlerFunction={changeModule}
                closePopup={closePopup}
              />
            )}
          </ModalBody>
        </Modal>
       <SwipeableDrawer
          anchor="left"
          open={openContextMenu}
          onClose={() => setOpenContextMenu(null)}
          onOpen={() => {}}
          PaperProps={{
            style: {
              minWidth: 300,
              maxWidth: '43%',
              background: 'rgba(0, 0, 0, 0.31)',
              borderRadius: '0px 10px 10px 0px',
              boxShadow: '0 4px 30px rgba(0, 0, 0, 0.1)',
              backdropFilter: 'blur(5px)',
              border: '1px solid rgba(255, 255, 255, 0.3)',
            }
          }}
        >
          <MenuContext onClose={() => setOpenContextMenu(null)}/>
        </SwipeableDrawer>

      </StyledLoginModalContainer>
    </>
  );
};
export default Header;
