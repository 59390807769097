import styled, { themeGet } from '@/shared/styled';

export const StyledPlaceBetView = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  display: block;
`;

export const StyledOverLay = styled.div`
  background: hsla(0, 0%, 5%, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

export const StyledBetContaiiner = styled.div`
  button.closebtn {
    background-color: transparent;
    border: 0;
    appearance: none;
    color: #4a6285;
    font-size: 40px;
    cursor: pointer;
    right: 18px;
    top: 18px;
    height: 20px;
    line-height: 20px;
    position: absolute;
    width: 20px;
    svg {
      line {
        stroke: #4a6285;
      }
    }
  }
  background: ${themeGet('colors.overlaybg')};
  border-radius: 30px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  z-index: 200;
  //height: 280px;
`;

export const StyledBetSlipHead = styled.div`
  padding: 30px 40px 25px 40px;
  display: flex;
  flex-direction: column;

  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-size: 20px;
    margin-bottom: 3px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    text-align: center;
  }
  p {
    font-size: 13px;
    line-height: 15px;
    margin-bottom: 20px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: #a3adc2;
  }
`;

export const StyledBetSlipButton = styled.div`
  padding: 20px 40px 35px 40px;
  display: flex;
  flex-direction: column;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
`;

export const StyledBetSlipList = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  h3 {
    color: #a3adc2;
    float: left;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 14px;
    text-transform: uppercase;
  }
  ul {
    border-bottom: 1px solid #d9e2eb;
    list-style: none;
    //margin-top: 8px;
    li {
      border-top: 1px solid #d9e2eb;
      float: left;
      padding: 7px 0;
      width: 100%;
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansBold')};
      color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    }
  }
`;

export const StyledBetSlipText = styled.div`
  display: flex;
  justify-content: space-between;
  p {
    color: #a3adc2;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansBold')};
    margin-bottom: 0.5rem !important;
  }
`;

export const StyledBetSlipbtn = styled.div`
  display: flex;
  justify-content: space-between;
  .yeare_button {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 14px;
    border: 0px;
    gap: 4px;
    height: 38px;
    padding: 0px 24px;
    color: ${themeGet('colors.pagefontcolor')};
    border-radius: 6px;
    width: 100%;
    text-align: center;
    justify-content: center;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 14px;
      border: 0px;
      height: 38px;
    }
  }

  .exit_button {
    background: none;
    border: 1.5px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 38px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 24px;
    border-radius: 6px;
    &:hover {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 38px;
      font-size: 14px;
    }
    &:active {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;
export const StyledButtonAmount = styled.div`
  padding-top: 15px;
  .amounttext {
    background: ${themeGet('colors.forminputbg')};
    //display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 14px;
    border: 0px;
    gap: 4px;
    height: 33px;
    padding: 0px 18px;
    color: ${themeGet('colors.white')};
    border-radius: 6px;
    width: 30%;
    text-align: center;
    justify-content: center;
    margin: 0 15px 15px 0;
    span {
      padding-right: 0px;
    }
    &:hover {
      background: ${themeGet('colors.forminputbg')};
      font-size: 14px;
      border: 0px;
      height: 33px;
    }
  }
  .active {
    background: ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.pagefontcolor')};
    &:hover {
      background: ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.pagefontcolor')};
    }
  }

  :nth-child(3) {
    margin-right: 0px;
  }
  :nth-child(6) {
    margin-right: 0px;
  }
  :nth-child(9) {
    margin-right: 0px;
  }
`;

export const StyledCustomAmount = styled.div`
  label {
    color: ${themeGet('colors.InputLableText')};
    font-size: 13px;
    margin-bottom: 3px;
  }
  input {
    padding-left: 12px;
  }
`;
