import React from 'react';
// @mui
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
// Components
import SimpleSearch from './search/SimpleSearch';
// Models
import { GAME_PROVIDERS } from '../@types/models/GameModel';

type Props = {
  providerId: string,
  displaySearch?: boolean,
  setSearchFilter?: (search: string) => void,
  availableSearchElements?: null|number,
};

const GameProviderHeader = ({ providerId, displaySearch, setSearchFilter, availableSearchElements }: Props) => {
  const gameProviderLabel = GAME_PROVIDERS[providerId];
  return (
    <Stack direction="row" justifyContent="space-between" style={{ paddingBottom: '1rem' }}>
      <Typography
        variant="h5"
        style={{
          color: '#C2C9D6',
          fontFamily: 'google_sansbold ,sans-serif',
          fontSize: 20,
          paddingLeft: 8,
          display: 'flex',
          alignItems: 'center',
        }}
      >
        {gameProviderLabel?.icon &&
          <span style={{ paddingRight: '0.5rem' }}>
            <gameProviderLabel.icon/>
          </span>
        }
        {gameProviderLabel?.name}
      </Typography>
      {displaySearch &&
        <div style={{maxWidth: 240 }}>
          <SimpleSearch
            onFilter={(search: string) => setSearchFilter && setSearchFilter(search)}
            size={availableSearchElements}
          />
        </div>
      }
    </Stack>
  );
};

export default GameProviderHeader;
