import styled, { device, themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
  width: 100%;
  .loginform {
    border-radius: 16px;
    // background: rgba(255, 255, 255, 0.05);
    // padding: 16px 16px 16px 16px;
    // margin-top: 10px;
  }
  .no_padding {
    padding-right: 0px;
  }
  .date_close {
    background-color: ${themeGet('colors.mainborder')};
    width: 50px;
    color: #fff;
  }
  .dob_btn {
    display: block;
    width: 100%;
    height: 42px;
    padding: 6px 12px 6px 14px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: #f0f0f0;
    background-color: ${themeGet('colors.forminputbg')};
    background-clip: padding-box;
    border: 1px solid #384766;
    border-radius: 6px 6px 6px 6px !important;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

    &[hidden] {
      display: none;
    }
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: auto;
    min-width: 280px;
    margin-top: 15px;
    height: 42px;
    border: none;
    padding: 0px 40px;
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    background: ${themeGet('colors.mainborder')};
  }
  .row {
    margin-bottom: 16px;
  }
  .mobilenumber {
    padding-left: 12px;
  }
  .termsandcondition {
    height: 250px;
    overflow-y: auto;
    color: #fff;
    padding-right: 10px;
    ${device.TabToLg} {
      height: 350px;
    }
    p {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
      margin-bottom: 6px;
    }
    h5 {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
    ol {
      padding-left: 25px;
      h4 {
        font-size: 16px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
      li {
        font-size: 14px;
        margin-bottom: 8px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
    }
  }
  .nomargin {
    margin-bottom: 0px;
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .scrollheight {
  }
  .rmdp-container {
    width: 100%;
  }
  .rmdp-input {
    width: 100%;
    height: 42px;
    padding: 6px 12px 6px 12px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: #f0f0f0;
    background-color: ${themeGet('colors.forminputbg')};
    background-clip: padding-box;
    border: 1px solid #1c2335;
    border-radius: 6px 6px 6px 6px !important;
  }
  .mobile_view {
    .mobilenumber {
      //padding-left: 26px;
      // border-radius: 0px 6px 6px 0px !important;
    }
  }
  input {
    padding-left: 12px;
  }
  select {
    padding-left: 12px !important;
  }
  .input-group {
    input.phonenonedit:read-only {
      background-color: ${themeGet('colors.forminputbg')};
      cursor: no-drop;
      //pointer-events: none;
    }
  }
  .otherincomeinput {
    margin-left: 10px !important;
  }

  .backarrow {
    position: absolute;
    top: 24px;
  }
  ${device.TabToLg} {
    .row {
      margin-bottom: 4px;
      .col-md-4 {
        margin-bottom: 10px;
      }
    }
  }
  ${device.MobSLSm} {
    .personalform{ height: 890px;
      background-color: #26334d;
      position: absolute;
      left: 0px;
      padding: 0px 20px 0px 20px;}
    .scrollheight {
      // height: 400px;
      // overflow-y: auto;
      // overflow-x: hidden;
    }
  }
  .rmdp-container {
    input {
      //cursor: no-drop;
      border: 1px solid ${themeGet('colors.largegamecardborder')};
      margin: 0px;
    }
    input:focus {
      box-shadow: none;
      border-color: ${themeGet('colors.forminputfocusborder')};
    }
    svg {
      display: none;
    }
  }
  ${device.TabToLg} {
    .signin_btn {
      width: 100%;
    }
  }
  ${device.MobToSmm}{
    .personalform{
      padding: 0px 20px 20px 20px;
      background-color: #26334d;
      position: absolute;
      left: 0px;
    }
  }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  text-align: center;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 6px;
`;

export const StyledSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;
export const StyledSubHeaderText = styled.div`
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    margin-bottom: 0px;
  }
`;

export const StyledSubHeadLogo = styled.div``;
export const StyledDesc = styled.p`
  font-size: 16px;
  text-align: center;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  margin-bottom: 30px;
  font-family: ${themeGet('fonts.googleSansRegular')};
  ${device.MobToMd} {
    width: 100%;
    margin: 0px auto;
    line-height: 22px;
 
   }
`;

export const StyledSteps = styled.div`
  p {
    color: #7685a0;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 15px;
    margin: 0px 0px 25px 0px !important;
    display: flex;
    align-items: center;
    gap: 4px;
    ${device.MobToSmm}{
      margin: 0px 0px 15px 0px !important;
    }
    svg {
      position: relative;
      width: 8px;
      top: -2px;
      path {
        fill: ${themeGet('colors.ErrorLableText')};
      }
    }
  }
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-size: 20px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    margin: 30px 0px 5px 0px;
    display: flex;
    align-items: center;
    span {
      color: ${themeGet('colors.mainborder')};
      font-family: ${themeGet('fonts.googleSansBold')};
    }
  }
  h6 {
    color: #7685a0;
    font-family: ${themeGet('fonts.googleSansRegular')};
    margin-bottom: 5px;
    font-size: 15px;
    ${device.MobToSmm}{
      font-size: 13px;
      margin-bottom: 15px !important;
    }
  }
  ${device.TabToLg} {
    h4 {
      white-space: nowrap;
      font-size: 18px;
      margin: 20px 0px 5px 0px;
    }
  }
`;

export const StyledAlertMessage = styled.div`
  color: #f31415;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  color: #c2c9d6;
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 24px;
  margin-top: 40px;
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 5px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 7px;
  top: 11px;
  color: #f0f0f0;
  font-size: 13px;
  z-index: 99;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0px;
  border-top: 1px solid;
  padding-top: 20px;
  margin-top: 5px;
  gap: 20px;

  .loginbtn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 13px;
    border: 0px;
    gap: 4px;
    height: 34px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 13px;
      border: 0px;
      height: 34px;
    }
    svg path {
      stroke: ${themeGet('colors.pagefontcolor')} !important;
    }
  }
  .signupbtn {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 34px;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    &:hover {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 34px;
      font-size: 13px;
    }
    &:active {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledPersonalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 20px;
`;

export const StyledSelectBox = styled.div`
  .input-group-text {
    padding-right: 0px;
  }
  select {
    border-radius: 6px 0px 0px 6px !important;
    padding-left: 30px !important;
    font-size: 13px !important;
    background-position: 95% 11px !important;
    background-repeat: no-repeat !important;
    background-size: 17px !important;
  }

  .loginform {
    select {
      font-size: 13px !important;
      background-position: 95% 11px !important;
      background-repeat: no-repeat !important;
    }
  }
`;
