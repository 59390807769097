import PubNub from 'pubnub';

const PUBNUB_SUBSCRIBE_KEY = process.env.REACT_APP_PUBNUB_SUBSCRIBE_KEY;

export default class Sockets {
  private _userId: string;
  private _updateModelAttributes: any;
  private _pubnub: PubNub;
  static instance: any;
  constructor(userId: string, updateModelAttributes: any) {
    this._userId = userId;
    this._updateModelAttributes = updateModelAttributes;
    this._pubnub = new PubNub({
      // TODO: later move this to env.*
      subscribeKey: PUBNUB_SUBSCRIBE_KEY,
      userId: this._userId,
      logVerbosity: false,
    });
    // Subscribe channel dedicated to a user.
    this._pubnub.subscribe({
      channels: [this._userId],
    });
    // Add listener.
    console.log(`[Sockets]: Connecting user ${this._userId}...`, PUBNUB_SUBSCRIBE_KEY)
    this._pubnub.addListener({
      status: statusEvent => {
        if (statusEvent.category === 'PNConnectedCategory') {
          console.log('[Sockets] Connected');
        }
      },
      message: messageEvent => {
        let updatedModelAttributes = undefined;
        try {
          updatedModelAttributes = messageEvent.message;
        } catch (error) {
          console.error('Incorrect <JSON> model:', messageEvent.message);
        }
        console.log('Sockets::message::model', updatedModelAttributes);

        // Expected that every model attributes has at least id and typename to
        // identify a model and all the attributes shall be updated.
        if (
          updatedModelAttributes &&
          updatedModelAttributes.id &&
          updatedModelAttributes.typename &&
          this._updateModelAttributes
        ) {
          //console.log('UPDATE MODEL', updatedModelAttributes);
          // You can test the flow from pubsub debug console sending model JSON.
          // {"createdAt":"2023-06-21T08:14:04.783Z", "typename":"user", "id":"02a81077-7565-425d-86de-b667e2895899"}
          this._updateModelAttributes(updatedModelAttributes);
        }
      },
      presence: presenceEvent => {
        console.log('Sockets::presence', presenceEvent);
      },
    });
  }

  /**
   * Unsubscribe socket from all the channels.
   */
  unsubscribe() {
    this._pubnub.unsubscribeAll();
  }

  /**
   * Create an singleton to handle sockets for a user.
   * @param {string} userId
   */
  static setSocketForUser(userId: string, setModelFunction: any) {
    // console.log('Sockets::setSocketForUser', userId);
    if (!this.instance) {
      this.instance = new Sockets(userId, setModelFunction);
    }

    return this.instance;
  }
}
