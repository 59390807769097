import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { Link } from 'react-router-dom';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { hasKey } from '@/shared/methods';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledDivider,
  StyledBottomText,
  StyledForgotText,
  StyledPhoneText,
} from './style';
import { MobileSvg } from '@/shared/svg';

interface ChildProps {
  phoneNumber: string;
  password: string;
  parentModalHandlerFunction: any;
  screen: string;
}

const OTPForm = (props: ChildProps) => {
  const [token, setToken] = useState('');
  const [loading, setLoading] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IOTPForm>();

  const onSubmit: SubmitHandler<IOTPForm> = data => {
    console.log(setToken);
    setLoading(true);
    data.password = props.password;
    data.phone = props.phoneNumber;
    data.token = token;
    data.agent = '';
    data.screen = props.screen;
  };

  /*useEffect(() => {
    if (isforgotOTPSuccess) {
      setToken(regOTPData.token);
    }
  }, [isforgotOTPSuccess]);

  useEffect(() => {
    setLoading(isRegLoading);
    if (isRegError) {
      setIsNotValid(true);
      setMessage(regErrorMessage);
    }
  }, [isRegError, isRegLoading]);*/

  return (
    <StyledWrap>
      <StyledTitle>OTP Verification</StyledTitle>
      <StyledDesc>Enter the OTP sent to {props.phoneNumber}.</StyledDesc>
      <form
        className='loginform'
        action='#'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <Row mb='20px'>
          <Col md={2} className='no_padding'>
            <Label display='block' mb='5px' htmlFor='email'>
              OTP
            </Label>
            <InputGroup>
              <StyledPhoneText>
                <strong>A-</strong>
              </StyledPhoneText>
              <Input className='otpinput' type='text' id='otp' name='otp' />
            </InputGroup>
          </Col>
          <Col md={10}>
            <FormGroup>
              <Label
                className='labelheight'
                display='block'
                mb='5px'
                htmlFor='email'
              >
                &nbsp;
              </Label>
              <InputGroup>
                <InputGroupAddon dir='prepend'>
                  <InputGroupText>
                    <MobileSvg />
                  </InputGroupText>
                </InputGroupAddon>
                <StyledPhoneText>A-</StyledPhoneText>
                <Input
                  className='mobilenumber'
                  type='text'
                  id='otp'
                  name='otp'
                  placeholder='Enter OTP'
                  feedbackText={errors?.otp?.message}
                  state={hasKey(errors, 'otp') ? 'error' : 'success'}
                  showState={!!hasKey(errors, 'otp')}
                  {...register('otp', {
                    required: 'OTP is required',
                    pattern: {
                      value: /^[0-9]{5}$/,
                      message: 'invalid OTP format',
                    },
                    maxLength: {
                      value: 6,
                      message: 'Minimum length is 6',
                    },
                  })}
                />
              </InputGroup>
            </FormGroup>
          </Col>
        </Row>
        <StyledForgotText>
          <span>Haven&apos;t received the verification code?</span>
          <button className='resendbtn'>Resend</button>
        </StyledForgotText>
        <Button type='submit' color='brand2' className='signin_btn'>
          {loading ? <ButtonSpinner /> : null}
          {props.screen === 'register' ? 'Sign Up' : 'Set Password'}
        </Button>
        <StyledDivider>or</StyledDivider>
        <StyledBottomText>
          Already have an account ?<Link to='/'> Log In</Link>{' '}
        </StyledBottomText>
      </form>
    </StyledWrap>
  );
};

export default OTPForm;
