// @ts-nocheck
import React, { useEffect, forwardRef, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
// @mui
import { Box, BoxProps } from '@mui/material';

// ----------------------------------------------------------------------

interface Props extends BoxProps {
  children: ReactNode;
  title: string;
  meta?: ReactNode;
}

const Page = forwardRef<HTMLDivElement, Props>(({ children, title = '', meta, ...other }, ref) => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "instant"
    })
  }, [window.pathname])
  return (
    <>
      <Helmet>
        <title>{`${title} | ${process.env.REACT_APP_SITE_TITLE}`}</title>
        {meta}
      </Helmet>

      <Box ref={ref} {...other} style={{ height: '100%' }}>
        {children}
      </Box>
    </>
  );
});

export default Page;
