import styled, {
  createGlobalStyle,
  css,
  keyframes,
  ThemeProvider,
} from 'styled-components';
import { themeGet } from '@styled-system/theme-get';
import tinycolor from 'tinycolor2';
import { fairplayTheme, arionplayTheme } from './theme';

const breakpoints = ['576px', '768px', '992px', '1300px', '1400px'];

export const device = {
  small: `@media screen and (min-width: ${breakpoints[0]})`,
  medium: `@media screen and (min-width: ${breakpoints[1]})`,
  large: `@media screen and (min-width: ${breakpoints[2]})`,
  xlarge: `@media screen and (min-width: ${breakpoints[3]})`,
  xxlarge: `@media screen and (min-width: ${breakpoints[4]})`,
  mdToLg: `@media (min-width: 768px) and (max-width: 991px)`,
  lgToXl: `@media (min-width: 992px) and (max-width: 1199px)`,
  MobToSL: `@media (min-width: 320px) and (max-width: 440px)`,
  TabToXl: `@media (min-width: 660px) and (max-width: 1023px)`,
  TabToLg: `@media (min-width: 320px) and (max-width: 1023px)`,
  MobToLg: `@media (min-width: 998px) and (max-width: 1023px)`,
  MobToMd: `@media (min-width: 320px) and (max-width: 767px)`,
  MobToSl: `@media (min-width: 420px) and (max-width: 767px)`,
  MobToSm: `@media (min-width: 350px) and (max-width: 419px)`,
  MobToSd: `@media (min-width: 320px) and (max-width: 349px)`,
  MobToSmm: `@media (min-width: 320px) and (max-width: 580px)`,
  MobSLSm: `@media (min-width: 320px) and (max-width: 375px)`,
  MobToLmx: `@media (min-width: 681px) and (max-width: 767px)`,
  MobToVmx: `@media (min-width: 581px) and (max-width: 680px)`,
  MobToDmx: `@media (min-width: 441px) and (max-width: 580px)`,
  MobToMmx: `@media (min-width: 381px) and (max-width: 440px)`,
  MobToSmx: `@media (min-width: 320px) and (max-width: 380px)`,
};

const themes = {
  fairplay: fairplayTheme,
  arionplay: arionplayTheme,
};

export {
  createGlobalStyle,
  css,
  keyframes,
  ThemeProvider,
  themeGet,
  fairplayTheme,
  arionplayTheme,
  tinycolor,
  themes,
};
export * from 'styled-system';
export default styled;
