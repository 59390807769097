import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
//
import {
  BannerTwitterSvg,
  BannerFacebookSvg,
  BannerTelegramSvg,
  BannerYouTubeSvg,
  BannerInstagramSvg,
  BannerAnchorSvg,
  BannerSpotifySvg,
  EGamesViewSvg,
  ArrowRightSvg,
  RealTimeGamesSvg,
  ComingSoonSvg,
  WEGamesSvg,
  EvolutionGamesSvg,
  WEGamesViewSvg,
  RealTimeGamViewsSvg,
  TripleCrownViewSvg,
  SpecialgamesSvg,
  MatchRacingSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledBannerContainer,
  StyledLargeGameContainer,
  StyledBannerImg,
  StyledBannerAdd,
  StyledBannerText,
  StyledBannerAddTitle,
  StyledBannerAddSocialIcons,
  StyledBannerFotter,
  StyledBannerLogo,
  StyledLoader,
  StyledViewAll,
  StyledGameList,
  StyledComingSoon,
  StyledComingSoonGame,
  StyledTwoGameCard,
  StyledTwoGameList,
  StyledTripleView,
  StyledLargeGameList,
  StyledEvlGameList,
  StyledEvlView,
} from './style';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import GameCard from '@/components/RTGgamecard/card';
import ComingSoongamecard from '@/components/ComingSoongamecard';
import GameService from '@/services/GameService';
import TwoGameCard from '@/components/2Ggamecard';
import TripleCrown from '@/components/TripleCrown';
import RTGGameCard from '@/components/RTGgamecard';
import EVLGameCard from '@/components/EVLgamecard';
import RacingService from '@/services/RacingService';
import ComingSoonPopUp from '@/components/ComingSoonPopUp';
import GameList from '@/components/GameList';
import GamesCard from '@/components/GamesCard';
// Models
import GameModel from '../../@types/models/GameModel';

import { useAppSelector } from '@/hooks/useAppSelector';

const Home = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

  const dispatch = useAppDispatch();
  const [gameList, SetGameList] = useState(null);
  const [raceEventList, SetRaceEventList] = useState(null);
  const { raceCardEventChange } = useAppSelector(
    state => state.raceinfo
  );


  const ageVerifyPopup = async () => {
    const ageflag = await CookieStorageService.getItem(
      'accept_terms_age_consent'
    );
    if (ageflag === undefined && ageflag != 'accept')
      dispatch(changeModule('deafultterms'));
  };

  const getRaceEventList = async () => {
    const response = await RacingService.getRaceEventList();
    const sortEvents = response.data.sort((x: any, y: any) => {
      return x.mtp - y.mtp;
    })
    SetRaceEventList(sortEvents);
  }
  const getGamesList = async () => {
    const response = await GameService.getGamesData();
    if (response.data && response.data.length > 0) {
      //filter ans sort by desc order
      const groupByCategory = response.data
        // .filter((game: any) => game.status === 'ACTIVE' && game.priority > 0)
        .sort((x: any, y: any) => {
          return y.priority - x.priority;
        })
        .reduce((group: any, game: any) => {
          const { providerId } = game;
          group[providerId] = group[providerId] ?? [];
          game.image = 'thumbnail_320x320.jpg';
          game.infoUrl = `${process.env.REACT_APP_GAME_S3_PATH}${game.providerId}/${game.id}/factsheet_eng.pdf`;
          //game.infoUrl = `${process.env.REACT_APP_CDN_IMAGES_PATH}/baccarat-factsheet.pdf`;
          // if(game.infoUrl === ''){
          //   game.infoUrl = `${process.env.REACT_APP_CDN_IMAGES_PATH}/factsheet.pdf`;
          // }

          group[providerId].push(game);
          return group;
        }, {});
      SetGameList(groupByCategory);
    }
  };

  useEffect(() => {
    ageVerifyPopup();
    getRaceEventList();
    getGamesList();
  }, []);

  useEffect(() => {
    console.log("RACE CARD EVENT")
    getRaceEventList();
  }, [raceCardEventChange]);

  // ----

  return (
    <>
      <StyledContainerFluid>
        <StyledBannerContainer>
          <StyledBannerImg>
            <img
              src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/Banner-HR-Web.jpg`}
              alt=''
            />
            <StyledBannerText>
              <img
                src={`/icons/partners/${process.env.REACT_APP_THEME === 'fairplay' ? 'international-horse-racing.png' : 'unodos-logo.png'}`}
                alt=''
              />
              <p>Coming Soon!</p>
            </StyledBannerText>
          </StyledBannerImg>
          <StyledBannerAdd>
            <StyledBannerAddTitle>
              <h6>JOIN OUR</h6>
              <h3>SPORTS BETTING COMMUNITY</h3>
            </StyledBannerAddTitle>
            <StyledBannerLogo>
              <Link to='/'>
                <img
                  src={process.env.REACT_APP_THEME_LOGO}
                  alt=''
                />
              </Link>
            </StyledBannerLogo>
            <StyledBannerAddSocialIcons>
              <ul data-testid='social-links'>
                <li>
                  <Link to='/'>
                    <BannerTwitterSvg />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <BannerFacebookSvg />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <BannerTelegramSvg />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <BannerYouTubeSvg />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <BannerInstagramSvg />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <BannerAnchorSvg />
                  </Link>
                </li>
                <li>
                  <Link to='/'>
                    <BannerSpotifySvg />
                  </Link>
                </li>
              </ul>
            </StyledBannerAddSocialIcons>
            <StyledBannerFotter>
              <p>Powered by </p>
              <Link to='/'>
                <img
                  src="/icons/partners/nemo-logo.png"
                  alt=''
                />
              </Link>
            </StyledBannerFotter>
          </StyledBannerAdd>
        </StyledBannerContainer>
        {/* <StyledTwoGameCard>
          <StyledTwoGameList>
            {[
              {
                id: '14',
                image: 'toteoddweb.png',
                mobile_img: 'toteoddmob.png',
                name: 'Sports Betting ',
                subtitle: 'Tote odds',
                desc: "Are you a hardcore karerista?  We've got international streaming for you. Get in on it now!",
              },
              {
                id: '141',
                image: 'fixedoddsweb.png',
                mobile_img: 'fixedoddmoble.png',
                name: 'Sports Betting',
                subtitle: 'Fixed odds',
                desc: 'Are you feeling lucky today? Test it out and place your bets! International races available.',
              },
            ].map(game => (
              <TwoGameCard {...game} key={game.id} />
            ))}
          </StyledTwoGameList>
            </StyledTwoGameCard> */}

        <StyledLoader></StyledLoader>

        <StyledLargeGameContainer>

        <Box style={{ padding: isMobileOrTablet ? '0 16px' : 0, marginBottom: '2rem' }}>
          {gameList && (
            <Grid container={true} spacing={2}>
              <Grid item={true} xs={12} md={6}>
                <GamesCard
                  title="Favorite Live Games"
                  games={
                    [...gameList['evo'], ...gameList['we']].filter((gameModel: GameModel) => ['LIVE', 'LIVE-DEALER'].includes(gameModel.category)).slice(0, 10)
                  }
                />
              </Grid>
              <Grid item={true} xs={12} md={6}>
                <GamesCard title="Favorite Slot Games" games={gameList['rtg'].reverse().slice(0, 10)}/>
              </Grid>
            </Grid>
          )}
        </Box>

        {(raceEventList != undefined && raceEventList.length > 0 && (
          <StyledLargeGameList>
            <h5><MatchRacingSvg />Triple Crown</h5>
            <StyledTripleView>
              <Stack direction="row" flexWrap="wrap" spacing={0} sx={{ gap: 3 }}>
                {raceEventList && raceEventList.map((game:any) => (
                  //<LargeGameCard {...game} key={game.id} />
                  <TripleCrown  desc={''} subtitle={''} {...game} key={game.id} />
                ))}
                <StyledViewAll>
                  <Link to='/triplecrownview'>
                    <TripleCrownViewSvg/>
                      <p>View all <ArrowRightSvg/> </p>
                  </Link>
                </StyledViewAll>
              </Stack>
            </StyledTripleView>
          </StyledLargeGameList>
        ))}

        <Box style={{ padding: isMobileOrTablet ? '0 16px' : 0 }}>
          {gameList &&
            ['we', 'evo', 'rtg'].map((providerId: string) => (
              <GameList
                key={providerId}
                providerId={providerId}
                games={gameList[providerId].slice(0, 11)}
                displayViewAllButton={true}
                size={isMobileOrTablet ? 'small' : 'medium'}
              />
            ))
          }
        </Box>
          <StyledComingSoon>
            <h5>
              <ComingSoonSvg />
              Coming Soon
            </h5>
            <StyledComingSoonGame>
              {[
                {
                  id: '9000e686-6983-42cd-b46b-5f8cfb92b8f7',
                  image: 'habanero-mobilebanner.png',
                  name: 'Baccarat',
                  infoUrl: 'baccarat-factsheet.pdf',
                },
                {
                  id: 'evolution_gaming__crazytime0000002',
                  image: 'sagamingbanner.png',
                  name: 'Crazy Time',
                  infoUrl: 'crazy-time-factsheet.pdf',
                },
              ].map(game => (
                <ComingSoongamecard {...game} key={game.id} />
              ))}
            </StyledComingSoonGame>
          </StyledComingSoon>
          {/* <ComingSoonPopUp id={''} image={''} name={''} desc={''} subtitle={''} providerId={''}/> */}
        </StyledLargeGameContainer>
      </StyledContainerFluid>
    </>
  );
};

export default Home;
