import { Link } from 'react-router-dom';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  FormGroup,
  Label,
  Input,
  Button,
  InputGroup,
  Textarea,
  Select,
} from '@/components';
import {
  ArrowRightSvg,
  MessageSvg,
  SendSvg,
  HeadPhoneSvg,
  FaqSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledTextArea,
} from './style';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
//import { useAppDispatch } from '@/hooks/useAppDispatch';
//import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';

const Contactus = () => {
  //const dispatch = useAppDispatch();
  const [sourceSubject, setsourceSubject] = useState('');
  //const [userData, setUserData] = useState({});
  //const { userInfo } = useAppSelector(state => state.auth);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<ContactForm>();

  const onSubmit: SubmitHandler<ContactForm> = async data => {
    console.log(data);
  };
  const handleDocumentTypeValue = (e: any) => {
    setsourceSubject(e.target.value);
  };

  const getUserDetails = async () => {
    const response = await AuthService.getUserData();
    setValue('firstName', response.data.firstName);
    setValue('lastName', response.data.lastName);
    setValue('phone', response.data.phone);
    setValue('email', response.data.email);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <>
      <StyledContainerFluid>
        <h3>Contact Us</h3>
        <StyledContainerPageView>
          <StyledPageRight>
            <StyledPageTextContainer>
              <h5>How can we help ?</h5>
              <form
                className='contactform loginform'
                action='#'
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <Row mb='20px'>
                  <Col md={6} className='no_padding'>
                    <Label display='block' mb='5px' htmlFor='email'>
                      First Name
                    </Label>
                    <InputGroup>
                      <Input
                        className='mobilenumber'
                        type='text'
                        id='firstName'
                        name='firstName'
                        placeholder='First Name'
                        readonly={false}
                        feedbackText={errors?.firstName?.message}
                        state={
                          hasKey(errors, 'firstName') ? 'error' : 'success'
                        }
                        showState={!!hasKey(errors, 'first')}
                        {...register('firstName', {
                          required: 'First Name is required',
                        })}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label display='block' mb='5px' htmlFor='email'>
                        Last Name
                      </Label>
                      <InputGroup>
                        <Input
                          className='mobilenumber'
                          type='text'
                          id='last'
                          readonly={false}
                          name='lastName'
                          placeholder='Last Name'
                          feedbackText={errors?.lastName?.message}
                          state={
                            hasKey(errors, 'lastName') ? 'error' : 'success'
                          }
                          showState={!!hasKey(errors, 'lastName')}
                          {...register('lastName', {
                            required: 'Last Name is required',
                          })}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row mb='20px'>
                  <Col md={6} className='no_padding'>
                    <Label display='block' mb='5px' htmlFor='email'>
                      Phone
                    </Label>
                    <InputGroup>
                      <Input
                        className='mobilenumber'
                        type='text'
                        readonly={false}
                        id='phone'
                        name='phone'
                        placeholder='Phone Number'
                        feedbackText={errors?.phone?.message}
                        state={hasKey(errors, 'phone') ? 'error' : 'success'}
                        showState={!!hasKey(errors, 'phone')}
                        {...register('phone', {
                          required: 'Phone is required',
                        })}
                      />
                    </InputGroup>
                  </Col>
                  <Col md={6}>
                    <FormGroup>
                      <Label display='block' mb='5px' htmlFor='email'>
                        Email
                      </Label>
                      <InputGroup>
                        <Input
                          className='mobilenumber'
                          type='text'
                          readonly={false}
                          id='email'
                          name='email'
                          feedbackText={errors?.email?.message}
                          state={hasKey(errors, 'email') ? 'error' : 'success'}
                          showState={!!hasKey(errors, 'email')}
                          {...register('email', {
                            required: 'Email is required',
                          })}
                        />
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row mb='20px'>
                  <Col md={12}>
                    <FormGroup>
                      <Label display='block' mb='5px' htmlFor='email'>
                        Subject
                      </Label>
                      <InputGroup>
                        <Select
                          id='subject'
                          name='subject'
                          className={`select ${
                            sourceSubject === '' ? 'placeholder' : ''
                          }`}
                          feedbackText={errors?.subject?.message}
                          state={
                            hasKey(errors, 'subject') ? 'error' : 'success'
                          }
                          showState={!!hasKey(errors, 'subject')}
                          {...register('subject', {
                            required: 'Subject is required',
                          })}
                          onChange={e => handleDocumentTypeValue(e)}
                        >
                          <option disabled={true} value='' selected>
                            --Select Subject Type--
                          </option>
                          <option value='withdrawls'>Withdrawals </option>
                          <option value='deposit'>Deposit </option>
                          <option value='kyc'>KYC </option>
                          <option value='accountinfo'>Account info </option>
                          <option value='others'>Others </option>
                          <option value='feedback'>
                            Suggestions / Feedback{' '}
                          </option>
                        </Select>
                      </InputGroup>
                    </FormGroup>
                  </Col>
                </Row>
                <Row mb='20px'>
                  <Col md={12}>
                    <Label display='block' mb='5px' htmlFor='email'>
                      Message
                    </Label>
                    <InputGroup className='textareamessage'>
                      <Textarea
                        id='message'
                        name='Message'
                        feedbackText={errors?.message?.message}
                        state={hasKey(errors, 'message') ? 'error' : 'success'}
                        showState={!!hasKey(errors, 'message')}
                        {...register('message', {
                          required: 'Message is required',
                          minLength: {
                            value: 6,
                            message: 'Minimum length is 6',
                          },
                        })}
                      ></Textarea>
                    </InputGroup>
                  </Col>
                </Row>
                <Button type='submit' color='brand2' className='send-btn'>
                  <SendSvg />
                  Send Message
                </Button>
              </form>
            </StyledPageTextContainer>
          </StyledPageRight>
          <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <FaqSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Learn more</p>
                <Link to='/faqs'>
                  <span>FAQs & Help</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default Contactus;
