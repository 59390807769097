import React from 'react';
import styled, {
  css,
  device,
  themeGet,
  space,
  SpaceProps,
} from '@/shared/styled';

interface IModal {
  $show: boolean;
  $size?: 'xl' | 'lg' | 'md' | 'sm';
  $centered?: boolean;
}

interface IBackdrop {
  $show: boolean;
}

export const StyledBackdrop = styled.div<IBackdrop>`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0;
  overflow: hidden;
  visibility: hidden;
  transition: all 0.15s linear;

  ${({ $show }) =>
    $show &&
    css`
      opacity: 0.8;
      visibility: visible;
    `}
`;

export const StyledModal = styled.div<IModal>`
  position: fixed;
  top: 0;
  left: 0;
  right:0;
  z-index: 1050;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  outline: 0;
  opacity: 0;
  visibility: hidden;
  //transition: all 0.15s linear;

  ${({ $show }) =>
    $show &&
    css`
      opacity: 1;
      visibility: visible;
    `}
  .modal-enter {
    opacity: 0.01;

    &.modal-dialog {
      transform: translateY(-50px);
      ${device.small}{
        max-width:100%;
      }
    }
  }
  .modal-enter-done {
    opacity: 1;
    //transition: all 150ms linear;

    &.modal-dialog {
      transform: translateY(0);
    }
  }
  .modal-exit {
    opacity: 1;

    &.modal-dialog {
      transform: translateY(0);
    }
  }
  .modal-exit-active {
    opacity: 0.01;
    //transition: all 150ms linear;

    &.modal-dialog {
      transform: translateY(-50px);
    }
  }
  .modal-dialog {
    position: relative;
    width: auto;
    margin: 0.5rem;
    pointer-events: none;
    ${device.small} {
      max-width: 500px;
      margin: 1.75rem auto;
    }
    ${({ $size }) =>
      $size === 'sm' &&
      css`
        ${device.small} {
          max-width: 300px;
        }
      `}
    ${({ $size }) =>
      $size === 'md' &&
      css`
        ${device.small} {
          max-width: 450px;
        }
      `}
        ${({ $size }) =>
          $size === 'lg' &&
          css`
            ${device.large} {
              max-width: 850px;
            }
            ${device.xxlarge} {
              max-width: 850px;
            }
          `}
		${({ $size }) =>
      $size === 'xl' &&
      css`
        ${device.large} {
          max-width: 800px;
        }
        ${device.xlarge} {
          max-width: 1140px;
        }
      `}
		${({ $centered }) =>
      $centered &&
      css`
        display: flex;
        align-items: center;
        min-height: calc(100% - 1rem);
        ${device.small} {
          min-height: calc(100% - 3.5rem);
        }
      `}
  }

  .modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${themeGet('colors.overlaybg')};
    background-clip: padding-box;
    border: 1px solid ${themeGet('colors.border')};
    outline: 0;
    border-radius: 30px;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    cursor: auto;

    .termspage{
      min-height:500px !important;
    }
  }

  }
  ${device.MobToMd}{
    .popupmobile{
      position: absolute !important;
      overflow: inherit !important;
      

    }
    .modal-content{
      left: 0% !important;
      position: absolute !important;
      top: 10px !important;
      transform: translate(0px, 0px) !important;
    }
  }
  ${device.MobToSmm}{
    .modal-dialog{
      margin:0px !important;
      height: 100%;
    }
    .modal-content {
      border-radius:12px 12px 0px 0px !important;
      top: 0px !important;
      height: 100%;
      transform: translate(0px, 50px) !important;
    }
  }
`;

export const StyledHeader = styled.div`
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding: 1rem 1rem;
  border-bottom: 1px solid ${themeGet('colors.border')};
  border-top-left-radius: calc(0.3rem - 1px);
  border-top-right-radius: calc(0.3rem - 1px);
`;

export const StyledTitle = styled.h6`
  margin-bottom: 0;
  line-height: 1.5;
`;

export const StyledClose = styled.button`
  background-color: transparent;
  border: 0;
  appearance: none;
  color: ${themeGet('colors.closebtncolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  font-size: 40px;
  cursor: pointer;
  right: 18px;
  top: 18px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  width: 20px;
  span {
    width: 20px;
    display: flex;
    height: 20px;
    svg {
      width: 20px;
      display: flex;
      height: 20px;
      line {
        stroke: ${themeGet('colors.closebtncolor')};
      }
    }
  }
`;

export const StyledBody = styled(({ ...rest }) => (
  <div {...rest} />
))<SpaceProps>`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 300px;
  padding: 30px 40px 25px 40px;
  .loginform {
    label {
      font-family: ${themeGet('fonts.googleSansMedium')};
      font-size: 14px;
      color: ${themeGet('colors.InputLableText')};
      letter-spacing: 0.042px;
      line-height: 20px;
      margin-bottom: 5px;
      //cursor: pointer;
      display: flex;
      span {
        color: #ff7c54;
        position: relative;
        left: 3px;
      }
    }
    select {
      appearance: none;
      background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/rwitc/select-down-arrow.svg) !important;
      background: var(--input-bg);
      background-position: 95% 10px !important;
      background-repeat: no-repeat !important;
      background-size: 18px !important;
      background-color: ${themeGet('colors.forminputbg')};
      padding-left: 33px;
      text-transform: capitalize;
    }
  }
  select {
    appearance: none;
    background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/rwitc/select-down-arrow.svg) !important;
    background: var(--input-bg);
    background-position: 95% 10px !important;
    background-repeat: no-repeat !important;
    background-size: 18px !important;
    background-color: ${themeGet('colors.forminputbg')};
    padding-left: 33px;
    text-transform: capitalize;
  }
  ${space};
  ${device.MobToMd} {
    padding: 20px 20px 20px 20px;
  }
`;

export const StyledFooter = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: flex-end;
  padding: 0.75rem;
  border-top: 1px solid ${themeGet('colors.border')};
  border-bottom-right-radius: calc(0.3rem - 1px);
  border-bottom-left-radius: calc(0.3rem - 1px);
  & > * {
    margin: ${themeGet('radii.rounded')};
  }
`;
