import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.css';
import App from './App';
import { HelmetProvider } from 'react-helmet-async';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import ThemeProvider from './redux/providers/theme-provider';
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { store } from './config/store';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './index.styles.css';

const root = createRoot(document.getElementById('root'));

root.render(
    <HelmetProvider>
      <Provider store={store}>
        <ThemeProvider>
          <App />
          <ToastContainer style={{ width: "500px" }} />
        </ThemeProvider>
      </Provider>
    </HelmetProvider>
);

serviceWorkerRegistration.unregister();
