export const flatDeep = (arr: any[], d = 1): any[] => {
  return d > 0
    ? arr.reduce((acc, val) => {
        return acc.concat(
          Array.isArray(val) ? flatDeep(val, d - 1) : val
        ) as any[];
      }, [])
    : arr.slice();
};

export const formatNumber = (x: number): string => {
  return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
};

export const hasKey = (obj: any, key: string): boolean => {
  let current = obj;
  const components = key.split('.');
  for (let i = 0; i < components.length; i++) {
    if (
      typeof current !== 'object' ||
      !Object.prototype.hasOwnProperty.call(current, components[i])
    ) {
      return false;
    }
    current = current[components[i]];
  }
  return true;
  //return !!Object.prototype.hasOwnProperty.call(obj, key);
};
