import styled, { device, themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
  width: 100%;
  .no_padding {
    padding-right: 0px;
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: 100%;
    margin-top: 15px;
    height: 42px;
    border: none;
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    background: ${themeGet('colors.mainborder')};
  }
  .row {
    margin-bottom: 16px;
  }
  .mobilenumber {
    padding-left: 52px;
  }
  ${device.MobToMd} {
    .row {
      margin-bottom: 0px;
      .col-md-12 {
        margin-bottom: 10px;
      }
    }
    .no_padding {
      padding-right: calc(var(--bs-gutter-x) * 0.5);
      margin-bottom: 16px;
    }
    .mobilecode {
      width: 125px;
      padding-right: 0px !important;
    }
    .mobilephone {
      width: calc(100% - 125px);
    }
  }
  .posswordicon {
    position: absolute;
    height: 42px;
    right: 0px;
    top: 0px;
    width: 30px;
    display: flex;
    justify-content: center;
    z-index: 99;
    button {
      padding: 0px;
      width: 30px;
      &:active {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
      &:focus {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
    }
    svg {
      path {
        fill: #6a7a9b;
      }
    }
  }
  .infopassword {
    margin-left: 6px;
    position: relative;
    top: -2px;
  }
  .passwodicon {
    width: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: #ffffff;
    // transition: background-color 5000s ease-in-out 0s;
    // box-shadow: inset 0 0 20px 20px #23232329;
    // background-clip: text !important;
    // background: ${themeGet('colors.forminputbg')} !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color:rgba(0, 0, 0, 0) !important;
    background-color:rgb(0, 0, 0,) !important;
    box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
  }
  input:-internal-autofill-selected {
    background-clip: padding-box !important;
    background-color: #000 !important;
    border: 1px solid #1c2335;
    border-radius: 6px 6px 6px 6px !important;
    //box-shadow:none !important;
    background: ${themeGet('colors.forminputbg')} ;
    background-clip: padding-box !important;
    // -webkit-background-clip: text !important ;
    // -webkit-text-fill-color: transparent !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-internal-autofill-selected:focus{
    background-color: ${themeGet('colors.forminputbg')} !important;
    //box-shadow:none !important;
    background-clip: padding-box !important;
    //background-clip: text !important;
    background: ${themeGet('colors.forminputbg')} !important;
    background-color:rgba(0, 0, 0, 0) !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-clip: padding-box !important;
    // background-clip: text !important;
    // -webkit-text-fill-color: rgb(255, 255, 255);
    // box-shadow: none !important;
    // transition: inherit !important;
    // background-color: ${themeGet('colors.forminputbg')} !important;
    // -webkit-background-clip: text !important ;
    // -webkit-text-fill-color: transparent !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color:rgba(0, 0, 0, 0) !important;
    background-color:rgb(0, 0, 0,) !important;
    box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
}
.form-control {
  // display: block;
  // width: 100%;
  // padding: $input-padding-y $input-padding-x;
  // font-family: $input-font-family;
  // @include font-size($input-font-size);
  // font-weight: $input-font-weight;
  // line-height: $input-line-height;
  // color: $input-color;
  background-color: ${themeGet('colors.forminputbg')} !important;;
  //background-clip: padding-box;
  box-shadow: none !important;
  //border: 1px solid ${themeGet('colors.largegamecardborder')}!important ;
  appearance: none; // Fix appearance for date inputs in Safari
  // background-color:rgba(0, 0, 0, 0) !important;
  // background-color:rgb(0, 0, 0,) !important;

}
.input-group input:autofill:hover, .input-group input:autofill:focus, .input-group input:autofill:active {
  background-clip: padding-box !important;
  //box-shadow: none !important;
  transition: inherit !important;
  background-color: ${themeGet('colors.forminputbg')} !important;
  background: ${themeGet('colors.forminputbg')} ;
  -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;

}
input:-internal-autofill-selected{}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid green;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color:rgba(0, 0, 0, 0) !important;
  background-color:rgb(0, 0, 0,) !important;
  box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
}
  input:-webkit-autofill,
    input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 6px;
  text-align: center;
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  margin-bottom: 30px;
  text-align: center;
`;

export const StyledAlertMessage = styled.div`
  // color: #f31415;
  // background-color: #f8d7da;
  // border-color: #f5c6cb;
  // position: relative;
  // padding: 5px 10px;
  // margin-bottom: 1rem;
  // border: 1px solid transparent;
  // border-radius: 0.25rem;
  // display: flex;
  // align-items: center;
  // gap: 4px;
  border-radius: 8px;
  border: 1px solid #F79009;
  background: rgba(255, 255, 255, 0.05);
  gap: 8px;
  padding: 8px;
  color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-size:13px;
  font-family: ${themeGet('fonts.googleSansMedium')};
  margin-bottom: 14px;
  display: flex;
  align-items: center;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 15px;
  color: #c2c9d6;
  font-family: ${themeGet('fonts.googleSansRegular')};
  border-top: 1px solid rgba(255, 255, 255, 0.15);
  padding-top: 24px;
  margin-top: 30px;
  margin-left: -40px;
  margin-right: -40px;
  ${device.MobToMd}{
    margin-left: -20px;
    margin-right: -20px;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 5px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 15px;
  }
  ${device.MobToMd} {
    margin-top: 10px;
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 31px;
  top: 11px;
  color: #f0f0f0;
  font-size: 13px;
  z-index: 99;
`;

export const StyledPersonalContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;
