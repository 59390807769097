import styled, { device, themeGet } from '@/shared/styled';

export const StyledFooter = styled.footer`
  p {
    color: #000;
  }
`;

export const StyledFooterSupport = styled.div`
  align-items: center;
  background: #1c1c1c;
  flex-direction: column;
  display: flex !important;
  justify-content: center;
  padding: 22px 0 16px;
  position: relative;
  p {
    color: #b5b5b5;
    font-family: ${themeGet('fonts.googleSansRegular')} !important;
    font-size: 14px;
    line-height: 20px;
    margin: 0px;
  }
  a {
    color: #b5b5b5 !important;
    font-size: 13px;
    text-decoration: underline;
    transition: color 0.3s;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
  ${device.TabToLg} {
    padding: 20px 16px;
    p {
      font-size: 12px;
      line-height: 17px;
      text-align: center;
      padding-left: 10px;
      padding-right: 10px;
    }
    &:last-chaild {
      padding-top: 10px;
    }
  }
`;

export const StyledFooterMain = styled.div`
  border-bottom: 1px dotted rgba(165, 176, 189, 0.2);
  border-top: 4px solid ${themeGet('colors.btnfontcolor')};
  padding: 12px 16px;
`;

export const StyledFooterContainer = styled.div`
  align-items: center;
  justify-content: space-between;
  display: flex !important;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
    padding: 0 12px;
  }
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledFooterLogo = styled.div`
display: flex;
align-items: center;
.nemologoview{
  border-left: 1px solid #595959;
  padding-left: 15px;
  margin-left: 15px;
}
 .nemologo{
  width: 90px;
 }

 ${device.MobToSmm}{
  .arionplaylogo{
    width: 150px;
  }
  .nemologo{
    width: 80px;
  }
 }
`;

export const StyledFooterPartnered = styled.div`
  ul {
    display: flex !important;
    align-items: center;
    gap: 10px;
    list-style: none;
  }
  img {
    transition: opacity 0.3s;
  }
  ${device.TabToLg} {
    margin-bottom: 20px;
    margin-top: 20px;
  }
  ${device.MobToSL}{
    img{
      width: 100%;
    }
  }
`;

export const StyledFooterSocialMedia = styled.div`
  ul {
    display: flex !important;
    align-items: center;
    gap: 10px;
    list-style: none;
    a:hover{
      svg path{  fill: ${themeGet('colors.btnfontcolor')};
    }
  }
  svg {
    overflow: hidden;
  }
  img,
  svg {
    vertical-align: middle;
  }
  svg path {
    fill: #e1e7ed;
    transition: all .3s;
  }
  ${device.TabToLg} {
    a{ display: flex !important;}
    a:hover{
      svg path{  fill: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledFooterMenu = styled.div`
  align-items: center;
  display: flex;
  justify-content: space-between;
  padding: 12px 16px;
  display: flex !important;
`;

export const StyledFooterMenuWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  ${device.xlarge} {
    float: inherit;
    margin: 0 auto;
    padding: 0 12px;
    width: 1280px !important;
  }
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledFooterMenuListContainer = styled.div`
  ul {
    display: flex !important;
    list-style: none;
  }
  li {
    active{ color: ${themeGet('colors.btnfontcolor')};}
    &:last-child {
      a {
        border-right: none;
        }
    }
  }
  li a {
    border-right: 1px solid #595959;
    color: #b5b5b5;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 14px;
    margin-right: 12px;
    padding-right: 12px;
    text-decoration: none;
    transition: color 0.3s;
    &:hover {
      color: ${themeGet('colors.btnfontcolor')};
    }
    &:focus {
      color: ${themeGet('colors.btnfontcolor')};
    }
    &.active {
      color: ${themeGet('colors.btnfontcolor')};
    }
    .active {
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
  &.active {
    color: ${themeGet('colors.btnfontcolor')};
  }
  ${device.TabToLg} {
    ul {
      float: left;
      width: 100%;
      display: initial !important;
      text-align: center;
      margin: 0px auto;
    }
    li {
      display: inline-block;
      margin-bottom: 7px;
      width: auto;
      &:last-child {
        a {
          border-right: 0px !important;
        }
      }
    }
    a {
      font-size: 12px !important;
      margin-right: 10px !important;
      padding-right: 10px !important;
      float: left;
    }
  }
`;

export const StyledFooterCopyRight = styled.div`
  p {
    //margin-top: 16px;
    font-size: 14px;
    color: ${themeGet('colors.copyrighttextcolor')};
    margin-bottom: 0px;
  }
  span {
    font-size: 13px;
    position: relative;
    top: 2px;
  }
  p.version_text {
    font-family: ${themeGet('fonts.googleSansBold')} !important;
    font-size: 10px !important;
    text-align: right;
  }
  ${device.TabToLg} {
    p {
      font-size: 11px;
      //padding-left: 10px;
      text-align: center;
      white-space: break-spaces;
    }
    span {
      font-size: 16px;
      position: relative;
      top: 2px;
    }
  }
`;
