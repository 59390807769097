import styled, { themeGet, device } from '@/shared/styled';

export const StyledKycDetailsView = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  ${device.MobToMd} {
    justify-content: center;
  }
`;

export const StyledKycSubmit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;
  border-left: 1px solid rgba(217, 217, 217, 0.3);
  margin-left: 15px;
  svg {
    path {
    }
  }
  h4 {
    color: ${themeGet('colors.mainborder')};
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
    color: ${themeGet('colors.copyrighttextcolor')};
    font-size: 15px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  button {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 31px;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 15px;
    border-radius: 6px;
  }
  //  ${device.mdToLg}{
  //   h4{display:none;}
  //   p{display:none;}
  //   //button{display:none;}
  //  }
  ${device.MobToMd} {
    padding: 6px 0px 6px 16px;
    margin-left: 0px;
    gap: 8px;
    border-left:0px;
    svg {
      height: 22px;
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
    button {
      height: 28px;
      font-size: 12px;
      padding: 0px 10px;
    }
  }
`;

export const StyledKycProgress = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;
  border-left: 1px solid rgba(217, 217, 217, 0.3);
  margin-left: 15px;
  svg {
    path {
    }
  }
  h4 {
    color: ${themeGet('colors.mainborder')};
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
    color: ${themeGet('colors.copyrighttextcolor')};
    font-size: 15px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  button {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 31px;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 15px;
    border-radius: 6px;
  }
  ${device.MobToMd} {
    padding: 6px 0px 6px 16px;
    margin-left: 0px;
    border-left:0px;
    gap: 8px;
    svg {
      height: 22px;
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
    button {
      height: 28px;
      font-size: 12px;
      padding: 0px 10px;
    }
  }
`;

export const StyledKycVerified = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;
  border-left: 1px solid rgba(217, 217, 217, 0.3);
  margin-left: 15px;
  svg {
    path {
    }
  }
  h4 {
    color: ${themeGet('colors.mainborder')};
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
    color: #059669;
    font-size: 15px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  button {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 31px;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 15px;
    border-radius: 6px;
  }
  ${device.MobToMd} {
    padding: 6px 0px 6px 16px;
    margin-left: 0px;
    gap: 8px;
    border-left:0px;
    svg {
      height: 22px;
    }
    h4 {
      font-size: 14px;
    }
    p {
      font-size: 13px;
    }
    button {
      height: 28px;
      font-size: 12px;
      padding: 0px 10px;
    }
  }
`;

export const StyledKycReSubmit = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  padding-left: 15px;
  border-left: 1px solid rgba(217, 217, 217, 0.3);
  margin-left: 15px;
  svg {
    path {
    }
  }
  h4 {
    color: ${themeGet('colors.mainborder')};
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    margin-bottom: 0px;
  }
  p {
    margin-bottom: 0px;
    color: #ff4d66;
    font-size: 15px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  button {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 31px;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 15px;
    border-radius: 6px;
  }
  ${device.MobToMd} {
    border-left: 0px;
    padding-left: 0px;
    gap: 7px;
    width: 100%;
    padding-top: 8px;
    padding-bottom: 8px;
    p {
      font-size: 14px;
    }
  }
`;
