import { Action, ThunkAction, configureStore } from '@reduxjs/toolkit';
import themeReducer from '@/redux/slices/theme';
import { authenticationReducer } from '@/redux/slices/login';
import { racecardReducer } from '@/redux/slices/racecard';
import moduleReducer from '@/redux/slices/module';
import { registerReducer } from '@/redux/slices/register';
import { registerOTPReducer } from '@/redux/slices/registerotp';
import { settingReducer } from '@/redux/slices/setting';
import { forgotOTPReducer } from '@/redux/slices/forgotpassword';
import { walletReducer } from '@/redux/slices/wallet';

export const store = configureStore({
  reducer: {
    theme: themeReducer,
    auth: authenticationReducer,
    register: registerReducer,
    registerotp: registerOTPReducer,
    raceinfo: racecardReducer,
    module: moduleReducer,
    setting: settingReducer,
    forgototp: forgotOTPReducer,
    wallet: walletReducer,
  },
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<
  ReturnType,
  RootState,
  unknown,
  Action<string>
>;
