import React from 'react';
import { Button } from '@/components';
import { LoginSvg, SignUpSvg } from '@/shared/svg';
import { StyledHeaderButtonGroup } from './style';

interface ChildProps {
  parentModalHandlerFunction: any;
}
export const PreLoginButtons = (props: ChildProps) => {
  return (
    <>
      <StyledHeaderButtonGroup>
        <Button
          onClick={() => props.parentModalHandlerFunction('login')}
          className='loginbtn'
        >
          {' '}
          <LoginSvg />
          Login{' '}
        </Button>
        <Button
          onClick={() => props.parentModalHandlerFunction('register')}
          className='signupbtn'
        >
          {' '}
          <SignUpSvg />
          Signup{' '}
        </Button>
      </StyledHeaderButtonGroup>
    </>
  );
};
