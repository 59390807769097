import React, { useEffect, useState } from 'react';
import {
  StyledContainerRunner,
  StyledjockyInfo,
  StyledRidingInfo,
  StyedHorseSireDamName,
  StyledJockyTrainer,
  StyledActionInfo,
  StyledActionButton,
  StyledOddInfo,
  StyledScrateched,
} from '@/pages/betting/style';
import { PlusIconSvg, SelectedSvg } from '@/shared/svg';
import { Button } from '@/components';

type RunnerType = {
  runner: any;
  raceStatus: string;
  betType: string;
  name: string;
  selectedPoolCode: string;
  selectedRunners: Function;
  selectedRunnersList: any;
  selectedLeg: number;
  positions: number;
  legs: number;
  boxOption: boolean;
  isSuspended:string;
};

const RunnerFixed: React.FC<RunnerType> = ({
  runner,
  raceStatus,
  betType,
  name,
  selectedPoolCode,
  selectedRunners,
  selectedRunnersList,
  selectedLeg,
  positions,
  legs,
  boxOption,
  isSuspended,
}) => {
  const [numberOfPositions, setNumberOfPositions] = useState(1);
  const [runnersList, setRunnersList] = useState([]);
  useEffect(() => {
    let pos = 1;
    if (!boxOption) pos = positions;
    setNumberOfPositions(pos);
  }, [positions, boxOption]);

  useEffect(() => {
    if (legs > 1) {
      const arr = [];
      arr[0] = selectedRunnersList[selectedLeg - 1];
      setRunnersList(arr);
    } else {
      setRunnersList(selectedRunnersList);
    }
  }, [legs, selectedLeg, selectedRunnersList]);

  return (
    <>
      <StyledContainerRunner
        className={`${runner.scratched === 'Y' ? 'scratched' : positions === 1 ? 'racecardheight' : 'racecardheight'}`}
      >
        <ul>
          <li>
            <StyledjockyInfo>
              <span className='infonumber'>{runner.position}</span>
              <img src={runner.jerseyUrl} />
              <small>({runner.drawNumber})</small>
            </StyledjockyInfo>
            <StyledRidingInfo>
              <StyedHorseSireDamName>
                <span className='horse_name'>{name}</span>
                <span className='sire_dam_name'>
                  ({runner.desc})*({runner.shortForm})*(Rt.{runner.rating})
                </span>
              </StyedHorseSireDamName>
              <StyledJockyTrainer>
                <span className='jockey_name'>
                  <b>J&nbsp;&nbsp;</b>
                  {runner.jockey.name}
                  <span>
                    ( wt {runner.weight} <span>Aw.0</span> ){' '}
                  </span>
                </span>
                <span className='trainer_name'>
                  <b>T&nbsp;&nbsp;</b>
                  {runner.trainer.name}{runner.scratched}
                </span>
              </StyledJockyTrainer>
            </StyledRidingInfo>
            <StyledActionInfo>
              {(isSuspended === 'Y' && (raceStatus === 'BST'))? (
                <StyledScrateched>
                <span>S</span>
                <p>Suspended</p>
              </StyledScrateched>
              ):(runner.scratched === 'Y') ? (
                <StyledScrateched>
                  <span>W</span>
                  <p>Scratched</p>
                </StyledScrateched>
              ) : raceStatus === 'BNS' || raceStatus === 'BSP' ? (
                ''
              ) : (raceStatus === 'DRL' || raceStatus === 'ALC') ? (
                <>
                  <StyledActionButton>
                    <Button type='button' className='winbutton'>
                    {runner.odds[selectedPoolCode.toUpperCase()] || 0}
                    </Button>
                  </StyledActionButton>
                </>
              ) : (
                <>
                  <StyledActionButton
                    className={`${positions === 1 ? 'multibet' : 'multibet'}`}
                  >
                    <div className='fixedbet'>
                        <Button
                          type='button'
                          className={`winbutton ${selectedPoolCode ==='fowin' && runnersList &&
                          runnersList[0] &&
                          runnersList[0].includes(runner.position)?'active':''}`}
                          disabled={parseFloat(runner.odds['FOWIN']) === 0}
                          onClick={() => selectedRunners('FOWIN', runner.position, runner.odds['FOWIN'])}
                        >
                          <p className='buttontext'>{runner.odds['FOWIN']}</p>
                          {selectedPoolCode ==='fowin' && runnersList &&
                          runnersList[0] &&
                          runnersList[0].includes(runner.position) ? (
                            <SelectedSvg />
                          ) : (
                            (parseFloat(runner.odds['FOWIN']) > 0 && <PlusIconSvg />)
                          )}
                        </Button>
                        <Button
                          type='button'
                          className={`winbutton ${selectedPoolCode ==='foplc' && runnersList &&
                          runnersList[0] &&
                          runnersList[0].includes(runner.position)?'active':''}`}
                          disabled={parseFloat(runner.odds['FOPLC']) === 0}
                          onClick={() => selectedRunners('FOPLC', runner.position, runner.odds['FOWIN'])}
                        >
                         <p className='buttontext'>{runner.odds['FOPLC']}</p>
                         {selectedPoolCode ==='foplc' && runnersList &&
                          runnersList[0] &&
                          runnersList[0].includes(runner.position) ? (
                            <SelectedSvg />
                          ) : (
                            (parseFloat(runner.odds['FOPLC']) > 0 && <PlusIconSvg />)
                          )}
                        </Button>
                      </div>
                  </StyledActionButton>
                </>
              )}
            </StyledActionInfo>
          </li>
        </ul>
      </StyledContainerRunner>
    </>
  );
};

export default RunnerFixed;
