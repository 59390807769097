/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { themeGet, SpaceProps, BorderProps } from '@/shared/styled';
import selectIcon from '@/components/forms/images/select.svg';

import {
  InputStyles,
  SuccessInputStyles,
  WarningInputStyles,
  ErrorInputStyles,
} from '@/components/forms/form-elements/style';
import {
  IFeedback,
  TCustomStyle,
} from '@/components/forms/form-elements/types';

interface IInput extends IFeedback, SpaceProps, BorderProps {
  $width?: string | any[];
  $height?: string | any[];
  $customStyle?: TCustomStyle;
}

export const StyledSelect = styled('select')<IInput>`
  border-color: ${themeGet('colors.text4')};
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background: #fff url(${selectIcon}) no-repeat right 0.625rem center/8px 10px;
  ${InputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'success' &&
    !!$showState &&
    !$showErrorOnly &&
    SuccessInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'warning' &&
    !!$showState &&
    !$showErrorOnly &&
    WarningInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'error' && !!$showState && !!$showErrorOnly && ErrorInputStyles};
`;
