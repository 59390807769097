import styled, { themeGet } from '@/shared/styled';

export const StyledResultsContainer = styled.div`
width: 100%;
display: flex;
position: relative;
flex-direction: column;
//border: 1px solid ${themeGet('colors.forminputbg')};
// border-radius: 10px 10px 10px 10px;
// height: 100%;
`;

export const StyledResultHeader = styled.div`
border-radius: 6px 6px 0 0;
border-top: none;
display: flex;
background:${themeGet('colors.tableheaderbg')};
padding: 10px 15px;
  .runners{
    width: calc(100% - 120px);
    font-size:12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    text-transform: uppercase;
  }
  .win{
    text-align: right;
    width: 60px;
    font-size:12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    text-transform: uppercase;
  }
  .place{
    text-align: right;
    width: 60px;
    font-size:12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    text-transform: uppercase;
  }
`;

export const StyledResultBody = styled.div`
  background: ${themeGet('colors.eventnumberdefaultbg')};
  border: 1px solid #232935;
  border-radius: 0 0 10px 10px;
  //max-height: calc(100vh - 210px);
  //height: 230px;
  //overflow: auto;
  ul{
    li{
      border-bottom: 1px solid #232935;
      padding: 10px 15px;
      width: 100%;
      display: flex;
      .position{ width: 40px;
        span{
          color: #c2c9d6;
          float: left;
          font-size: 12px;
          line-height: 20px;
          width: 100%;
        }
      }
      .horse_no{
        background: ${themeGet('colors.mainborder')};
        border-radius: 2px;
        color: ${themeGet('colors.white')};
        font-family: ${themeGet('fonts.googleSansMedium')};
        height: 20px;
        line-height: 20px;
        margin-right: 10px;
        text-align: center;
        width: 30px;
      }
      .horse_name{
        font-family: ${themeGet('fonts.googleSansMedium')};
        font-size: 12px;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        width: calc(100% - 200px);
        color: #c2c9d6;
      }
      .win{    
        text-align: right;
        width: 60px;
        color: #c2c9d6;
        float: left;
        font-size: 12px;
        line-height: 20px;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
      .place{
        text-align: right;
        width: 60px;
        color: #c2c9d6;
        float: left;
        font-size: 12px;
        line-height: 20px;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
      .multi_pool{
        line-height: 16px;
        width: calc(100% - 60px);
        color: #c2c9d6;
        font-size: 12px;
        font-family: ${themeGet('fonts.googleSansMedium')};
        display: flex;
        flex-direction: column;
      }
      .place{
        text-align: right;
        width: 60px;
        color: #c2c9d6;
        font-size: 12px;
        line-height: 20px;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
    }
  }
`;