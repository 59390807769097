import { PayloadAction, createSlice } from '@reduxjs/toolkit';

export interface IModule {
  moduleName: string;
}

const initialState: IModule = {
  moduleName: '',
};

export const moduleSlice = createSlice({
  name: 'module',
  initialState,
  reducers: {
    changeModule: (state, action: PayloadAction<string | null>) => {
      state.moduleName = action.payload;
    },
  },
});
export const { changeModule } = moduleSlice.actions;
export default moduleSlice.reducer;
