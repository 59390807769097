/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, {
  themeGet,
  css,
  space,
  border as borderStyles,
  SpaceProps,
  BorderProps,
  LayoutProps,
  device,
} from '@/shared/styled';
import { IFeedback, TCustomStyle } from './types';

interface IInput extends IFeedback, SpaceProps, BorderProps, LayoutProps {
  $width?: string | any[];
  $height?: string | any[];
  $customStyle?: TCustomStyle;
}

export const InputStyles = css<IInput>`
  display: block;
  width: 100%;
  height: 42px;
  padding: 6px 12px 6px 33px;
  font-size: 14px;
  font-family: ${themeGet('fonts.googleSansRegular')};
  color: #f0f0f0;
  background-color: ${themeGet('colors.forminputbg')};
  background-clip: padding-box;
  border: 1px solid #384766;
  border-radius: 6px 6px 6px 6px !important;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;

  &[hidden] {
    display: none;
  }

  ${({ $width }) =>
    !!$width &&
    css`
      ${!Array.isArray($width) &&
      css`
        width: ${$width};
      `}
      ${Array.isArray($width) &&
      css`
        width: ${$width[0]};
        ${device.small} {
          width: ${$width[1]};
        }
        ${device.medium} {
          width: ${$width[2]};
        }
        ${device.large} {
          width: ${$width[3]};
        }
        ${device.xlarge} {
          width: ${$width[4]};
        }
      `}
    `}

  ${({ $height }) =>
    !!$height &&
    css`
      ${!Array.isArray($height) &&
      css`
        height: ${$height};
      `}
      ${Array.isArray($height) &&
      css`
        height: ${$height[0]};
        ${device.small} {
          height: ${$height[1]};
        }
        ${device.medium} {
          height: ${$height[2]};
        }
        ${device.large} {
          height: ${$height[3]};
        }
        ${device.xlarge} {
          height: ${$height[4]};
        }
      `}
    `}

    &::placeholder {
    color: ${themeGet('colors.white')};
    font-size: ${themeGet('fontSize.body')};
    font-family: ${themeGet('fonts.body')};
  }
  &:-ms-input-placeholder {
    color: ${themeGet('colors.white')};
    font-size: ${themeGet('fontSize.body')};
    font-family: ${themeGet('fonts.body')};
  }
  &::-ms-input-placeholder {
    color: ${themeGet('colors.white')};
    font-size: ${themeGet('fontSize.body')};
    font-family: ${themeGet('fonts.body')};
  }
  &:disabled,
  &[readonly] {
    background-color: ${themeGet('colors.whisper')};
  }
  &:focus {
    color: #f0f0f0;
    background-color: ${themeGet('colors.forminputbg')};
    border-color: ${themeGet('colors.forminputfocusborder')};
    outline: 0;
    box-shadow: none;
  }

  ${({ $customStyle }) =>
    $customStyle === 'noborder' &&
    css`
      padding: 0;
      color: ${themeGet('colors.text')};
      border-width: 0;
      background-color: transparent;
      &:focus {
        box-shadow: none;
        color: ${themeGet('colors.text')};
      }
    `}

  ${({ $customStyle }) =>
    $customStyle === 'nofocus' &&
    css`
      &:focus {
        box-shadow: none;
      }
    `}
    ${props =>
    props.theme.name === 'dark' &&
    css`
      background-color: transparent;
      border-color: ${themeGet('colors.gray800')};
      color: ${themeGet('colors.gray300')};
      &::placeholder {
        color: ${themeGet('colors.gray300')};
      }
      &:-ms-input-placeholder {
        color: ${themeGet('colors.gray300')};
      }
      &::-ms-input-placeholder {
        color: ${themeGet('colors.gray300')};
      }
      &:focus {
        background-color: transparent;
      }
    `}

    ${space};
  ${borderStyles};
`;

export const SuccessInputStyles = css`
  border-color: ${themeGet('colors.success')};
  padding-right: calc(1.5em + 0.9375rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.23438rem) center;
  background-size: calc(0.75em + 0.46875rem) calc(0.75em + 0.46875rem);
  &:focus {
    border-color: ${themeGet('colors.success')};
    //box-shadow: 0 0 0 0.2rem rgb(16 183 89 / 25%);
  }
`;

export const WarningInputStyles = css`
  border-color: ${themeGet('colors.warning')};
  padding-right: calc(1.5em + 0.9375rem);
  background-repeat: no-repeat;
  background-position: right calc(0.375em + 0.23438rem) center;
  background-size: calc(0.75em + 0.46875rem) calc(0.75em + 0.46875rem);
  &:focus {
    border-color: ${themeGet('colors.warning')};
    box-shadow: none;
  }
`;

export const ErrorInputStyles = css`
  border-color: #ff7c54;

  &:focus {
    border-color: ${themeGet('colors.forminputfocusborder')};
  }
`;

export const allowedProps = [
  'width',
  'height',
  'p',
  'px',
  'py',
  'pl',
  'pr',
  'pt',
  'pb',
  'm',
  'mx',
  'my',
  'ml',
  'mr',
  'mt',
  'mb',
  'color',
  'border',
  'borderWidth',
  'fontSize',
  'fontWeight',
];

interface IProps extends BorderProps, SpaceProps {}

export const StyledBoxWrap = styled('div')<IProps>`
  min-height: inherit;
  position: relative;
  display: block;
  padding-left: 1.5rem;
  ${space};
`;
