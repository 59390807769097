import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
  ArrowLeftSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledTextArea,
  StyledpageViewMain,
  StyledpagHeader,
  StyledpagBody,

  
} from './style';
import Accordion from 'react-bootstrap/esm/Accordion';


const FaqDepositsWithdrawals = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
      <h3>FAQs &amp; Help</h3>
        <StyledContainerPageView>
        <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledpageViewMain>
                <StyledpagHeader>
                 <Link to='/faqs'><ArrowLeftSvg/></Link>  <h4>Deposits, Withdrawals & Refund</h4>
                </StyledpagHeader>
                <StyledpagBody>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What is the process for depositing funds into my account?</Accordion.Header>
                      <Accordion.Body>
                        {/* <h4>{process.env.REACT_APP_SITE_TITLE} makes depositing funds a breeze. Just follow these simple steps:</h4> */}
                        <h4>{process.env.REACT_APP_SITE_TITLE} makes depositing funds a breeze. Just follow these simple steps:</h4>
                        <ul>
                        <li>Log in to your account using your mobile number and password.</li>
                        <li>Click the ""Deposit"" button on the top right corner of the page.</li>
                        <li>Enter the desired deposit amount.</li>
                        <li>You will be redirected to the Gcash page, confirm your deposit using your gcash account and complete the transaction successfully.</li>
                        <li>Upon successful completion, the amounts should automatically be credited into your {process.env.REACT_APP_SITE_TITLE} wallet.</li>
                        </ul>
                        {/* <ul>
                          <li>Log in to your account using your username and password.</li>
                          <li>Navigate to the "Deposit" or "My Account" page.</li>
                          <li>Select your preferred deposit method.</li>
                          <li>Input the necessary payment details, such as credit card number, UPI ID, or Net banking information.</li>
                          <li>Enter the desired deposit amount.</li>
                          <li>Confirm your deposit to complete the transaction successfully.</li>
                        </ul> */}
                      </Accordion.Body>
                    </Accordion.Item>
                    {/* <Accordion.Item eventKey="1">
                      <Accordion.Header>What deposit methods are available?</Accordion.Header>
                      <Accordion.Body>
                      <h4>{process.env.REACT_APP_SITE_TITLE} provides multiple deposit methods, such as:</h4>
                        <ul>
                          <li>Credit card</li>
                          <li>Debit card</li>
                          <li>UPI</li>
                          <li>E-wallets</li>
                        </ul>
                        <p>Please be aware that availability may vary based on your region.</p>
                      </Accordion.Body>
                    </Accordion.Item> */}
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>Is there a minimum deposit amount?</Accordion.Header>
                      <Accordion.Body>
                      <p>Yes, the minimum amount you can deposit is ₹100.</p>
                      </Accordion.Body>
                    </Accordion.Item> 
                  {/* <Accordion.Item eventKey="3">
                      <Accordion.Header>What should I do if my deposit is not accepted?</Accordion.Header>
                      <Accordion.Body>
                      <p>If your deposit is not accepted, please check that you have sufficient funds or remaining credit
available. If the issue persists, don't hesitate to contact our support team for prompt assistance and
resolution. We are here to help you with any deposit-related concerns you may have.</p>
                      </Accordion.Body>
                  </Accordion.Item> */}
                  <Accordion.Item eventKey="2">
                      <Accordion.Header>How do I withdraw my winnings?</Accordion.Header>
                      <Accordion.Body>
                      <h4>Click on your wallet balance found on the top right corner of the page, and find the ""Withdraw"" section.</h4>
                      <ul>
                        <li>Confirm your Gcash mobile number. Note: You may only withdraw into your personal Gcash wallet. Withdrawal of funds to another person's Gcash account may cause for account suspension.</li>
                        <li>Enter the amount you wish to withdraw.</li>
                        <li>Click on the ""Continue"" button to submit.</li>
                      </ul>
<p>Wait for a confirmation message to verify that your withdrawal request has been processed.
Please note that processing times for withdrawals may vary depending on the correctness of the provided Gcash account number. If you encounter any issues or have questions during the process, feel free to contact our support team for assistance.</p>
                      {/* <ul>
                          <li>Go to the "My Account" page and find the "Withdraw" section.</li>
                          <li>Enter the amount you wish to withdraw.</li>
                          <li>Click on the "Withdraw" button.</li>
                          <li>Wait for a confirmation message to verify that your withdrawal request has been processed.</li>
                        </ul>
                        <p>Please note that processing times for withdrawals may vary depending on the chosen withdrawal
method and any additional verification requirements. If you encounter any issues or have questions
during the process, feel free to contact our support team for assistance.</p> */}
                      </Accordion.Body>
                  </Accordion.Item>
                  </Accordion>
                </StyledpagBody>
              </StyledpageViewMain>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default FaqDepositsWithdrawals;
