import React, { useEffect, useState } from 'react';
import PersonalForm from './personal';
import AccountForm from './account';
import SignupotpForm from './signupotp';
import AccountcreatedForm from './accountcreated';

interface ChildProps {
  parentModalHandlerFunction: any;
  parentModalSizeHandler: any;
  setPhoneNumber: any;
  setPassword: any;
  setScreen: any;
  closePopup(arg0: string): void;
}

const SignupForm = (props: ChildProps) => {
  const [displayStep, setDisplayStep] = useState('account');

  const changeSteps = (moduleName?: string) => {
    if (moduleName === 'login') {
      //props.closePopup('close');
      props.parentModalHandlerFunction('login');
      return;
    }
    setDisplayStep(moduleName);
    if (moduleName === 'account') props.parentModalSizeHandler('lg');
    if (moduleName === 'signupotp') props.parentModalSizeHandler('md');
  };

  useEffect(() => {
    setDisplayStep('account');
  }, []);

  const closePopup = () => {
    props.closePopup('close');
  };

  return (
    <>
      {displayStep === 'personal' && <PersonalForm changeSteps={changeSteps} />}
      {displayStep === 'account' && <AccountForm changeSteps={changeSteps} />}
      {displayStep === 'signupotp' && (
        <SignupotpForm changeSteps={changeSteps} />
      )}
      {displayStep === 'accountcreated' && (
        <AccountcreatedForm changeSteps={changeSteps} closePopup={closePopup} />
      )}
    </>
  );
};

export default SignupForm;
