import classnames from 'clsx';
import { StyledClose } from './style';
import { IClose } from './types';
import { CloseIconSvg } from '@/shared/svg';
import React from 'react';

const ModalClose = ({ className, onClose, ...restProps }: IClose) => {
  return (
    <StyledClose
      type='button'
      className={classnames(className, 'close')}
      onClick={onClose}
      {...restProps}
      data-dismiss='modal'
      aria-label='Close'
    >
      <span aria-hidden='true'>
        <CloseIconSvg />
      </span>
    </StyledClose>
  );
};

ModalClose.displayName = 'ModalClose';

export default ModalClose;
