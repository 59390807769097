import styled, { css } from '@/shared/styled';
import { IFeedback } from '@/components/forms/form-elements/types';

export const StyledFeedback = styled.div<IFeedback>`
  width: 100%;
  margin-top: 0.25rem;
  font-size: 11px;
  ${({ $state, $showErrorOnly }) =>
    $state !== 'error' &&
    $showErrorOnly &&
    css`
      display: none;
    `}
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'success' &&
    $showState &&
    !$showErrorOnly &&
    css`
      color: #ff7c54;
    `};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'warning' &&
    $showState &&
    !$showErrorOnly &&
    css`
      color: #ff7c54;
    `};

  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'error' &&
    $showState &&
    $showErrorOnly &&
    css`
      color: #ff7c54;
      position: absolute;
      top: 38px;
      right: 0px;
      text-align: right;
    `};
`;
