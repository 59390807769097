import React, { useState, useEffect } from 'react';
import { ProfileNameSvg } from '@/shared/svg';
import {
  StyledMyAccountContainer,
  StyledMyAccountView,
  StyledMyAccountHeader,
  StyledMyAccountBoby,
  StyledMyAccountLeft,
  StyledMyAccountRight,
  StyledAccountProfile,
  StyledAccountButton,
  StyledAccountName,
  StyledAccountMide,
  StyledAccountAction,
  StyledMyactiveHeader,
  StyledMyactiveBody,
  StyledMobileList,
  StyledAccountMobile,
} from './style';
import AuthService from '@/services/AuthService';
import { useAppSelector } from '@/hooks/useAppSelector';
import moment from 'moment/moment';
import CookieStorageService from '@/services/CookieStorageService';
import { resetUser, setIsAuthenticatedData } from '@/redux/slices/login';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { history } from '@/helpers/history';

const Myactivity = () => {
  const { userInfo } = useAppSelector(state => state.auth);
  const dispatch = useAppDispatch();
  const [myactivityData, setMyactivityData] = useState([]);
  const getMyactivityData = async () => {
    const response = await AuthService.getMyactivityData();
    setMyactivityData(response.data);
  };
  const logoutUser = () => {
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    dispatch(setIsAuthenticatedData(false));
    dispatch(resetUser());
    history.push('/');
  };
  useEffect(() => {
    getMyactivityData();
  }, []);
  return (
    <>
      <StyledMyAccountContainer>
        <StyledMyAccountView>
          <StyledMyAccountHeader>
            <h4>My Activity</h4>
          </StyledMyAccountHeader>
          <StyledMyAccountBoby>
            {/* <StyledMyAccountLeft>
              <StyledAccountProfile>
                <ProfileNameSvg />
                <h5>{userInfo?.username}</h5>
                <p>
                  Member since{' '}
                  {moment(userInfo?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </p>
              </StyledAccountProfile>
              <StyledAccountButton>
                <button
                  type='button'
                  className='signout_profile'
                  onClick={() => logoutUser()}
                >
                  Sign Out
                </button>
                <button type='button' className='deactiv_profile'>
                  Deactivate
                </button>
              </StyledAccountButton>
            </StyledMyAccountLeft> */}

            <StyledMyAccountRight>
              <h4>Displaying your latest account activity.</h4>
              <StyledMyactiveHeader>
                <ul>
                  <li>
                    <StyledAccountName>Action</StyledAccountName>
                    <StyledAccountMide>Description</StyledAccountMide>
                    <StyledAccountMide>IP Address</StyledAccountMide>
                    <StyledAccountAction>Time</StyledAccountAction>
                    <StyledAccountMobile>
                      IP Address/Time
                    </StyledAccountMobile>
                  </li>
                </ul>
              </StyledMyactiveHeader>
              <StyledMyactiveBody>
                <ul>
                  {myactivityData.map((item, index) => {
                    return (
                      <>
                        <li key={index}>
                          <StyledAccountName>{item.action}</StyledAccountName>
                          <StyledAccountMide>{item.description}</StyledAccountMide>
                          <StyledAccountMide>{item.ip}</StyledAccountMide>
                          <StyledAccountAction>
                            {moment(item.updatedAt).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                          </StyledAccountAction>
                          <StyledMobileList>
                           <p>{item.ip}</p>
                           <p>
                           {moment(item.updatedAt).format(
                              'DD/MM/YYYY HH:mm:ss'
                            )}
                           </p>
                          </StyledMobileList>
                        </li>
                      </>
                    );
                  })}
                </ul>
              </StyledMyactiveBody>
            </StyledMyAccountRight>
          </StyledMyAccountBoby>
        </StyledMyAccountView>
      </StyledMyAccountContainer>
    </>
  );
};

export default Myactivity;
