import { createSlice } from '@reduxjs/toolkit';

const initialState: IAuthentication = {
  userInfo: undefined,
  isAuthenticated: false,
  isKYCStatus: 'created',
  isKYCRejected: undefined,
  isKYCRejectedRemarks: undefined,
  isKYCRejectedReason: undefined,
  isLoading: false,
  isKYCPopup: false,
  isSuccess: undefined,
  isError: undefined,
  errorMessage: undefined,
};

const authenticationSlice = createSlice({
  name: 'login',
  initialState,
  reducers: {
    resetUser: state => {
      Object.assign(state, initialState);
    },
    setUserData(state, action) {
      state.userInfo = action.payload;
    },
    setKYCRejectedData(state, action) {
      state.isKYCRejected = action.payload;
    },
    setKYCRejectedRemarks(state, action) {
      state.isKYCRejectedRemarks = action.payload;
    },
    setKYCRejectedReason(state, action) {
      state.isKYCRejectedReason = action.payload;
    },
    setIsAuthenticatedData(state, action) {
      if (action.payload != undefined) state.isAuthenticated = action.payload;
    },
    setIsKYCStatus(state, action) {
      if (action.payload != undefined) state.isKYCStatus = action.payload;
    },
    setIsKYCPopup(state, action) {
      if (action.payload != undefined) state.isKYCPopup = action.payload;
    },
  },
});
export const {
  setUserData,
  setIsAuthenticatedData,
  setIsKYCStatus,
  setIsKYCPopup,
  resetUser,
  setKYCRejectedData,
  setKYCRejectedReason,
  setKYCRejectedRemarks,
} = authenticationSlice.actions;
export const authenticationReducer = authenticationSlice.reducer;
