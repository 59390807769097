import styled, { device, themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
  width: 100%;
  .loginform {
    border-radius: 16px;
    background: rgba(255, 255, 255, 0.05);
    padding: 16px 16px 16px 16px;
    margin-top: 20px;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
  .no_padding {
    padding-right: 0px;
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: 100%;
    margin-top: 15px;
    height: 42px;
    border: none;
  }
  .row {
    margin-bottom: 16px;
  }
  .mobilenumber {
    padding-left: 52px;
  }
  .termsandcondition {
    height: 300px;
    overflow-y: auto;
    color: #fff;
    padding-right: 10px;
    ${device.TabToLg} {
      height: 350px;
    }
    p {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
      margin-bottom: 6px;
    }
    h5 {
      font-size: 15px;
      color: #a4adc0;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
    ol {
      padding-left: 25px;
      h4 {
        font-size: 16px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
      li {
        font-size: 14px;
        margin-bottom: 8px;
        color: #a4adc0;
        font-family: ${themeGet('fonts.googleSansRegular')};
      }
    }
  }
  .nomargin {
    margin-bottom: 0px;
  }
  form {
    display: flex;
    width: 100%;
    flex-direction: column;
  }
  .termsview {
  }
  .bettypetext{
    h6{
      color:${themeGet('colors.eventnumberdefaulttxtcolor')};
      font-size: 15px
      font-family: ${themeGet('fonts.googleSansMedium')};

     }
     li{
      color:${themeGet('colors.eventnumberdefaulttxtcolor')};
      font-size: 15px;
      font-family: ${themeGet('fonts.googleSansBold')};
      line-height: 20px;
     }
     p{
      color:#A3ADC2;
      font-size: 15px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      line-height: 20px;
     }
  }
`;

export const StyledOverView  = styled.div`
position: fixed;
top: 0;
left: 0;
right: 0;
z-index: 1040;
width: 100vw;
height: 100vh;
background-color: #000;
opacity: 0;
overflow: hidden;
visibility: hidden;
transition: all 0.15s linear;
opacity: 0.8;
visibility: visible;

`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  text-align: center;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 4px;
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    a {
      color: ${themeGet('colors.mainborder')};
    }
  }
  ${device.MobToMd} {
    margin-bottom: 15px;
  }
`;

export const StyledSubHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  ${device.MobToMd} {
    flex-direction: column-reverse;
  }
`;
export const StyledSubHeaderText = styled.div`
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    margin-bottom: 0px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    line-height: 19px;
  }
  ${device.MobToMd} {
    p {
      text-align: center;
      br {
        display: none;
      }
    }
  }
`;

export const StyledSubHeadLogo = styled.div`
  ${device.MobToMd} {
    width: 100%;
    text-align: center;
    img {
      margin-bottom: 15px;
    }
  }
`;
export const StyledDesc = styled.p`
  font-size: 15px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  margin-bottom: 20px;
`;

export const StyledAlertMessage = styled.div`
  // color: #f31415;
  // background-color: #f8d7da;
  // border-color: #f5c6cb;
  // position: relative;
  // padding: 5px 10px;
  // margin-bottom: 1rem;
  // border: 1px solid transparent;
  // border-radius: 0.25rem;
  // display: flex;
  // align-items: center;
  // gap: 4px;
  border-radius: 8px;
  border: 1px solid #F79009;
  background: rgba(255, 255, 255, 0.05);
  gap: 8px;
  padding: 8px;
  color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-size:13px;
  font-family: ${themeGet('fonts.googleSansMedium')};
  margin-bottom: 14px;
  display: flex;
  align-items: center;
  svg{
    path{
      fill:#DC6803;
    }
  }
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  color: #c2c9d6;
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 5px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 34px;
  top: 10px;
  color: #f0f0f0;
  font-size: 13px;
  z-index: 99;
`;
export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: end;
  padding: 0px;
  padding-top: 24px;
  gap: 20px;
  a {
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 16px;
    padding: 0px 24px;
    color: ${themeGet('colors.mainborder')}; !important;
    width: 100%;
    text-align: center;
    justify-content: center;
    &:hover {
    }
  }
  
`;
export const StyledBrowserClose = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 40px 0px;
  gap: 30px;
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  }
  svg {
    path:last-child {
      fill: inherit;
    }
  }
`;

export const  StyledPopupView = styled.div`
display: flex;
flex-direction: column;
width: 100%;
background-color: #26334d;
background-clip: padding-box;
border: 1px solid;
outline: 0;
border-radius: 30px;
left: 50%;
position: fixed;
top: 50%;
transform: translate(-50%, -50%);
cursor: auto;
z-index: 9999;
width: 750px;
padding: 30px 40px 25px 40px;
`;

export const StyledClose = styled.button`
  background-color: transparent;
  border: 0;
  appearance: none;
  color: ${themeGet('colors.closebtncolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  font-size: 40px;
  cursor: pointer;
  right: 18px;
  top: 18px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  width: 20px;
  span {
    width: 20px;
    display: flex;
    height: 20px;
    svg {
      width: 20px;
      display: flex;
      height: 20px;
      line {
        stroke: ${themeGet('colors.closebtncolor')};
      }
    }
  }
`;
