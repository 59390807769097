import React, { useState }  from 'react';
// @mui
import Button from '@mui/material/Button';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import Popover from '@mui/material/Popover';
//
import { Button as FButton } from '@/components';
import ProfileDropDown from '@/components/profile/profile-dropdown';
import WalletDropdown from '@/components/profile/wallet-dropdown';
import { DepositSvg } from '@/shared/svg';

import { StyledHeaderButtonGroup } from './style';

interface ChildProps {
  parentModalHandlerFunction: any;
}

export const PostLoginButtons = (props: ChildProps) => {
  const [openPopover, setOpenPopover] = useState<boolean>(false);
  const closePopup = () => setOpenPopover(false);
  return (
    <StyledHeaderButtonGroup>
      <WalletDropdown />
      {/* <Button
        onClick={() => props.parentModalHandlerFunction('kyc')}
        className='loginbtn'
      >
        {' '}
        <DepositSvg />
        WithDraw{' '}
      </Button> */}
      <FButton
        onClick={() => props.parentModalHandlerFunction('deposit')}
        className='loginbtn Deposit'
      >
        {' '}
        <DepositSvg />
        <span>Deposit </span>
      </FButton>
      <ProfileDropDown />

      <Popover
        open={openPopover}
        onClose={closePopup}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        PaperProps={{
          style: { width: '80%', height: '80%', position: 'relative' },
        }}
      >
        <div style={{ position: 'relative' }}>
          <CardHeader
            style={{ position: 'absolute', top: 0, left: 0, right: 0, }}
            title={'???'}
            action={
              <Button variant="outlined" size="small" color="primary" onClick={closePopup}>
                X
              </Button>
            }
          />
          <CardContent style={{ overflow: 'scroll', paddingTop: 64 }}>
            test
          </CardContent>
        </div>
      </Popover>
    </StyledHeaderButtonGroup>
  );
};
