import styled, { themeGet } from '@/shared/styled';

export const StyledAgeContainer = styled.div`
  display: block;
`;

export const StyledAgeOverLay = styled.div``;

export const StyledOverlayContainer = styled.div``;

export const StyledOverlayHead = styled.div`
  display: flex;
  justify-content: space-between;
  // padding-left: 43px;
  // padding-right: 43px;
  padding-top: 15px;
  padding-bottom: 30px;
  text-align: center;
  align-items: center;
  .agepopupimg {
    width: 60px;
  }
`;
export const StyledOverlayBody = styled.div`
  height: auto;
  // padding: 30px 43px 40px;
  text-align: center;
`;

export const StyledOverlatTextView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  }
  svg {
    circle {
      fill: ${themeGet('colors.mainborder')};
    }

    path:last-child {
      fill: ${themeGet('colors.white')};
    }
  }
  h4 {
    font-size: 15px;
    margin-bottom: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    a {
      color: ${themeGet('colors.mainborder')};
    }
  }
  p {
    color: #cecece;
    font-size: 17px;
    margin-top: 10px;
    margin-bottom: 0px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  .agree {
    font-size: 17px;
    margin-bottom: 25px;
    margin-top: 0px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    a {
      color: ${themeGet('colors.mainbackground')};
      text-decoration: underline;
    }
  }

  .yeare_button {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    border: 0px;
    gap: 4px;
    height: 42px;
    padding: 0px 24px;
    color: ${themeGet('colors.pagefontcolor')};
    border-radius: 6px;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 16px;
      border: 0px;
      height: 42px;
    }
  }

  .exit_button {
    background: none;
    border: 1.5px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 42px;
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 24px;
    border-radius: 6px;
    &:hover {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 42px;
      font-size: 16px;
    }
    &:active {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledAgeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 20px;
`;
export const StyledBrowserClose = styled.div`
  display: flex;
  align-items: center;
  flex-direction: column;
  height: 100%;
  padding: 40px 0px;
  gap: 30px;
  svg {
    path:last-child {
      fill: inherit;
    }
  }
`;
