import React, { useState } from 'react';
import {
  StyledRacePlaceInfo,
  StyledRaceNameDetails,
  StyledRaceNosContainer,
  StyledButtonFixedTote,
  StyledLiveMobileView,
  StyledRaceInfoText,
} from './style';
import { Link } from 'react-router-dom';
import { RaceArrowLeftIconSvg, RaceArrowRightIconSvg, RaceDownArrowIconSvg,LiveVideoIconSvg,StopVideoIconSvg } from '@/shared/svg';

interface childProps {
  parentChangeRaceNumberFn: any;
  parentChangeRaceFn: Function;
  parentDisplayVideoFn: Function;
  parentChangeBetTypeFn: any;
  totalRaces: number;
  betType: string;
  displayVideo: boolean;
  currentRaceNumber: number;
  currentRaceInfo: RaceInfoProps;
}

const RaceInfo = (props: childProps) => {
  
  return (
    <>
      <StyledRacePlaceInfo>
        <span className='time_info'>{props.currentRaceInfo.raceTime}</span>
        <span className='race_place'>{props.currentRaceInfo.venueName}</span>
        <span className='racelistpopup'><Link to=''><RaceDownArrowIconSvg/> </Link> </span>
        <span className='race_no'>Race {props.currentRaceNumber}</span>
        <span className='racecard_text'>Info & Tips</span>
        <span className='odds_text'>Odds</span>
        <ul className='mobileviewracenext'>
          <li>
            <Link to={''} onClick={() =>props.parentChangeRaceFn('prev')}>
             <RaceArrowLeftIconSvg/> 
            </Link>
          </li>
          <li>
            <Link to={''} onClick={()=>props.parentChangeRaceFn('next')}>
              <RaceArrowRightIconSvg/>
            </Link>
          </li>
        </ul>
        
      </StyledRacePlaceInfo>
      <StyledRaceInfoText>
      <div className='mobileracenameinfo'>
          <ul>
            <li>
              <span className='race_name'>{props.currentRaceInfo.raceName}</span>
              <span className='race_details_info'>
                {props.currentRaceInfo.distance} M
              </span>
            </li>
          </ul>
        </div>
      </StyledRaceInfoText>
      <StyledLiveMobileView>
        <ul>
          <li>
            <Link to='/'>
              Info & Tips
            </Link>
          </li>
          <li>
            <Link to='/'>
              Odds
            </Link>
          </li>
          <li className='livevideo'>
            <Link to={''} onClick={()=>props.parentDisplayVideoFn()}>
            {props.displayVideo ? <><StopVideoIconSvg/> STOP</> : <><LiveVideoIconSvg/>  LIVE</>}
            </Link>
           
          </li>
        </ul>
      </StyledLiveMobileView>
      <StyledButtonFixedTote>
        <div className='fixedtotebutton '>
          <ul>
            <li className={`${props.betType === 'tote'?'active':''}`}>
              <button onClick={() => props.parentChangeBetTypeFn('tote')}>TOTE</button>
            </li>
            <li className={`${props.betType === 'fixed'?'active':''}`}> 
              <button onClick={() => props.parentChangeBetTypeFn('fixed')}>FIXED</button>
            </li>
          </ul>
        </div>
      </StyledButtonFixedTote>
      <StyledRaceNameDetails>
        <ul>
          <li>
            <span title={`${props.currentRaceInfo.raceName}`} className='race_name'>{props.currentRaceInfo.raceName}</span>
            <span className='race_details_info'>
              {props.currentRaceInfo.distance} M
            </span>
          </li>
        </ul>
      </StyledRaceNameDetails>
      <StyledRaceNosContainer>
        <h4>Race</h4>
        <ul>
          {[...Array(props.totalRaces)].map(
            (value: undefined, index: number) => (
              <li key={index}>
                <Link
                  to={''}
                  className={`${
                    props.currentRaceNumber === index + 1 ? 'active' : ''
                  }`}
                  onClick={() => props.parentChangeRaceNumberFn(index + 1)}
                >
                  {index + 1}
                </Link>
              </li>
            )
          )}
        </ul>
      </StyledRaceNosContainer>
    </>
  );
};

export default RaceInfo;
