import { createSlice } from '@reduxjs/toolkit';

const initialState: IWalletInfo = {
  selectedUserWallet: undefined,
  userWalletList: undefined,
};

const walletSlice = createSlice({
  name: 'wallet',
  initialState,
  reducers: {
    setSelectedUserWallet(state, action) {
      state.selectedUserWallet = action.payload;
    },
    setUserWalletList(state, action) {
      state.userWalletList = action.payload;
    },
  },
});
export const { setSelectedUserWallet, setUserWalletList } = walletSlice.actions;
export const walletReducer = walletSlice.reducer;
