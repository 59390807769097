import styled, { device, themeGet } from '@/shared/styled';

export const StyledContainerFluid = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 60px;
  h3 {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;

    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.MobToMd} {
    padding-top: 0px;
    padding-bottom: 0px;
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const StyledContainerPageView = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0px;
  ${device.TabToLg} {
    flex-direction: column-reverse;
  }
`;

export const StyledPageLeft = styled.div`
  display: flex;
  padding-right: 20px;
  width: 366px;
  flex-direction: column;
  ${device.TabToLg} {
    width: 100%;
    padding-right: 0px;
  }
`;

export const StyledPageRight = styled.div`
  display: flex;
  width: calc(100% - 366px);
  ${device.TabToLg} {
    width: 100%;
  }
`;

export const StyledInfoBox = styled.div`
  background: ${themeGet('colors.pageinfotxtcolor')};
  border: 1px solid ${themeGet('colors.pageinfoborder')};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  span {
    width: 55px;
  }
  svg {
  }
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageTextContainer = styled.div`
  background: ${themeGet('colors.pagecontainerbg')};
  border: 1px solid ${themeGet('colors.pagecontainerborder')};
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  ${device.TabToLg} {
    padding: 16px;
    margin-bottom: 20px;
  }
`;

export const StyledPageBanner = styled.div`
  width: 100%;
  position: relative;
  img {
    border-radius: 10px;
    float: left;
    //margin-bottom: 25px;
    width: 100%;
    ${device.TabToLg} {
      height: 100%;
      object-fit: cover;
    }
  }
  ${device.TabToLg} {
    height: 140px;
  }
`;


export const StyledTextArea = styled.div`
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  .chat_with_text {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
  p {
    color: #c2c9d6;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 16px;
    ${device.TabToLg} {
      font-size: 20px;
      line-height: 26px;
    }
  }
  strong {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-weight: 400;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    font-family: ${themeGet('fonts.googleSansRegular')};
    span {
      padding-right: 5px;
    }

    svg path {
      stroke: ${themeGet('colors.homebannertxtcolor')};
    }
  }
  small {
    color: #b5b5b5;
    width: 100%;
    margin-top: 0.25rem !important;
  }
`;

export const StyledpageViewMain = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
`;

export const StyledpagHeader = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
h4{
  border-left:1px solid #2F3847;
  font-size: 20px;
  color: #C2C9D6;
  padding-left: 20px;
  margin-left: 20px;
  font-family: ${themeGet('fonts.googleSansMedium')};
 }
 
 }
`;

export const StyledpagBody = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
  padding-top:20px;
  ul{
    padding-left: 20px;
    padding-top: 10px;
    li{
      color: #A3ADC2;
      font-size: 13px;
      line-height: 20px;
    }
  }
  .accordion-item{
    background: none;
    margin-bottom: 15px;
    border-radius: 10px;
    border:1px solid #2A323D;
    color:#B7CBC5;
    button{
      background: none;
      box-shadow:none;
      font-size: 15px;
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
    .accordion-button.collapsed{
      color:#C2C9D6;
      border:1px solid #2A323D;
    }
    .accordion-button{
      color:${themeGet('colors.mainbackground')};
      border:1px solid ${themeGet('colors.mainbackground')};
      border-bottom:0px;
      border-radius:10px 10px 0px 0px;
      
    }
    .accordion-button::after{
      background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/accordiondown.png) !important; 
      content"";
    }
    .accordion-button:not(.collapsed)::after{
      background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/accordiondownup.png) !important;
      content"";
      transform: rotate(0deg);
      transition: all 0.3s;
    }
    .accordion-collapse.show{
      border:1px solid ${themeGet('colors.mainbackground')};
      border-top:0px;
      border-radius:0px 0px 10px 10px;
    }
    .accordion-body{
      padding: 0px 20px 20px;
      h4{ 
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansBold')};
        color:#A3ADC2;
        }
      ol{
        padding-left: 20px;
        padding-top: 10px;
        li{
          color: #A3ADC2;
          font-size: 13px;
          line-height: 20px;
          font-family: ${themeGet('fonts.googleSansRegular')};
          margin-bottom:6px;
        }
      }
      p{
        color: #A3ADC2;
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansRegular')};
        padding-top: 10px;
      }
    }
  }
`;