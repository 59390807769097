/**
 * Component dedicated to display external content and game provider games.
 *
 * First call API to get an external provider game URL, often sign by a special token.
 * If our API return a URL, add iFrame with a URL.
 */
import React, { useState, useEffect } from 'react';
//import { useLocation } from 'react-router-dom';
import md5 from 'md5';
import { useAppSelector } from '@/hooks/useAppSelector';
import CookieStorageService from '@/services/CookieStorageService';
import AuthService from '@/services/AuthService';
import { StyledRacingContainer } from '../home/style';

const Game = () => {
  //   const pathname = props.location.pathname;
  //   const isRTGgame = pathname && pathname.includes('rtg');
  const [gameURL, setGameURL] = useState(null);
  //const location = useLocation();
  //const pathname = location.pathname;
  const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet } = useAppSelector(state => state.wallet);

  const setIFrameURL = async () => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token === undefined) window.open(`/`, '_self');
    else {
      const sessionId = `${md5(token)}_${selectedUserWallet.id}`;
      setGameURL(
        `https://partners.arionplay.com/sportsbetting/prod/?sessionId=${sessionId}`
      );
    }
  };

  useEffect(() => {
    if (userInfo && selectedUserWallet) setIFrameURL();
  }, [userInfo, selectedUserWallet]);

  return (
    gameURL && (
      <>
        <StyledRacingContainer>
          <iframe
            id='game'
            name='Game'
            style={{ display: 'block', width: '100%', height: '100vh' }}
            src={gameURL}
          ></iframe>
        </StyledRacingContainer>
      </>
    )
  );
};

export default Game;
