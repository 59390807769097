import { FormGroup, Button, ButtonSpinner } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/hooks/useAppSelector';
import { Link } from 'react-router-dom';

import {
  StyledWrap,
  StyledTitle,
  StyledSubHeader,
  StyledSubHeaderText,
  StyledSubHeadLogo,
  StyledButtonContainer,
  StyledBrowserClose,
} from './style';

import { BrowserCloseIconSvg } from '@/shared/svg';

interface ChildProps {
  closePopup(arg0: string): void;
  parentModalHandlerFunction: any;
  page: string;
}

const TermsForm = (props: ChildProps) => {
  const [message, setMessage] = useState('');
  const [enableTerms, setEnableTerms] = useState(false);
  const [scrollObj, setScrollObj] = useState({});
  const [isNotValid, setIsNotValid] = useState(false);
  const [decline, setDecline] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, errorMessage } = useAppSelector(
    state => state.auth
  );

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<TermsCForm>();

  const onSubmit: SubmitHandler<TermsCForm> = data => {
    console.log(errors, data, isNotValid, scrollObj, message);
    setLoading(true);
    if (props.page === 'register')
      props.parentModalHandlerFunction('signupageconsent');
    else if (props.page === 'login')
      props.parentModalHandlerFunction('signinageconsent');
    else props.parentModalHandlerFunction('defaultpageageconsent');
  };

  const handleDecline = () => {
    if (props.page === 'defaultterms') setDecline(true);
    else props.closePopup('close');
  };

  const handleScroll = (e: { target: any }) => {
    const target = e.target;
    const actualHeight = target.scrollHeight - target.scrollTop;

    const clientHeight = Math.round(target.clientHeight) + 5;
    setScrollObj({
      actualHeight: Math.round(actualHeight),
      clinet: clientHeight,
    });
    if (Math.round(actualHeight) < clientHeight) {
      setEnableTerms(true);
    }
  };

  const refresh = () => {
    location.reload();
  }


  useEffect(() => {
    setLoading(isLoading);
    if (isError) {
      setIsNotValid(true);
      setMessage(errorMessage);
    }
  }, [isError, isLoading]);

  return (
    <StyledWrap>
      {!decline && (
        <>
          <StyledTitle>Acknowledgement</StyledTitle>
          <StyledSubHeader>
            <StyledSubHeaderText>
              <p>
                Online Gaming
                <br />
                Terms and Conditions
                <br />
                As of May 3, 2022
              </p>
            </StyledSubHeaderText>
            <StyledSubHeadLogo>
              <Link to='/'>
                <img
                  height="40px"
                  src={process.env.REACT_APP_THEME_LOGO}
                  alt=''
                />
              </Link>
            </StyledSubHeadLogo>
          </StyledSubHeader>
          <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
            <div className='termsview'>
              <FormGroup className='loginform'>
                <div className='termsandcondition' onScroll={handleScroll}>
                  <h5>ACKNOWLEDGEMENT</h5>
                  <p>Online Gaming</p>
                  <p>Terms and Conditions</p>
                  <p>
                    By accessing and using NEMO&apos;s{' '}
                    {process.env.REACT_APP_SITE_TITLE} Online Gaming Portal,
                    members are deemed to have read and fully understand
                    NEMO&apos;s Online Gaming Terms and Conditions.
                  </p>
                  <p>
                    The Following Terms and Conditions shall govern the use of
                    NEMO&apos;s {process.env.REACT_APP_SITE_TITLE} Online Casino
                    Portal
                  </p>
                  <p>General :</p>
                  <ol type='1'>
                    <li>
                      NEMO&apos;s {process.env.REACT_APP_SITE_TITLE} Online
                      Casino Portal can only be accessed and used by valid
                      registered members within the Philippine jurisdiction
                      only. Valid Registered members are those who have no
                      pending exclusion orders from PAGCOR or NEMO.
                    </li>
                    <li>
                      In pursuance to the PAGCOR Charter and Malacañang
                      Memorandum Circular No. 8: The following persons are not
                      allowed to stay and/or play at the gambling casinos:
                      Government official or employee connected directly with
                      the operation of the Government or any of its agencies,
                      member of the Armed Forces of the Philippines, including
                      the Army, Navy, Air Force, or the Philippine National
                      Police.
                    </li>
                    <li>
                      Members are only allowed to open only one (1) Online
                      Gaming Account for personal use.
                    </li>
                    <li>
                      The Online Gaming Account of the member shall not be used
                      for or on behalf of another person. The member shall not
                      allow or authorize any third party to access or use his or
                      her account. Members shall, at all times, be responsible
                      for any activity on his or her account.
                    </li>
                    <li>
                      Members are expected to never share their PIN with anyone.
                      Any activities/transactions in your account are considered
                      valid if your Member ID No. and PIN have been entered
                      correctly.
                    </li>
                    <li>
                      NEMO&apos;s {process.env.REACT_APP_SITE_TITLE} online
                      casino portal is a service provided by NEMO to provide a
                      platform for casino games and shall not be used as a bank
                      account
                    </li>
                    <li>
                      NEMO may, in certain circumstances as provided in
                      NEMO&apos;s
                      {process.env.REACT_APP_SITE_TITLE} Online Gaming
                      Implementing Rules and Guidelines, conduct verification of
                      your identity or sources of funds deposited.
                    </li>
                    <li>
                      NEMO strictly prohibits any reproduction, copying,
                      recording, taking any screenshots, broadcasting, or any
                      other similar activities, of any contents, footages or
                      images shown on the NEMO{' '}
                      {process.env.REACT_APP_SITE_TITLE} Online Casino Portal
                      including, but not limited to, images or video footage of
                      NEMO&apos;s dealers and other persons involved in online
                      gaming.
                    </li>
                    <h4>Privacy Notice :</h4>
                    <li>
                      By accessing or using NEMO&apos;s{' '}
                      {process.env.REACT_APP_SITE_TITLE} Online Casino Portal,
                      the user consents to the collection, sharing or processing
                      of his or her personal information in accordance with the
                      Data Privacy Law and NEMO&apos;s Data Privacy Policy. NEMO
                      may from time to time process or share such personal
                      information with other entities as may be required in its
                      business processes and operations in accordance with its
                      Data Privacy Policy or for purposes of complying with any
                      legal, regulatory, governmental or judicial requirement.
                    </li>
                    <h4>Online Gaming :</h4>
                    <li>
                      Members are aware of the possibility of winning or losing
                      money when playing on any of our offered games. Members
                      are solely responsible for and shall assume all losses
                      incurred throughout its play.
                    </li>
                    <li>
                      Members fully understand the rules and procedures of all
                      games, including their odds, payouts and betting limits
                      offered in NEMO&apos;s {process.env.REACT_APP_SITE_TITLE}{' '}
                      Online Casino Portal.
                    </li>
                    <li>
                      It is the Member&apos;s responsibility to ensure the
                      correctness of bets placed at all times.
                    </li>
                    <li>
                      The winnings may be subject to any taxes or fees which
                      shall be for the account of the member. Members shall be
                      responsible for reporting the winnings and remitting the
                      applicable taxes and fees, if any, to the appropriate
                      authorities.
                    </li>
                    <li>
                      In the event of technical malfunctions, system error or
                      any other errors in relation to the access or usage of
                      NEMO&apos;s
                      {process.env.REACT_APP_SITE_TITLE} Online Casino Portal,
                      NEMO will resolve the issue/s in accordance with its
                      standard operating procedures.
                    </li>
                    <h4>
                      Restriction / Suspension / Termination of Online Gaming
                      Account :
                    </h4>
                    <li>
                      NEMO may restrict, suspend or terminate your access and
                      use of the Online Gaming Account at any given time and at
                      our own discretion, pending completion of identity or
                      sources of fund verification.
                    </li>
                    <li>
                      NEMO may restrict or suspend your access and use of the
                      Online Gaming account should there be any deposits and/or
                      withdrawals from the Member Online Casino Account without
                      any corresponding gaming activity.
                    </li>
                    <li>
                      NEMO, at any time, has the right to suspend or terminate,
                      without prior notice, the Online Gaming Account of any
                      member who:
                      <ol>
                        <li>
                          Allowed, whether intentionally or not, another person
                          to use its Online Gaming Account;
                        </li>
                        <li>Attempted to defraud the system;</li>
                        <li>
                          Engaged in any fraudulent, irregular or criminal
                          activities, such as but not limited to , collusion,
                          cheating, money laundering, etc;
                        </li>
                        <li>Violated any of these terms and conditions; or;</li>
                        <li>
                          Violated the regulations set by PAGCOR or NEMO&apos;s
                          policies and regulations.
                        </li>
                      </ol>
                    </li>
                    <li>
                      Upon termination of the Online Gaming Account, the member
                      may also be endorsed to PAGCOR for banning.
                    </li>
                    <h4>Miscellaneous Provisions :</h4>
                    <li>
                      NEMO does not guarantee that its online casino portal will
                      have uninterrupted availability or that it is infallible
                      or that it will be free of bugs, viruses or other similar
                      errors. We may at our own discretion, temporarily suspend
                      access to the online casino portal without notice, due to
                      circumstances such as system failure, maintenance, repair
                      or such other reasons beyond our control.
                    </li>
                    <li>
                      NEMO shall not be held liable for any service interruption
                      or any errors and results thereof caused by the
                      member&apos;s internet provider or NEMO&apos;s internet
                      provider.
                    </li>
                    <li>
                      NEMO may contain hyperlinks to external or third-party
                      websites, including but not limited to, content, products,
                      programs, services or software, that are not operated or
                      managed by NEMO. NEMO shall not be held responsible or
                      liable for any of the information or representation
                      contained in the third-party websites. Furthermore, in
                      accessing the third-party website, it is the member&apos;s
                      sole responsibility to review the terms and conditions or
                      any other provisions stipulated therein.
                    </li>
                    <li>
                      NEMO reserves the right to modify, change and revise these
                      Terms and Conditions at any time, without notice or
                      compensation and subject to compliance with PAGCOR
                      regulations. This includes, but not limited to, the right
                      to postpone, temporarily halt, or terminate any services,
                      offers and such other activities or transactions in
                      NEMO&apos;s {process.env.REACT_APP_SITE_TITLE}
                      Online Casino Portal at its sole and absolute discretion.
                    </li>
                    <li>
                      NEMO shall not be liable to any persons for any injuries,
                      losses or damages in respect of, in connection with and/or
                      arising from the access and utilization of NEMO&apos;s{' '}
                      {process.env.REACT_APP_SITE_TITLE}
                      Online Casino Portal.
                    </li>
                    <li>
                      All decisions of NEMO are final, conclusive and binding.
                    </li>
                    <li>
                      This agreement shall be governed by and construed, in
                      accordance with the laws of the Philippines. The parties
                      shall use their best efforts to settle amicably all
                      disputes arising out of or in connection with NEMO&apos;s{' '}
                      {process.env.REACT_APP_SITE_TITLE} Online Casino Portal.
                      In case of litigation or judicial action becomes
                      necessary, the parties shall submit to the jurisdiction of
                      the appropriate courts of filipino city, to the exclusion
                      of all other venues.
                    </li>
                    <li>
                      The English language version of these Terms and Conditions
                      shall prevail; all other versions of these Terms and
                      Conditions are only provided for reference. These Terms
                      and Conditions supersede all previously issued Terms and
                      Conditions.
                    </li>
                  </ol>
                </div>
              </FormGroup>
            </div>
            <div className='nomargin'>
              <StyledButtonContainer>
                <Button
                  type='button'
                  color='brand2'
                  className='signupbtn'
                  onClick={() => handleDecline()}
                >
                  Decline
                </Button>
                <Button
                  type='submit'
                  color='brand2'
                  className='loginbtn'
                  disabled={!enableTerms ? true : false}
                >
                  {loading ? <ButtonSpinner /> : null}Accept
                </Button>
              </StyledButtonContainer>
            </div>
          </form>
        </>
      )}
      {decline && (
        <>
          <StyledBrowserClose>
            <BrowserCloseIconSvg />
            <StyledTitle>
              <p>
                In-order to access the website you must acknowledge the terms
                and conditions.
                <br /> <br /> Please <Link to='/' onClick={refresh}>refresh</Link> browser to try
                again.
              </p>
            </StyledTitle>
          </StyledBrowserClose>
        </>
      )}
    </StyledWrap>
  );
};

export default TermsForm;
