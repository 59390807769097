import React from 'react';
import Button, { ButtonProps } from '@/components/ui/button';

const DropdownToggle = (props: ButtonProps) => {
  const { children, ...restProps } = props;
  return <Button {...restProps}>{children}</Button>;
};

DropdownToggle.defaultProps = {
  label: 'DropdownToggle',
  className: 'dropdown-toggle',
};

DropdownToggle.displayName = 'DropdownToggle';

export default DropdownToggle;
