import styled, { device, themeGet } from '@/shared/styled';

export const StyledMywalletContainerFluid = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 60px;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  .textalignright {
    text-align: right;
  }
  .incomeamount {
    color: #529652;
  }
  ${device.TabToLg}{
    padding-top: 0px;
    padding-left:0px;
    padding-right:0px;
    .mywalletfilter{
      display:none !important;
    }
    .mobileselectview{
      display:block !important;
      // select{
      //   border-radius: 20px;
      //   border: 1px solid #616972;
      //   color: #B7B9BB;
      //   font-size: 12px;
      //   font-family: ${themeGet('fonts.googleSansMedium')};
      //   padding: 0px 14px;
      //   background:none;
      //   width: 93px;
      //   height: 32px;
      //   background:#000;
      //   option{
      //    background:#000;
      //    color:#fff;
      //    padding:0px;
      //    font-size: 10px;
      //    height: 10px;
      //    font-family: ${themeGet('fonts.googleSansBold')};
      //   }
      // }
    }
  }
  .mobileselectview{
    display:none;
  }
`;

export const StyledMywalletHeader = styled.div`
  align-items: center;
  justify-content: space-between;
  padding: 20px 0px 10px;
  display: flex;
  ${device.TabToLg}{
    padding-left:16px;
    padding-right:16px;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom: 20px;
    gap: 20px;
  }
`;

export const StyledWalletType = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  ${device.TabToLg}{
    mywalletfilter{
      display:none !important;
    }
  }
  h4 {
    border-right: 1px solid #4c4c4c;
    color: ${themeGet('colors.eventnumberselectedborder')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    margin-right: 15px;
    padding-right: 15px;
    display: flex;
    margin-bottom: 0px;
    white-space: nowrap;
    ${device.MobToSd} {
      font-size: 16px !important;
      margin-right: 0px;
      padding-right: 0px !important;
      white-space: break-spaces;
     }
     ${device.MobToSm} {
      font-size: 17px !important;
      margin-right: 0px;
      padding-right: 0px !important;
      white-space: break-spaces;
     }
    ${device.TabToLg} {
      font-size: 18px;
      margin-right: 0px;
      padding-right: 10px;
     }
  }
  p {
    color: #b7b9bb;
    margin-bottom: 0px;
    display: flex;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }

  .allbtn {
    background: ${themeGet('colors.overlaybg')};
    border-radius: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .depositbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .withdrawbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .accounttype{
    border-radius: 20px;
    border: 1px solid #616972;
    color: #B7B9BB;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    padding: 0px 14px;
    background:none;
    width: auto;
    height: 32px;
  }
  .mywalletfilter{
    display: flex;
    align-items: center;
    gap: 10px;
  }
`;

export const StyledWalletSearch = styled.div`
 .input-group-text{
  padding: 0.36875rem 0.3rem;
  background:${themeGet('colors.pagecontainerinput')};
 }
  input {
    height: 34px;
    background:${themeGet('colors.pagecontainerinput')};
    border:none;
    &:focus{
      background:${themeGet('colors.pagecontainerinput')};
    }
  }
  ${device.TabToLg}{
    width: 100%;
  }
`;

export const StyledWalletDate = styled.div`
  display: flex;
  align-items: center;
  gap: 15px;
  .allbtn {
    background: ${themeGet('colors.overlaybg')};
    border-radius: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .depositbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
  .withdrawbtn {
    border: 1px solid #616972;
    border-radius: 20px;
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 12px;
    line-height: 32px;
    padding: 0px 14px;
    text-decoration: none;
    height: 32px;
    display: flex;
  }
`;

export const StyledMywalletTable = styled.div`
  margin-top: 8px;
  // border: 1px solid ${themeGet('colors.tableheaderborder')};
  // border-radius: 6px;
  // min-height: 290px;
  table {
    width: 100%;
    thead {
      background: ${themeGet('colors.tableheaderbg')};
      tr th {
        border: 1px solid #2c3340;
        border-top: none;
        color: #c8cfd9;
        font-family: ${themeGet('fonts.googleSansMedium')};
        font-size: 12px;
        padding: 10px 8px;
        white-space: nowrap;
        text-transform: capitalize;
        font-weight: inherit;
      }
    }
    tbody {
      tr td {
        border: 1px solid ${themeGet('colors.tableheaderborder')};
        border-top: none;
        color: #b7b9bb;
        padding: 10px 8px;
        height: 58px;
        //white-space: pre-line;
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansMedium')};
      }
    }
  }
  .bettime{ display:none;}
  .bettypelist{ display:none;}
  .balancetext{display:none;}
  .incomtext{display:none;}
  .balanceaftertext{display:none;}
  .expensestext{display:none;}
  .remarktext{display:none;}
  ${device.TabToLg} {
    .balancetext{display:block;}
    .incomtext{display:block;}
    .balanceaftertext{display:block;}
    .expensestext{display:block;}
    .remarktext{display:block;}
    //display:none;
    border: 0px;
    thead {
      border: none;
      clip: rect(0 0 0 0);
      height: 1px;
      margin: -1px;
      overflow: hidden;
      padding: 0;
      position: absolute;
      width: 1px;
    }
    table tr {
      //border-bottom: 3px solid ${themeGet('colors.tableheaderborder')} !important;
      display: block;
      //border: 1px solid ${themeGet('colors.tableheaderborder')};
      // margin-bottom: 0.625em;
      // margin-bottom: 15px;
      //background: ${themeGet('colors.pageinfotxtcolor')};
      border-radius: 0px;
      padding:10px 16px 20px 16px;
      border-top: 4px solid #14181D;
      border-bottom: 1px solid #21252D;
    }
    table tbody tr td {
      border:0px;
      //border-bottom: 1px solid ${themeGet('colors.tableheaderborder')};
      display: block;
      text-align: right;
      padding: 0px 0px;
      min-height: 38px;
      color: #b7b9bb;
      font-family: ${themeGet('fonts.googleSansBold')};
      font-size: 13px;
      position: relative;
      height: auto;
    }
    td::before {
      content: attr(data-label);
      display: flex;
      position: absolute;
      top: 8px;
    }
    table td:last-child {
      border-bottom: 0;
    }
    .bettime{ display:block;}
    .bettypelist{display:block;}
    .webticketid{ display:none;}
    .timemobile{display:none;}
    .wallwttime{
      display: flex;
      justify-content: space-between;
      align-items: center;
      //margin-bottom: 13px;
      text-align: left;
    }
    .mywalletview{
      color: #B5B5B5;;
      display: flex;
      width:100%;
      flex-direction: column;
      border-top:4px solid #14181D;
      border-bottom:1px solid #21252D;
      padding:20px 16px 30px 16px;
    }
    .balancetext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      background: rgba(27, 31, 37, 0.30);
    }
    .incomtext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
    .expensestext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      background: rgba(27, 31, 37, 0.30);
    }
    .balanceaftertext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-radius: 8px;
      padding:8px 10px;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
    }
    .remarktext{
      display: flex;
      justify-content: space-between;
      align-items: center;
      border-top:2px solid rgba(27, 31, 37, 0.50);
      padding: 8px 10px;
    }
    .textname{
      color: rgba(183, 185, 187, 0.50);
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansRegular')};
    }
    .bettype{
      border-radius: 20px;
      padding: 0px 14px;
      color: #AB7E65;
      font-size: 12px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      background: linear-gradient(128deg, #1B1F25 0%, rgba(27, 31, 37, 0.30) 64.58%, rgba(27, 31, 37, 0.70) 97.92%);
      height:30px;
      display: flex;
      align-items: center;
    }
    .wallwttime{
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 8px 10px;
      //margin-bottom: 13px;
    }
  
    .idtext{
      color:#B7B9BB;
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansBold')};
      padding: 0px 0px;
      white-space: break-spaces;
      word-break: break-all;
    }
    .timetext{
      color: #898989;
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      padding: 0px 0px;
    }
    .incomecolor{
      color: #529652;
      font-family: ${themeGet('fonts.googleSansMedium')};
      font-weight: inherit;
    }
  }
`;

export const StyledMywalletTableVied = styled.div`
  border: 1px solid ${themeGet('colors.tableheaderborder')};
  border-radius: 6px;
  min-height: 490px;
`;

export const StyledMywalletMobile = styled.div`
 display:none;
 ${device.TabToLg} {
  display:block;
 }

`;

export const StyledMywalletMobileHead = styled.div`
  display: flex;
  ul{
    display: flex;
    list-style: none;
    border-bottom: 1px solid #21252D;
    padding-bottom: 10px;
    width: 100%;
    overflow: auto;
    li{
      display: flex;
      border-right:1px solid #2E2E2E;
      a{
        padding:0px 18px;
        font-size: 13px;
        color:${themeGet('colors.copyrighttextcolor')};
        font-family: ${themeGet('fonts.googleSansMedium')};
        white-space: nowrap;
      }
    }
   }
`;

export const StyledMywalletMobileList = styled.div`
  display: flex;
  flex-direction: column;
  .mywalletview{
    color: #B5B5B5;;
    display: flex;
    width:100%;
    flex-direction: column;
    border-top:4px solid #14181D;
    border-bottom:1px solid #21252D;
    padding:20px 16px 30px 16px;
  }
  .balancetext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    background: rgba(27, 31, 37, 0.30);
  }
  .incomtext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .expensestext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    background: rgba(27, 31, 37, 0.30);
  }
  .balanceaftertext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 8px;
    padding:8px 10px;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .remarktext{
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-top:2px solid rgba(27, 31, 37, 0.50);
    padding: 8px 10px;
  }
  .textname{
    color: rgba(183, 185, 187, 0.50);
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  .bettype{
    border-radius: 20px;
    padding: 0px 14px;
    color: #AB7E65;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    background: linear-gradient(128deg, #1B1F25 0%, rgba(27, 31, 37, 0.30) 64.58%, rgba(27, 31, 37, 0.70) 97.92%);
    height:30px;
    display: flex;
    align-items: center;
  }
  .wallwttime{
    display: flex;
    justify-content: space-between;
    align-items: center;
    //margin-bottom: 13px;
  }

  .idtext{
    color:#B7B9BB;
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 0px;
  }
  .timetext{
    color: #898989;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    padding: 0px 0px;
  }
  .incomecolor{
    color: #529652;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-weight: inherit;
  }
  display:none;
 ${device.TabToLg} {
  display:block ;
 }
`;

export const StyledPagination = styled.div`
 display:flex;
 justify-content: space-between;
 padding-top:20px;
 ${device.TabToLg}{
  padding: 10px 16px;
  margin: 2px;
  background: linear-gradient(180deg, rgba(38, 51, 77, 0.80) 0%, rgba(25, 33, 50, 0.48) 100%);
  box-shadow:0px 4px 4px rgba(0, 0, 0, 0.25);
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  .pagination-bar{width: 100% !important;}
  .itempage{
    display:none !important;
  }
 }
 .itempage{
  display: flex;
  align-items: center;
  gap: 10px;
  
  p{
    color: #B5B5B5;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    text-transform: capitalize;
  }
  select{
    border: 0px;
    background: none;
    color: #fff;
  }
 }
.pagination-bar {
  //width: 100%;
  display: flex;
  justify-content: flex-end;
  width:88%;
  position: relative;
}
.pagesize{
  position: absolute;
  left: 0px;
  top: 3px;
}
.pagination-container {
  display: flex;
  list-style-type: none;
  .pagenext{
    width: 24px;
    height: 24px;
    border: 2px solid #616972;
    border-radius: 100px !important;
    padding: 0px !important;
    min-width: 24px !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
  }
  .currentpage{ margin-right:15px !important;}
  .pagination-item {
    padding: 0 0px;
    //height: 32px;
    text-align: center;
    margin: auto 2px;
    color: #fff;
    display: flex;
    box-sizing: border-box;
    align-items: center;
    letter-spacing: 0.01071em;
    border-radius: 16px;
    line-height: 1.43;
    font-size: 13px;
    min-width: 24px;
    justify-content: center;

    &.dots:hover {
      background-color: transparent;
      cursor: default;
    }
    &:hover {
      background-color: rgba(0, 0, 0, 0.04);
      cursor: pointer;
    }

    &.selected {
      background-color: rgba(0, 0, 0, 0.08);
    }

    .arrow {
      &::before {
        position: relative;
        /* top: 3pt; Uncomment this to lower the icons as requested in comments*/
        //content: '';
        /* By using an em scale, the arrows will size with the font */
        display: inline-block;
        width: 0.4em;
        height: 0.4em;
        border-right: 0.12em solid rgba(255, 255, 255, 0.87);
        border-top: 0.12em solid rgba(255, 255, 255, 0.87);
      }

      &.left {
        //transform: rotate(-135deg) translate(-50%);
      }

      &.right {
        //transform: rotate(45deg);
      }
    }

    &.disabled {
      pointer-events: none;

      .arrow::before {
        border-right: 0.12em solid rgba(255, 255, 255, 0.43);
        border-top: 0.12em solid rgba(255, 255, 255, 0.43);
      }

      &:hover {
        background-color: transparent;
        cursor: default;
      }
    }
  }
}

`;
