import { SpaceProps } from '@/shared/styled';
import classnames from 'clsx';
import { StyledGroup } from './style';
import React from 'react';

interface IProps extends SpaceProps {
  className?: string;
  children: React.ReactNode;
}

const FormGroup = ({ children, className, ...rest }: IProps) => {
  return (
    <StyledGroup className={classnames(className, 'form-group')} {...rest}>
      {children}
    </StyledGroup>
  );
};

export default FormGroup;
