import React, { useState, useEffect } from 'react';
import { ProfileNameSvg,SearchIconSvg,CalendariconSvg } from '@/shared/svg';
import {
  StyledMyAccountContainer,
  StyledMyAccountView,
  StyledMyAccountHeader,
  StyledMyAccountBoby,
  StyledTripleHead,
  StyledTripleCrownContainer,
  StyledTripleListHead,
  StyledTripleListBody,
  StylesTripleSearch,
  
} from './style';
import moment from 'moment/moment';
import AuthService from '@/services/AuthService';
import { resetUser, setIsAuthenticatedData } from '@/redux/slices/login';
import { Link, useNavigate } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import CookieStorageService from '@/services/CookieStorageService';
import { changeModule } from '@/redux/slices/module';
import { Input, InputGroup, InputGroupAddon, InputGroupText, Select } from '@/components';
import RacingService from '@/services/RacingService';
import { useAppSelector } from '@/hooks/useAppSelector';

const TripleCrownView = () => {
  const [raceEventList, SetRaceEventList] = useState(null);
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const { raceCardEventChange } = useAppSelector(
    state => state.raceinfo
  );
  const getRaceEventList = async () => {
    const response = await RacingService.getRaceEventList();
    SetRaceEventList(response.data);
  }
  useEffect(()=>{
    getRaceEventList();
  },[])

  const openInfoPopup = async (id: string, name: string) => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token === undefined || token == '') {
      dispatch(changeModule('login'));
      return false;
    }
    history(`/racing/${id}`);
  }

  useEffect(() => {
    console.log("RACE CARD EVENT")
    getRaceEventList();
  }, [raceCardEventChange]);

  return (
    <>
      <StyledMyAccountContainer>
        <StyledMyAccountView>
          <StyledMyAccountHeader>
            <div className='triplecrownhead'>
            <h4>Triple Crown</h4>
            <ul>
              <li className='active'>
                <Link to='/' >Today</Link>
              </li>
              <li>
                <Link to='/' className=''>Tomorrow</Link>
              </li>
              <li>
                <Link to='/' className=''><p>Race Calendar</p><span className='calender-icon'><CalendariconSvg /></span></Link>
              </li>
            </ul>
            </div>
            <StylesTripleSearch>
            <InputGroup>
              <InputGroupAddon dir='prepend'>
                <InputGroupText>
                    <SearchIconSvg />
                </InputGroupText>
              </InputGroupAddon>
              <Input
                className='mobilenumber'
                type='text'
                id='last'
                name='Search'
                placeholder='Search'

              />
            </InputGroup>
            </StylesTripleSearch>
          </StyledMyAccountHeader>
          <StyledMyAccountBoby>
            <StyledTripleHead>
              <p>{moment().format('dddd,DD MMM YYYY')} <span className='dash'>-</span><br/> Total {!raceEventList?0:raceEventList.length} Venue(s)</p>
              <Select 
               id=''
               name='countrycode'
               className='select'
              >
                <option>View all</option>
                <option>AUS / Oakbank</option>
                <option>SA / Greyville</option>
                <option>IND / Mysore</option>
                <option>FR / Cagnes Sur Mer</option>
                <option>IRE / Dundalk</option>
              </Select>
            </StyledTripleHead>
            {raceEventList && raceEventList.map((game:any) => (
              <StyledTripleCrownContainer>
              <StyledTripleListHead>
                <h4>{game.venueName}</h4>
                {/*<h4>AUS / <span>Oakbank</span></h4>*/}
                <p>{game.totalEvents} <span>Races</span></p>
              </StyledTripleListHead>
              <StyledTripleListBody>
                <ul>
                  {game.events && game.events.map((event:any) =>(
                    <li className={`${event.status === 'DRL'?'result':(event.status === 'BSP'?'stopbet':(event.mtp<=10?'betl10':(event.mtp>10 && event.mtp<=60?'next':'')))}`}>
                      <Link to={''} onClick={() => openInfoPopup(game.id, event.venueName)}>
                        <p className='raceno'>R{event.raceNumber}</p>
                        <p className='racetivme'>{event.status ==='DRL'?'RESULT':`${event.mtp>0?event.mtp:0}`}<span>m</span></p>
                      </Link>
                    </li>
                  ))}
                </ul>
              </StyledTripleListBody>
              </StyledTripleCrownContainer>
            ))}
          </StyledMyAccountBoby>
        </StyledMyAccountView>
      </StyledMyAccountContainer>
    </>
  );
};

export default TripleCrownView;
