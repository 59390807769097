import React from 'react';
import { Col } from 'react-bootstrap';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';
import { Row } from 'styled-bootstrap-grid';

export const UploadProfilePic = ({ setFiles }: { setFiles: Function }) => {
  const handleChangeStatus = (
    fileWithMeta: IFileWithMeta,
    status: StatusValue
  ) => {
    if (['done', 'removed'].includes(status)) {
      // TODO call parent prop functin and add to files
      const { file } = fileWithMeta;
      setFiles(file, status);
    }
  };

  return (
    <>
      <Row className='nomarginn row '>
        <Col md={10} className='nopaddinglrft singleupload'>
          <Dropzone
            onChangeStatus={handleChangeStatus}
            canRemove={true}
            // onSubmit={handleSubmitFiles}
            accept='image/*'
            inputContent={(files, { reject }) =>
              reject ? (
                'Image files only'
              ) : (
                <span className='dragefiletext'>
                  Drag your files here to upload <br /> or{' '}
                  <small>Choose File</small>
                </span>
              )
            }
            styles={{
              dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
              inputLabel: (files, { reject }) =>
                reject ? { color: 'red' } : {},
            }}
            maxFiles={1}
            inputWithFilesContent={files => `${1 - files.length} more`}
            submitButtonDisabled
          />
        </Col>
        <Col md={2} className='selfiimg'>
          <img
            src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/selfiimg.svg`}
            alt=''
          />
          {/* <img 
          src={showPreview || `${process.env.REACT_APP_CDN_IMAGES_PATH}/selfiimg.svg`}
          alt=''
        /> */}
        </Col>
      </Row>
    </>
  );
};
