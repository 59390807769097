import classnames from 'clsx';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  Select,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
} from '@/components';
import {
  StyledAgeContainer,
  StyledAgeOverLay,
  StyledOverlayContainer,
  StyledOverlayHead,
  StyledOverlayBody,
  StyledOverlatTextView,
  StyledAgeButtonContainer,
  StyledClose,
  StyledTitle,
  StyledDesc,
  StyledInfoCircle,
} from './style';
import {
  CloseIconSvg,
  PasswordSvg,
  EyeHideIconSvg,
  EyeIconSvg,
  ErrorWarningiconSvg,
  KycVerifiedIconSvg,
  InfocircleIconSvg,
} from '@/shared/svg/index';
import { Link } from 'react-router-dom';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import React, { useEffect, useState } from 'react';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import { useForm, SubmitHandler } from 'react-hook-form';
import AuthService from '@/services/AuthService';
import { StyledAlertMessage } from '../bettype/style';
import { StyledSucessMessage, StyledWrapSucess } from './style';
import { changeModule } from '@/redux/slices/module';
import CookieStorageService from '@/services/CookieStorageService';
import { setIsAuthenticatedData, resetUser } from '@/redux/slices/login';
import { history } from '@/helpers/history';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  closePopup: any;
}

const ChangePasswordPopUp = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState<any>('');
  const [passwordType, setPasswordType] = useState('password');
  const [newpasswordType, setNewPasswordType] = useState('password');
  const [conpasswordType, setConPasswordType] = useState('password');
  const [passwordHint, setPasswordHint] = useState(false);
  const [loading, setLoading] = useState(false);
  const [isNotValid, setIsNotValid] = useState(false);
  const [displayScreen, setDisplayScreen] = useState('part1');
  const { isLoading, isError, errorMessage } = useAppSelector(
    state => state.auth
  );
  const {
    register,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm<IChangepasswordForm>();

  const showPassword = () => {
    if (passwordType === 'password') {
      setPasswordType('text');
      return;
    }
    setPasswordType('password');
  };
  const showNewPassword = () => {
    if (newpasswordType === 'password') {
      setNewPasswordType('text');
      return;
    }
    setNewPasswordType('password');
  };
  const showConPassword = () => {
    if (conpasswordType === 'password') {
      setConPasswordType('text');
      return;
    }
    setConPasswordType('password');
  };
  const onSubmit: SubmitHandler<IChangepasswordForm> = async data => {
    setLoading(true);
    console.log(data, 'data');
    const response = await AuthService.changePassword(data);
    console.log(response, 'response');
    if (response.status) {
      setLoading(false);
      setDisplayScreen('part2');
      const msg = () => (<span>Your password has been changed successfully <br/> Use your new password to login</span>);
      setMessage(msg); 
      } else {
      setDisplayScreen('part1');
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data);
    }
  };
  const closePopup = () => {
    props.closePopup();
  };
  const showLogin = () => {
    closePopup();
    logoutUser();    
    //dispatch(changeModule('login'));
  };

  const logoutUser = () => {
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    dispatch(setIsAuthenticatedData(false));
    dispatch(resetUser());
    document.location.href="/";
  };

  // useEffect(() => {
  //   const msg = () => (<span>Your password has been changed successfully <br/> Use your new password to login</span>);
  //   setMessage(msg); 
  // }, []);


  return (
    <StyledAgeContainer>
      <StyledAgeOverLay></StyledAgeOverLay>
      <StyledOverlayContainer>
        <StyledClose
          type='button'
          onClick={closePopup}
          className={classnames(classnames, 'close')}
          data-dismiss='modal'
          aria-label='Close'
        >
          <span aria-hidden='true'>
            <CloseIconSvg />
          </span>
        </StyledClose>
        {/* <StyledDesc>We will get back to your account</StyledDesc> */}
        {displayScreen == 'part1' ? (
          <>
            <StyledTitle>Change Password</StyledTitle>
            <StyledOverlayBody>
              <StyledOverlatTextView>
                <form
                  className='loginform'
                  autoComplete='off'
                  action='#'
                  noValidate
                  onSubmit={handleSubmit(onSubmit)}
                >
                  {isNotValid ? (
                    <StyledAlertMessage>
                      <ErrorWarningiconSvg /> {message}
                    </StyledAlertMessage>
                  ) : null}

                  <Row mb='20px'>
                    <Col md={12}>
                      <Label display='block' mb='5px' htmlFor='email'>
                        Current Password
                      </Label>
                      <InputGroup>
                        <Input
                          id='oldpassword'
                          type={passwordType}
                          placeholder='Enter Your Current Password'
                          feedbackText={errors?.oldpassword?.message}
                          state={
                            hasKey(errors, 'oldpassword') ? 'error' : 'success'
                          }
                          showState={!!hasKey(errors, 'oldpassword')}
                          {...register('oldpassword', {
                            required: 'Old Password is required',
                            minLength: {
                              value: 6,
                              message: 'Minimum length is 6',
                            },
                          })}
                        />
                        <InputGroupAddon className='posswordicon'>
                          <div
                            className='passwodicon'
                            color='light'
                            onClick={() => showPassword()}
                          >
                            {passwordType === 'password' && <EyeHideIconSvg />}
                            {passwordType === 'text' && <EyeIconSvg />}
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row mb='20px'>
                    <Col md={12}>
                      <Label display='block' mb='5px' htmlFor='email'>
                        New Password
                        <p className='infopassword'>
                          <Link
                            to={''}
                            onMouseOver={() => setPasswordHint(true)}
                            onMouseLeave={() => setPasswordHint(false)}
                          >
                            <InfocircleIconSvg />
                            {passwordHint && (
                              <StyledInfoCircle>
                                Password should be 8 characters long and must
                                contain
                                <br />
                                atleast 1 uppercase, 1 lowercase, 1 special
                                character
                                <br />
                                (%$@!*&#) and 1 number
                              </StyledInfoCircle>
                            )}
                          </Link>
                        </p>
                      </Label>
                      <InputGroup>
                        <Input
                          id='password'
                          type={newpasswordType}
                          placeholder='Enter New Password'
                          feedbackText={errors?.password?.message}
                          state={
                            hasKey(errors, 'password') ? 'error' : 'success'
                          }
                          showState={!!hasKey(errors, 'password')}
                          {...register('password', {
                            required: 'Password is required',
                            minLength: {
                              value: 6,
                              message: 'Minimum length is 6',
                            },
                          })}
                          onFocus={() => setPasswordHint(true)}
                          onBlur={() => setPasswordHint(false)}
                        />
                        <InputGroupAddon className='posswordicon'>
                          <div
                            className='passwodicon'
                            color='light'
                            onClick={() => showNewPassword()}
                          >
                            {newpasswordType === 'password' && (
                              <EyeHideIconSvg />
                            )}
                            {newpasswordType === 'text' && <EyeIconSvg />}
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                  <Row mb='20px'>
                    <Col md={12}>
                      <Label display='block' mb='5px' htmlFor='email'>
                        Confirm New Password
                      </Label>
                      <InputGroup>
                        <Input
                          id='newpassword'
                          type={conpasswordType}
                          placeholder='Re-enter New Password'
                          feedbackText={errors?.newpassword?.message}
                          state={
                            hasKey(errors, 'newpassword') ? 'error' : 'success'
                          }
                          showState={!!hasKey(errors, 'newpassword')}
                          {...register('newpassword', {
                            required: 'Confirm Password is required',
                            validate: {
                              matchesPreviousPassword: value => {
                                const { password } = getValues();
                                return (
                                  password === value ||
                                  'Passwords should match!'
                                );
                              },
                            },
                            minLength: {
                              value: 6,
                              message: 'Minimum length is 6',
                            },
                          })}
                        />
                        <InputGroupAddon className='posswordicon'>
                          <div
                            className='passwodicon'
                            color='light'
                            onClick={() => showConPassword()}
                          >
                            {conpasswordType === 'password' && (
                              <EyeHideIconSvg />
                            )}
                            {conpasswordType === 'text' && <EyeIconSvg />}
                          </div>
                        </InputGroupAddon>
                      </InputGroup>
                    </Col>
                  </Row>
                  <StyledAgeButtonContainer>
                    <Button type='submit' color='brand2' className='signin_btn'>
                      {loading ? <ButtonSpinner /> : null}Update Password
                    </Button>
                  </StyledAgeButtonContainer>
                </form>
              </StyledOverlatTextView>
            </StyledOverlayBody>
          </>
        ) : displayScreen == 'part2' ? (
          <StyledWrapSucess>
            <KycVerifiedIconSvg />
            <StyledSucessMessage>
              <h6>Password Changed!</h6>
              <p>{message}</p>
              <Button type='submit' className='signin_btn' onClick={() => showLogin()}>
                Login
              </Button>
              {/* <Link to={''} }>
                <p>LOGIN</p>
              </Link> */}
            </StyledSucessMessage>
          </StyledWrapSucess>
        ) : (
          ''
        )}
      </StyledOverlayContainer>
    </StyledAgeContainer>
  );
};

export default ChangePasswordPopUp;
