import styled, { device, themeGet } from '@/shared/styled';

export const StyledMyAccountContainer = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 60px;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.TabToLg}{
    padding-top: 0px;
    padding-left:0px;
    padding-right:0px;
  }
`;

export const StyledMyAccountView = styled.div`
  justify-content: space-between;
  padding: 20px 0px 10px;
  display: flex;
  flex-direction: column;
`;

export const StyledMyAccountHeader = styled.div`
display: flex;
justify-content: space-between;
.calender-icon{
  display:none;
}
  h4 {
    color: ${themeGet('colors.eventnumberselectedborder')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    margin-right: 15px;
    padding-right: 15px;
    display: flex;
    margin-bottom: 20px;
    margin-top: 5px;
    border-right:1px solid #4C4C4C;
    padding-right:20px;
    margin-right:20px;
    ${device.TabToLg} {
      font-size: 18px;
      margin-right: 10px;
      padding-right: 10px;
      margin-bottom: 0px;
    }
  }
  ul{
    display: flex;
    list-style: none;
    gap: 10px;
    li{
      display: flex;
      border-radius: 20px;
      border: 1px solid #616972;
      height:33px;
      align-items: center;
      a{
        color:#B7B9BB;
        font-size: 12px;
        font-family: ${themeGet('fonts.googleSansMedium')};
        padding: 7px 16px;
      }
      
    }
    .active{
      background: #25406B;
      border:0px;
      
      a{
        color:${themeGet('colors.white')};
      }
    }
    
  }
  .triplecrownhead{
    display: flex;
  }
  ${device.TabToLg}{
    padding-left:16px;
    padding-right:16px;
    align-items: flex-start;
    flex-direction: column;
    padding-bottom:20px;
    gap: 20px;
    .calender-icon{
      display:block;
    }
    ul
     {
      li{
        a{
          p{
            display:none;
          }
        }
      }
     }
  }

`;

export const StylesTripleSearch = styled.div`
.input-group-text{
  padding: 0.26875rem 0.3rem;
  background:${themeGet('colors.pagecontainerinput')};
}
.mobilenumber {
  padding: 8px 15px 8px 35px;
  height:33px;
}
input {
  height: 34px;
  background:${themeGet('colors.pagecontainerinput')};
  border:none;
  &:focus{
    background:${themeGet('colors.pagecontainerinput')};
  }
}
${device.TabToLg}{
  width: 100%;
}
`;

export const StyledMyAccountBoby = styled.div`
  border: 1px solid #2c3340;
  border-radius: 6px;
  display: flex;
  width: 100%;
  flex-direction: column;
  ${device.mdToLg} {
  }
  ${device.MobToMd} {
    flex-direction: column;
  }
`;
export const StyledMyAccountLeft = styled.div`
  background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/my-account-bg.png);
  background-position: 0 100%;
  background-repeat: no-repeat;
  border-right: 1px solid #2c3340;
  display: flex;
  flex-direction: column;
  min-height: 581px;
  position: relative;
  width: 350px;
  justify-content: space-between;
  padding: 40px 0px;
  ${device.mdToLg} {
    //height: 180px;
    width: 250px;
  }
  ${device.MobToMd} {
    min-height: 180px;
    width: 100%;
  }
`;

export const StyledAccountProfile = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 20px;
    text-align: center;
  }
  p {
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 14px;
    text-align: center;
  }
`;

export const StyledAccountButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;
  .signout_profile {
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
    height: 31px;
    background: ${themeGet('colors.mainborder')};
    border: 1px solid ${themeGet('colors.mainborder')};
    border-radius: 20px;
    color: ${themeGet('colors.white')};
    width: 79px;
  }
  .deactiv_profile {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    border-radius: 20px;
    color: ${themeGet('colors.btnfontcolor')};
    width: 91px;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
    height: 31px;
  }
`;

export const StyledMyAccountRight = styled.div`
  display: flex;
  flex-direction: column;
  width: calc(100% - 350px);
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      align-items: center;
      border-bottom: 1px solid #212733;
      display: flex;
      height: 59px;
      justify-content: space-between;
      padding: 10px 20px;
      width: 100%;
    }
  }
  ${device.mdToLg} {
    width: calc(100% - 150px);
  }
  ${device.MobToMd} {
    width: 100%;
  }
`;
export const StyledAccountName = styled.div`
  color: #768089;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 13px;
  width: 40%;
`;

export const StyledAccountMide = styled.div`
  color: #b7b9bb;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 13px;
  width: 30%;
  text-align: left;
`;

export const StyledAccountAction = styled.div``;

export const StyledTripleHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  padding: 20px;
  align-items: center;
  p{
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    br{
      display:none;
      ${device.MobToMd}{
        display:block;
      }
    }
    ${device.MobToMd}{
      font-size: 13px;
      line-height: 18px;
    .dash{display:none;}
    }
  }
  select{
    width: 136px;
    border-radius: 6px;
    border: 1px solid rgba(88, 102, 131, 0.60);
    background-color: rgba(96, 116, 154, 0.20);
    height: 33px;
    padding-left:12px;
    font-size: 12px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    option{
      font-size: 12px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      background-color: rgba(96, 116, 154, 0.20);
    }
  }
  select:focus{
    border: 1px solid rgba(88, 102, 131, 0.60);
    background-color: rgba(96, 116, 154, 0.20);
  }
`;

export const StyledTripleCrownContainer = styled.div`
 display: flex;
 justify-content: space-between;
 flex-direction: column;
`;

export const StyledTripleListHead = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  border-top: 1px solid #2C3340;
  background: rgba(27, 31, 37, 0.60);
  height: 40px;
  padding: 0px 20px;
  align-items: center;
  h4{
    color:#95A1B9;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansBold')};
  }
  p{
    color:#95A1B9;
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
`;

export const StyledTripleListBody = styled.div`
display: flex;
 ul{
  display: flex;
  list-style: none;
  width: 100%;
  gap: 16px;
  //padding-bottom:10px;
  padding:16px 20px 20px 20px;
  overflow:auto;
  ${device.MobToMd}{
    padding:16px 16px 20px 16px;
    gap: 12px;
  }

  li{
    display: flex;
    //padding: 0px 32px;
    //gap: 5px;
    border-radius: 6px;
    border: 1px solid #1C6B51;
    background: #191919;
    flex-direction: column;
    text-align: center;
    height:53px;
    justify-content: center;
    
    a{
      display: flex;
      padding: 8px 32px;
      //gap: 5px;
      //border-radius: 6px;
      //border: 1px solid #1C6B51;
      //background: #191919;
      flex-direction: column;
      text-align: center;
      height:53px;
      justify-content: center;
      width: 106px;
      ${device.MobToMd}{
        padding: 8px 14px;
        width: 70px;
      }
    }
    p{
      color: #B7B9BB;
      line-height: 16px;
      justify-content: center;
    }
    .raceno{
      font-size: 15px;
      font-family: ${themeGet('fonts.googleSansBold')};
    }
    .racetivme{
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansBold')};
      span{
        font-size: 13px;
        font-family: ${themeGet('fonts.googleSansRegular')};
        position:relative;
        top:-1px;
        left:4px;
      }
    }
  }
  .result{
    border-radius: 6px;
    background: rgba(255, 255, 255, 0.10);
    border:0px;
  }
  .stopbet{
    border-radius: 6px;
    background: #C62F2B;
    border:0px;
    p{
      color:${themeGet('colors.white')};
    }
    
  }
  .betl10{
    background: #DC6426;
    border:0px;
    color:${themeGet('colors.white')};
    
    a{
      color:${themeGet('colors.white')};
      p{
        color:${themeGet('colors.white')};
      }
    }
  }

  .next{
    border-radius: 6px;
    background: #F4C455;
    border:0px;
    p{
      color:#181818;
    }
  }
 }
`;