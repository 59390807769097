import React, { useEffect, useState } from 'react';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import RaceInfo from './raceinfo';
import RaceVideo from './video';
import RunnerInfo from './runner';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';

import {
  StyledContainerBetFluid,
  StyledContainerLeft,
  StyledContainerLeftHeader,
  StyledContainerRight,
  StyledContainerLeftBody,
  StyledContainerFluid,
  StyledHelpBox,
  StyledHelpHead,
  StyledHelpBody,
} from './style';
import { HelpTextSvg } from '@/shared/svg';
import { getRacingData } from '@/redux/slices/racecard';
import RacingService from '@/services/RacingService';
import { Link, useLocation, useParams } from 'react-router-dom';
import Sockets from '@/services/SocketService';
import { updateModelAttributes } from '@/services/SocketModelUpdate';
import BetTypeText from '@/components/bettype';

const Betting = () => {
  const [venueId, setVenueId] = useState(null);
  const [raceNumber, setRaceNumber] = useState(0);
  const [totalRaces, setTotalRaces] = useState(0);
  const [betType, setBetType] = useState('tote');
  const [currentRaceNumber, setCurrentRaceNumber] = useState(1);
  const [displayVideo, setDisplayVideo] = useState(false);
  const dispatch = useAppDispatch();
  const location = useLocation();
  const navigate:NavigateFunction = useNavigate();
  const pathname = location.pathname;
  //const [betTypePopup, setBetTypePopup] = useState('betExplanation');
  const [betTypePopup, setBetTypePopup] = useState('');
  const [deviceType, setDeviceType] = useState(null);
  const [currentRaceInfo, setCurrentRaceInfo] = useState<{
    programId: number;
    venueName: string;
    venueCode: string;
    raceNumber: number;
    betType: string;
    raceName: string;
    distance: string;
    raceTime: string;
    raceStatus: string;
    runners: IRunner[];
    poolsActive: IPool[];
    poolResults: IPoolResults[];
    isSuspended: string;
  }>({
    raceStatus: '',
    programId: 0,
    raceNumber: 0,
    betType: 'tote',
    raceName: '',
    raceTime: '',
    venueName: '',
    venueCode: '',
    distance: '',
    runners: [],
    poolsActive: [],
    poolResults: [],
    isSuspended: 'Y',
  });
  const { isLoading, isError, errorMessage, isSuccess, raceCardChange } = useAppSelector(
    state => state.raceinfo
  );
  let { rId, rNumber } = useParams();
  useEffect(() => {
    const path = pathname.replace(/^\/|\/$/g, '');
    const raceId = path.split('/');
    if(raceId.length>2){
      setCurrentRaceNumber(parseInt(raceId[2]))
    }
    Sockets.setSocketForUser(raceId[1], updateModelAttributes);
    dispatch(getRacingData(raceId[1]));
  }, [rNumber]);

  useEffect(() => {
    if (isSuccess) {
      renderRaceCard();
    }
  }, [isSuccess]);

  useEffect(() => {
      console.log(raceCardChange,"====RACECARD CHANGE");
      renderRaceCard();
  }, [raceCardChange]);

  const renderRaceCard = async () => {
    changeRaceNumber();
  };

  const changeRaceNumber = async () => {
    let raceObject: any = await RacingService.getLocalRaceData();
    const path = pathname.replace(/^\/|\/$/g, '');
    const raceId = path.split('/');
    raceObject = JSON.parse(raceObject);
    const data = raceObject.details;
    const race = currentRaceNumber - 1;
    setVenueId(data[race].raceCardId);
   
    if(raceId.length>2){
      setRaceNumber(parseInt(raceId[2]));
    }
    else{
      setRaceNumber(data[race].raceNumber);
    }

    setTotalRaces(data.length);
    setCurrentRaceInfo({
      programId: data[race].raceCardId,
      venueCode: data[race].vCode,
      venueName: data[race].vName,
      raceNumber: data[race].raceNumber,
      raceTime: data[race].raceTime,
      raceName: data[race].name,
      distance: data[race].length,
      raceStatus: data[race].status,
      runners: data[race].runners,
      poolsActive: data[race].poolsActive,
      poolResults: data[race].poolResults,
      isSuspended: data[race].isSuspended,
      betType:betType
    });
  };

  const setBetTypeFn = (data:any) =>{
    const bType = {
      betType: data
    }
    setBetType(data);
    setCurrentRaceInfo({...currentRaceInfo,...bType});
  }

  const openBetTypePopup = (val:any) => {
    setBetTypePopup(val);
  }

  const closePopup = () => {
    setBetTypePopup('');
  }

  const changeRace = (type:string) =>{
    let currentNumber = currentRaceNumber;
    if(type === 'prev'){
      if(currentRaceNumber > 1){
        currentNumber = currentRaceNumber-1;
      }
    } else {
      if(currentRaceNumber < totalRaces){
        currentNumber = currentRaceNumber+1;
      }
    }
    setTimeout(() => {
      navigate(`/racing/${venueId}/${currentNumber}`);
    }, 100);
  }

  const shifttRace = (id:number) =>{
    setTimeout(() => {
      navigate(`/racing/${venueId}/${id}`);
    }, 100);
  }

  const showVideo = () => {
      setDisplayVideo(!displayVideo);
  }

  useEffect(() => {
    if (currentRaceNumber) changeRaceNumber();
  }, [currentRaceNumber]);

  const isMobile = () =>{
      const { innerWidth: width} = window;
      if(innerWidth>1024){
        setDeviceType("desktop");
      } else{
        setDeviceType("mobile");
      }
  }

  useEffect(() => {
    function handleResize() {
      console.log("RESIZE=====")
      isMobile();
    }
    window.addEventListener('resize', handleResize)
  })

  useEffect(() => {
    isMobile();
  }, []);

  return (
    <>
      <StyledContainerFluid>
        <StyledContainerBetFluid>
          <StyledContainerLeft>
            <StyledContainerLeftHeader>
              <RaceInfo
                totalRaces={totalRaces}
                currentRaceInfo={currentRaceInfo}
                parentChangeRaceNumberFn={shifttRace}
                parentChangeRaceFn={changeRace}
                parentChangeBetTypeFn={setBetTypeFn}
                betType={betType}
                currentRaceNumber={currentRaceNumber}
                parentDisplayVideoFn={showVideo}
                displayVideo={displayVideo}
              />
            </StyledContainerLeftHeader>
            <StyledContainerLeftBody>
                {(displayVideo && deviceType === 'mobile') && (
                    <div className='racevideoview'>
                        <RaceVideo venueId={venueId}/>
                    </div>
                )}
                 {(deviceType === 'desktop') && (
                    <div className='racevideoview'>
                        <RaceVideo venueId={venueId}/>
                    </div>
                )}
                <StyledHelpBox>
                  <StyledHelpHead>
                    <h4>Help</h4>
                    <Link to='/faqs'>
                      View all
                    </Link>
                  </StyledHelpHead>
                  <StyledHelpBody>
                    <ul>
                      <li className={`${betTypePopup === 'betExplanation' ? 'active' : ''}`} >
                      <Link to={''} onClick={() => openBetTypePopup('betExplanation')}>
                          <HelpTextSvg/>
                          <p>Bet Types explanations.</p>
                        </Link>
                      </li>
                      <li className={`${betTypePopup === 'howToBet' ? 'active' : ''}`} >
                      <Link to={''} onClick={() => openBetTypePopup('howToBet')}>
                          <HelpTextSvg />
                          <p>How to Bet ?</p>
                        </Link>
                      </li>
                      <li className={`${betTypePopup === 'depositWithdraw' ? 'active' : ''}`} >
                      <Link to={''} onClick={() => openBetTypePopup('depositWithdraw')}>
                          <HelpTextSvg />
                          <p>How to Deposit and
                            <br/> Withdraw ?</p>
                        </Link>
                      </li>
                    </ul>
                  </StyledHelpBody>
                </StyledHelpBox>
            </StyledContainerLeftBody>
          </StyledContainerLeft>
          <StyledContainerRight>
            {venueId && <RunnerInfo {...currentRaceInfo} />}
            {betTypePopup !== '' && (<BetTypeText betTypePopup={betTypePopup} closePopup={closePopup} />)}
          </StyledContainerRight>
          
        </StyledContainerBetFluid>
      </StyledContainerFluid>
    </>
  );
};

export default Betting;
