import styled, { themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
  svg {
    margin: 15px 0px;
    path {
      fill: ${themeGet('colors.mainborder')};
    }
  }
  width: 100%;
  text-align: center;
  .no_padding {
    padding-right: 0px;
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')};
    width:auto;
    margin-top: 15px;
    height: 42px;
    border: none;
    0px 24px
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    background: ${themeGet('colors.mainborder')};
  }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 7px;
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  margin-bottom: 35px;
`;
