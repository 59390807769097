import React from 'react';
import { Spinner } from 'react-bootstrap';

interface Props {
  className?: string;
}

const ButtonSpinner: React.FC<Props> = ({ ...rest }) => {
  return (
    <>
      <Spinner animation='border' role='status' {...rest} size='sm'>
        <span className='visually-hidden'></span>
      </Spinner>
    </>
  );
};

export default ButtonSpinner;
