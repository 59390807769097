import React from 'react';
import Dropzone, { IFileWithMeta, StatusValue } from 'react-dropzone-uploader';
import 'react-dropzone-uploader/dist/styles.css';

export const UploadIdFiles = ({ setFiles }: { setFiles: Function }) => {
  // add type defs to function props to get TS support inside function bodies,
  // and not just where functions are passed as props into Dropzone
  // const getUploadParams: IDropzoneProps['IFileWithMeta'] = (files) => {
  //    return;
  // };

  // const handleSubmitFiles: IDropzoneProps['onSubmit'] = (files, allFiles) => {
  //   console.log(files.map(f => f.meta));
  //   allFiles.forEach(f => f.remove());
  //   //TO
  // };

  const handleChangeStatus = (
    fileWithMeta: IFileWithMeta,
    status: StatusValue
  ) => {
    // console.log(status, meta)
    // if (!this.props.onChangeStatus) return
    console.log(status);
    console.log(fileWithMeta);
    if (['done', 'removed'].includes(status)) {
      // TODO call parent prop functin and add to files
      const { file } = fileWithMeta;
      setFiles(file, status);
      //  setFiles();
    }
  };

  return (
    <Dropzone
      onChangeStatus={handleChangeStatus}
      accept='image/*'
      inputContent={(files, { reject }) =>
        reject ? (
          'Image files only'
        ) : (
          <span className='dragefiletext'>
            Drag your files here to upload
            <br /> or <small>Choose File</small>
          </span>
        )
      }
      styles={{
        dropzoneReject: { borderColor: 'red', backgroundColor: '#DAA' },
        inputLabel: (files, { reject }) => (reject ? { color: 'red' } : {}),
      }}
    />
  );
};
