import React from 'react';

import { StyledWrap, StyledTitle, StyledDesc } from './style';
import { Button } from '@/components';
import { TickCircleSvg } from '@/shared/svg';

interface ChildProps {
  closePopup(arg0: string): void;
  changeSteps: any;
}

const RegisterSuccess = (props: ChildProps) => {
  return (
    <StyledWrap>
      <TickCircleSvg />
      <StyledTitle>Welcome to {process.env.REACT_APP_SITE_TITLE}</StyledTitle>

      <StyledDesc>Your password has been reset successfully!</StyledDesc>
      <Button
        type='submit'
        color='brand2'
        className='signin_btn'
        onClick={() => props.closePopup('close')}
      >
        Login Now
      </Button>
    </StyledWrap>
  );
};

export default RegisterSuccess;
