import React, { useState } from 'react';
import {
  StyledAgeContainer,
  StyledOverlayContainer,
  StyledOverlayHead,
  StyledOverlayBody,
  StyledOverlatTextView,
  StyledAgeButtonContainer,
  StyledBrowserClose,
} from './style';
import { AgeVerifySvg, BrowserCloseIconSvg } from '@/shared/svg/index';
import { Link } from 'react-router-dom';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  page: string;
  closePopup(arg0: string): void;
}

const AgeSignup = ({ onClick, page, closePopup }: ChildProps) => {
  const [decline, setDecline] = useState(false);
  const submitDecline = () => {
    if (page === 'default') {
      setDecline(true);
    } else {
      closePopup('close');
    }
  };
  const refresh = () => {
    location.reload();
  }

  return (
    <StyledAgeContainer>
      <StyledOverlayContainer>
        {!decline && (
          <StyledOverlayHead>
            <Link to=''>
              <img
                height="40px"
                src={process.env.REACT_APP_THEME_LOGO}
                alt=''
              />
            </Link>
            <Link to=''>
              <img
                height="40px"
                className='agepopupimg'
                src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/PAGCORLogo.svg`}
                alt=''
              />
            </Link>
          </StyledOverlayHead>
        )}
        <StyledOverlayBody>
          {!decline && (
            <StyledOverlatTextView>
              <h4>Age Verification</h4>
              <AgeVerifySvg />
              <p>
                I hereby declare that I am 21 years of age and that I have read
                and accepted the terms and condtions
              </p>
              {/* <p className='agree'>
              <Link to='/'>terms and condtions</Link>.
            </p> */}
              <StyledAgeButtonContainer>
                <button
                  className='exit_button'
                  id='exit_consent'
                  onClick={() => submitDecline()}
                >
                  Decline
                </button>
                <button
                  className='yeare_button'
                  id='age_consent'
                  onClick={onClick}
                >
                  Accept
                </button>
              </StyledAgeButtonContainer>
            </StyledOverlatTextView>
          )}
          {decline && (
            <StyledOverlatTextView>
              <StyledBrowserClose>
                <BrowserCloseIconSvg />
                {/* <h4>Please close the browser</h4> */}
                <h4>
                  In-order to access the website you must be 21 years of age and
                  have read and accepted the terms and conditions.
                  <br /> <br />
                  Please <Link to='/' onClick={refresh}>refresh</Link> browser to try again.
                </h4>
              </StyledBrowserClose>
            </StyledOverlatTextView>
          )}
        </StyledOverlayBody>
      </StyledOverlayContainer>
    </StyledAgeContainer>
  );
};

export default AgeSignup;
