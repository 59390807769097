import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
  ArrowLeftSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledTextArea,
  StyledpageViewMain,
  StyledpagHeader,
  StyledpagBody,

  
} from './style';
import Accordion from 'react-bootstrap/esm/Accordion';


const FaqTrustSafety = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
      <h3>FAQs &amp; Help</h3>
        <StyledContainerPageView>
        <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledpageViewMain>
                <StyledpagHeader>
                 <Link to='/faqs'><ArrowLeftSvg/></Link>  <h4>Trust & Safety</h4>
                </StyledpagHeader>
                <StyledpagBody>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Who sets the online odds and lines?</Accordion.Header>
                      <Accordion.Body>
                        <p>At {process.env.REACT_APP_SITE_TITLE}, our team of expert oddsmakers play a crucial role in setting and continuously adjusting
                        the odds and lines for various events and games. These odds are determined by considering multiple
                        factors, such as the team's performance, player statistics, historical data, injuries, weather conditions,
                        and other relevant information.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </StyledpagBody>
              </StyledpageViewMain>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default FaqTrustSafety;
