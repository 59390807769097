import React from 'react';
import {
  StyledPlaceBetView,
  StyledOverLay,
  StyledBetContaiiner,
  StyledBetSlipHead,
  StyledBetSlipButton,
  StyledBetSlipList,
  StyledBetSlipText,
  StyledBetSlipbtn,
} from './style';
import { CloseIconSvg } from '@/shared/svg/index';
import moment from 'moment';
import { intToOrdinalNumberString } from '@/helpers/util';
import { ButtonSpinner } from '@/components';

interface ChildProps {
  parentCloseHandlerFunction: Function;
  parentSubmitBetHandlerFunction: Function;
  betLoad: boolean;
  venueName: string;
  raceNumber: string;
  selectedRunnersList: any;
  totalAmount: number;
  poolName: string;
  gstData: any;
}

const PlaceBetPopup = (props: ChildProps) => {
  return (
    <StyledPlaceBetView>
      <StyledOverLay></StyledOverLay>
      <StyledBetContaiiner>
        <button
          className='closebtn'
          type='button'
          onClick={() => props.parentCloseHandlerFunction()}
          data-dismiss='modal'
          aria-label='Close'
        >
          <CloseIconSvg />
        </button>
        <StyledBetSlipHead>
          <h4>
            {' '}
            {props.venueName} - Race {props.raceNumber}
          </h4>
          <p>{moment().format('DD-MM-YYYY H:m:s')}</p>
          <StyledBetSlipList>
            <h3 className='poolname'>{props.poolName}</h3>
            <ul className='racenumberlist'>
              {props.selectedRunnersList.map((items: any[], index: number) => (
                <li key={index}>
                  {props.selectedRunnersList.length > 1 && (
                    <>
                      <>
                        <span>{index + 1}</span>
                      </>
                      <sup>{intToOrdinalNumberString(index + 1)}</sup>
                    </>
                  )}
                 <span> {items.join(', ')}</span>
                </li>
              ))}
            </ul>
            <h3 className='betface'>BetFace Value <span>{props.gstData.betFaceValue}</span></h3>
            <h3>Odds Value <span>{props.gstData.oddsValuePaid}</span></h3>
            <h3>CGST@ 14%  <span>{props.gstData.cGST}</span></h3>
            <h3>SGST@ 14%  <span>{props.gstData.sGST}</span></h3>
            <h3 className='totalamount'>Total   <span>{props.gstData.totalAmount}</span></h3>
            <h3 className='potential'>Potential Payout   <span>{props.gstData.potentialPay}</span></h3>
          </StyledBetSlipList>
        </StyledBetSlipHead>
        <StyledBetSlipButton>
          <StyledBetSlipText>
            <p>Total Amount</p>
            <p>
              <span>&#8369;</span> {props.totalAmount}
            </p>
          </StyledBetSlipText>
          <StyledBetSlipbtn>
            <button
              className='exit_button'
              id='exit_consent'
              onClick={() => props.parentCloseHandlerFunction()}
            >
              Cancel
            </button>
            <button
              className='yeare_button'
              id='age_consent'
              onClick={() => props.parentSubmitBetHandlerFunction()}
            >
              {props.betLoad ? <ButtonSpinner /> : null}Place Bet
              
            </button>
          </StyledBetSlipbtn>
        </StyledBetSlipButton>
      </StyledBetContaiiner>
    </StyledPlaceBetView>
  );
};

export default PlaceBetPopup;
