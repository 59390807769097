import { createSlice } from '@reduxjs/toolkit';

const initialState: ISetting = {
  ageVerifyFlag: false,
  modalSize: 'md',
};

const settingSlice = createSlice({
  name: 'setting',
  initialState,
  reducers: {
    setAgeVerifyPopup(state, action) {
      state.ageVerifyFlag = action.payload;
    },
    setModalSize(state, action) {
      state.modalSize = action.payload;
    },
  },
});
export const { setAgeVerifyPopup, setModalSize } = settingSlice.actions;
export const settingReducer = settingSlice.reducer;
