import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
  ArrowLeftSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledTextArea,
  StyledpageViewMain,
  StyledpagHeader,
  StyledpagBody,

  
} from './style';
import Accordion from 'react-bootstrap/esm/Accordion';


const FaqAccountAssistance = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
      <h3>FAQs &amp; Help</h3>
        <StyledContainerPageView>
        <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledpageViewMain>
                <StyledpagHeader>
                 <Link to='/faqs'><ArrowLeftSvg/></Link>  <h4>Account Assistance & Controls</h4>
                </StyledpagHeader>
                <StyledpagBody>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>What are the reasons for limiting my account?</Accordion.Header>
                      <Accordion.Body>
                        <h4>Account limitations can indeed be imposed for various reasons, and some of the common factors include:</h4>
                        <ul>
                          <li>Consistent High Winnings: Accounts that consistently win large amounts may undergo additional scrutiny to ensure fair play and prevent potential exploitation of the platform.</li>
                          <li>Suspected Arbitrage Betting: Arbitrage betting involves taking advantage of price discrepancies in the betting market to guarantee profits. Some platforms may limit accounts engaged in such practices.</li>
                          <li>Bonus Abuse: If a user is found exploiting bonuses or promotions in a way that violates the terms and conditions, their account may be limited.</li>
                          <li>Breaches of Terms and Conditions: Any violation of the platform's terms and conditions, such as creating multiple accounts or engaging in fraudulent activities, can lead to account limitations.</li>
                          <li>Excessive Price Sensitivity: Constantly placing bets right before an event's start time to capitalize on last-minute odds changes might raise concerns and lead to limitations.</li>
                        </ul>
                        <p>It's essential for users to adhere to the rules and guidelines set by the platform to maintain a fair and
                        enjoyable betting experience for everyone. If your account has been limited, it's advisable to contact
                        the platform's support team for clarification and assistance in resolving the issue.
                        For any additional inquiries or if you require assistance, feel free to reach out to our dedicated support
                        team. We are committed to ensuring that you have a pleasant and satisfying betting experience on
                        {process.env.REACT_APP_SITE_TITLE}. Our team is readily available to address your concerns and provide the necessary support
                        whenever you need it. Happy betting!</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </StyledpagBody>
              </StyledpageViewMain>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default FaqAccountAssistance;
