import {
  FormGroup,
  Label,
  Input,
  Button,
  Select,
  InputGroup,
  ButtonSpinner,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import { hasKey } from '@/shared/methods';
import { Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledSteps,
  StyledPersonalContainer,
} from './style';
import { LeftArrowIconSvg, StarIconSvg } from '@/shared/svg';
import { useDispatch } from 'react-redux';
import { useAppSelector } from '@/hooks/useAppSelector';
import AuthService from '@/services/AuthService';
import { UploadIdFiles } from './uploadId';
import { UploadProfilePic } from './uploadProfilePic';
import { setIsKYCStatus } from '@/redux/slices/login';

import axios from 'axios';

interface ChildProps {
  changeSteps: any;
  closePopup(arg0: string): void;
}

const UploadForm = (props: ChildProps) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [sourceIncome, setSourceIncome] = useState('');
  const { regData } = useAppSelector(state => state.register);
  const [documents, setDocuments] = useState([]);
  const [selfieDocuments, setSelfiDocuments] = useState([]);
  const [showKycDocError, setShowKycDocError] = useState(false);
  const [showKycSelfiDocError, setShowKycSelfiDocError] = useState(false);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm<IUser>();

  // // add type defs to function props to get TS support inside function bodies,
  // // and not just where functions are passed as props into Dropzone
  // const getUploadParams: IDropzoneProps['getUploadParams'] = () => ({
  //   url: 'https://httpbin.org/post',
  // });

  // const handleSubmitFiles: IDropzoneProps['onSubmit'] = (files, allFiles) => {
  //   console.log(files.map(f => f.meta));
  //   allFiles.forEach(f => f.remove());
  // };
  const handleDocumentTypeValue = (e: any) => {
    setSourceIncome(e.target.value);
  };

  const onSubmit: SubmitHandler<IUser> = async data => {
    let kycErr = false;

    setShowKycDocError(false);
    setShowKycSelfiDocError(false);
    if (!documents.length) {
      setShowKycDocError(true);
      kycErr = true;
    }

    if (!selfieDocuments.length) {
      setShowKycSelfiDocError(true);
      kycErr = true;
    }

    if (kycErr) return;
    setLoading(true);
    let documentPayload = {};
    const fileUploadIds: any[] = [];
    const totalFiles = documents.length;
    await Promise.all(
      documents.map(async file => {
        const filePath = await AuthService.getUploadFilePath(
          `category=kyc&type=${file.type}`
        );
        if (filePath.status) {
          const result = await axios({
            method: 'put',
            url: filePath.data,
            data: file,
            headers: {
              'Content-Type': file.type,
            },
          });
          if (result.status == 200) {
            const [imageName] = filePath.data.split('?');
            const [filePathId] = imageName.split('/').slice(-1);
            fileUploadIds.push(filePathId);
            console.log(documentPayload, 'documentPayload', totalFiles);
            if (fileUploadIds.length == totalFiles) {
              documentPayload = {
                kycDocuments: fileUploadIds,
                kycDocumentType: data.kycType,
                kycIDNumber: data.kycID,
              };
              console.log(documentPayload, 'documentPayload');
              // const response = await AuthService.updateUserProfile(userUpdateObj);
            }
          }
        }
      })
    );
    console.log(documentPayload, 'beof');
    const selfiFileUploadIds: any[] = [];
    const selfiTotalFiles = selfieDocuments.length;
    await Promise.all(
      selfieDocuments.map(async file => {
        const filePath = await AuthService.getUploadFilePath(
          `category=kyc&type=${file.type}`
        );
        if (filePath.status) {
          const result = await axios({
            method: 'put',
            url: filePath.data,
            data: file,
            headers: {
              'Content-Type': file.type,
            },
          });
          if (result.status == 200) {
            const [imageName] = filePath.data.split('?');
            const [filePathId] = imageName.split('/').slice(-1);
            selfiFileUploadIds.push(filePathId);
            if (selfiFileUploadIds.length == selfiTotalFiles) {
              documentPayload = {
                ...documentPayload,
                kycSelfies: selfiFileUploadIds,
              };
              const response = await AuthService.updateUserProfile(
                documentPayload
              );
              console.log(response, 'response');
              if (response.status) {
                props.changeSteps('kycsuccess');
                dispatch(setIsKYCStatus('PENDING'));
              }
              //props.closePopup('close')
            }
          }
        }
      })
    );
    //props.changeSteps('address');
  };

  useEffect(() => {
    console.log(regData);
  }, [regData]);

  //useEffect(() => {}, []);

  const handleDocumetFiles = (file: any, status: string) => {
    if (status === 'removed') {
      const filterFiles = documents.filter(bfile => bfile.name !== file.name);
      setDocuments(filterFiles);
    } else {
      setDocuments(files => [...files, file]);
    }
  };

  const handleSelfiDocumetFiles = (file: any, status: string) => {
    if (status === 'removed') {
      const filterFiles = selfieDocuments.filter(
        bfile => bfile.name !== file.name
      );
      setSelfiDocuments(filterFiles);
    } else {
      setSelfiDocuments(files => [...files, file]);
    }
  };

  const gotoAccount = () => {
    props.changeSteps('address');
  };

  return (
    <StyledWrap>
      <Link to={''} className='backarrow' onClick={() => gotoAccount()}>
        <LeftArrowIconSvg />
      </Link>
      <StyledTitle>KYC Verification</StyledTitle>
      <StyledDesc>
        Please share the following details to verify your identity
      </StyledDesc>
      <StyledSteps>
        <h4>
          <span>Step 3/3</span>- Upload Documents
        </h4>
        <p>
          <StarIconSvg />
          Fields are mandatory
        </p>
      </StyledSteps>
      <form
        action='#'
        className='loginform'
        onSubmit={handleSubmit(onSubmit)}
        noValidate
      >
        <div className='scrollheight'>
          <Row>
            {/* <Col md={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Document Type<span>*</span>
                </Label>
                <InputGroup>
                  <Input
                    id='kycType'
                    type='text'
                    name='kycType'
                    placeholder='Enter your Document Type'
                    feedbackText={errors?.kycType?.message}
                    state={hasKey(errors, 'kycType') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'kycType')}
                    {...register('kycType', {
                      required: 'kycType is required',
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col> */}
            <Col md={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Document Type<span>*</span>
                </Label>
                <InputGroup>
                  <Select
                    id='kycType'
                    name='kycType'
                    className={`select ${
                      sourceIncome === '' ? 'placeholder' : ''
                    }`}
                    feedbackText={errors?.kycType?.message}
                    state={hasKey(errors, 'kycType') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'kycType')}
                    {...register('kycType', {
                      required: 'Document Type is required',
                    })}
                    onChange={e => handleDocumentTypeValue(e)}
                  >
                    <option disabled={true} value='' selected>
                      --Select Document Type--
                    </option>
                    <option value='driverlicense'>Driver License</option>
                    <option value='umid'>Unified Multi-Purpose ID(UMID)</option>
                    <option value='philhealthid'>PhilHealth ID</option>
                    <option value='tin'>Tax Identification(TIN)</option>
                    <option value='nationalid'>Philippine National ID</option>
                    <option value='voterid'>Voter&apos;s ID</option>
                    <option value='passport'>Passport</option>
                    <option value='sss'>Social Security System Card</option>
                    <option value='postalid'>Postal ID</option>
                  </Select>
                </InputGroup>
              </FormGroup>
            </Col>
            <Col md={6}>
              <FormGroup>
                <Label display='block' mb='5px' htmlFor='email'>
                  Document ID Number<span>*</span>
                </Label>
                <InputGroup>
                  {/* <InputGroupAddon dir='prepend'>
                    <InputGroupText>
                      <CitySvg />
                    </InputGroupText>
                  </InputGroupAddon> */}
                  <Input
                    id='kycID'
                    type='text'
                    name='kycID'
                    placeholder='Enter your Document ID'
                    feedbackText={errors?.kycID?.message}
                    state={hasKey(errors, 'kycID') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'kycID')}
                    {...register('kycID', {
                      required: 'Document ID is required',
                      pattern: {
                        value: /^([A-Za-z]|[0-9]|_)+$/,
                        message: 'Alphanumeric allowed',
                      },
                    })}
                  />
                </InputGroup>
              </FormGroup>
            </Col>
          </Row>

          <Row className=''>
            <div className='uploaddrag'>
              <Col md={12} className='nopaddinglrft doubleupload'>
                <Label display='block' mb='5px' htmlFor='email'>
                  Upload Document selected above<span>*</span>
                </Label>
                <UploadIdFiles setFiles={handleDocumetFiles} />
                {showKycDocError && (
                  <div className='errormessage'>Documents is required</div>
                )}
              </Col>
            </div>
          </Row>
          <Row className=''>
            <div className='uploaddrag'>
              <Col md={12} className='nopaddingview'>
                <Label
                  className='nopaddinglrft'
                  display='block'
                  mb='5px'
                  htmlFor='email'
                >
                  Upload Selfie with Document selected above<span>*</span>
                </Label>
                <UploadProfilePic setFiles={handleSelfiDocumetFiles} />
                {showKycSelfiDocError && (
                  <div className='errormessage'>
                    Selfie Document is required
                  </div>
                )}
              </Col>
            </div>
          </Row>

          {/* <Row className='uploaddrag'>
            <UploadIdFiles setFiles={handleDocumetFiles} />
          </Row>
          <Row>
            <UploadProfilePic setFiles={handleSelfiDocumetFiles} />
          </Row> */}
        </div>
        <StyledPersonalContainer>
          <Button type='submit' color='brand2' className='signin_btn'>
            {loading ? <ButtonSpinner /> : null} Continue
          </Button>
        </StyledPersonalContainer>
      </form>
    </StyledWrap>
  );
};

export default UploadForm;
