import { forwardRef } from 'react';
import classnames from 'clsx';
import { StyledCheckbox, StyledCheckboxLabel } from './style';
import { StyledBoxWrap } from '@/components/forms/form-elements/style';
import { IInputProps } from '@/components/forms/form-elements/types';
import React from 'react';

interface IProps extends IInputProps {
  checked?: boolean;
  label: string;
}

const Checkbox = forwardRef<HTMLInputElement, IProps>(
  (
    {
      className,
      disabled,
      feedbackText,
      id,
      name,
      label,
      checked,
      onChange,
      onClick,
      onBlur,
      value,
      ...restProps
    },
    ref
  ) => {
    return (
      <StyledBoxWrap
        className={classnames(className, 'custom-checkbox')}
        {...restProps}
      >
        <StyledCheckbox
          type='checkbox'
          disabled={disabled}
          id={id}
          name={name}
          checked={checked}
          onChange={onChange}
          onClick={onClick}
          onBlur={onBlur}
          value={value}
          ref={ref}
        />
        <StyledCheckboxLabel
          htmlFor={id}
          className={`${feedbackText ? 'checkbox-error' : ''}`}
        >
          {label}
        </StyledCheckboxLabel>
      </StyledBoxWrap>
    );
  }
);

Checkbox.displayName = 'Checkbox';

export default Checkbox;
