import React, { useEffect, useState } from 'react';
import {
  StyledKycDetailsView,
  StyledKycSubmit,
  StyledKycProgress,
  StyledKycVerified,
  StyledKycReSubmit,
} from './style';
import {
  KycSubmitIconSvg,
  KycProgressIconSvg,
  KycVerifiedIconSvg,
  KycResubmitIconSvg,
} from '@/shared/svg/index';
import { useAppSelector } from '@/hooks/useAppSelector';
import { changeModule } from '@/redux/slices/module';
import { useAppDispatch } from '@/hooks/useAppDispatch';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const KycDetails = ({ onClick }: ChildProps) => {
  const dispatch = useAppDispatch();
  const { isKYCStatus, isKYCRejectedReason, isAuthenticated, isKYCPopup } = useAppSelector(
    state => state.auth
  );
  const [verificationStatus, setVerificationStatus] = useState('');
  const [reason, setReason] = useState('');
  const [kycpopup, setKycpopup] = useState(false);
  const setKYCStatus = (data: any) => {
    setVerificationStatus(data);
  };
  const showKYCInfo = () => {
    dispatch(changeModule('kyc'));
  };

  useEffect(() => {
    if (isAuthenticated && isKYCStatus) {
      setVerificationStatus(isKYCStatus);
      setKYCStatus(isKYCStatus);
    } else {
      setVerificationStatus('');
    }
  }, [isAuthenticated, isKYCStatus, isKYCPopup]);

  useEffect(() => {
    if (isKYCRejectedReason) setReason(isKYCRejectedReason);
  }, [isKYCRejectedReason]);

  useEffect(() => {
    if (isKYCPopup) setKycpopup(isKYCPopup);
  }, [isKYCPopup]);

  return (
    <StyledKycDetailsView>
      {verificationStatus === 'created' && (
        <StyledKycSubmit>
          <KycSubmitIconSvg />
          <button onClick={() => showKYCInfo()}>Submit KYC</button>
        </StyledKycSubmit>
      )}
      {verificationStatus === 'PENDING' && (
        <StyledKycProgress>
          <KycProgressIconSvg />
          <p>KYC Verification in Progress</p>
        </StyledKycProgress>
      )}
      {(verificationStatus === 'VERIFIED' && !kycpopup)  &&  (
        <StyledKycVerified>
          <KycVerifiedIconSvg />
          <p>KYC Verified</p>
        </StyledKycVerified>
      )}
      {verificationStatus === 'DECLINED' && (
        <StyledKycReSubmit>
          <KycResubmitIconSvg />
          <p>KYC Declined ( {reason} )</p>{' '}
          <button onClick={() => showKYCInfo()}>Re-Submit KYC</button>
        </StyledKycReSubmit>
      )}
    </StyledKycDetailsView>
  );
};

export default KycDetails;
