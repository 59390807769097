import styled, { device, themeGet } from '@/shared/styled';

export const StyledMyAccountContainer = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 60px;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.TabToLg}{
    padding-top: 0px;
    padding-left:0px;
    padding-right:0px;
  }
`;

export const StyledMyAccountView = styled.div`
  justify-content: space-between;
  padding: 20px 0px 10px;
  display: flex;
  flex-direction: column;
`;

export const StyledMyAccountHeader = styled.div`
  h4 {
    color: ${themeGet('colors.eventnumberselectedborder')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    margin-right: 15px;
    padding-right: 15px;
    display: flex;
    margin-bottom: 15px;
    margin-top: 5px;
    ${device.TabToLg} {
      font-size: 18px;
      margin-right: 0px;
      padding-left: 16px;
      padding-right:16px;
      margin-bottom: 0px;
     }
  }
`;

export const StyledMyAccountBoby = styled.div`
  border: 1px solid #2c3340;
  border-radius: 6px;
  display: flex;
  width: 100%;
  ${device.mdToLg} {
  }
  ${device.MobToMd} {
    flex-direction: column;
    border-radius: 0px;
    border:0px;
  }
`;
export const StyledMyAccountLeft = styled.div`
  background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/arionplay/my-account-bg.png);
  background-position: 0 100%;
  background-repeat: no-repeat;
  border-right: 1px solid #2c3340;
  display: flex;
  flex-direction: column;
  min-height: 581px;
  position: relative;
  width: 350px;
  justify-content: space-between;
  padding: 40px 0px;
  ${device.mdToLg} {
    //height: 180px;
    width: 250px;
  }
  ${device.MobToMd} {
    min-height: 180px;
    padding-top:0px;
    background-size: contain;
    width: calc(100% - 32px);
    margin:0px 16px;
    border-radius: 6px;
    border: 1px solid #2c3340;
  }
`;

export const StyledAccountProfile = styled.div`
  padding-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h5 {
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 20px;
    text-align: center;
  }
  p {
    color: #b7b9bb;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-size: 14px;
    text-align: center;
  }
`;

export const StyledAccountButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 20px;
  gap: 20px;
  .signout_profile {
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
    height: 31px;
    background: ${themeGet('colors.mainborder')};
    border: 1px solid ${themeGet('colors.mainborder')};
    border-radius: 20px;
    color: ${themeGet('colors.white')};
    width: 79px;
  }
  .deactiv_profile {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    border-radius: 20px;
    color: ${themeGet('colors.btnfontcolor')};
    width: 91px;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 12px;
    height: 31px;
  }
`;

export const StyledMyAccountRight = styled.div`
  display: flex;
  flex-direction: column;
  width:100%;
  //width: calc(100% - 350px);
  h4 {
    font-size: 13px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 20px;
    margin: 0px;
    ${device.TabToLg} {
      padding: 16px;
    }
  }
  ul {
    display: flex;
    flex-direction: column;
    width: 100%;
    li {
      align-items: center;
      border-bottom: 1px solid #212733;
      display: flex;
      height: 50px;
      justify-content: space-between;
      padding: 10px 20px;
      width: 100%;
      ${device.MobToLg}{
        align-items: flex-start;
      }
    }
  }
  ${device.mdToLg} {
    //width: calc(100% - 150px);
  }
  ${device.MobToMd} {
    width: 100%;
  }
`;
export const StyledAccountName = styled.div`
  color: #768089;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 13px;
  width: 40%;
  text-align: left;
  ${device.TabToLg}{
    white-space: break-spaces;
    word-break: break-all;
    width: 60%;
    font-family: ${themeGet('fonts.googleSansRegular')};
    padding-right:15px;
  }
`;

export const StyledAccountMide = styled.div`
  color: #b7b9bb;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 13px;
  width: 40%;
  text-align: left;
  ${device.TabToLg}{
    display:none !important;
   }
`;

export const StyledAccountAction = styled.div`
  color: #b7b9bb;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 13px;
  width: 20%;
  text-align: left;
  ${device.TabToLg}{
    display:none !important;
   }
`;
export const StyledAccountMobile = styled.div`
 display:none;
 ${device.TabToLg}{
  display:block !important;
  color: #768089;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 13px;
  width:40%;
 }
`;
export const StyledMobileList = styled.div`
 display:none;
 ${device.TabToLg}{
  display:block !important;
  color: #b7b9bb;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 13px;
  width:40%;
 }
`;

export const StyledMyactiveHeader = styled.div`
  ul {
    background: ${themeGet('colors.pagecontainerinput')};
    border-top: 1px solid ${themeGet('colors.pagecontainerinputborder')};
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    li {
      display: flex;
      border-bottom: none;
      padding: 0px;
      height: auto;
      color: #fcfcfc;
      
    }
  }
`;

export const StyledMyactiveBody = styled.div`
  //height: 450px;
  overflow-y: auto;
  ${device.TabToLg}{
    height: auto;
    overflow-y: auto;
    ul{
      li{
        ${device.TabToLg}{
          border-top: 1px solid #21252D;
          border-bottom:4px solid #14181D;
          height:auto;
          align-items: flex-start;
        }
      }
    }
  }
`;
