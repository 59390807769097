import React from 'react';
import {
  StyledOtherContries,
  StyledOtherContriesContainer,
  StyledOtherLogo,
  StyledAttention,
  StyledOtherContact,
} from './home/style';
import { AttentionIconSvg, ContactMailIconSvg } from '@/shared/svg';
import { Link } from 'react-router-dom';

const OtherContries = () => {
  return (
    <>
      <StyledOtherContries>
        <StyledOtherContriesContainer>
          <StyledOtherLogo>
            <img
              src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/other_logo.svg`}
              alt=''
            />
          </StyledOtherLogo>
          <StyledAttention>
            <AttentionIconSvg />
            <h4>ATTENTION!</h4>
            <p>
              {process.env.REACT_APP_SITE_TITLE} is not available in your country.
              <br />
              Our services are only available
              <br />
              in the <span>Philippines.</span>
            </p>
          </StyledAttention>
          <StyledOtherContact>
            <ContactMailIconSvg />
            <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>
              <p>contact@{process.env.REACT_APP_SITE_TITLE}.com</p>
            </Link>
          </StyledOtherContact>
        </StyledOtherContriesContainer>
      </StyledOtherContries>
    </>
  );
};

export default OtherContries;
