import styled, { themeGet } from '@/shared/styled';

export const StyledAgeContainer = styled.div`
  display: block;
`;

export const StyledAgeOverLay = styled.div`
  background: hsla(0, 0%, 5%, 0.7);
  height: 100%;
  left: 0;
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 100;
`;

export const StyledOverlayContainer = styled.div`
  background: ${themeGet('colors.overlaybg')};
  border-radius: 30px;
  left: 50%;
  position: fixed;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 400px;
  z-index: 200;
  padding: 30px 40px 25px 40px;
  .loginform{ width: 100%;
   input{
    padding-left:12px !important;
   }
  }

  .loginform label {
    font-family: 'google_sansmedium',sans-serif;
    font-size: 14px;
    color: #A4ADC0;
    letter-spacing: 0.042px;
    line-height: 20px;
    margin-bottom: 10px;
    display: flex;
  }
  .posswordicon {
    position: absolute;
    height: 42px;
    right: 0px;
    top: 0px;
    width: 30px;
    display: flex;
    justify-content: center;
    z-index: 99;
    button {
      padding: 0px;
      width: 30px;
      &:active {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
      &:focus {
        box-shadow: none;
        color: inherit;
        background-color: inherit;
        border-color: inherit;
        border: none;
      }
    }
    svg {
      path {
        fill: #6a7a9b;
      }
    }
  }
  .infopassword {
    margin-left: 6px;
    position: relative;
    top: -2px;
  }
  .passwodicon {
    width: 35px;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    // -webkit-background-clip: text;
    // -webkit-text-fill-color: #ffffff;
    // transition: background-color 5000s ease-in-out 0s;
    // box-shadow: inset 0 0 20px 20px #23232329;
    // background-clip: text !important;
    // background: ${themeGet('colors.forminputbg')} !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color:rgba(0, 0, 0, 0) !important;
    background-color:rgb(0, 0, 0,) !important;
    box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
  }
  input:-internal-autofill-selected {
    background-clip: padding-box !important;
    background-color: #000 !important;
    border: 1px solid #1c2335;
    border-radius: 6px 6px 6px 6px !important;
    //box-shadow:none !important;
    background: ${themeGet('colors.forminputbg')} ;
    background-clip: padding-box !important;
    // -webkit-background-clip: text !important ;
    // -webkit-text-fill-color: transparent !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-internal-autofill-selected:focus{
    background-color: ${themeGet('colors.forminputbg')} !important;
    //box-shadow:none !important;
    background-clip: padding-box !important;
    //background-clip: text !important;
    background: ${themeGet('colors.forminputbg')} !important;
    background-color:rgba(0, 0, 0, 0) !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
  }
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    background-clip: padding-box !important;
    // background-clip: text !important;
    // -webkit-text-fill-color: rgb(255, 255, 255);
    // box-shadow: none !important;
    // transition: inherit !important;
    // background-color: ${themeGet('colors.forminputbg')} !important;
    // -webkit-background-clip: text !important ;
    // -webkit-text-fill-color: transparent !important;
    -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;
    background-color:rgba(0, 0, 0, 0) !important;
    background-color:rgb(0, 0, 0,) !important;
    box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
}
.form-control {
  // display: block;
  // width: 100%;
  // padding: $input-padding-y $input-padding-x;
  // font-family: $input-font-family;
  // @include font-size($input-font-size);
  // font-weight: $input-font-weight;
  // line-height: $input-line-height;
  // color: $input-color;
  background-color: ${themeGet('colors.forminputbg')} !important;;
  //background-clip: padding-box;
  box-shadow: none !important;
  //border: 1px solid ${themeGet('colors.largegamecardborder')}!important ;
  appearance: none; // Fix appearance for date inputs in Safari
  // background-color:rgba(0, 0, 0, 0) !important;
  // background-color:rgb(0, 0, 0,) !important;

}
.input-group input:autofill:hover, .input-group input:autofill:focus, .input-group input:autofill:active {
  background-clip: padding-box !important;
  //box-shadow: none !important;
  transition: inherit !important;
  background-color: ${themeGet('colors.forminputbg')} !important;
  background: ${themeGet('colors.forminputbg')} ;
  -webkit-text-fill-color: #fff;
    -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
    transition: background-color 5000s ease-in-out 0s;

}
input:-internal-autofill-selected{}
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  //border: 1px solid green;
  -webkit-text-fill-color: #fff;
  -webkit-box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color:rgba(0, 0, 0, 0) !important;
  background-color:rgb(0, 0, 0,) !important;
  box-shadow: rgb(28, 35, 53) 0px 0px 0px 1000px inset !important;
}
  input:-webkit-autofill,
    input:-webkit-autofill:focus {
    transition: background-color 0s 600000s, color 0s 600000s;
  }
  .row {
    margin-bottom: 24px;
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: 100%;
    margin-top: 15px;
    height: 42px;
    border: none;
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    background: ${themeGet('colors.mainborder')};
  }
`;

export const StyledOverlayHead = styled.div`
  display: flex;
  justify-content: space-between;
  // padding-left: 43px;
  // padding-right: 43px;
  padding-top: 0px;
  padding-bottom: 30px;
  text-align: center;
  align-items: center;
  .agepopupimg {
    width: 60px;
  }
`;
export const StyledOverlayBody = styled.div`
  height: auto;
  // padding: 30px 43px 40px;
  //text-align: center;
`;

export const StyledOverlatTextView = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  svg {
    circle {
      fill: ${themeGet('colors.mainborder')};
    }

    path:last-child {
      fill: ${themeGet('colors.white')};
    }
  }
  h4 {
    font-size: 24px;
    margin-bottom: 20px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  // p {
  //   color: #cecece;
  //   font-size: 17px;
  //   margin-top: 10px;
  //   margin-bottom: 0px;
  //   font-family: ${themeGet('fonts.googleSansRegular')};
  // }
  .agree {
    font-size: 17px;
    margin-bottom: 25px;
    margin-top: 0px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    a {
      color: ${themeGet('colors.mainbackground')};
      text-decoration: underline;
    }
  }

  .yeare_button {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    border: 0px;
    gap: 4px;
    height: 42px;
    padding: 0px 24px;
    color: ${themeGet('colors.pagefontcolor')};
    border-radius: 6px;
    &:hover {
      background: ${themeGet('colors.mainborder')};
      font-size: 16px;
      border: 0px;
      height: 42px;
    }
  }

  .exit_button {
    background: none;
    border: 1px solid ${themeGet('colors.mainborder')};
    color: ${themeGet('colors.btnfontcolor')};
    display: flex;
    align-items: center;
    gap: 4px;
    height: 42px;
    font-size: 16px;
    font-family: ${themeGet('fonts.googleSansBold')};
    padding: 0px 24px;
    border-radius: 6px;
    &:hover {
      background: none;
      border: 1.5px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
      height: 42px;
      font-size: 16px;
    }
    &:active {
      background: none;
      border: 1px solid ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.btnfontcolor')};
    }
  }
`;

export const StyledAgeButtonContainer = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
  justify-content: center;
  margin-top: 20px;
  margin-bottom: 20px;
`;

export const StyledClose = styled.button`
  background-color: transparent;
  border: 0;
  appearance: none;
  color: ${themeGet('colors.closebtncolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  font-size: 40px;
  cursor: pointer;
  right: 18px;
  top: 18px;
  height: 20px;
  line-height: 20px;
  position: absolute;
  width: 20px;
  span {
    width: 20px;
    display: flex;
    height: 20px;
    svg {
      width: 20px;
      display: flex;
      height: 20px;
      line {
        stroke: ${themeGet('colors.closebtncolor')};
      }
    }
  }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 6px;
  text-align: center;
  padding-bottom:24px;
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  margin-bottom: 30px;
  text-align: center;
`;
export const StyledInfoCircle = styled.div`
  display: block;
  position: absolute;
  min-width: 380px;
  word-break: break-all;
  top: -99px;
  left: -125px;
  z-index: 999;
  background: #ff0;
  height: 90px;
  font-size: 13px;
  line-height: 17px;
  padding: 10px 14px;
  border-radius: 10px;
  background: #4a5a76;
  color: ${themeGet('colors.white')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  &::after {
    display: inline-block;
    margin-left: 0.255em;
    vertical-align: 0.255em;
    content: '';
    border-top: 8px solid #4a5a76;
    border-right: 8px solid transparent;
    border-bottom: 0;
    border-left: 8px solid transparent;
    color: #fff;
    position: absolute;
    bottom: -8px;
    left: 124px;
  }
`;

export const StyledSucessMessage = styled.div`
font-size:18px;
text-align:center;
 color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
 padding-top:24px;
 h6{
   color:${themeGet('colors.eventnumberdefaulttxtcolor')};
   font-size: 24px;
   font-family: ${themeGet('fonts.googleSansMedium')};
   padding-bottom:20px;
 }
 p{
  font-size: 15px;
  color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-family: ${themeGet('fonts.googleSansRegular')};
  margin-bottom:30px;
 }
`;

export const StyledWrapSucess = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px;
  svg{
    width: 60px;
    height: 60px;
    path{
      fill:#17a637;
    }
  }

`;

export const StyledWrapError  =styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 30px 0px;
  svg{
    width: 60px;
    height: 60px;
    path{
      fill:#db071b;
    }
  }
`;