import React from 'react';

import { StyledWrap, StyledTitle, StyledDesc } from './style';
import { Button } from '@/components';
import { TickCircleSvg } from '@/shared/svg';

interface ChildProps {
  parentModalHandlerFunction: any;
  screen: any;
}

const RegisterSuccess = (props: ChildProps) => {
  return (
    <StyledWrap>
      <TickCircleSvg />
      <StyledTitle>Welcome to {process.env.REACT_APP_SITE_TITLE}</StyledTitle>

      <StyledDesc>
        {props.screen === 'register'
          ? 'Registration Completed successfully.'
          : 'Your password has been reset successfully!'}
      </StyledDesc>
      <Button type='submit' color='brand2' className='signin_btn'>
        Okay
      </Button>
    </StyledWrap>
  );
};

export default RegisterSuccess;
