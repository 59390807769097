import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import Card from '@mui/material/Card';
import CardActionArea from '@mui/material/CardActionArea';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
// Components
import BorderCard from './BorderCard';
// Models
import GameModel from '../@types/models/GameModel';
// SVG
import { PlaySvg, InfoSvg } from '@/shared/svg/index';

const GameCategoryDiv = styled('div')(({ theme }) => ({
  position: 'absolute',
  top: -1,
  right: -1,
  padding: '1px 10px',
  fontFamily: 'google_sansmedium, sans-serif',
  fontSize: 10,
  zIndex: 1,
  color: '#ffffff',
  borderRadius: '0px 0px 0px 8px',
  borderBottom: '1px solid #384766',
  borderLeft: '1px solid #384766',
  background: 'linear-gradient(180deg, #182740 0%, #02173A 100%)',
}));

const GameNameDiv = styled('div')(({ theme }) => ({
  color: '#A3ADC2',
  fontSize: 12,
  textShadow: '1px 1px 1px #000',
  fontFamily: 'google_sansmedium, sans-serif',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  padding: 10,
}));

const GameCardImageWrapperDiv = styled('div')(({ theme }) => ({
  border: '1px solid #000',
  overflow: 'hidden',
  borderRadius: 10,
}));

type Props = {
  gameModel: GameModel,
  size?: number,
  displayName?: boolean,
  displayCategory?: boolean,
  onClick?: (gameId: string) => void,
};

const GameCard = ({ gameModel, size, displayName, displayCategory, onClick }: Props) => {
  const CARD_DEFAULT_IMAGE_SIZE = 160;
  if (!size) size = CARD_DEFAULT_IMAGE_SIZE;
  // State
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);
  // Const.
  const cardImageDefaultPath = `${process.env.REACT_APP_GAME_S3_PATH}${gameModel.providerId}/noimage/${gameModel.providerId}-no-image.png`;
  const cardImagePath = `${process.env.REACT_APP_GAME_S3_PATH}${gameModel.providerId}/${gameModel.id}/thumbnail_320x320.jpg`;
  const gameLink = `/game/${gameModel.providerId}/${gameModel.id}/`;

  return (
    <BorderCard key={gameModel.id} style={{
      minWidth: size || 'auto',
      minHeight: size || 'auto',
    }}>
      <CardActionArea component={Link} to={gameLink} onClick={() => {onClick && onClick(gameModel.id)}}>
        {
          (displayCategory === undefined || displayCategory === true) ? (
            <GameCategoryDiv>
              {gameModel.category}
            </GameCategoryDiv>
          ) : null
        }
        <GameCardImageWrapperDiv>
          <CardMedia
            component="img"
            loading="lazy"
            height={size}
            width={size}
            alt={gameModel.name}
            image={cardImagePath}
            onError={(e: any) => {
              e.target.src = cardImageDefaultPath;
            }}
            onLoad={() => setIsImageLoaded(true)}
          />
          {!isImageLoaded && <Skeleton
              variant="rectangular"
              width={size}
              height={size}
              animation="wave"
              sx={{
                position: 'absolute',
                top: 0,
                bottom: 0,
                left: 0,
                right: 0,
                "&::after":{background: `linear-gradient( 90deg, transparent, #B4AFA524, transparent)`}
              }}
            />
          }
          {
            (displayName === undefined || displayName === true) ? (
              <GameNameDiv>
                {gameModel.name}
              </GameNameDiv>
            ) : null
          }
        </GameCardImageWrapperDiv>
      </CardActionArea>
    </BorderCard>
  );
};

export default GameCard;
