import React, { useEffect, useState } from 'react';
import PersonalForm from './personal';
import AddressForm from './address';
import UploadForm from './upload';
import KYCSuccess from './success';

interface ChildProps {
  parentModalHandlerFunction: any;
  parentModalSizeHandler: any;
  closePopup(arg0: string): void;
}

const KycForm = (props: ChildProps) => {
  const [displayStep, setDisplayStep] = useState('personal');

  const changeSteps = (moduleName?: string) => {
    setDisplayStep(moduleName);
    if (moduleName === 'kycsuccess') props.parentModalSizeHandler('md');
  };

  useEffect(() => {
    setDisplayStep('personal');
  }, []);

  const closePopup = () => {
    props.closePopup('close');
  };

  return (
    <>
      {displayStep === 'personal' && <PersonalForm changeSteps={changeSteps} />}
      {displayStep === 'address' && <AddressForm changeSteps={changeSteps} />}
      {displayStep === 'uploaddocuments' && (
        <UploadForm changeSteps={changeSteps} closePopup={closePopup} />
      )}
      {displayStep === 'kycsuccess' && (
        <KYCSuccess changeSteps={changeSteps} closePopup={closePopup} />
      )}
    </>
  );
};

export default KycForm;
