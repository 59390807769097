import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { KycVerifiedIconSvg, TrashIconSvg } from '@/shared/svg';

import {
  StyledContainerFotter,
  StyledFotterHead,
  StyledFotterPole,
  StyledFotterSelected,
  StyledBetSlipContainer,
  StyledBetTrash,
  StyledBetSlipButton,
  StyledContainerRightFotter,
} from '@/pages/betting/style';
import { Button } from 'react-bootstrap';
import { getTicketCount } from './ticketService';
import { intToOrdinalNumberString } from '@/helpers/util';
import BetAmount from '@/pages/betting/betamount';
import PlaceBetPopup from '@/pages/betting/placebetpopup';
import BetSuccess from '@/pages/betting/betsuccess';
import CookieStorageService from '@/services/CookieStorageService';
import AuthService from '@/services/AuthService';
import md5 from 'md5';
import {
  setUserData,
} from '@/redux/slices/login';
import { useAppSelector } from '@/hooks/useAppSelector';
import { StyledSucessMessage, StyledWrapSucess } from '@/pages/withdraw/style';
import axios from 'axios';

//import { position } from 'styled-system';
//import { LefArrowIconSvg } from '@/shared/svg';

const RunnerFooter = ({
  selectedRunnersList,
  venueCode,
  programId,
  unitBet,
  setUnitBetFn,
  venueName,
  raceNumber,
  resetTicket,
  betCalculateAmount,
  poolName,
  legs,
  oddsValuePaid,
}: {
  selectedRunnersList: any;
  venueCode: string;
  programId: number;
  venueName: string;
  unitBet: number;
  setUnitBetFn: Function;
  raceNumber: any;
  resetTicket: Function;
  betCalculateAmount: Function;
  poolName: string;
  legs: number;
  oddsValuePaid:any;
}) => {
  const { userInfo } = useAppSelector(state => state.auth);
  const [totalTickets, setTotalTickets] = useState(0);
  const [totalAmount, setTotalAmount] = useState(0);
  const [enableBetSlip, setEnableBetSlip] = useState(false);
  const [enableBetSuccess, setEnableBetSuccess] = useState(false);
  const [customAmountSlip, setCustomAmountSlip] = useState(false);
  const [betAmount, setBetAmount] = useState(10);
  const [message, setMessage] = useState('');
  const [betLoad, setBetLoad] = useState(false);
  const [gstData, setGstData] = useState([]);

  const calculateTicketAmount = () => {
    const ticketCount = getTicketCount(selectedRunnersList, poolName, legs);
    if (betAmount === 0) setBetAmount(unitBet);
    setTotalTickets(ticketCount);
    setTotalAmount(ticketCount * betAmount);
    betCalculateAmount(ticketCount * betAmount);
  };

  const handleBetAmount = (val: number) => {
    setBetAmount(val);
    setUnitBetFn(val);
    setCustomAmountSlip(false);
  };

  const handleCloseBetAmount = () => {
    setCustomAmountSlip(false);
  };

  const previewSubmitBet = async () => {
    const ticket = {
      minimumBet: unitBet,
      pool: poolName,
      raceNumber: raceNumber,
      totalTickets: totalTickets,
    };
    setEnableBetSlip(true);

    const ticketFormat = selectedRunnersList.map((item: any) => {
      return item.join(',');
    });
    let finalTicket = ticketFormat.join('-');
    if (legs > 1) {
      finalTicket = ticketFormat.join('/');
    }

    const temp = {
      programId: programId, 
      raceNumber: raceNumber, 
      betType: poolName.toUpperCase(),   
      betString: 1, 
      betQuantity:1,
      betAmount: totalAmount,
      totalAmount: totalAmount,            
      oddsValuePaid: oddsValuePaid ? oddsValuePaid : undefined,
    };
    const response = await AuthService.getTicketDetails(temp);
    if(response.status){
     
      setGstData(response.data);
    }
  };

  const submitBet = async () => {
    setBetLoad(true);
    const ticketFormat = selectedRunnersList.map((item: any) => {
      return item.join(',');
    });
    let finalTicket = ticketFormat.join('-');
    if (legs > 1) {
      finalTicket = ticketFormat.join('/');
    }

    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    const sessionId = `${md5(token)}#${userInfo.selectedWalletId}`;

    const ticketObject = {
      entryType: 'sale',
      betAmount: betAmount,
      unitBet: unitBet,
      totalAmount: totalAmount,
      sessionId:sessionId,
      action: 'submit',
      runners: finalTicket,
      runnersDisplay: finalTicket,
      betQuantity: 1,
      programId: programId,      
      poolName: poolName,
      eventId: raceNumber,
      venueCode: venueCode,
      groupCode:'',
      customerId:sessionId,
      betType: 'tote',
    };

    const response = await AuthService.submitBet(ticketObject);
    if(response.status){
      setBetLoad(false);
      setEnableBetSlip(false);
      setEnableBetSuccess(true);
      setMessage(response.data.msg)
    } else {
      setBetLoad(false);
    }
  };

  const previewCustomAmountList = () => {
    setCustomAmountSlip(true);
  };

  const closeBetSlip = () => {
    setEnableBetSlip(false);
  };

  const closeBetSuccess = () => {
    setEnableBetSuccess(false);
  };

  const trashOrder = () => {
    resetTicket();
  };

  useEffect(() => {
    calculateTicketAmount();
  }, [betAmount]);

  useEffect(() => {
    calculateTicketAmount();
  }, [selectedRunnersList]);

  return (
    <>
      {totalAmount > 0 && ( <StyledContainerRightFotter>
        <StyledContainerFotter>
          <StyledFotterHead>
            <StyledFotterPole>{poolName.toUpperCase()}</StyledFotterPole>
            <StyledFotterSelected>
              {selectedRunnersList.map((items: any[], index: number) => (
                <div className='winplaces' key={index}>
                  {selectedRunnersList.length > 1 && items.length > 0 && (
                    <span className='ordinal_text'>
                      {index + 1}
                      <sup> {intToOrdinalNumberString(index + 1)}</sup>
                    </span>
                  )}
                  <ul key={index}>
                    {items && items.length == 0 ? (
                      <li>&nbsp;</li>
                    ) : (
                      items.map((item: number) => (
                        <li key={item}>
                          <Link to={''}>{item}</Link>
                        </li>
                      ))
                    )}
                  </ul>
                </div>
              ))}
            </StyledFotterSelected>
          </StyledFotterHead>
          <StyledBetSlipContainer>
            <StyledBetTrash>
              <Link to={''} onClick={() => trashOrder()}>
                <TrashIconSvg />
              </Link>
            </StyledBetTrash>

            <StyledBetSlipButton>
              <Button
                className='betslip_select'
                onClick={() => previewCustomAmountList()}
              >
                <span>&#8369;</span> {unitBet}
              </Button>
              {totalAmount > 0 && (
                <Button
                  className='betslip_button'
                  onClick={() => previewSubmitBet()}
                >
                  Place Bet - <span>&#8369;</span>
                  <span>{totalAmount}</span>
                </Button>
              )}
            </StyledBetSlipButton>
          </StyledBetSlipContainer>
          {customAmountSlip && (
            <BetAmount
              parentHandlerFunction={handleBetAmount}
              parentCloseHandlerFunction={handleCloseBetAmount}
              unitBet={unitBet}
            />
          )}
          {enableBetSlip && (
            <PlaceBetPopup
              parentCloseHandlerFunction={closeBetSlip}
              parentSubmitBetHandlerFunction={submitBet}
              betLoad = {betLoad}
              venueName={venueName}
              poolName={poolName}
              raceNumber={raceNumber}
              selectedRunnersList={selectedRunnersList}
              totalAmount={totalAmount}
              gstData={gstData}
            />
          )}
          {enableBetSuccess && (
            <BetSuccess parentCloseHandlerFunction={closeBetSuccess} />
          )}
        </StyledContainerFotter>
      </StyledContainerRightFotter> )}
    </>
  );
};

export default RunnerFooter;
