export const intToOrdinalNumberString = (num: number): string => {
  num = Math.round(num);
  const numString = num.toString();

  if (Math.floor(num / 10) % 10 === 1) {
    return numString + 'th';
  }

  switch (num % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
