import React from 'react';
import {
  StyledLoaderContainer,
  StyledLoadView,
  StyledLoadLogo,
  StyledLoadGif,
} from './style';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const Loading = ({ onClick }: ChildProps) => {
  console.log(onClick);
  return (
    <StyledLoaderContainer>
      <StyledLoadView>
        <StyledLoadLogo>
          {/* <img
            src={process.env.REACT_APP_THEME_LOGO_MOBILE}
            alt=''
          /> */}
        </StyledLoadLogo>
        <StyledLoadGif>
          {/* <img
            className='loading_img'
            src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/loader.gif`}
            alt=''
          /> */}
           <img
              src={process.env.REACT_APP_THEME_LOGO_MOBILE} alt=''
            />
          <div className="custom-loader">
          </div>
          {/* <h6>Loading...</h6> */}
        </StyledLoadGif>
      </StyledLoadView>
    </StyledLoaderContainer>
  );
};

export default Loading;
