import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
  ArrowLeftSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledTextArea,
  StyledpageViewMain,
  StyledpagHeader,
  StyledpagBody,

  
} from './style';
import Accordion from 'react-bootstrap/esm/Accordion';


const FaqEgamesInquiries = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
      <h3>FAQs &amp; Help</h3>
        <StyledContainerPageView>
        <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledpageViewMain>
                <StyledpagHeader>
                 <Link to='/faqs'><ArrowLeftSvg/></Link>  <h4>Evolution Games Inquiries</h4>
                </StyledpagHeader>
                <StyledpagBody>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>Evolution Games Inquiries Testing</Accordion.Header>
                      <Accordion.Body>
                        <h4>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Sagittis aliquam malesuada bibendum arcu.</h4>
                        <ol>
                          <li>Lectus sit amet est placerat in. Faucibus pulvinar elementum integer enim neque volutpat.</li>
                          <li>Eget aliquet nibh praesent tristique magna sit.</li>
                          <li>Aliquet enim tortor at auctor urna nunc id. Habitant morbi tristique senectus et netus et malesuada.</li>
                          <li>Etiam tempor orci eu lobortis elementum nibh.</li>
                        </ol>
                        <p>Maecenas sed enim ut sem viverra aliquet eget sit. Augue mauris augue neque gravida in fermentum. Duis convallis convallis tellus id interdum velit laoreet.</p>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </StyledpagBody>
              </StyledpageViewMain>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default FaqEgamesInquiries;
