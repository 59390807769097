import styled, { device, themeGet } from '@/shared/styled';

export const StyledContainerFluid = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 60px;
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 20px;
    margin-bottom: 30px;
    ${device.TabToLg} {
      margin-bottom: 20px;
    }
  }
  h3 {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.TabToLg} {
    padding-top: 0px;
    padding-bottom: 0px;
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }

  .textareamessage {
    div {
      bottom: -20px;
      top: inherit;
    }
  }
  select {
    appearance: none;
    background-image: url(https://static-web.fra1.cdn.digitaloceanspaces.com/rwitc/select-down-arrow.svg) !important;
    background-position: 98% 10px !important;
    background-repeat: no-repeat !important;
    background-size: 18px !important;
    padding-left: 12px;
    text-transform: capitalize;
    background: ${themeGet('colors.pageinfotxtcolor')};
    border: 1px solid ${themeGet('colors.pageinfoborder')};
  }
  select.placeholder {
    color: #6f8caa !important;
    opacity: 1;
  }
`;

export const StyledContainerPageView = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0px;
  flex-direction: row-reverse;
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledPageLeft = styled.div`
  display: flex;
  padding-right: 20px;
  width: 366px;
  flex-direction: column;
  ${device.TabToLg} {
    width: 100%;
    padding-right: 0px;
    padding-top: 20px;
  }
`;

export const StyledPageRight = styled.div`
  display: flex;
  width: calc(100% - 366px);
  ${device.TabToLg} {
    width: 100%;
  }
`;

export const StyledInfoBox = styled.div`
  background: ${themeGet('colors.pageinfotxtcolor')};
  border: 1px solid ${themeGet('colors.pageinfoborder')};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  span {
    width: 55px;
  }
  svg {
  }
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageTextContainer = styled.div`
  background: ${themeGet('colors.pagecontainerbg')};
  border: 1px solid ${themeGet('colors.pagecontainerborder')};
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  .contactform {
    .row {
      margin-bottom: 20px;
      .no_padding {
        ${device.TabToLg} {
          margin-bottom: 20px;
        }
      }
    }
    label {
      color: #a3adc2;
      font-size: 14px;
      line-height: 18px;
      margin-bottom: 5px;
    }
    input {
      background: ${themeGet('colors.pagecontainerinput')};
      //border: 1px solid ${themeGet('colors.pagecontainerinputborder')};
      border-radius: 6px;
      color: #a3adc2;
      height: 42px;
      padding: 0.375rem 0.75rem;
      &:focus {
        border: 1px solid #57626f;
      }
    }
    textarea {
      background: ${themeGet('colors.pagecontainerinput')};
      //border: 1px solid ${themeGet('colors.pagecontainerinputborder')};
      border-radius: 6px;
      color: #a3adc2;
      min-height: 120px;
      resize: none;
      padding: 0.375rem 0.75rem;
      &:focus {
        border: 1px solid #57626f;
      }
    }
  }

  .send-btn {
    background: ${themeGet('colors.mainborder')};
    border-radius: 6px;
    color: ${themeGet('colors.pagefontcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    height: 41px;
    width: 147px;
    display: flex;
    align-items: center;
    gap: 6px;
    margin-top: 35px;
    margin-bottom: 10px;
    svg path {
      stroke: ${themeGet('colors.pagefontcolor')};
    }
  }
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageBanner = styled.div`
  img {
    border-radius: 10px;
    float: left;
    margin-bottom: 25px;
    width: 100%;
  }
`;

export const StyledPageTextView = styled.div`
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
  }
  p {
    color: #a3adc2;
    font-size: 14px;
    line-height: 22px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  .weare_passio {
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
`;

export const StyledTextArea = styled.div`
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  .chat_with_text {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
  p {
    color: #c2c9d6;
    font-size: 24px;
    line-height: 31px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    margin-bottom: 16px;
  }
  strong {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-weight: 400;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    font-family: ${themeGet('fonts.googleSansRegular')};
    span {
      padding-right: 5px;
    }

    svg path {
      stroke: ${themeGet('colors.homebannertxtcolor')};
    }
  }
  small {
    color: #b5b5b5;
    width: 100%;
    margin-top: 0.25rem !important;
  }
`;

export const StyledSupportContent = styled.div`
  align-items: center;
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #2f3847;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
`;

export const StyledSupportInfo = styled.div`
  align-items: center;
  display: flex;
  font-family: ${themeGet('fonts.googleSansRegular')};
  span {
    align-items: center;
    display: flex;
  }

  small {
    font-size: 16px;
    line-height: 24px;
    color: #c2c9d6;
    margin-left: 12px;
  }

  strong {
    border-left: 1px solid #4c4c4c;
    color: ${themeGet('colors.btnfontcolor')};
    margin-left: 10px;
    padding-left: 10px;
  }
`;

export const StyledSupportAvailable = styled.div`
  span {
    color: #a3adc2;
    float: left;
    font-size: 14px;
    line-height: 24px;
  }
`;

// export const StyledDivider = styled.div``;
// export const StyledBottomText = styled.div``;
// export const StyledForgotText = styled.div``;
// export const StyledPhoneText = styled.div``;
// export const StyledAlertMessage = styled.div``;
