import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  AboutLogoSvg,
  HeadPhoneSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledPageBanner,
  StyledPageTextView,
  StyledTextArea,
  StyledSupportContent,
  StyledSupportInfo,
  StyledSupportAvailable,
} from './style';

const Aboutus = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
        <h3>About Us</h3>
        <StyledContainerPageView>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledPageBanner>
                <img
                  src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/about-banner-img.png`}
                  alt=''
                />
              </StyledPageBanner>
              <StyledPageTextView>
                <h5>Who We Are</h5>
                <h5 className='weare_passio'>
                  We are a passionate team of computer nerds, sports
                  enthusiasts, statisticians, bookies and digital wizards who
                  have combined forces to create applications that treat punters
                  like the legends they are.
                </h5>
                <p>
                  Immerse yourself in the new world of personalised play with a
                  host of entertaining services in the palm of your hand. Our
                  team have created a robust platform that s fast, safe and
                  secure. The slick interface means more game-play and less
                  horsing around. It all starts with a superior online betting
                  experience. {process.env.REACT_APP_SITE_TITLE} features exciting sports action, the
                  best horse racing from around the globe, innovative products
                  and thrilling promotions.
                </p>
                <p>
                  The key to {process.env.REACT_APP_SITE_TITLE} success is the importance placed on
                  quality customer service - Players are never more than a click
                  or call away. {process.env.REACT_APP_SITE_TITLE} makes it easy to request information,
                  services and support 24/7
                </p>
                <StyledSupportContent>
                  <StyledSupportInfo>
                    <span>
                      <HeadPhoneSvg />
                      <small>Customer Support</small>
                    </span>
                    <strong><Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link></strong>
                  </StyledSupportInfo>
                  <StyledSupportAvailable>
                  
                    <span>Available: 24x7</span>
                  </StyledSupportAvailable>
                </StyledSupportContent>
              </StyledPageTextView>
            </StyledPageTextContainer>
          </StyledPageRight>
          <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <AboutLogoSvg />
              </span>

              <StyledTextArea>
                <p>
                  Welcome to {process.env.REACT_APP_SITE_TITLE}, your trusted
                  source for Entertainment and Betting events Worldwide.
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default Aboutus;
