import styled, { themeGet, device } from '@/shared/styled';

export const StyledCard = styled.div`
  position: relative;
  width:191px;
  a {
    align-items: center;
    //background: ${themeGet('colors.largegamecardbackground')};
    border: 1.5px solid ${themeGet('colors.largegamecardborder')};
    border-radius: 10px;
    color: ${themeGet('colors.white')};
    display: flex;
    flex-direction: column;
    height: 220px;
    padding: 2px;
    text-decoration: none;
    transition: all 0.3s;
    width:191px;
    ${device.TabToXl} {
      //flex-direction: column;
      //height: auto;
    }
    ${device.MobToMd} {
      //flex-direction: column;
      //height: auto;
    }
  }
  a:hover {
    //background: ${themeGet('colors.largegamecardbackground')};
    border: 1.5px solid ${themeGet('colors.btnfontcolor')};
    span {
      //background: ${themeGet('colors.mainborder')};
      color: ${themeGet('colors.pagefontcolor')};
      svg path {
        fill: ${themeGet('colors.pagefontcolor')};
      }
    }
  }
  ${device.TabToLg} {
    a {
      height: auto;
    }
  }
  ${device.MobToSl} {
    width:160px;
    a{
      width:160px;
      height: 184px;
    }
  }
  ${device.MobToSm}{
    width:140px;
    a{
      width:140px;
      height: 170px;
    }
  }
  ${device.MobToSd}{
    width:120px;
    a{
      width:120px;
      height: auto;
    }
  }
`;

export const StyledCardImgContainer = styled.div`
  height: 150px;
  img{
    border-radius: 7px;
  }
  ${device.MobToSL} {
    width: 100% !important;
  }
  ${device.TabToLg} {
    width: 100% !important;
  }
  ${device.TabToXl} {
    width: 100% !important;
    img {
      width: 100%;
    }
  }
  ${device.MobToMd}{
    height: auto;
  }

  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
  }
`;

export const StyledCardBodyContainer = styled.div`
  padding-left: 0;
  width: 100%;
`;
export const StyledBardFooterContainer = styled.div`
display: flex;
justify-content: space-between;
width: 100%;
padding: 6px 10px 5px 10px;
align-items: center;
  p{
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    position: relative;
    top: 3px;
    line-height: 13px;
  }
button.time{
  border-radius: 4px;
  background:#D75428;
  padding: 0px 6px;
  height:20px;
  color:${themeGet('colors.white')};
  font-size: 14px;
  font-family: ${themeGet('fonts.googleSansBold')};
  span{
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    position: relative;
    top: -0.5px;
    left: 0.5px;
  }
}
${device.MobToMd} {
  padding: 7px 10px 3px 10px; 
  p{
    font-size: 13px;
    top: 1px;
  }
  button.time{
    height:15px;
    font-size: 13px;
    display: flex;
    align-items: center;
    span{
      font-size: 13px;
    }
  }
 
}
${device.MobToSd}{
  padding: 5px 5px 5px 5px;
}
`;

export const StyledCardBodyTitle = styled.h4`
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 14px;
  font-weight: inherit;
  margin-top: 10px;
  padding-left: 10px;
  //text-shadow: 0 1px 3px rgb(0 0 0 / 90%);
  white-space: nowrap;
  white-space: break-spaces;
  align-items: center;
  gap: 8px;
  display: flex;
  svg{
    border: 1px solid rgba(255, 255, 255, 0.80);
  }
  img{

  }
  ${device.MobToMd} {
    font-size: 13px;
    margin-top: 9px;
    padding-right: 8px;
    
    //display: inherit;
    //gap: 8px;
    p{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 96%;
    }
    svg{
      width:19px;
      height:13px;
      //margin-right:8px;
    }

  }
  ${device.MobToSd}{
    font-size: 13px;
    gap: 5px;
    font-size: 13px;
    padding-left: 5px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 96%;
    //display: inherit;
    p{
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      width: 96%;
    }
    svg{
      width:19px;
      height:13px;
      //margin-right:5px;
    }
  }

`;

export const StyledCardBodyContent = styled.p`
  color: ${themeGet('colors.copyrighttextcolor')};
  font-family: ${themeGet('fonts.googleSansRegular')} !important;
  font-size: 15px;
  line-height: 19px;
  margin-top: 10px;
`;

export const StyledCardButtonContainer = styled.span`
  // align-items: center;
  // background: none;
  // border: 1px solid ${themeGet('colors.btnfontcolor')};
  // border-radius: 6px;
  // color: ${themeGet('colors.btnfontcolor')};
  // display: flex;
  // font-family: ${themeGet('fonts.googleSansBold')} !important;
  // font-size: 14px;
  // gap: 6px;
  // height: 34px;
  // justify-content: center;
  // margin-top: 15px;
  // min-width: 98px;
  // padding: 6px 10px;
  // transition: all 0.3s;
  // width: 111px;
  // svg path {
  //   fill: ${themeGet('colors.btnfontcolor')};
  // }
`;
export const StyledRtgHoverContainer = styled.div`
  span {
    align-items: center;
    display: flex;
    justify-content: center;
    background: ${themeGet('colors.OverLaySubGames')};
    border-radius: 6px;
    flex-direction: column;
    height: 152px;
    left: 2px;
    opacity: 0;
    position: absolute;
    top: 2px;
    transition: opacity 0.3s;
    width:calc(100% - 4px);
    &:hover {
      opacity: 1;
    }
    .playnow_btn {
      background: ${themeGet('colors.btnfontcolor')};
      border: 1px solid ${themeGet('colors.btnfontcolor')};
      border-radius: 6px;
      color: ${themeGet('colors.pagefontcolor')};
      font-family: ${themeGet('fonts.googleSansBold')} !important;
      font-size: 14px;
      gap: 6px;
      height: 34px;
      min-width: 98px;
      padding: 6px 10px;
      transition: all 0.3s;
      width: 111px;
      display: flex;
      align-items: center;
    }
    ${device.MobToMd}{
      height: 110px;
      z-index: 99;
    }
  }
  .tryit_btn {
    align-items: flex-end;
    background: none;
    border: 1.2px solid ${themeGet('colors.btnfontcolor')};
    border-radius: 6px;
    color: ${themeGet('colors.btnfontcolor')};
    display: inline-flex;
    font-size: 13px;
    gap: 6px;
    margin-top: 20px;
    padding: 7px 16px 7px 15px;
    font-family: ${themeGet('fonts.googleSansBold')} !important;
  }
`;