/**
 * Component dedicated to display external content and game provider games.
 *
 * First call API to get an external provider game URL, often sign by a special token.
 * If our API return a URL, add iFrame with a URL.
 */

import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
// Mui
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
// Service
import GameService from '@/services/GameService';
// Components
import Page from '@/components/Page';
import Loading from '@/components/Loading';


const GamePage = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));
  // Calculate iframe height.
  const calculateIframeHeight = () => {
    const isMobile = (window.innerWidth < 620 || window.innerHeight < 620);
    return window.innerHeight - (isMobile ? 60 : 0);
  }
  // State
  const [iframeHeight, setIframeHeight] = useState(calculateIframeHeight())
  const [gameURL, setGameURL] = useState(null);
  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState('');
  const location = useLocation();

  const hideSpinner = () => {
    setLoading(false);
  };

  const setIFrameURL = async (apiOpenGameURL: string) => {
    const gameURL = await GameService.fetchGameDetails(apiOpenGameURL);
    if (gameURL.status) {
      setGameURL(gameURL.data);
      if(location.pathname.includes('evo/')){
        setEvolutionScript(gameURL.data)
      }
    } else {
      hideSpinner();
      setMessage(gameURL.error)
    }
  };

  const setEvolutionScript = (url:string) => {
    const scripturl = 'https://studio.evolutiongaming.com/mobile/js/iframe.js';
    let script = document.createElement("script");
    script.src = scripturl;
    script.type = "application/javascript";
    script.async = true;
    document.head.appendChild(script);
    // setTimeout(() => {
    //     const scripturl2 = '/iframe.js';
    //     let script2 = document.createElement("script");
    //     script2.src = scripturl2;
    //     script2.type = "application/javascript";
    //     script2.async = true;
    //     document.head.appendChild(script2);
    // }, 2000);
  };

  useEffect(() => {
    if (location.pathname) {
      const path = location.pathname.replace(/^\/|\/$/g, '');
      setIFrameURL(`${path}/open`);
    }
  }, [location.pathname]);

  useEffect(() => {
    const handleResize = () => setIframeHeight(calculateIframeHeight());
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, []);

  return (
    <Page key={location.pathname} title="Game">
      {loading && (<Loading />)}
      {gameURL && (
        <iframe
          key={gameURL}
          id="game"
          name="Game"
          src={gameURL}
          scrolling="no"
          allowFullScreen={true}
          onLoad={hideSpinner}
          style={{
            width: '100%',
            height: iframeHeight,
            // This is fucked and needs to be fixed after moving to Material-UI.
            paddingTop: isMobileOrTablet ? 0 : 60,
          }}
        ></iframe>
      )}
      {message !== '' && (
        <div className='gameerrormessage'>
          <p>{message}</p>
        </div>
      )}
    </Page>
  );
};

export default GamePage;
