/* eslint-disable no-param-reassign */
/* eslint-disable @typescript-eslint/no-unused-expressions */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export type TTheme = 'fairplay' | 'arionplay';

export interface UIState {
  theme: TTheme;
}

const initialState: UIState = {
  theme: (process.env.REACT_APP_THEME || 'arionplay') as TTheme,
};

const themeSlice = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme(state, action: PayloadAction<{ theme: TTheme }>) {
      const {
        payload: { theme },
      } = action;

      state.theme = theme;
    },
  },
});

export const { toggleTheme } = themeSlice.actions;
export default themeSlice.reducer;
