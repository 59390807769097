import themeGet from '@styled-system/theme-get';
import styled from 'styled-components';

export const StyledWalletList = styled.div`
  width: 229px;
  margin-bottom:12px;
  a {
    padding: 10px 5px !important;
    //border-bottom: 1px solid rgba(165, 176, 189, 0.3);
    justify-content: space-between;
    p{ color: #B5B5B5; text-align: right; font-family: ${themeGet('fonts.googleSansMedium')};}
    .radioview{
      display: flex;
      gap: 8px;
      font-weight: inherit;
      div{
        padding-left: 20px
      }
    }
    .custom-radio{
      label{
        padding-left:0px;
      }
      label::before{
        width: 16px;
        height: 16px;
        border:1.5px solid #B5B5B5;
      }
    }
    .custom-radio{
      label::after{
        left: -16px;
        width: 16px;
        height: 16px;
        // background:${themeGet('colors.mainbackground')};
      }
    }
  }
  a.active {
    //color: ${themeGet('colors.mainborder')};
  }
  ,
  a a.active svg path {
    stroke: ${themeGet('colors.mainborder')};
  }
  a.active:hover {
   // color: ${themeGet('colors.mainborder')} !important;
  }
`;
