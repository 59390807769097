/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { SpaceProps, BorderProps, device } from '@/shared/styled';
import {
  InputStyles,
  SuccessInputStyles,
  WarningInputStyles,
  ErrorInputStyles,
} from '@/components/forms/form-elements/style';
import {
  IFeedback,
  TCustomStyle,
} from '@/components/forms/form-elements/types';

interface IInput extends IFeedback, SpaceProps, BorderProps {
  $width?: string | any[];
  $height?: string | any[];
  $customStyle?: TCustomStyle;
}

export const StyledTextarea = styled('textarea')<IInput>`
  height: auto;
  min-height: 80px;
  ${device.large} {
    min-height: 100px;
  }
  ${InputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'success' &&
    !!$showState &&
    !$showErrorOnly &&
    SuccessInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'warning' &&
    !!$showState &&
    !$showErrorOnly &&
    WarningInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'error' && !!$showState && !!$showErrorOnly && ErrorInputStyles};
`;
