import React, { useEffect, useState } from 'react';
// Components
import Dropdown from '@/components/ui/dropdown/dropdown';
import DropdownToggle from '@/components/ui/dropdown/dropdown-toggle';
import DropdownMenu from '@/components/ui/dropdown/dropdown-menu';
import DropdownItem from '@/components/ui/dropdown/dropdown-item';
import { Link } from 'react-router-dom';
import {
  UserLoginSvg,
  MyWalletSvg,
  BettingHistorySvg,
  MyAccountSvg,
  MyActivitySvg,
  LogoutSvg,
  FaqHelp,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';
import AuthService from '@/services/AuthService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { setIsAuthenticatedData, resetUser } from '@/redux/slices/login';
import { history } from '@/helpers/history';
const ProfileDropDown = () => {
  const [selectedOption, setSelectedOption] = useState<String>();
  const dispatch = useAppDispatch();

  const selectChange = (val: any) => {
    setSelectedOption(val);
  };

  const logoutUser = () => {
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    dispatch(setIsAuthenticatedData(false));
    dispatch(resetUser());
    history.push('/');
  };

  const selectMenu = async () => {
    const { pathname } = window.location;
    if (pathname.includes('mywallet')) setSelectedOption('mywallet');
    if (pathname.includes('bettinghistory')) setSelectedOption('bettinghistory');
    if (pathname.includes('myaccount')) setSelectedOption('myaccount');
    if (pathname.includes('myactivity')) setSelectedOption('myactivity');
    if (pathname.includes('faqs')) setSelectedOption('faqs');
  };

  useEffect(() => {
    selectMenu();
  }, []);

  return (
    <Dropdown>
      <DropdownToggle className='userloginbtn'>
        <UserLoginSvg />
      </DropdownToggle>
      <DropdownMenu>
        <Link
          to='/mywallet'
          className={"profile "+ (selectedOption === 'mywallet' ? 'active' : '')}
          onClick={() => selectChange('mywallet')}
        >
          <MyWalletSvg />
          My Wallet
        </Link>
        <Link
          to='/bettinghistory'
          className={"profile "+ (selectedOption === 'bettinghistory' ? 'active' : '')}
          onClick={() => selectChange('bettinghistory')}
        >
          <BettingHistorySvg />
          Betting History
        </Link>
        <Link
          to='/myaccount'
          className={"profile "+ (selectedOption === 'myaccount' ? 'active' : '')}
          onClick={() => selectChange('myaccount')}
        >
          <MyAccountSvg />
          My Account
        </Link>
        <Link
          to='/myactivity'
          className={"profile "+ (selectedOption === 'myactivity' ? 'active' : '')}
          onClick={() => selectChange('myactivity')}
        >
          <MyActivitySvg />
          My Activity
        </Link>
        <Link
          to='/faqs'
          className={"profile "+ (selectedOption === 'faqs' ? 'active' : '')}
          onClick={() => selectChange('faqs')}
        >
          <FaqHelp />
          FAQs & Help
        </Link>
        <DropdownItem path={''} onClick={() => logoutUser()}>
          <LogoutSvg />
          <span>Logout</span>
        </DropdownItem>
      </DropdownMenu>
    </Dropdown>
  );
};

export default ProfileDropDown;
