import classnames from 'clsx';
import { StyledDropItem } from './style';
import React from 'react';

interface IDropItem {
  children: React.ReactNode;
  path: string;
  onClick?: () => void;
  className?: string;
  active?: boolean;
}

const DropdownItem = ({
  children,
  path,
  className,
  active,
  onClick,
}: IDropItem) => (
  <StyledDropItem
    active={active}
    path={path}
    onClick={onClick}
    className={classnames(className, 'dropdown-item')}
  >
    {children}
  </StyledDropItem>
);

export default DropdownItem;
