import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
  ArrowLeftSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledTextArea,
  StyledpageViewMain,
  StyledpagHeader,
  StyledpagBody,

  
} from './style';
import Accordion from 'react-bootstrap/esm/Accordion';


const FaqsDetails = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
      <h3>FAQs &amp; Help</h3>
        <StyledContainerPageView>
        <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledpageViewMain>
                <StyledpagHeader>
                 <Link to='/faqs'><ArrowLeftSvg/></Link>  <h4>Account Enquiries</h4>
                </StyledpagHeader>
                <StyledpagBody>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>How do I register for an account?</Accordion.Header>
                      <Accordion.Body>
                        <h4>To create an account with Arionplay, simply follow these steps:</h4>
                        <ul>
                          <li>Locate the ""Sign Up"" button situated in the top right corner of the screen.</li>
                          <li>Fill in your Gcash registered mobile number and birthdate.</li>
                          <li>Establish a strong and secure password for your account.</li>
                          <li>Click ""Create Account""</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>What should I do if I forget my account password?</Accordion.Header>
                      <Accordion.Body>
                        {/* <h4>In case you forget your login password, don't worry! Just follow these simple steps to retrieve it:</h4>
                        <ul>
                          <li>Head to the login screen and click on the "Forgot Password" link.</li>
                          <li>Enter the email address associated with your www.arionplay.com account.</li>
                          <li>Look out for a Password Email sent to your registered email address.</li>
                          <li>Follow the instructions provided in the email to set up a new password for your account.</li>
                        </ul> */}
                        <h4>In case you forget your login password, don't worry! Just follow these simple steps to retrieve it:</h4>
                        <ul>
                          <li>Head to the login screen and click on the ""Forgot Password"" button.</li>
                          <li>Provide mobile number associated with your www.arionplay.com account.</li>
                          <li>Set up a new password and confirm."</li>
                        </ul>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>What information do I need to provide during registration?</Accordion.Header>
                      <Accordion.Body>
                        <h4>During the registration process, you will be required to provide essential information to create your
                        account. The specific details may vary depending on the platform or service, but typically, you will
                        need to provide the following:</h4>
                        <ul>
                          <li>Locate the ""Sign Up"" button situated in the top right corner of the screen.</li>
                          <li>Fill in your Gcash registered mobile number and birthdate.</li>
                          <li>Establish a strong and secure password for your account.</li>
                          <li>Click ""Create Account""</li>
                        </ul>
                        {/* <ul>
                          <li>Full Name: Your complete legal name as it appears on official documents.</li>
                          <li>Phone Number: Your contact number for account-related communication</li>
                          <li>Email Address: A valid email where you'll receive account-related communications.</li>
                          <li>Password: A secure and unique password to protect your account.</li>
                          <li>Username: Often used as an identifier for your account.</li>
                          <li>Date of Birth: Your birthdate for age verification purposes.</li>
                          <li>Contact Information: Such as your phone number or address.</li>
                          <li>Consent to Terms and Conditions: Agreeing to the platform's rules and policies.</li>
                        </ul>
                        <p>Identification documents may be requested for security purposes. Rest assured that your information
                        will be handled confidentially and in accordance with data protection laws. For any concerns or
                        questions, contact our support team.</p> */}
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </StyledpagBody>
              </StyledpageViewMain>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default FaqsDetails;
