import styled, { device, themeGet } from '@/shared/styled';

export const StyledContainerFluid = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 60px;
  h3 {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.MobToMd} {
    padding-top: 0px;
    padding-bottom: 0px;
    padding:0px;
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const StyledContainerPageView = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0px;
  flex-direction: column;
  align-items: center;
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledPageLeft = styled.div`
  display: flex;
  padding-right: 20px;
  width: 366px;
  flex-direction: column;
  ${device.TabToLg} {
    width: 100%;
    padding-right: 0px;
    padding-top: 0px;
  }
`;

export const StyledPageRight = styled.div`
  display: flex;
  width: calc(100% - 366px);
  ${device.TabToLg} {
    width: 100%;
  }
`;

export const StyledInfoBox = styled.div`
  background: ${themeGet('colors.pageinfotxtcolor')};
  border: 1px solid ${themeGet('colors.pageinfoborder')};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  span {
    width: 55px;
  }
  svg {
  }
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageTextContainer = styled.div`
  background: ${themeGet('colors.pagecontainerbg')};
  border: 1px solid ${themeGet('colors.pagecontainerborder')};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  width: 100%;
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageBanner = styled.div`
  img {
    border-radius: 10px;
    float: left;
    margin-bottom: 25px;
    width: 100%;
  }
`;

export const StyledPageTextView = styled.div`
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    ${device.TabToLg} {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 12px;
    }
  }
  p {
    color: #a3adc2;
    font-size: 14px;
    line-height: 22px;
    margin-bottom: 16px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    ${device.TabToLg} {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 12px;
    }
    a {
      color: #b5b5b5;
      font-size: 13px;
      text-decoration: underline;
      transition: color 0.3s;
    }
  }
  .weare_passio {
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  ul {
    margin-bottom: 16px;
    margin-left: 16px;
    li {
      list-style: disc;
      color: #b5b5b5;
      font-family: ${themeGet('fonts.googleSansRegular')};
      font-size: 14px;
      line-height: 20px;
      margin-bottom: 5px;
      ${device.TabToLg} {
        font-size: 13px;
        line-height: 19px;
      }
    }
  }
`;

export const StyledTextArea = styled.div`
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  .chat_with_text {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
  p {
    color: #c2c9d6;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 16px;
    ${device.TabToLg} {
      font-size: 20px;
      line-height: 26px;
    }
  }
  strong {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-weight: 400;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    font-family: ${themeGet('fonts.googleSansRegular')};
    span {
      padding-right: 5px;
    }

    svg path {
      stroke: ${themeGet('colors.homebannertxtcolor')};
    }
  }
  small {
    color: #b5b5b5;
    width: 100%;
    margin-top: 0.25rem !important;
  }
`;

export const StyledUnsubscribe = styled.div`
 background:${themeGet('colors.overlaybg')};
 border-radius: 30px;
 min-width: 610px;
 display: flex;
 padding:22px 30px 30px 30px;
 flex-direction: column;
 ${device.MobToSmm}{
  width: 100%;
  min-width: 100%
 }
`;

export const StyledUnsubHead = styled.div`
 display: flex;
 width: 100%;
 align-items: center;
 justify-content: center;
 padding-bottom:22px;
`;
export const StyledUnsubBody = styled.div`
 border-radius: 16px;
 background: #1E2A41;
 display: flex;
 width: 100%;
 flex-direction: column;
 align-items: center;
 padding: 40px;
 gap: 25px;
 text-align: center;
  h4{
    font-size: 20px;
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  p{
    color:${themeGet('colors.InputLableText')};
    font-size: 14px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    svg{
      padding-left:5px;
    }
  }
  .resubscribe{
    font-size: 12px;
    color:rgba(255, 255, 255, 0.45);
    line-height: 18px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
`;

export const StyledAccountButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  &.reasonview{
    flex-direction: column;
    align-items: baseline;
    gap: 0px;
    span{
      font-size: 14px;
      font-family: ${themeGet('fonts.googleSansMedium')};
      color:${themeGet('colors.white')};
      padding-bottom:6px;
    }
    select{
      border-radius: 8px;
      border: 1px solid #344360;
      background: #141A28;
      color:#6A7A9B;
      font-size: 16px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      padding: 10px 14px;
      margin-bottom:30px;
      width: 100%;
      option{
        font-size: 12px;
      }
    }
    .deactiv_profile{
      width: 100%;
    }
  }
  gap: 20px;
  padding-top: 10px;
  .signout_profile {
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    height: 41px;
    border: 1.5px solid ${themeGet('colors.mainborder')};
    border-radius: 6px;
    color: ${themeGet('colors.mainborder')};
    width: 140px;
    background:none;
    padding:0px 18px
  }
  .deactiv_profile {
    background: ${themeGet('colors.mainborder')};
    border-radius: 6px;
    color: ${themeGet('colors.white')};
    width: 140px;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    height: 41px;
    padding:0px 18px
  }
`;