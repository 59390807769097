/* eslint-disable @typescript-eslint/no-unsafe-return */
import styled, { themeGet, tinycolor } from '@/shared/styled';
import check from '@/components/forms/images/check.svg';

export const StyledCheckboxLabel = styled.label`
  position: relative;
  margin-bottom: 0;
  vertical-align: top;
  display: inline-block;
  //height: 20px;
  padding-left: 10px;
  &:before {
    transition: background-color 0.15s ease-in-out,
      border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    position: absolute;
    top: 44%;
    transform: translateY(-50%);
    left: -16px;
    display: block;
    width: 18px;
    height: 18px;
    pointer-events: none;
    content: '';
    //background-color: ${themeGet('colors.btnfontcolor')};
    border: 1px solid #fff;
    border-radius: 0.25rem;
  }
  &:after {
    position: absolute;
    top: 47%;
    transform: translateY(-50%);
    left: -13px;
    display: block;
    width: 12px;
    height: 12px;
    content: '';
    // background: ${themeGet('colors.btnfontcolor')};
    background-repeat: no-repeat;
    background-size: 100%;
  }
  &.checkbox-error {
    &:before {
      border: 1px solid #ff7c54 !important;
    }
  }
`;

export const StyledCheckbox = styled.input`
  position: absolute;
  left: 0;
  z-index: -1;
  width: 1rem;
  height: 1.15625rem;
  opacity: 0;
  &:checked {
    & ~ label:before {
      color: #fff;
      border-color: ${themeGet('colors.primary')};
      background-color: ${themeGet('colors.primary')};
    }
    & ~ label:after {
      background-image: url(${check});
    }
  }
  &:disabled {
    & ~ label {
      color: ${themeGet('colors.gray600')};
    }
    &:checked ~ label:before {
      border-color: ${props =>
        tinycolor(props.theme.colors.primary as string)
          .setAlpha(0.2)
          .toString()};
      background-color: ${props =>
        tinycolor(props.theme.colors.primary as string)
          .setAlpha(0.5)
          .toString()};
    }
  }
  &:active {
    & ~ label:before {
      color: #fff;
      background-color: ${props =>
        tinycolor(props.theme.colors.primary as string)
          .lighten(35)
          .toString()};
      border-color: ${props =>
        tinycolor(props.theme.colors.primary as string)
          .lighten(35)
          .toString()};
    }
  }
  &:focus {
    & ~ label:before {
      box-shadow: none;
      border: 1px solid ${themeGet('colors. mainbackground')};
      ${props =>
        tinycolor(props.theme.colors.primary as string)
          .setAlpha(0.25)
          .toString()};
    }
    &:not(:checked) {
      & ~ label:before {
        border-color: ${props =>
          tinycolor(props.theme.colors.primary as string)
            .lighten(25)
            .toString()};
      }
    }
  }
`;
