import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
// Mui
import { useTheme, styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
// Components
import Page from '@/components/Page';
import GameList from '@/components/GameList';
// Services
import GameService from '@/services/GameService';
// SVG
import { EvolutionGamesSvg, RealTimeGamesSvg, WEGamesSvg } from '@/shared/svg';

// This must be fix on a global state when header is added.
const StyledPageDiv = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('md')]: {
    marginTop: 80,
  },
}));

const GamesPage = () => {
  const theme = useTheme();
  const isMobileOrTablet = useMediaQuery(theme.breakpoints.down('md'));

  const [gameList, SetGameList] = useState(null);
  const { pathname } = window.location;

  let gameProvider = '';
  if (pathname.includes('we')) gameProvider = 'we';
  if (pathname.includes('evo')) gameProvider = 'evo';
  if (pathname.includes('rtg')) gameProvider = 'rtg';
  let { module } = useParams();

  const getGamesList = async () => {
    const response = await GameService.getGamesForOperator(gameProvider);
    if (response.data.length > 0) {
      //filter ans sort by desc order
      const groupByCategory = response.data
        .filter(
          (game: any) =>
            game.providerId === gameProvider
        )
        .sort((x: any, y: any) => {
          return y.priority - x.priority;
        })
        .reduce((group: any, game: any) => {
          const { providerId } = game;
          group[providerId] = group[providerId] ?? [];
          game.image = 'thumbnail_320x320.jpg';
          game.infoUrl = `${process.env.REACT_APP_CDN_IMAGES_PATH}/baccarat-factsheet.pdf`;
          group[providerId].push(game);
          return group;
        }, {});
      SetGameList(groupByCategory);
    }
  };

  const getGameName = (provider: string) => {
    const gameObj: any = GameService.getGameInfo(provider);
    return gameObj.name;
  };

  useEffect(() => {
    getGamesList();
  }, [module]);

  return (
    <Page title="Games">
      <StyledPageDiv>
        <Container maxWidth={'xl'} style={{ minHeight: '80vh' }}>
          {gameList &&
            Object.entries(gameList).map((gameObjects: any, index: number) => (
              <GameList
                key={gameObjects[0]}
                providerId={gameObjects[0]}
                games={gameList[gameObjects[0]]}
                displaySearch={true}
                size={isMobileOrTablet ? 'small' : 'medium'}
              />
            ))
          }
        </Container>
      </StyledPageDiv>
    </Page>
  );
};

export default GamesPage;
