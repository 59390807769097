import React, { useEffect, useState } from 'react';
import {
  StyledResultsContainer,
  StyledResultHeader,
  StyledResultBody,
} from './style';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}
type RaceCardResults = {
  poolResults: any;
  runners: any;
  raceStatus: string;
};

const RaceCardResults: React.FC<RaceCardResults> = ({
  poolResults,
  runners,
  raceStatus,
}) => {
  const [poolRaceResults, setPoolRaceResults] = useState([]);
  const [poolWinResults, setPoolWinResults] = useState([]);
  const [poolPlcResults, setPoolPlcResults] = useState([]);
  const [winners, setWinners] = useState([]);

  const postionFormation = (val: any) => {
    switch (val.toString()) {
      case '1':
        return (
          <span>
            1<sup>st</sup>
          </span>
        );
      case '2':
        return (
          <span>
            2<sup>nd</sup>
          </span>
        );
      case '3':
        return (
          <span>
            3<sup>rd</sup>
          </span>
        );
      default:
        return (
          <span>
            {val}
            <sup>th</sup>
          </span>
        );
    }
  };

  const poolResultsFn = () => {
    if (poolResults.length > 0) {
      const poolResultData = poolResults
        .map((item: any) => {
          if (item.name !== 'WIN' && item.name !== 'PLC') {
            return item;
          }
        })
        .filter(Boolean);
      setPoolRaceResults(poolResultData);

      const poolWinResultData = poolResults
        .map((item: any) => {
          if (item.name === 'WIN') {
            return item.dividend;
          }
        })
        .filter(Boolean);
      setPoolWinResults(poolWinResultData);

      let plcData: any[] = [];
      const poolPlcResultData = poolResults
        .map((item: any) => {
          if (item.name === 'PLC') {
            let d = item.dividend.split(',');
            let w = item.winner.split(',');

            for (let i = 0; i < w.length; i++) {
              if (d[i] != undefined) plcData[w[i]] = parseInt(d[i])?parseInt(d[i]):'-';
              else plcData[w[i]] = '-';
            }
            return item;
          }
        })
        .filter(Boolean);
      setPoolPlcResults(poolPlcResultData);

      if (runners.length > 0) {
        const poolWinnerResultData = runners
          .map((runner: any) => {
            if (
              runner.winPosition == 1 ||
              runner.winPosition == 2 ||
              runner.winPosition == 3
            ) {
              let w = runner.odds.WIN;
              if (raceStatus === 'ALC') w = '';
              let temp = {
                name: runner.name,
                position: runner.position,
                winPosition: runner.winPosition?parseInt(runner.winPosition):'-',
                winPositionFormat: postionFormation(runner.winPosition),
                odds: { WIN: poolWinResultData, PLC: plcData[runner.position] },
              };
              //setWinners(temp);
              return temp;
            }
          })
          .filter(Boolean);
        setWinners(poolWinnerResultData);
      }
    }
  };
  useEffect(() => {
    if(poolResults)
      poolResultsFn();
  }, [raceStatus,poolResults]);

  return (
    <StyledResultsContainer>
      {/* <StyledResultHeader>
        <span className='runners'>RUNNERS</span>
        <span className='win'>WIN</span>
        <span className='place'>PLACE</span>
      </StyledResultHeader> */}
      <StyledResultBody>
        <ul>
          {winners != undefined &&
            winners.length > 0 &&
            winners?.map((item: any, index: number) => (
              <li key={index}>
                <span className='position'>
                  <span className=''>{item.winPositionFormat}</span>
                </span>
                <span className='horse_no'>{item.position}</span>
                <span className='horse_name'>{item.name}</span>
                <span className='win'>{item.odds.WIN}</span>
                <span className='place'>{item.odds.PLC}</span>
              </li>
            ))}

          {poolRaceResults != undefined &&
            poolRaceResults.length > 0 &&
            poolRaceResults?.map((items: any, index: number) => (
              <li key={index}>
                <span className='multi_pool'>
                  {items.name}
                  {items.winner !== '' && ','}
                  <b>{items.winner}</b>
                </span>
                <span className='place'>{items.dividend}</span>
              </li>
            ))}
        </ul>
      </StyledResultBody>
    </StyledResultsContainer>
  );
};

export default RaceCardResults;
