import { createSlice } from '@reduxjs/toolkit';

const initialState: Iregister = {
  regData: undefined,
};

const registerSlice = createSlice({
  name: 'register',
  initialState,
  reducers: {
    resetRegister: state => {
      Object.assign(state, initialState);
    },
    updateSignupData: (state, action) => {
      state.regData = { ...state.regData, ...action.payload };
    },
  },
});
export const { resetRegister, updateSignupData } = registerSlice.actions;
export const registerReducer = registerSlice.reducer;
