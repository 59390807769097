import React, { useRef, useState, useEffect } from "react"
import videojs from "video.js"
import "video.js/dist/video-js.css";
const VideoJsPlayer = (props: any) => {
  const videoRef = useRef(null)
  const [player, setPlayer] = useState(null)

  useEffect(() => {
    if (!videoRef || player) return

    const initPlayer = videojs(
      videoRef.current,
      props,
      function onPlayerReady() {
        console.log("onPlayerReady", this)
      }
    )
    setPlayer(initPlayer)

    return () => {
      if (player) player.dispose()
    }
  }, [props, player])

  return (
    <div>
      <div data-vjs-player>
        <video ref={videoRef} className="video-js" />
      </div>
    </div>
  )
}

export default VideoJsPlayer
