import React, { useEffect, useState } from 'react';
import {
  StyledContainerRunner,
  StyledjockyInfo,
  StyledRidingInfo,
  StyedHorseSireDamName,
  StyledJockyTrainer,
  StyledActionInfo,
  StyledActionButton,
  StyledOddInfo,
  StyledScrateched,
} from '@/pages/betting/style';
import { PlusIconSvg, SelectedSvg } from '@/shared/svg';
import { Button } from '@/components';

type RunnerType = {
  runner: any;
  raceStatus: string;
  betType: string;
  name: string;
  selectedPoolCode: string;
  selectedRunners: Function;
  selectedRunnersList: any;
  selectedLeg: number;
  positions: number;
  legs: number;
  boxOption: boolean;
};

const Runner: React.FC<RunnerType> = ({
  runner,
  raceStatus,
  betType,
  name,
  selectedPoolCode,
  selectedRunners,
  selectedRunnersList,
  selectedLeg,
  positions,
  legs,
  boxOption,
}) => {
  const [numberOfPositions, setNumberOfPositions] = useState(1);
  const [runnersList, setRunnersList] = useState([]);
  useEffect(() => {
    let pos = 1;
    if (!boxOption) pos = positions;
    setNumberOfPositions(pos);
  }, [positions, boxOption]);

  useEffect(() => {
    if (legs > 1) {
      const arr = [];
      arr[0] = selectedRunnersList[selectedLeg - 1];
      setRunnersList(arr);
    } else {
      setRunnersList(selectedRunnersList);
    }
  }, [legs, selectedLeg, selectedRunnersList]);

  return (
    <>
      <StyledContainerRunner
        className={`${runner.scratched === 'Y' ? 'scratched' : positions === 1 ? '' : 'racecardheight'}`}
      >
        <ul>
          <li>
            <StyledjockyInfo>
              <span className='infonumber'>{runner.position}</span>
              <img src={runner.jerseyUrl} />
              <small>({runner.drawNumber})</small>
            </StyledjockyInfo>
            <StyledRidingInfo>
              <StyedHorseSireDamName>
                <span className='horse_name'>{name}</span>
                <span className='sire_dam_name'>
                  ({runner.desc})*({runner.shortForm})*(Rt.{runner.rating})
                </span>
              </StyedHorseSireDamName>
              <StyledJockyTrainer>
                <span className='jockey_name'>
                  <b>J&nbsp;&nbsp;</b>
                  {runner.jockey.name}
                  <span>
                    ( wt {runner.weight} <span>Aw.0</span> ){' '}
                  </span>
                </span>
                <span className='trainer_name'>
                  <b>T&nbsp;&nbsp;</b>
                  {runner.trainer.name}{runner.scratched}
                </span>
              </StyledJockyTrainer>
            </StyledRidingInfo>
            <StyledActionInfo>
              {runner.scratched === 'Y' ? (
                <StyledScrateched>
                  <span>W</span>
                  <p>Scratched</p>
                </StyledScrateched>
              ) : raceStatus === 'BNS' || raceStatus === 'BSP' ? (
                ''
              ) : (raceStatus === 'DRL' || raceStatus === 'ALC') ? (
                <>
                  <StyledActionButton>
                    <Button type='button' className='winbutton'>
                    {runner.odds[selectedPoolCode.toUpperCase()] || 0}
                    </Button>
                  </StyledActionButton>
                </>
              ) : (
                <>
                  <StyledActionButton
                    className={`${positions === 1 ? 'singlebet' : 'multibet'}`}
                  >
                    <div className='totebet'>
                    {[...Array(numberOfPositions)].map((x, i) => (
                      <div key={i}>
                        <Button
                          type='button'
                          className={`winbutton ${
                            runnersList &&
                            runnersList[i] &&
                            runnersList[i].includes(runner.position)
                              ? `active`
                              : ''
                          }`}
                          key={i}
                          onClick={() => selectedRunners(i, runner.position)}
                        >
                          <p className='buttontext'>{positions === 1 ? '' : i + 1}</p>
                          {runnersList &&
                          runnersList[i] &&
                          runnersList[i].includes(runner.position) ? (
                            <SelectedSvg />
                          ) : (
                            <PlusIconSvg />
                          )}
                        </Button>
                      </div>
                    ))}
                    </div>
                  </StyledActionButton>
                  <StyledOddInfo>
                    <p>
                      <span>WIN</span>
                      <span>ODDS</span>
                      <span>{runner.odds[selectedPoolCode.toUpperCase()] || runner.odds['WIN']}</span>
                    </p>
                  </StyledOddInfo>
                </>
              )}
            </StyledActionInfo>
          </li>
        </ul>
      </StyledContainerRunner>
    </>
  );
};

export default Runner;
