import { fairplay, arionplay } from './colors';

const breakpoints = ['576px', '768px', '992px', '1200px', '1400px'];

const defaultThemeOpt = {
  fontSize: {
    body: '0.875rem',
    h1: ['2.1875rem', '2.1875rem', '2.1875rem', '2.1875rem'],
    h2: ['1.75rem', '1.75rem', '1.75rem'],
    h3: ['1.53125rem', '1.53125rem'],
    h4: ['1.3125rem', '1.3125rem'],
    h5: ['1.09375rem', '1.09375rem'],
    h6: ['0.875rem', '0.875rem'],
  },
  fonts: {
    body: `'google_sansregular', sans-serif`,
    heading: `'google_sansregular', sans-serif`,
    googleSansRegular: `'google_sansregular', sans-serif`,
    googleSansMedium: `'google_sansmedium', sans-serif`,
    googleSansBold: `'google_sansbold', sans-serif`,
  },
  fontWeights: {
    // body: 400,
    // heading: 500,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  radii: {
    sm: '3px',
    md: '6px',
    lg: '8px',
    rounded: '4px',
    circle: '50%',
    pill: '500px',
  },
  shadows: {
    default: '0 0 12px 3px rgba(0, 0, 0, 0.06)',
    sm: '0px -1px 1px 0px rgba(0,0,0, .2)',
    lg: '0 1rem 3rem rgba(0, 0, 0, .175)',
    input: '0 0 0 0.2rem rgb(1 104 250 / 25%)',
  },
  breakpoints: [...breakpoints],
  transition: 'all 0.4s ease 0s',
  anchor: {
    primary: {
      color: 'white',
      bg: 'red',
    },
  },
};

export const fairplayTheme = {
  name: 'fairplay',
  colors: { ...fairplay },
  ...defaultThemeOpt,
};

export const arionplayTheme = {
  name: 'arionplay',
  colors: { ...arionplay },
  ...defaultThemeOpt,
};
