import styled, { device, themeGet } from '@/shared/styled';

export const StyledContainerFluid = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding-top: 60px;
  ${device.MobToMd} {
    padding-top: 0px;
    padding-bottom:20px;
  }
  .marquee {
    font-size: 14px;
  }
`;

export const StyledNewsUpdate = styled.div`
  padding: 16px 16px 16px 20px;
  background: ${themeGet('colors.bodybackground')};
  color: #c2c9d6;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  h4 {
    border-right: 1px solid #4c4c4c;
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansBold')} !important;
    font-size: 15px;
    padding-right: 15px;
    width: 150px;
    margin-bottom: 0px;
  }
  ${device.TabToLg} {
    padding: 14px 16px;
    h4 {
      font-size: 12px;
      padding-right: 8px;
      //white-space: nowrap;
      width: auto;
    }
    .marquee-container {
      font-size: 11px;
      width: calc(100% - -159px);
      //margin-left: 10px;
    }
  }
`;

export const StyledNewsUpdateContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const StyledBannerContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  //padding-top: 10px !important;
  gap: 1rem;
  padding: 20px 16px 20px 16px;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.TabToLg} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
    padding: 10px 16px 20px 16px;
  }
`;

export const StyledBannerImg = styled.div`
  border-radius: 10px;
  border-radius: 16px;
  box-shadow: 0 4px 4px rgb(0 0 0 / 25%);
  display: grid;
  grid-column: span 3 / span 3;
  position: relative;
  img {
    border-radius: 10px;
    height: 240px;
    object-fit: cover;
    width: 100%;
  }
  ${device.TabToLg} {
    min-height: 160px;
    width: 100%;
    img {
      border-radius: 10px;
      //height: 160px;
      object-position: 60% 50%;
      //opacity: 0.6;
    }
  }
  ${device.MobToMd} {
    min-height: 126px;
    img {
      border-radius: 10px;
      height: 126px;
      object-position: 60% 50%;
      //opacity: 0.6;
    }
  }
`;

export const StyledBannerText = styled.div`
  display: flex;
  height: 100%;
  justify-content: start;
  left: 0;
  padding: 20px 20px 20px 20px;
  position: absolute;
  width: 100%;
  flex-direction: row;
  align-items: center;
  img{
    width: 166px;
    height: 170px;
  }
  h4 {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 34px;
    line-height: 35px;
    margin-bottom: 0px;
  }
  p {
    color: #cecece;
    font-family: ${themeGet('fonts.googleSansRegular')} !important;
    font-size: 32px;
    line-height: 20px;
    margin-top: 0px ;
    margin: 0px;
    padding-left:20px;
  }

  ${device.TabToLg} {
    h4 {
      font-size: 24px;
      line-height: 27px;
    }
    p {
      font-size: 20px;
      line-height: 24px;
      margin-top: 10px;
      font-family: ${themeGet('fonts.googleSansBold')} !important;
      //width: 100px;
      padding-left: 10px;
      &:last-child {
        margin-top: 0px;
      }
    }
  }
  ${device.MobToMd} {
    p{
      width: 100px;
    }
    img{
      width: 87.15px;
      height: 89.25px;
    }
  }

`;

export const StyledBannerAdd = styled.div`
  background: url(https://static-web.fra1.cdn.digitaloceanspaces.com/rwitc/social-banner-img.png);
  display: grid;
  background-position: 50% 50%;
  background-size: cover;
  border-radius: 8px;
  height: 100%;
  width: 100%;
  ${device.MobToLg} {
    display: none;
  }
  ${device.mdToLg} {
    display: none;
  }
  ${device.MobToMd}{
    display: none;
  }
`;

export const StyledBannerAddTitle = styled.div`
  padding: 15px 11px 0px;
  text-align: center !important;
  h3 {
    font-size: 20px;
    line-height: 22px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-style: italic;
    margin-bottom: 5px;
  }
  h6 {
    font-size: 14px;
    line-height: 18px;
    margin-bottom: 5px;
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-style: italic;
  }
`;

export const StyledBannerAddSocialIcons = styled.div`
  text-align: center !important;
  padding-top: 4px;

  ul {
    list-style: none;
    margin-top: 8px;
    li {
      display: inline-block;
      padding-right: 8px;
    }
  }
`;

export const StyledBannerLogo = styled.div`
  text-align: center;
`;

export const StyledBannerFotter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding-right: 15px;
  padding-bottom: 5px;
  padding-top: 12px;

  p {
    margin-bottom: 0px;
    font-size: 13px;
    font-style: italic;
    font-weight: 600;
    line-height: 15px;
    padding-right: 10px;
  }
  img {
    border-radius: 0;
    height: auto;
    object-fit: fill;
    width: 105px;
    height: 30px;
  }
`;
export const StyledLargeGameContainer = styled.div`
  padding: 0px 16px 20px 16px;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.MobToMd} {
    padding: 0px 0px 0px 0px;
  }
`;

export const StyledLargeGameBlock = styled.div`
  ${device.xlarge} {
  }
`;

export const StyledLargeGameList = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  //grid-template-columns: repeat(4, minmax(0, 1fr));
  ${device.TabToLg} {
    //grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  ${device.TabToXl} {
    //grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  h5{
    color:${ themeGet('colors.eventnumberdefaulttxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')} ;
    font-size: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    ${device.MobToMd} {
      font-size: 18px;
      padding-left:16px;
    }
  }
`;

export const StyledTripleView = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: row;
  overflow: auto;
  //padding-bottom: 10px;
  ${device.MobToMd} {
    gap: 12px;
    padding-bottom: 10px;
    padding-right: 16px;
    padding-left: 16px;
  }

`;
export const StyledGameCardView = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: row;
  overflow: auto;
  //padding-bottom: 10px;
  //grid-template-columns: repeat(6, minmax(0, 1fr));
  ${device.mdToLg} {
    //grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  ${device.TabToLg} {
   // grid-template-columns: repeat(2, minmax(0, 1fr));
    //margin-bottom: 20px;
  }
  ${device.MobToSL} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${device.MobToMd} {
    gap: 12px;
    padding-bottom: 10px;
    padding-right: 16px;
    padding-left: 16px;

  }
`;

export const StyledEvlView = styled.div`
  display: flex;
  gap: 18px;
  flex-direction: row;
  overflow: auto;
  //padding-bottom: 10px;
  //grid-template-columns: repeat(6, minmax(0, 1fr));
  ${device.mdToLg} {
    //grid-template-columns: repeat(3, minmax(0, 1fr)) !important;
  }
  ${device.TabToLg} {
    //grid-template-columns: repeat(2, minmax(0, 1fr));
    //margin-bottom: 20px;
  }
  ${device.MobToSL} {
    //grid-template-columns: repeat(2, minmax(0, 1fr));
  }

  ${device.MobToMd} {
    gap: 12px;
    padding-bottom: 10px;
    padding-right: 16px;
    padding-left: 16px;
  }
`;

export const StyledGameList = styled.div`
  display: flex;
  //margin-bottom: 24px;
  margin-top: 20px;
  flex-direction: column;
  h5 {
    color:${ themeGet('colors.eventnumberdefaulttxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')} !important;
    font-size: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    ${device.MobToMd} {
      font-size: 18px;
      padding-left:16px;
    }
  }
  ${device.MobToMd} {
    margin-top: 10px;
  }
`;
export const StyledViewAll = styled.div`
//width: 191px;
align-items: center;
  background: rgba(29, 37, 53, 0.20);
  border: 1px solid ${themeGet('colors.largegamecardborder')};
  border-radius: 10px;
  color: ${themeGet('colors.white')};
  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: all 0.3s;
  width:191px;
  position: relative;
  cursor: pointer;
  justify-content: center;
  &:hover {
    background: rgba(29, 37, 53, 0.20);;
    border: 1.5px solid ${themeGet('colors.btnfontcolor')};
    span {
      svg path {
        fill: ${themeGet('colors.pagefontcolor')};
      }
    }
    .hoverview {
      opacity: 1;
      ${device.MobToSL} {
        height: calc(100% - 45px);
      }
      ${device.TabToXl} {
        height: calc(100% - 45px);
      }
    }
  }
 a{
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color:${themeGet('colors.mainbackground')};
  gap:26px;
  width: 191px;
 }
 ${device.MobToSl} {
  width:160px;
  a{
    width:160px;
    //height: 184px;
  }
}
${device.MobToSm}{
  width:140px;
  height: auto;
  h6{
    font-size:13px;
    width: 96px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  svg{
    width: 83.77px;
    height: 51.937px;
  }
  p{
    svg{
      width: auto;
      height: auto;
    }
  }
  a{
    width:140px;
    gap: 20px;
    //height: 170px;
  }
}
${device.MobToSd}{
  width:120px;
  height: auto;
  svg{
    width: 83.77px;
    height: 51.937px;
  }
  a{
    width:120px;
    //height: 170px;
  }
}
`;

export const StyledEvlGameList = styled.div`
  display: flex;
  //margin-bottom: 24px;
  margin-top: 20px;
  flex-direction: column;
  h5 {
    color:${ themeGet('colors.eventnumberdefaulttxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')} ;
    font-size: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    ${device.MobToMd} {
      font-size: 18px;
      padding-left:16px;
    }
  }
  ${device.MobToMd} {
    margin-top: 10px;
  }
`;

export const StyledComingSoon= styled.div`
  display: flex;
  //margin-bottom: 24px;
  margin-top: 20px;
  flex-direction: column;
  h5 {
    color:${ themeGet('colors.eventnumberdefaulttxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')} ;
    font-size: 20px;
    margin-bottom: 16px;
    display: flex;
    align-items: center;
    gap: 10px;
    ${device.MobToMd} {
      font-size: 18px;
      padding-left:16px;
    }
  }
  ${device.MobToMd} {
    margin-top: 10px;
  }
`;
export const  StyledComingSoonGame= styled.div`
display: flex;
gap: 18px;
flex-direction: row;
overflow: auto;
//padding-bottom: 10px;
${device.MobToMd} {
  gap: 12px;
  padding-bottom: 10px;
  padding-right: 16px;
  padding-left: 16px;
}
`;

export const StyledErrorPage = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding-top: 100px;
`;

export const StyledErrorContainer = styled.div`
  padding: 20px 16px 20px 20px;
  background: ${themeGet('colors.bodybackground')};
  color: #c2c9d6;
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 60vh;
  justify-content: center;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  h4 {
    font-size: 38px;
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
  a {
    margin-top: 60px;
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 13px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')};
    width: 150px;
    align-items: center;
    justify-content: center;
    border-radius: 4px;
    height: 42px;
    border: none;
  }
`;

export const StyledOtherContries = styled.div`
  background: #fff;
  height: 100vh;
`;

export const StyledOtherContriesContainer = styled.div`
  display: flex;
  align-items: center;
  height: 100vh;
  justify-content: space-between;
  flex-direction: column;
  padding: 80px;
`;

export const StyledOtherLogo = styled.div`
  img {
    height: 55px;
  }
`;

export const StyledAttention = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  h4 {
    font-size: 36px;
    margin-bottom: 40px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    margin-top: 20px;
    color: ${themeGet('colors.mainbackground')};
  }
  p {
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    font-size: 20px;
    span {
      font-family: ${themeGet('fonts.googleSansBold')};
    }
  }
`;

export const StyledOtherContact = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  a {
    color: #666d77;
    font-size: 20px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    :focus {
      outline: none;
    }
  }
`;

export const StyledTwoGameCard = styled.div`
  padding: 5px 16px 20px 16px;
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
`;

export const StyledTwoGameList = styled.div`
  position: relative;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  gap: 1rem;

  ${device.MobToLg} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${device.TabToXl} {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  ${device.MobToMd} {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
`;

export const StyledLoader = styled.div``;

export const StyledRacingContainer = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: 100%;
  padding-top: 100px;
`;

export const StyledJoinCommunity = styled.div`
 display: none;
 ${device.MobToSmm}{
  display: block !important;
  padding: 16px;
  .joinour{
    .mobile_viewjoin{
      display: block !important;
    }
    h6{
      padding-top:30px;
      padding-bottom: 20px;
    }
    h3{
      padding-top:5px;
      padding-bottom: 30px;
    }
    ul{
      padding: 10px 0px 40px 0px;
    }
  }
 }
`;