import { FormGroup, Button, ButtonSpinner } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Row from 'react-bootstrap/Row';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { useAppSelector } from '@/hooks/useAppSelector';
import { changeModule } from '@/redux/slices/module';
import {
  StyledWrap,
  StyledAlertMessage,
  StyledButtonContainer,
  StyledSteps,
} from './style';

import { AlertWarningiconSvg } from '@/shared/svg';

interface ChildProps {
  changeSteps: any;
  closePopup(arg0: string): void;
}

const AccountcreatedForm = (props: ChildProps) => {
  const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const { isLoading, isError, errorMessage } = useAppSelector(
    state => state.auth
  );

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AccountCForm>();

  const onSubmit: SubmitHandler<AccountCForm> = data => {
    console.log(data, errors);
    setLoading(true);
    props.changeSteps('registersuccess');
  };

  const showKYCForm = () => {
    props.closePopup('close');
    dispatch(changeModule('kyc'));
  };

  useEffect(() => {
    setLoading(isLoading);
    if (isError) {
      setIsNotValid(true);
      setMessage(errorMessage);
    }
  }, [isError, isLoading]);

  return (
    <StyledWrap>
      <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
        {isNotValid ? (
          <StyledAlertMessage>
            <AlertWarningiconSvg /> {message}
          </StyledAlertMessage>
        ) : null}
        <StyledSteps>
          <img
            src={process.env.REACT_APP_THEME_LOGO_MOBILE}
            alt=''
          />
          <h4>Account Created Successfully</h4>
        </StyledSteps>
        <Row mb='20px'>
          <FormGroup className='loginform'>
            <div className='termsandcondition'>
              <h5>
                Submit your KYC to avoid any interruption to your gaming
                experience!
              </h5>
              <p>
                <span>Note:</span>KYC verification takes 24 - 48 hours to
                complete.
              </p>
            </div>
          </FormGroup>
        </Row>
        <Row className='nomargin'>
          <StyledButtonContainer>
            <Button
              type='button'
              color='brand2'
              className='signupbtn'
              onClick={() => props.closePopup('close')}
            >
              Skip for now
            </Button>
            <Button
              type='submit'
              color='brand2'
              className='loginbtn'
              onClick={() => showKYCForm()}
            >
              {loading ? <ButtonSpinner /> : null}Complete Kyc
            </Button>
          </StyledButtonContainer>
        </Row>
      </form>
    </StyledWrap>
  );
};

export default AccountcreatedForm;
