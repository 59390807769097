import styled, { device, themeGet } from '@/shared/styled';

export const StyledContainerFluid = styled.div`
  background: ${themeGet('colors.bodybackground')};
  min-height: calc(100vh - 223px);
  padding: 20px 16px 30px;
  padding-top: 100px;
  h3 {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 16px;
    margin-top: 20px;
  }
  ${device.xlarge} {
    width: 1280px !important;
    float: inherit;
    margin: 0px auto;
  }
  ${device.MobToMd} {
    padding-top: 0px;
    padding-bottom: 0px;
    h3 {
      font-size: 20px;
      line-height: 24px;
    }
  }
`;

export const StyledContainerPageView = styled.div`
  display: flex;
  width: 100%;
  padding-top: 0px;
  flex-direction: row-reverse;
  ${device.TabToLg} {
    flex-direction: column;
  }
`;

export const StyledPageLeft = styled.div`
  display: flex;
  padding-right: 20px;
  width: 366px;
  flex-direction: column;
  ${device.TabToLg} {
    width: 100%;
    padding-right: 0px;
    padding-top: 20px;
  }
`;

export const StyledPageRight = styled.div`
  display: flex;
  width: calc(100% - 366px);
  ${device.TabToLg} {
    width: 100%;
  }
`;

export const StyledInfoBox = styled.div`
  background: ${themeGet('colors.pageinfotxtcolor')};
  border: 1px solid ${themeGet('colors.pageinfoborder')};
  border-radius: 10px;
  margin-bottom: 20px;
  padding: 24px;
  display: flex;
  flex-direction: row;
  span {
    width: 55px;
  }
  svg {
  }
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageTextContainer = styled.div`
  background: ${themeGet('colors.pagecontainerbg')};
  border: 1px solid ${themeGet('colors.pagecontainerborder')};
  border-radius: 10px;
  padding: 24px;
  width: 100%;
  ${device.TabToLg} {
    padding: 16px;
  }
`;

export const StyledPageBanner = styled.div`
  img {
    border-radius: 10px;
    float: left;
    margin-bottom: 25px;
    width: 100%;
  }
`;

export const StyledPageTextView = styled.div`
  h5 {
    color: #c2c9d6;
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 20px;
    line-height: 25px;
    margin-bottom: 20px;
    ${device.TabToLg} {
      font-size: 18px;
      line-height: 23px;
      margin-bottom: 12px;
    }
  }
  p {
    color: #a3adc2;
    font-size: 14px;
    line-height: 22px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    ${device.TabToLg} {
      font-size: 13px;
      line-height: 21px;
      margin-bottom: 12px;
    }
  }
  .weare_passio {
    font-family: ${themeGet('fonts.googleSansRegular')};
  }
`;

export const StyledTextArea = styled.div`
  width: calc(100% - 55px);
  display: flex;
  flex-direction: column;
  .chat_with_text {
    font-size: 16px !important;
    margin-bottom: 0 !important;
  }
  p {
    color: #c2c9d6;
    font-size: 24px;
    line-height: 31px;
    margin-bottom: 16px;
    ${device.TabToLg} {
      font-size: 20px;
      line-height: 26px;
    }
  }
  strong {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    font-weight: 400;
  }
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-size: 16px;
    line-height: 20px;
    text-decoration: none;
    font-family: ${themeGet('fonts.googleSansRegular')};
    span {
      padding-right: 5px;
    }

    svg path {
      stroke: ${themeGet('colors.homebannertxtcolor')};
    }
  }
  small {
    color: #b5b5b5;
    width: 100%;
    margin-top: 0.25rem !important;
  }
`;

export const StyledSupportContent = styled.div`
  align-items: center;
  border-radius: 10px 10px 0 0;
  border-top: 1px solid #2f3847;
  display: flex;
  justify-content: space-between;
  padding: 16px 16px 0;
  margin-top: 20px;
  ${device.TabToLg} {
    flex-direction: column;
    padding: 12px 0px 0px 0px;
    justify-content: flex-start;
    margin-top: 12px;
    align-items: flex-start;
  }
`;

export const StyledSupportInfo = styled.div`
  align-items: center;
  display: flex;
  font-family: ${themeGet('fonts.googleSansRegular')};
  span {
    align-items: center;
    display: flex;
  }

  small {
    font-size: 16px;
    line-height: 24px;
    color: #c2c9d6;
    margin-left: 12px;
  }

  strong {
    border-left: 1px solid #4c4c4c;
    color: ${themeGet('colors.btnfontcolor')};
    margin-left: 10px;
    padding-left: 10px;
    a{
      color:${themeGet('colors.mainbackground')};
    }
    ${device.TabToLg} {
      border-left: none;
      margin: 0px;
      padding: 10px 0px 5px 0px;
    }
  }
  ${device.TabToLg} {
    flex-direction: column;
    align-items: flex-start;
  }
`;

export const StyledSupportAvailable = styled.div`
  span {
    color: #a3adc2;
    float: left;
    font-size: 14px;
    line-height: 24px;
  }
`;
