import classname from 'clsx';
import { SpaceProps } from '@/shared/styled';
import { StyledInputGroupWrap } from './style';
import React from 'react';

interface IProps extends SpaceProps {
  className?: string;
  children: React.ReactNode;
  dir?: 'append' | 'prepend';
}

const InputGroupAddon = ({
  children,
  dir = 'append',
  className,
  ...rest
}: IProps) => {
  return (
    <StyledInputGroupWrap
      className={classname(className, `input-group-${dir}`)}
      $dir={dir}
      {...rest}
    >
      {children}
    </StyledInputGroupWrap>
  );
};

export default InputGroupAddon;
