import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
  SearchIconSvg,
  FaqDownArrowSvg,
  FaqUpArrowSvg,
  FaqPlaySvg,
  FlagIconSvg,
  AccountEnquiriesSvg,
  FaqDepositIconSvg,
  RacingBetsEnquiriesSvg,
  SportsBetsEnquiriesSvg,
  PunterAssistSvg,
  TrustSafetySvg,
  EgameInquriesSvg,
  RtgGameInquriesSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledPageBanner,
  StyledPageTextView,
  StyledTextArea,
  StyledSearchHolder,
  StyledInputHolder,
  // StyledFaqLeft,
  // StyledFaqRight,
  // StyledAccordian,
  // StyledAccordianHeading,
  // StyledAccordianContent,
} from './style';


const Faqs = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
        <h3>FAQs &amp; Help</h3>
        <StyledContainerPageView>
          <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledPageBanner>
                <img
                  src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/faq-banner-img.png`}
                  alt=''
                />
                <StyledSearchHolder>
                  <h4>How can we help ?</h4>
                  <StyledInputHolder>
                    <input
                      type='text'
                      placeholder='Search for a topic or question...'
                    />
                    <button type='button'>
                      <SearchIconSvg />
                    </button>
                  </StyledInputHolder>
                </StyledSearchHolder>
              </StyledPageBanner>
              <StyledPageTextView>
                <h6>TOPICS</h6>
                <ul>
                  <li>
                    <Link to='/faqs/gettingstarted' className='active'>
                      <div className='iconsvg'>
                      <FlagIconSvg />
                      </div>
                      <h4>Getting Started</h4>
                      <p>Start of on the right foot!<br/>Not the left one!</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to='/faqs/details'>
                      <div className='iconsvg'>
                      <AccountEnquiriesSvg />
                      </div>
                      <h4>Account Enquiries</h4>
                      <p>You’re a special snowflake and so<br/>is your account</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to='/faqs/depositswithdrawals'>
                    <div className='iconsvg'>
                      <FaqDepositIconSvg />
                    </div>
                      <h4>Deposits, Withdrawals & Refund</h4>
                      <p>That feel when you look at your<br/>bank account</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li>
                </ul>
                <ul>
                  <li>
                    <Link to='/faqs/sportsbetsenquiries'>
                      <div className='iconsvg'>
                      <SportsBetsEnquiriesSvg />
                      </div>
                      <h4>Sports Bets Enquiries</h4>
                      <p>You're a special snowflake and so <br/>is your account</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li>
                  <li>
                    <Link to='/faqs/accountassistance'>
                    <div className='iconsvg'>
                      <PunterAssistSvg />
                    </div>
                      <h4>Account Assistance &<br/> Controls</h4>
                      <p>All you can eat self-serve <br/>problem solving</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li>
                  <li>
                   <Link to='/faqs/trustsafety'>
                    <div className='iconsvg'>
                      <TrustSafetySvg />
                    </div>
                      <h4>Trust & Safety</h4>
                      <p>Keep things safe & sound for you <br/> and your buddies</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li>
                </ul>
                <ul>
                  {/* <li>
                    <Link to='/faqs/egamesinquiries'>
                      <div className='iconsvg'>
                        <EgameInquriesSvg />
                      </div>
                      <h4>Evolution Games Inquiries</h4>
                      <p>Keep things safe & sound for you <br/> and your buddies</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li> */}
                  {/* <li>
                    <Link to='/faqs/rtggamesinquiries'>
                      <div className='iconsvg'>
                        <RtgGameInquriesSvg />
                      </div>
                      <h4>Real Time Games Inquiries</h4>
                      <p>Keep things safe & sound for you <br/> and your buddies</p>
                      <div className='arrow'>
                         <ArrowRightSvg />
                      </div>
                    </Link>
                  </li> */}
                  <li>

                  </li>
                  
                </ul>
                
              </StyledPageTextView>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default Faqs;
