import React, { useState, useEffect, useMemo } from 'react';
import {
  StyledMywalletContainerFluid,
  StyledMywalletHeader,
  StyledMywalletTable,
  StyledWalletType,
  StyledMywalletMobile,
  StyledMywalletMobileHead,
  StyledMywalletMobileList,
  StyledWalletSearch,
  StyledWalletDate,
  StyledPagination,
  StyledMywalletTableVied
} from './style';
import AuthService from '@/services/AuthService';
import { useAppSelector } from '@/hooks/useAppSelector';
import moment from 'moment/moment';
import { Link } from 'react-router-dom';
import { InputGroup } from 'react-bootstrap';
import { Input, InputGroupAddon, InputGroupText } from '@/components';
import { SearchIconSvg } from '@/shared/svg';
import Pagination from '../pagination/Pagination';

//let PageSize = 5;

const options = [
  {
    label: "10",
    value: 10,
  },
  {
    label: "20",
    value: 20,
  },
  {
    label: "30",
    value: 30,
  },
  {
    label: "40",
    value: 40,
  },
];

const Bettinghistory = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [perPage, setPerPage] = useState(10);
  const [walletId, setWalletId] = useState('');
  const { userInfo } = useAppSelector(state => state.auth);
  const [bettingHistoryData, setBettingHistoryData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [currentTotalRecords, setCurrentTotalRecords] = useState(0);
  const [walletRecords, setWalletRecords] = useState<Array<IWallet>>([]);

  const getBettingHistoryData = async () => {
    const response = await AuthService.getBettingHistoryData();
    const result = await AuthService.getMyWallets();
    setBettingHistoryData(response.data);
    setWalletRecords(result.data);
  };

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * perPage;
    const lastPageIndex = firstPageIndex + perPage;
    let filtered2 = bettingHistoryData;
    if(walletId!=='')
      filtered2 = bettingHistoryData.filter(
        (item: any) => item.currency === walletId
      );
    setCurrentTotalRecords(filtered2.length);
    return filtered2.slice(firstPageIndex, lastPageIndex);

  }, [bettingHistoryData,currentPage,perPage,walletId]);

  const handleChange = async (e:any) => {
    setPerPage(e.target.value);
  }

  const filterWallet = async (e:any) => {
    let filterVal = '';
    if(e.target.value!='')
    filterVal = (e.target.value == 'REAL' ? 'PHP' : 'PST');
    setWalletId(filterVal);
  };

  useEffect(() => {
    if (userInfo) getBettingHistoryData();
  }, [userInfo]);

  // useEffect(() => {
  //   setFilteredData(currentTableData);
  // }, [bettingHistoryData]);

  return (
    <>
      <StyledMywalletContainerFluid>
        <StyledMywalletHeader>
          <StyledWalletType>
            <h4>Betting History</h4>
            <select className='accounttype' onChange={e => filterWallet(e)}>
              <option value="">All</option>
              {walletRecords.map((item: any, index: number) => {
              return (
                <option value={item.type}>
                  <Link to={'#'} key={index} className='depositbtn'>
                  {item.name} ({item.currency})
                  </Link>
                </option>
                );
              })}

            </select>
          </StyledWalletType>
        </StyledMywalletHeader>
        <StyledMywalletTable>
        <StyledMywalletTableVied>
          <table>
            <thead>
              <tr>
                <th scope='col'>Ticket Id</th>
                <th scope='col'>Time</th>
                <th scope='col'>Partner</th>
                <th scope='col'>Game name</th>
                <th scope='col'>Round Id</th>
                <th scope='col'>Game Category</th>
                <th scope='col'>Bet On</th>
                <th scope='col'>Odds</th>
                <th scope='col' className='textalignright'>
                  Bet Amount
                </th>
                <th scope='col' className='textalignright'>
                  Won Amount
                </th>
                <th scope='col' className='textalignright'>
                  Balance
                </th>
                <th scope='col'>Status</th>
              </tr>
            </thead>
            <tbody>
              {currentTableData.map((item, index) => {
                return (
                  <>
                    <tr key={index}>
                      <td ><p className='webticketid'>{(item.ticketId || '').substring(0, 12)}</p>
                       <div className='wallwttime'>
                          <div className='bettime'>
                            <p className='idtext'>{item.ticketId}</p>
                            <p className='timetext'>{moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}</p>
                          </div>
                          <div className='bettypelist'>
                            <p className='bettype'>{item.type}</p>
                          </div>
                       </div>
                      </td>
                      <td data-label='Time' className='timemobile'>
                        {moment(item.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                      </td>
                      <td ><p className='webticketid'>{item.providerName}</p>
                      <div className='balancetext'><p className='textname'>Partner</p><p>{item.providerName}</p></div>
                      </td>
                      <td ><p className='webticketid'>{item.gameName}</p>
                        <div className='incomtext'><p className='textname'>Game name</p><p className=''>{item.gameName}</p></div>
                      </td>
                      <td>
                        <p className='webticketid'>{(item.roundId || item.venueId || '').substring(0, 12)}</p>
                        <div className='incomtext'><p className='textname'>Round Id</p>
                        <p className=''>{item.roundId || item.venueId}</p></div>
                      </td>
                      <td>
                        <p className='webticketid'>{item.gameCategory}</p>
                        <div className='expensestext'>
                          <p className='textname'>Game category</p>
                          <p>{item.gameCategory}</p>
                        </div>
                      </td>
                      <td ><p className='webticketid'>{item.betOn}</p>
                        <div className='balanceaftertext'><p className='textname'>Bet On</p><p>{item.betOn}</p></div>
                      </td>
                      <td ><p className='webticketid'>-</p>
                        <div className='expensestext'><p className='textname'>Odds</p><p>{item.expenses}</p></div>
                      </td>
                      <td  className='textalignright'>
                      <p className='webticketid'>{item.amount}</p>
                        <div className='remarktext'><p className='textname'>Bet Amount</p><p>{item.amount}</p></div>
                      </td>
                      <td
                        className='textalignright incomeamount'
                      >
                       <p className='webticketid'> {item.wonAmount > 0 && '+ '}
                        {item.wonAmount}</p>
                        <div className='expensestext'><p className='textname'>Won Amount</p>
                          <p className='incomecolor'>{item.wonAmount > 0 && '+ '}
                            {item.wonAmount}
                          </p>
                        </div>
                      </td>
                      <td  className='textalignright'>
                        <p className='webticketid'>{item.balance}</p>
                      </td>
                      <td ><p className='webticketid'>{item.status}</p>
                        <div className='incomtext'><p className='textname'>Status</p><p className=''>{item.status}</p></div>
                      </td>
                    </tr>
                  </>
                );
              })}
              <tr
                className={`${
                  currentTableData.length === 0 ? 'd-full' : 'd-none'
                }`}
              >
                <td colSpan={12} className='text-danger text-center'>
                  No Data Found
                </td>
              </tr>
            </tbody>
          </table>
          </StyledMywalletTableVied>
          <StyledPagination>
          <div className='itempage'>
            <p>item per page </p>
            <select value={perPage} onChange={handleChange}>
              {options.map((option) => (
                <option value={option.value}>{option.label}</option>
              ))}
            </select>
          </div>
          <Pagination
            className='pagination-bar'
            currentPage={currentPage}
            totalCount={currentTotalRecords}
            pageSize={perPage}
            onPageChange={(page: number) => setCurrentPage(page)}
          />
          </StyledPagination>
        </StyledMywalletTable>
      </StyledMywalletContainerFluid>
    </>
  );
};

export default Bettinghistory;
