import { createSlice } from '@reduxjs/toolkit';

const initialState: IForgotOTP = {
  forgotFormData: undefined,
};

const forgotOTPSlice = createSlice({
  name: 'regotp',
  initialState,
  reducers: {
    resetForgot: state => {
      Object.assign(state, initialState);
    },
    updateForgotData: (state, action) => {
      state.forgotFormData = { ...state.forgotFormData, ...action.payload };
    },
  },
});
export const { resetForgot, updateForgotData } = forgotOTPSlice.actions;
export const forgotOTPReducer = forgotOTPSlice.reducer;
