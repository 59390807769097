import React from 'react';
import { StyledHeaderWrapper } from '@/layout/header/style';
import GameMenuHeader from '@/layout/header/gamemenu';

const GameHeader = () => {
  return (
    <>
      <StyledHeaderWrapper>
        {/* <GameMenuHeader /> */}
      </StyledHeaderWrapper>
    </>
  );
};
export default GameHeader;
