import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { SecuritySvg, HeadPhoneSvg, ArrowRightSvg, UnsubscribeIconSvg, UnsubscribeReasonIconSvg, SmileIconSvg, UnsubscribedIconSvg } from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledUnsubscribe,
  StyledUnsubHead,
  StyledUnsubBody,
  StyledAccountButton,
} from './style';
import { Label } from '@/components';

const Unsubscribe = () => {
  return (
    <>
      <StyledContainerFluid>
        <StyledContainerPageView>
          <StyledUnsubscribe>
            <StyledUnsubHead>
              <Link to='/'>
                <strong className='desk_logo'>
                  <img
                    width={'160px'}
                    src={process.env.REACT_APP_THEME_LOGO}
                    alt=''
                  />
                </strong>
              </Link>
            </StyledUnsubHead>
            <StyledUnsubBody>
              <UnsubscribeIconSvg/>
             <h4>Unsubscribe ?</h4>
             <p>Are you sure, you’d like to stop receiving email from {process.env.REACT_APP_SITE_TITLE}?</p>
             <StyledAccountButton className='buttonmobile'>
                  <button type='button' className='deactiv_profile'>
                    Unsubscribe
                  </button>
                  <button
                    type='button'
                    className='signout_profile'
                  >
                    Cancel
                  </button>
             </StyledAccountButton>
            </StyledUnsubBody>

            <StyledUnsubBody>
              <UnsubscribeReasonIconSvg/>
             <h4>Unsubscribe from our mailing list</h4>
             <p>Our gaming entertainment just won’t be the same anymore <SmileIconSvg/> <br/>
             We are sad to see you go, but we also would like to respect your decision.<br/>
             Do tell us why your interest snapped, so that we can try and set it right.</p>
             <StyledAccountButton className='buttonmobile reasonview'>
              <span>
                Reason
              </span>
              <select>
                <option>Please select reason</option>
                <option>Content is irrelevant</option>
                <option>Too many emails from {process.env.REACT_APP_SITE_TITLE}</option>
                <option>Never signed up {process.env.REACT_APP_SITE_TITLE}</option>
                <option>Interested earlier but not too inclined now</option>
              </select>
              <button type='button' className='deactiv_profile'>
                Unsubscribe
              </button>
             </StyledAccountButton>
            </StyledUnsubBody>

            <StyledUnsubBody>
              <UnsubscribedIconSvg/>
             <h4>You have been unsubscribed!</h4>
             <p>Your email address j.s******com has been removed from our mailing list.<br/>
               You won’t receive any more email from us.</p>
             <p className='resubscribe'>If you change your mind,<br/>
please go to your {process.env.REACT_APP_SITE_TITLE} user profile and re-subscribe!</p>
            </StyledUnsubBody>
          </StyledUnsubscribe>

        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default Unsubscribe;
