import React, { useState, useEffect } from 'react';
import RunnerHeader from './runnerheader';
import Runner from './runner';
import RunnerFixed from './runnerfixed';
import RunnerFooter from './runnerfooter';
import {
  StyledContainerRight,
  StyledContainerRightBody,
  StyledContainerToteHead,
  StyledButtonFixedToteButton,
  StyledResultHeader,
  StyledContainerRightview,
} from '@/pages/betting/style';
import {
  setPositionsForSelectedPool,
  setPoolName,
  setLegsForSelectedPool,
} from '@/redux/slices/racecard';
import RacingService from '@/services/RacingService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import RaceCardResults from '@/components/RaceCardResults';

const RunnerInfo = ({
  raceStatus,
  programId,
  venueCode,
  raceNumber,
  betType,
  runners,
  venueName,
  poolsActive,
  poolResults,
  isSuspended,
}: RaceInfoProps & { raceNumber: number, betType: string }) => {
  const dispatch = useAppDispatch();

  const [selectedPool, setSelectedPool] = useState('win');
  const [selectedVenue, setSelectedVenue] = useState('');
  const [selectedRunnersList, setSelectedRunnersList] = useState([]);
  const [selectedLeg, setSelectedLeg] = useState(1);
  const [positions, setPositions] = useState(1);
  const [legs, setLegs] = useState(0);
  const [betAmount, setBetAmount] = useState(0);
  const [unitBet, setUnitBet] = useState(0);
  const [boxOption, setBoxOption] = useState(false);
  const [oddsValuePaid, setOddsValuePaid] = useState(0);

  const changeSelectedPool = (poolName: string) => {
    const poolObject: any = RacingService.getPoolData(poolName);
    
    setLegs(poolObject.legs);
    setPositions(poolObject.bettingPos);
    dispatch(setPoolName(poolName));
    dispatch(setLegsForSelectedPool(legs));
    dispatch(setPositionsForSelectedPool(poolObject.bettingPos));
    setSelectedVenue(venueName);

    if (legs > 1) setSelectedLeg(1);
    const pool = poolsActive.filter(item => {
      return item.code.toLowerCase() === poolName.toLowerCase();
    });
    if (pool.length > 0) setUnitBet(parseInt(pool[0].unitBet));
    setSelectedPool(poolName);
    initRunnersList(poolObject.legs, poolObject.bettingPos);
    setBoxOption(false);
  };

  useEffect(() => {
    changeSelectedPool('win');
  }, []);

  const initRunnersList = (legs: number, positions: number) => {
    let arrayCount = 1;
    if (positions === 1) {
      arrayCount = legs > 0 ? legs : positions;
    } else {
      arrayCount = positions;
    }
    const arr = [];
    for (let i = 0; i < arrayCount; i++) {
      arr[i] = [];
    }
    setSelectedRunnersList(arr);
  };

  const selectedRunners = (position: any, runner: any) => {
    let positionRunner = position;
    if (legs > 1) positionRunner = selectedLeg - 1;
    const tempRunnersList = [...selectedRunnersList];
    if (!tempRunnersList[positionRunner]) {
      tempRunnersList[positionRunner] = [];
    }

    if (boxOption) {
      for (let i = 0; i < positions; i++) {
        const pos = tempRunnersList[i].indexOf(runner);
        if (pos >= 0) tempRunnersList[i].splice(pos, 1);
        else tempRunnersList[i].push(runner);
      }
    } else {
      const pos = tempRunnersList[positionRunner].indexOf(runner);
      if (pos >= 0) tempRunnersList[positionRunner].splice(pos, 1);
      else tempRunnersList[positionRunner].push(runner);
    }
    setSelectedRunnersList(tempRunnersList);
  };

  const selectedFixedRunners = (poolName:string, runner: any, oddsValuePaid: any)=>{
    setOddsValuePaid(oddsValuePaid);
    poolName = poolName.toLowerCase();
    changeSelectedPool(poolName);
    const tempRunnersList:any = [];
    if (!tempRunnersList[0]) {
      tempRunnersList[0] = [];
    }
    tempRunnersList[0][0] = runner;
    if((selectedPool === poolName) && (selectedRunnersList.length>0 && selectedRunnersList[0].includes(runner))){
      tempRunnersList[0] = [];
    }
    
    setSelectedRunnersList(tempRunnersList);
  }

  const onClickAll = (position: number, checked: boolean) => {
    let positionRunner = position;
    if (legs > 1) positionRunner = selectedLeg;
    const tempRunnersList = [...selectedRunnersList];
    const arr: number[] = [];
    if (checked) {
      runners.forEach(item => {
        if (item.scratched !== 'Y') arr.push(item.position);
      });
    }
    if (boxOption) {
      for (let i = 0; i < positions; i++) {
        tempRunnersList[i] = arr;
      }
    } else {
      tempRunnersList[positionRunner] = arr;
    }
    setSelectedRunnersList(tempRunnersList);
  };

  const onClickBox = (checked: boolean) => {
    setBoxOption(checked);
    initRunnersList(legs, positions);
  };

  const changeSelectedLeg = (leg: number) => {
    setSelectedLeg(leg);
  };

  const resetTicket = () => {
    changeSelectedPool(selectedPool);
  };

  const betCalculateAmount = (val:any) => {
    setBetAmount(val);
  };

  useEffect(()=>{
    if(raceNumber){
      changeSelectedPool('win');
    }
  },[raceNumber])

  useEffect(()=>{
    if(betType==='tote'){
      changeSelectedPool('win');
    }else{
      changeSelectedPool('fowin');
    }
  },[betType])



  return (
    <StyledContainerRight>
      {(raceStatus === 'BNS' || raceStatus === 'BSP') && (
        <>
          <StyledContainerToteHead>
            <p className='bet_status_text'>
              {raceStatus === 'BNS'
                ? 'Bets not started yet'
                : 'Stop Bet issued. Race results are yet to be published.'}
            </p>
          </StyledContainerToteHead>
        </>
      )}
      {raceStatus === 'BST' && (
        <RunnerHeader
          poolsActive={...poolsActive}
          onClickAll={onClickAll}
          positions={positions}
          selectedLeg={selectedLeg}
          boxOption={boxOption}
          legs={legs}
          betType={betType}
          onClickBox={onClickBox}
          selectedRaceNumber={raceNumber}
          selectedPool={selectedPool}
          changeSelectedPool={changeSelectedPool}
          changeSelectedLeg={changeSelectedLeg}
        />
      )}
     
      <StyledContainerRightBody>
        {((raceStatus === 'ALC' || raceStatus === 'DRL') && (poolResults!==undefined && poolResults.length>0)) && (
          <><StyledResultHeader>
            <span className='runners'>RUNNERS</span>
            <span className='win'>WIN</span>
            <span className='place'>PLACE</span>
          </StyledResultHeader>
          <div></div>
          <RaceCardResults
              poolResults={...poolResults}
              runners={runners}
              raceStatus={raceStatus} />
          </>
        )}
        <StyledContainerRightview className={`${betAmount > 0 ? 'betvalid' : ''}`}>
        {betType ==='tote' && runners.map((runner: any) => (
          <Runner
            raceStatus={raceStatus}
            name={runner.name}
            betType={betType}
            selectedPoolCode={selectedPool}
            runner={runner}
            key={runner.runnerId}
            selectedLeg={selectedLeg}
            boxOption={boxOption}
            positions={positions}
            legs={legs}
            selectedRunnersList={selectedRunnersList}
            selectedRunners={selectedRunners}
          />
        ))}
        {betType ==='fixed' && runners.map((runner: any) => (
          <RunnerFixed
            raceStatus={raceStatus}
            name={runner.name}
            betType={betType}
            isSuspended={isSuspended}
            selectedPoolCode={selectedPool}
            runner={runner}
            key={runner.runnerId}
            selectedLeg={selectedLeg}
            boxOption={boxOption}
            positions={positions}
            legs={legs}
            selectedRunnersList={selectedRunnersList}
            selectedRunners={selectedFixedRunners}
          />
        ))}
        </StyledContainerRightview>
      </StyledContainerRightBody>

      <RunnerFooter
        programId={programId}
        venueCode={venueCode}
        poolName={selectedPool}
        venueName={selectedVenue}
        unitBet={unitBet}
        setUnitBetFn={setUnitBet}
        raceNumber={raceNumber}
        resetTicket={resetTicket}
        betCalculateAmount={betCalculateAmount}
        legs={legs}
        oddsValuePaid={oddsValuePaid}
        selectedRunnersList={selectedRunnersList}
      />
    </StyledContainerRight>
  );
};

export default RunnerInfo;
