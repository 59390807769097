import React, { useEffect, useState } from 'react';
import { Input, Label } from '@/components';
import {
  StyledPlaceBetView,
  StyledOverLay,
  StyledBetContaiiner,
  StyledBetSlipHead,
  StyledBetSlipButton,
  StyledBetSlipbtn,
  StyledButtonAmount,
  StyledCustomAmount,
} from './style';
import { CloseIconSvg,KycVerifiedIconSvg } from '@/shared/svg/index';
import { useForm } from 'react-hook-form';

interface ChildProps {
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  parentCloseHandlerFunction: Function;
}

const BetSuccess = (props: ChildProps) => {
  const [amount, setAmount] = useState(10);
  const [isError, setIsError] = useState(false);
  const { register, setValue } = useForm();

  
  return (
    <StyledPlaceBetView>
      <StyledOverLay></StyledOverLay>
      <StyledBetContaiiner>
        <button
          className='closebtn'
          type='button'
          onClick={() => props.parentCloseHandlerFunction()}
          data-dismiss='modal'
          aria-label='Close'
        >
          <CloseIconSvg />
        </button>
        <StyledBetSlipHead>
        <KycVerifiedIconSvg />
        <h4>Bet placed successfully</h4> 
        </StyledBetSlipHead>
      </StyledBetContaiiner>
    </StyledPlaceBetView>
  );
};

export default BetSuccess;
