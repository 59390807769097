import React, { useEffect } from 'react';
import {
  StyledOtherContries,
  StyledOtherContriesContainer,
  StyledOtherLogo,
  StyledAttention,
  StyledOtherContact,
} from './home/style';
import { AttentionIconSvg, ContactMailIconSvg } from '@/shared/svg';
import { Link } from 'react-router-dom';
import { changeModule } from '@/redux/slices/module';
import { useAppDispatch } from '@/hooks/useAppDispatch';
const UnAuthorized = () => {
  const dispatch = useAppDispatch();
  const showLogin = () => {
      dispatch(changeModule('login'));
  }
  useEffect(()=>{
    showLogin();
  },[])
  return (
    <>
      <StyledOtherContries>
        <StyledOtherContriesContainer>
          <StyledOtherLogo>
            <Link to='/'>
              <img
                src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/other_logo.svg`}
                alt=''
              />
            </Link>
          </StyledOtherLogo>
          <StyledAttention>
            <Link to={'/'} onClick={()=> showLogin() } style={{ textAlign: 'center' }}>
              <AttentionIconSvg />
              <h4>Please login</h4>
              <p>
                to access this page.
              </p>
            </Link>
          </StyledAttention>
          <StyledOtherContact>
            <ContactMailIconSvg />
            <Link to={'/'} onClick={()=> showLogin() }>
              <p>LOGIN</p>
            </Link>
          </StyledOtherContact>
        </StyledOtherContriesContainer>
      </StyledOtherContries>
    </>
  );
};

export default UnAuthorized;
