import { FormGroup, Button } from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useState } from 'react';
import Row from 'react-bootstrap/Row';
import { StyledWrap, StyledButtonContainer, StyledSteps } from './style';

interface ChildProps {
  parentModalHandlerFunction: any;
  message: string;
  closePopup(arg0: string): void;
}

const KYCProgress = (props: ChildProps) => {
  const [loading, setLoading] = useState(false);

  const {
    handleSubmit,
    formState: { errors },
  } = useForm<AccountCForm>();

  const onSubmit: SubmitHandler<AccountCForm> = data => {
    console.log(loading, data, errors);
    setLoading(true);
  };

  return (
    <StyledWrap>
      <form action='#' onSubmit={handleSubmit(onSubmit)} noValidate>
        <StyledSteps>
          <img
            src={process.env.REACT_APP_THEME_LOGO_MOBILE}
            alt=''
          />
          <h4>KYC Verification in Progress!</h4>
        </StyledSteps>
        <Row mb='20px'>
          <FormGroup className='loginform'>
            <div className='termsandcondition'>
              <h5>{props.message}</h5>
              {props.message === '' && (<h5>
                Thank you for submitting. KYC verification typically takes 24-48
                hours, we&apos;ll notify you once it&apos;s complete.
              </h5>)}
              {props.message !== '' && (<h5>
               Note: KYC verification typically takes 24-48
                hours, we&apos;ll notify you once it&apos;s complete.
              </h5>)}
            </div>
          </FormGroup>
        </Row>
        <Row className='nomargin'>
          <StyledButtonContainer>
            <Button
              type='button'
              color='brand2'
              className='signupbtn'
              onClick={() => props.closePopup('close')}
            >
              Continue Gaming
            </Button>
          </StyledButtonContainer>
        </Row>
      </form>
    </StyledWrap>
  );
};

export default KYCProgress;
