import React from 'react';
// @mui
import Card from '@mui/material/Card';
import { styled } from '@mui/material/styles';

// TODO move this to MUI-Theme
const themeColor = process.env.REACT_APP_THEME === 'fairplay' ? '#2F594D' : '#384766';
const themeColorHover = process.env.REACT_APP_THEME === 'fairplay' ? '#FDE246' : '#dc6426';

const BorderCard = styled(Card)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  overflow: 'hidden',
  backgroundColor: '#121212',
  borderRadius: '10px',
  border: `1px solid ${themeColor}`,
  transition: 'all 150ms linear',
  '.MuiCardMedia-root': {
    transition: 'all 190ms linear',
  },
  '&:hover': {
    borderColor: themeColorHover,
    boxShadow: `0 1px 10px ${themeColorHover}9d`,
    '.MuiCardMedia-root': {
      transform: 'scale3d(1.03, 1.03, 1)',
    }
  },
}));

export default BorderCard;
