import React, { useState } from 'react';
import { Link } from 'react-router-dom';
// @mui
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import CardActionArea from '@mui/material/CardActionArea';
// Components
import BorderCard from './BorderCard';
import GameCard from './GameCard';
import GameProviderHeader from './GameProviderHeader';
// Models
import GameModel from '../@types/models/GameModel';
// SVG
import {
  EGamesViewSvg,
  ArrowRightSvg,
  WEGamesSvg,
  WEGamesViewSvg,
  RealTimeGamViewsSvg,
} from '@/shared/svg';

type Props = {
  games: null | Array<GameModel>,
  providerId: string,
  displayViewAllButton?: boolean,
  displaySearch?: boolean,
  size?: 'medium' | 'small',
  onClick?: (gameId: string) => void,
};

const GameList = ({games, providerId, displayViewAllButton, displaySearch, size, onClick}: Props) => {
  const [gameNameFilter, setGameNameFilter] = useState<string>('');
  const imgSize = size === 'small' ? 100 : 160;

  let viewAllIcon: null | React.ReactElement = null;
  switch (providerId) {
    case 'we':
      viewAllIcon = <WEGamesViewSvg />;
      break;
    case 'evo':
      viewAllIcon = <EGamesViewSvg />;
      break;
    case 'rtg':
      viewAllIcon = <RealTimeGamViewsSvg />;
      break;
  }
  const filteredGamesList = games.filter(
    (gameModel: GameModel) =>
      gameNameFilter.length ? gameModel.name?.toLowerCase().indexOf(gameNameFilter) != -1 : true
  );
  return (
    <Box key={providerId} mt={4}>
      <GameProviderHeader
        providerId={providerId}
        displaySearch={displaySearch}
        setSearchFilter={(gameName: string) => setGameNameFilter(gameName)}
        availableSearchElements={filteredGamesList?.length}
      />
      {
        (filteredGamesList && filteredGamesList.length) && (
          <Stack
            key={gameNameFilter}
            direction="row"
            flexWrap="wrap"
            spacing={0}
            sx={{ gap: size==='small' ? 1 : 3 }}
          >
            {filteredGamesList.map((gameModel: GameModel, index: number) => (
              <div key={`${gameModel.id}-${index}`} style={{ width: imgSize+2 }}>
                <GameCard gameModel={gameModel} size={imgSize} onClick={onClick}/>
              </div>
            ))}
            {displayViewAllButton &&
              <BorderCard style={{ width: imgSize+2 }}>
                <CardActionArea
                  component={Link}
                  to={`games/${providerId}`}
                  style={{
                    height: '100%',
                    width: '100%',
                    color: '#dc6426',
                    display: 'flex',
                    padding: '32px 8px',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {viewAllIcon}
                  <Box sx={{ p: 1 }}>
                    View all
                    <ArrowRightSvg />
                  </Box>
                </CardActionArea>
              </BorderCard>
            }
          </Stack>
        )
      }
    </Box>
  );
};

export default GameList;
