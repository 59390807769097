import * as React from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from 'react-router-dom';

import Loading from './components/Loading';

import Layout from './layout';
import AuthService from './services/AuthService';
const Home = React.lazy(() => import('./pages/home/home'));
const GamesPage = React.lazy(() => import('./pages/GamesPage'));
const Betting = React.lazy(() => import('./pages/betting'));
const Verifyotp = React.lazy(() => import('./pages/verifyotp/verifyotp'));
const Terms = React.lazy(() => import('./pages/terms/terms'));
const Aboutus = React.lazy(() => import('./pages/aboutus/aboutus'));
const Contactus = React.lazy(() => import('./pages/contact/contactus'));
const Mywallet = React.lazy(() => import('./pages/mywallet/mywallet'));
const MyAccount = React.lazy(() => import('./pages/myaccount/myaccount'));
const Myactivity = React.lazy(() => import('./pages/myactivity/myactivity'));
const PaymentReturn = React.lazy(() => import('./pages/payment/return'));
const PaymentCancel = React.lazy(() => import('./pages/payment/cancel'));
const Bettinghistory = React.lazy(
  () => import('./pages/bettinghistory/bettinghistory')
);
const GamePage = React.lazy(() => import('./pages/GamePage'));
const Racing = React.lazy(() => import('./pages/racing'));
const Responsiblegaming = React.lazy(
  () => import('./pages/responsible/responsible')
);
const Privacypolicy = React.lazy(
  () => import('./pages/privacypolicy/privacypolicy')
);
const ErrorNotFound = React.lazy(() => import('./pages/error-404'));
const AccessDenied = React.lazy(() => import('./pages/error-403'));

import { useAppDispatch } from '@/hooks/useAppDispatch';
import {
  setIsAuthenticatedData,
  setUserData,
  setIsKYCStatus,
  setKYCRejectedRemarks,
  setKYCRejectedReason,
  setIsKYCPopup,
} from '@/redux/slices/login';
import CookieStorageService from './services/CookieStorageService';
import { PrivateRoute } from './privateRoute';
import Sockets from './services/SocketService';
import { updateModelAttributes } from './services/SocketModelUpdate';
import Betrules from './pages/betrules/betrules';
import Faqs from './pages/faqs/faqs';
import FaqsDetails from './pages/faqsdetails/faqsdetails';
import FaqGettingStarted from './pages/faqgettingstarted/faqgettingstarted';
import FaqDepositsWithdrawals from './pages/faqdepositswithdrawals/faqdepositswithdrawals';
import FaqSportsbetsEnquiries from './pages/faqsportsbetsenquiries/faqsportsbetsenquiries';
import FaqAccountAssistance from './pages/faqaccountassistance/faqaccountassistance';
import FaqTrustSafety from './pages/faqtrustsafety/faqtrustsafety';
import FaqEgamesInquiries from './pages/faqegamesinquiries/faqegamesinquiries';
import FaqRtggamesInquiries from './pages/faqrtggamesinquiries/faqrtggamesinquiries';
import { element } from 'prop-types';
import TripleCrownView from './pages/triplecrownview/triplecrownview';

import UnAuthorized from './pages/access-denied';
import Unsubscribe from './pages/unsubscribe/unsubscribe';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  //const { userInfo } = useAppSelector(state => state.auth);
  const pageBlockByIP = async () => {
    const geoData = await AuthService.getGeoData();
    const allowedCountries = ['IN', 'AE', 'PH'];
    const allowedIps = ['87.246.163.177'];
    if (
      !(
        allowedCountries.includes(geoData.data.country) ||
        allowedIps.includes(geoData.data.ip)
      )
    ) {
      if (!window.location.pathname.includes('accessdenied')) {
        window.location.replace('/accessdenied');
      }
    }
    getUserInfo();
  };
  const getUserInfo = async () => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token != undefined) {
      const userData = await AuthService.getUserData();
      if (userData.status) {
        Sockets.setSocketForUser(userData.data.id, updateModelAttributes);
        dispatch(setUserData(userData.data));
        dispatch(setIsAuthenticatedData(true));
        dispatch(setIsKYCStatus(userData.data.kycVerified));
        dispatch(setIsKYCPopup(userData.data.kycPopupClose));
        if (
          userData.data.kycVerified !== undefined &&
          userData.data.kycVerified === 'DECLINED'
        ) {
          dispatch(setKYCRejectedRemarks(userData.data.kycDeclinedRemarks));
          dispatch(setKYCRejectedReason(userData.data.kycDeclinedReason));
        }
      }
    }
  };

  React.useEffect(() => {
    pageBlockByIP();
  }, []);
  return (
    <>
      <Router>
        <React.Suspense fallback={<Loading />}>
          <Routes>
            <Route element={
              <Layout>
                <Outlet />
              </Layout>
            }>
              <Route path='/' element={<Home />} />
              {/* Footer Links */}
              <Route path='/terms' element={<Terms />} />
              <Route path='/betrules' element={<Betrules />} />
              <Route path='/responsiblegaming' element={<Responsiblegaming />} />
              <Route path='/privacypolicy' element={<Privacypolicy />} />
              <Route path='/aboutus' element={<Aboutus />} />
              <Route path='/contactus' element={<Contactus />}/>
              <Route path='/faqs' element={<Faqs />} />
              {/* Faq Links */}
              <Route path='/faqs/details' element={<FaqsDetails />} />
              <Route path='/faqs/gettingstarted' element={<FaqGettingStarted />}/>
              <Route path='/faqs/depositswithdrawals' element={<FaqDepositsWithdrawals />}/>
              <Route path='/faqs/sportsbetsenquiries' element={<FaqSportsbetsEnquiries />}/>
              <Route path='/faqs/accountassistance' element={<FaqAccountAssistance />} />
              <Route path='/faqs/trustsafety' element={<FaqTrustSafety />} />
              <Route path='/faqs/egamesinquiries' element={<FaqEgamesInquiries />} />
              <Route path='/faqs/rtggamesinquiries' element={<FaqRtggamesInquiries />} />
              {/* Triple Crown  */}
              <Route path='/triplecrownview' element={<TripleCrownView />} />
              {/* Payments */}
              <Route
                path='/payment/return/:id'
                element={<PaymentReturn />}
              />
              <Route
                path='/payment/cancel/:id'
                element={<PrivateRoute component={PaymentCancel} />}
              />
              {/* Main pages */}
              <Route path='/myactivity' element={<PrivateRoute component={Myactivity} />} />
              <Route path='/bettinghistory' element={<PrivateRoute component={Bettinghistory} />}/>
              <Route path='/mywallet' element={<PrivateRoute component={Mywallet} />}/>
              <Route path='/myaccount' element={<PrivateRoute component={MyAccount} />} />
              {/* Email verification  */}
              <Route path='/myaccount/email/verify' element={<Verifyotp />}/>
              {/* Games */}
              <Route path='/games/:module' element={<GamesPage />}/>
            </Route>
            {/* No footer */}
            <Route element={
              <Layout hideFooter>
                <Outlet />
              </Layout>
            }>
              <Route
                path='/game/:providerId/:id'
                element={<PrivateRoute component={GamePage} />}
              />
              <Route
                path='/accessdenied'
                element={<AccessDenied />}
              />
              <Route
                path='/horseracingtote'
                element={<PrivateRoute component={Racing} />}
              />
              <Route
                path='/horseracingfixed'
                element={<PrivateRoute component={Racing} />}
              />
              <Route
                path='/racing'
                element={<PrivateRoute component={Betting} />}
              />
              <Route
                path='/racing/:id'
                element={<PrivateRoute component={Betting} />}
              />
               <Route
                path='/racing/:rId/:rNumber'
                element={<PrivateRoute component={Betting} />}
              />
            </Route>
            {/* No header, no footer */}
            <Route
              element={
                <Layout hideHeader hideFooter>
                  <Outlet />
                </Layout>
              }
            >
              <Route path='/unsubscribe' element={<Unsubscribe />} />
              <Route path='/unauthorized' element={<UnAuthorized />} />
              <Route path='/error-404' element={<ErrorNotFound />} />
            </Route>
          </Routes>
        </React.Suspense>
      </Router>
    </>
  );
};

export default App;
