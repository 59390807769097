/* eslint-disable @typescript-eslint/no-explicit-any */
import styled, { SpaceProps, BorderProps } from '@/shared/styled';
import {
  IFeedback,
  TCustomStyle,
} from '@/components/forms/form-elements/types';
import {
  InputStyles,
  SuccessInputStyles,
  WarningInputStyles,
  ErrorInputStyles,
} from '@/components/forms/form-elements/style';

interface IInput extends IFeedback, SpaceProps, BorderProps {
  $width?: string | any[];
  $height?: string | any[];
  $customStyle?: TCustomStyle;
}

export const StyledInput = styled('input')<IInput>`
  ${InputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'success' &&
    $showState &&
    !$showErrorOnly &&
    SuccessInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'warning' &&
    $showState &&
    !$showErrorOnly &&
    WarningInputStyles};
  ${({ $state, $showState, $showErrorOnly }) =>
    $state === 'error' && $showState && $showErrorOnly && ErrorInputStyles};
`;
