import {
  FormGroup,
  Label,
  Input,
  Button,
  ButtonSpinner,
  InputGroup,
} from '@/components';
import { useForm, SubmitHandler } from 'react-hook-form';
import React, { useEffect, useState } from 'react';
import Col from 'react-bootstrap/Col';
import { useAppSelector } from '@/hooks/useAppSelector';
import { hasKey } from '@/shared/methods';
import AuthService from '@/services/AuthService';

import {
  StyledWrap,
  StyledTitle,
  StyledDesc,
  StyledAlertMessage,
  StyledButtonContainer,
  StyledAlertMessageSucess,
  StyledPhoneText,
  StyledErrorMessage,
  StyledWrapError,
  StyledWrapSucess,
  StyledSucessMessage,
} from './style';
import {
  AlertWarningiconSvg,
  KycVerifiedIconSvg,
  TickCircleSvg,
} from '@/shared/svg';
import CookieStorageService from '@/services/CookieStorageService';

interface ChildProps {
  parentModalHandlerFunction: any;
}

const DepositForm = (props: ChildProps) => {
  console.log(props);
  //const dispatch = useAppDispatch();
  const [message, setMessage] = useState('');
  const [messageDetails, setMessageDetails] = useState('');
  const [messagestatus, setMessageStatus] = useState('');
  const [isNotValid, setIsNotValid] = useState(false);
  const [loading, setLoading] = useState(false);
  const [displayDiv, setDisplayDive] = useState('part1');
  const [withDrawObj, setWithDrawObj] = useState(false);

  //const { userInfo } = useAppSelector(state => state.auth);
  const { selectedUserWallet } = useAppSelector(state => state.wallet);

  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<IDepositForm>();

  const getUserInfo = async () => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token != undefined) {
      const userData = await AuthService.getUserData();
      if(!userData.data.withdrawDetails?.gcash && selectedUserWallet.type != 'DEMO') {
        setWithDrawObj(true);
      }
    }
  };


  const onGcashSubmit: SubmitHandler<IDepositForm> = async data => {
    const res = {withdrawDetails:{gcash:{mobile:'0'+parseInt(data.phoneNumber),status:'verified'}}}
    const response = await AuthService.updateUserProfile(res);
    if(response.status){
      setWithDrawObj(false);
      setValue('phoneNumber', '');
    }
  }

  const onSubmit: SubmitHandler<IDepositForm> = async data => {
    setLoading(true);
    const response = await AuthService.withdraw(data, selectedUserWallet.id);
    console.log(response,"WODTHDRAW RESPONSE");
    if (response.data.message) {
      if(response.data.message.toLowerCase() === 'success'){
        setMessageStatus('success');
        setMessage('Your transaction has successfully completed.');
      } else if(response.data.message.toLowerCase() === 'fail'){
        setMessageStatus('error');
        setMessage(`Your transaction has failed.`);
        setMessageDetails(response.data.details);
      } else {
        setMessageStatus('error');
        setMessage('Your transaction has been in process.');
      }
      setLoading(false);
      setIsNotValid(true);

    } else {
      setMessageStatus('error');
      setLoading(false);
      setIsNotValid(true);
      setMessage(response.data.error);
    }
    setDisplayDive('part2');
  }

  useEffect(() => {
    getUserInfo();
  }, []);

  return (
    <StyledWrap>
      {(displayDiv === 'part1' && !withDrawObj && selectedUserWallet.type != 'DEMO') ? (
        <><StyledTitle>Withdraw</StyledTitle><StyledDesc>Please enter the amount to withdraw to your Wallet Account.</StyledDesc><form
          className='loginform'
          action='#'
          onSubmit={handleSubmit(onSubmit)}
          noValidate
        >
          <div>
            <Col md={12} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='email'>
                Amount
              </Label>
              <FormGroup>
              <StyledPhoneText>&#8369;</StyledPhoneText>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    id='amount'
                    name='amount'
                    placeholder='Enter Amount'
                    feedbackText={errors?.amount?.message}
                    state={hasKey(errors, 'amount') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'amount')}
                    {...register('amount', { required: 'Amount is required' })}
                  />1
                </InputGroup>
              </FormGroup>
            </Col>
          </div>
          <StyledButtonContainer>
            <Button type='submit' color='brand2' className='signin_btn'>
              {loading ? <ButtonSpinner /> : null}Continue
            </Button>
          </StyledButtonContainer>
        </form></>
      ) : (messagestatus === 'success' && !withDrawObj && selectedUserWallet.type != 'DEMO') ? (
        <StyledWrapSucess>
          <KycVerifiedIconSvg />
          <StyledSucessMessage>{message}</StyledSucessMessage>
        </StyledWrapSucess>
      ) : (messagestatus === 'error' && !withDrawObj && selectedUserWallet.type != 'DEMO') ?  (
        <StyledWrapError>
          <AlertWarningiconSvg />
           <StyledErrorMessage>{message}</StyledErrorMessage>
           {messageDetails && <StyledErrorMessage><span>{messageDetails}</span></StyledErrorMessage> }
        </StyledWrapError>
      ): (selectedUserWallet.type == 'DEMO') ?  (
        <StyledWrapError>
          <AlertWarningiconSvg />
           <StyledErrorMessage>For money withdrawals, kindly switch to a real account.</StyledErrorMessage>
           {messageDetails && <StyledErrorMessage><span>{messageDetails}</span></StyledErrorMessage> }
        </StyledWrapError>
      ) :
      <><StyledTitle>Gcash Mobile Verification</StyledTitle><StyledDesc>Verify your Gcash registered number to withdraw amount from your wallet.</StyledDesc>
      <form
          className='loginform'
          action='#'
          onSubmit={handleSubmit(onGcashSubmit)}
          noValidate
        >
          <div>
            <Col md={12} className='no_padding'>
              <Label display='block' mb='5px' htmlFor='email'>
                Phone Number
              </Label>
              <FormGroup>
                <InputGroup>
                  <Input
                    className='mobilenumber'
                    type='text'
                    id='phoneNumber'
                    name='phoneNumber'
                    placeholder='Enter Phone Number'
                    feedbackText={errors?.phoneNumber?.message}
                    state={hasKey(errors, 'phoneNumber') ? 'error' : 'success'}
                    showState={!!hasKey(errors, 'phoneNumber')}
                    {...register('phoneNumber', {
                      required: 'Phone Number is required',
                      pattern: {
                        value: /^(?!(0))[0-9]{10}$/,
                        message: 'Enter 10 digit valid phone number',
                      },
                    })}

                  />2
                </InputGroup>
              </FormGroup>
            </Col>
          </div>
          <StyledButtonContainer>
            <Button type='submit' color='brand2' className='signin_btn'>
              {loading ? <ButtonSpinner /> : null}Verify
            </Button>
          </StyledButtonContainer>
        </form></>
        }
    </StyledWrap>
  );
};

export default DepositForm;
