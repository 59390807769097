import styled, { themeGet } from '@/shared/styled';

export const StyledWrap = styled.div`
  width: 100%;
  .no_padding {
    padding-right: 0px;
  }
  .signin_btn {
    background: ${themeGet('colors.mainborder')};
    display: flex;
    align-items: center;
    font-family: ${themeGet('fonts.googleSansBold')};
    font-size: 16px;
    gap: 4px;
    color: ${themeGet('colors.pagefontcolor')} !important;
    width: 100%;
    margin-top: 15px;
    height: 42px;
    border: none;
    margin-top: 40px;
    margin-bottom: 20px;
  }
  .btn-check:checked + .signin_btn.btn,
  :not(.btn-check) + .btn:active,
  .signin_btn.btn:first-child:active,
  .signin_btn.btn.active,
  .signin_btn.btn.show {
    background: ${themeGet('colors.mainborder')};
  }
  .row {
    margin-bottom: 16px;
  }
  .mobilenumber {
    padding-left: 28px;
  }
  .otpinput {
    padding-left: 10px;
  }

  .labelheight {
    height: 20px;
  }
  form {
    position: relative;
  }
  .no_padding {
    .form-group {
      position: relative;
    }
  }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  font-family: ${themeGet('fonts.googleSansBold')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 6px;
  text-align: center;
`;

export const StyledDesc = styled.p`
  font-size: 16px;
  color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
  margin-bottom: 30px;
  text-align: center;
`;

export const StyledAlertMessage = styled.div`
  color: #f31415;
  background-color: #f8d7da;
  border-color: #f5c6cb;
  position: relative;
  padding: 5px 10px;
  margin-bottom: 1rem;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 4px;
  top: -9px;
  position: relative;
`;

export const StyledLabelWrap = styled.div`
  margin-bottom: 5px;
  justify-content: space-between;
  display: flex;
`;

export const StyledDivider = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  text-transform: uppercase;
  letter-spacing: 0.5px;
  margin: 15px 0;
  color: #c2c9d6;
  &:after,
  &:before {
    content: '';
    display: block;
    flex: 1;
    height: 2px;
    background-color: ${themeGet('colors.tableheaderborder')};
  }
  &:before {
    margin-right: 10px;
  }
  &:after {
    margin-left: 10px;
  }
`;

export const StyledBottomText = styled.div`
  margin-top: 20px;
  text-align: center;
  font-size: 13px;
  color: #c2c9d6;
  a {
    color: ${themeGet('colors.homebannertxtcolor')};
    font-family: ${themeGet('fonts.googleSansMedium')};
    font-size: 14px;
  }
`;

export const StyledForgotText = styled.div`
  text-align: center;
  padding: 5px 0px;
  a {
    background: none;
    color: ${themeGet('colors.mainborder')};
    font-family: google_sansmedium;
    font-size: 14px;
  }
  span {
    color: #c2c9d6;
    font-size: 13px;
    margin-right: 5px;
  }
  .resendbtn {
    background: none;
    font-size: 14px;
    border: none;
    border-radius: 0;
    color: ${themeGet('colors.mainbackground')};
  }
`;
export const StyledPhoneText = styled.div`
  position: absolute;
  left: 14px;
  top: 12px;
  color: #a4adc0;
  font-size: 13px;
  z-index: 99;
  font-family: ${themeGet('fonts.googleSansBold')};
`;

export const StyledAlertMessageSucess = styled.div`
  color: #155724;
  background-color: #d4edda;
  border-color: #c3e6cb;
  // position: absolute;
  padding: 5px 10px;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  display: flex;
  align-items: center;
  gap: 4px;
  font-size: 14px;
  width: 100%;
  font-family: ${themeGet('fonts.googleSansBold')};
  top: -9px;
  position: relative;
`;

export const StyledButtonContainer = styled.div`
  display: flex;
  justify-content: center;
`;
