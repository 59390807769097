import React, { useState, useEffect } from 'react';
import { ProfileNameSvg } from '@/shared/svg';
import {
  StyledMyAccountContainer,
  StyledMyAccountView,
  StyledMyAccountHeader,
  StyledMyAccountBoby,
  StyledMyAccountLeft,
  StyledMyAccountRight,
  StyledAccountProfile,
  StyledAccountButton,
  StyledAccountName,
  StyledAccountMide,
  StyledAccountAction,
  StyledMobileView,
} from './style';
import moment from 'moment/moment';
import AuthService from '@/services/AuthService';
import CookieStorageService from '@/services/CookieStorageService';
import { resetUser, setIsAuthenticatedData } from '@/redux/slices/login';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { history } from '@/helpers/history';
import Switch from './Switch';
import ChangePasswordPopUp from '@/components/ChangePasswordPopUp';

const MyAccount = () => {
  const dispatch = useAppDispatch();
  //const [verificationStatus, setVerificationStatus] = useState('');
  const [userInfo, setUserInfo] = useState<IUser>(null);
  const [value, setValue] = useState(false);
  const [displayModule, setDisplayModule] = useState(false);
  const logoutUser = () => {
    CookieStorageService.deleteCookieData(AuthService.getAuthKey());
    dispatch(setIsAuthenticatedData(false));
    dispatch(resetUser());
    history.push('/');
    location.reload();
  };
  const getMyaccountData = async () => {
    const response = await AuthService.getUserData();
    if(response.data.emailNotification == undefined)
    response.data.emailNotification = false;

    setValue(response.data.emailNotification);
    setUserInfo(response.data);
  };
  const setEmailverification = async (val:any) => {
    const res = { emailNotification: val };
    const response = await AuthService.updateUserProfile(res);
    setValue(val);
  }
  const openInfoPopup = async () => {
    setDisplayModule(true);
  }

  const closePopup = () => {
    setDisplayModule(false);
  }


  useEffect(() => {
    getMyaccountData();
  }, []);
  return (
    <>
      <StyledMyAccountContainer>
        <StyledMyAccountView>
          <StyledMyAccountHeader>
            <h4>My Account</h4>
          </StyledMyAccountHeader>
          <StyledMyAccountBoby>
            <StyledMyAccountLeft>
              <StyledAccountProfile>
                <ProfileNameSvg />
                <h5>u-{userInfo?.id.slice(0,8)}</h5>
                {userInfo?.firstName != '' && userInfo?.lastName != '' && <h5>{userInfo?.firstName} {userInfo?.middleName}{' '} {userInfo?.lastName}</h5>}
                <p>
                  Member since{' '}
                  {moment(userInfo?.createdAt).format('DD/MM/YYYY HH:mm:ss')}
                </p>
              </StyledAccountProfile>
              <StyledAccountButton>
                <button type='button' className='deactiv_profile' onClick={() => openInfoPopup()}>
                  Change Password
                </button>
                <button
                  type='button'
                  className='signout_profile'
                  onClick={() => logoutUser()}
                >
                  Sign Out
                </button>
              </StyledAccountButton>
            </StyledMyAccountLeft>

            <StyledMyAccountRight>
              <ul>
                <li>
                  <StyledAccountName>User ID</StyledAccountName>
                  <StyledAccountMide>u-{userInfo?.id.slice(0,8)}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Full Name</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.firstName} {userInfo?.middleName}{' '}
                    {userInfo?.lastName}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Mobile number</StyledAccountName>
                  <StyledAccountMide>{userInfo?.phone}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Date of birth</StyledAccountName>
                  <StyledAccountMide>
                    {moment(userInfo?.dob).format('DD/MM/YYYY')}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Email address</StyledAccountName>
                  <StyledAccountMide>{userInfo?.email}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Postal Code</StyledAccountName>
                  <StyledAccountMide>{userInfo?.postalCode}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Address1</StyledAccountName>
                  <StyledAccountMide className='texttransform'>{userInfo?.address2}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Address2</StyledAccountName>
                  <StyledAccountMide className='texttransform'>{userInfo?.address1}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>City</StyledAccountName>
                  <StyledAccountMide className='texttransform'>{userInfo?.city}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Source of income</StyledAccountName>
                  <StyledAccountMide className='texttransform'>
                    {userInfo?.sourceOfIncome}
                  </StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Income range</StyledAccountName>
                  <StyledAccountMide>{userInfo?.income}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Occupation</StyledAccountName>
                  <StyledAccountMide className='texttransform'>{userInfo?.occupation}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Country Code</StyledAccountName>
                  <StyledAccountMide>{userInfo?.countryCode}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <StyledAccountName>Nationality</StyledAccountName>
                  <StyledAccountMide className='texttransform'>{userInfo?.nationality}</StyledAccountMide>
                  <StyledAccountAction></StyledAccountAction>
                </li>
                <li>
                  <p className='receivenotifi'>
                    Receive email notifications and promotional material from
                    {process.env.REACT_APP_SITE_TITLE}
                  </p>
                  <StyledAccountMide>
                  <Switch
                      isOn={value}
                      onColor="#DD631A"
                      handleToggle={() => setEmailverification(!value)}
                    />
                  </StyledAccountMide>
                </li>
              </ul>
              <StyledMobileView>
                <StyledAccountButton className='buttonmobile'>
                  <button type='button' className='deactiv_profile' onClick={() => openInfoPopup()}>
                    Change Password
                  </button>
                  <button
                    type='button'
                    className='signout_profile'
                    onClick={() => logoutUser()}
                  >
                    Sign Out
                  </button>
                </StyledAccountButton>
              </StyledMobileView>
              {displayModule && (<ChangePasswordPopUp closePopup={closePopup}/>)}
            </StyledMyAccountRight>
          </StyledMyAccountBoby>
        </StyledMyAccountView>
      </StyledMyAccountContainer>
    </>
  );
};

export default MyAccount;
