import CookieStorageService from '@/services/CookieStorageService';
import AuthService from '@/services/AuthService';
export const getHeaderInfo = async function () {
  const token = await CookieStorageService.getItem(AuthService.getAuthKey());

  return {
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
  };
};

export const getFormDataHeader = async function () {
  const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  return {
    headers: {
      'Content-Type': 'multipart/form-data',
      Authorization: `Bearer ${token}`,
    },
  };
};
