import React from 'react';
import styled, {
  SpaceProps,
  TypographyProps,
  LayoutProps,
} from '@/shared/styled';

interface IProps extends SpaceProps, TypographyProps, LayoutProps {}

export const StyledLabel = styled(({ ...rest }) => <label {...rest} />)<IProps>`
  display: inline-block;
`;
