import React from 'react';
import { StyledErrorPage, StyledErrorContainer } from './home/style';
import { Link } from 'react-router-dom';

const ErrorNotFound = () => {
  return (
    <>
      <StyledErrorPage>
        <StyledErrorContainer>
          <h4>PAGE NOT FOUND</h4>
          <Link to='/'>Homepage</Link>
        </StyledErrorContainer>
      </StyledErrorPage>
    </>
  );
};

export default ErrorNotFound;
