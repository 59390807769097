import CookieStorageService from '@/services/CookieStorageService';
import { post, get, put } from '@/services/HttpService';

export enum Auth {
  AUTH_ACCESS_TOKEN = 'accessToken',
}

const BASE_URL = process.env.REACT_APP_AUTHENTICATION_API_URL;
const CMS_URL = process.env.REACT_APP_RACING_API_URL;

export default class AuthService {
  public static authenticate = async (data: ILoginForm) => {
    if (process.env.REACT_APP_THEME && process.env.REACT_APP_THEME !== 'arionplay') {
      data.agent = process.env.REACT_APP_THEME;
    }
    const response = await post(`${BASE_URL}signin/phone`, data);
    if (response.token != undefined) {
      CookieStorageService.setItem(Auth.AUTH_ACCESS_TOKEN, response.token, 1);
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static register = async (data: IUser) => {
    // If the theme is different that means we are using another site.
    if (process.env.REACT_APP_THEME && process.env.REACT_APP_THEME !== 'arionplay') {
      data.agent = process.env.REACT_APP_THEME;
    }
    const response = await post(`${BASE_URL}signup/phone/register`, data);
    if (response.token != undefined) {
      CookieStorageService.setItem(Auth.AUTH_ACCESS_TOKEN, response.token, 1);
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static getOTP = async (data: ISignupOTP) => {
    if (process.env.REACT_APP_THEME && process.env.REACT_APP_THEME !== 'arionplay') {
      data.agent = process.env.REACT_APP_THEME;
    }
    const response = await post(`${BASE_URL}signup/phone/otp`, data);
    console.log(response)
    if (response.token != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static changePassword = async (data: any) => {
    const response = await post(`${BASE_URL}user/password/change`, data);
    if (response.verifiedAt != undefined) {
      return { status: true, data: 'password changed successfully' };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static resendOTP = async (data: ISignupOTP) => {
    if (process.env.REACT_APP_THEME && process.env.REACT_APP_THEME !== 'arionplay') {
      data.agent = process.env.REACT_APP_THEME;
    }
    const response = await post(`${BASE_URL}signup/phone/otp/resend`, data);
    if (response.token != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static getGeoData = async () => {
    const response = await get(`${BASE_URL}geoip`);
    if (response.country != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };



  public static getUploadFilePath = async (url: any) => {
    const response = await get(`${BASE_URL}user/upload?` + url);
    if (response != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static getUserData = async () => {
    try {
      const response = await get(`${BASE_URL}user`);
      return { status: true, data: response };
    } catch (err) {
      return { status: false, data: err };
    }
  };

  public static getForgotOTP = async (data: ForgotForm) => {
    const response = await post(`${BASE_URL}user/password/forgot`, data);
    if (response.token != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static resetPassword = async (data: IOTPForm) => {
    const response = await post(`${BASE_URL}user/password/forgot/reset`, data);
    if (response.verifiedAt != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static resendForgotPassword = async (data: IOTPForm) => {
    const response = await post(`${BASE_URL}user/password/forgot/reset`, data);
    if (response.verifiedAt != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static resendForgotPasswordAgain = async (data: IOTPForm) => {
    const response = await post(`${BASE_URL}user/password/forgot/resend`, data);
    if (response.token != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static verifyEmailOtp = async (data: any) => {
    console.log('verifyEmailOtp', data);
    const response = await post(`${BASE_URL}user/verify/email`, data);
    console.log('verifyEmailOtp::response', response);
    if ((response && response.id) || response.status != undefined) {
      return { status: true, data: 'Thank you for the email verification.' };
    } else {
      return { status: false, data: response.error };
    }
  };
  public static isAuthenticated = (): boolean => {
    return CookieStorageService.getItem(Auth.AUTH_ACCESS_TOKEN) ? true : false;
  };

  public static getAuthKey() {
    return Auth.AUTH_ACCESS_TOKEN;
  }

  public static getUserWalletData = async (walletId: string) => {
    const response = await get(`${BASE_URL}wallet/${walletId}`);
    if (response.status != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static deposit = async (data: IDepositForm, userId: string) => {
    const response = await post(`${BASE_URL}wallet/${userId}/deposit?theme=${process.env.REACT_APP_THEME || ''}`, data);
    if (response.error === null) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static withdraw = async (data: IWithdrawForm, userId: string) => {
    const response = await post(`${BASE_URL}wallet/${userId}/withdraw`, data);
    if (response.data != undefined && response.data.transid != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response };
    }
  };

  public static getMyWallets = async () => {
    const response = await get(`${BASE_URL}user/wallets`);
    return { status: true, data: response };
  };

  public static getMyWalletData = async () => {
    const response = await get(`${BASE_URL}user/transactions`);
    return { status: true, data: response };
    // if (response.status != undefined) {
    //   return { status: true, data: response };
    // } else {
    //   return { status: false, data: response.error };
    // }
  };
  public static updateUserProfile = async (data: any) => {
    const response = await put(`user`, data);
    console.log(response);
    if (response.id != undefined) {
      return { status: true, data: response };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static getBettingHistoryData = async () => {
    const response = await get(`${BASE_URL}user/bets`);
    return { status: true, data: response };
  };
  public static getMyactivityData = async () => {
    const response = await get(`${BASE_URL}user/actions`);
    return { status: true, data: response };
  };

  public static submitBet = async (data: any) => {
    const response = await post(`${BASE_URL}submitbet`, data);
    if (response.status == "SUCCESS") {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: response.error };
    }
  };

  public static getTicketDetails = async (data: any) => {
    const response = await post(`${BASE_URL}ticketdetails`, data);
    if (response.status == 'SUCCESS') {
      return { status: true, data: response.data };
    } else {
      return { status: false, data: response.error };
    }
  };





}
