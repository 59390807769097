import styled, { themeGet, device } from '@/shared/styled';

export const StyledEvlCard = styled.div`
  position: relative;
  width:191px;
  height:190px;
  a {
    //align-items: center;
    //background: ${themeGet('colors.largegamecardbackground')};
    //border: 1.5px solid ${themeGet('colors.largegamecardborder')};
    //border-radius: 10px;
    color: ${themeGet('colors.white')};
    display: flex;
    flex-direction: column;
    text-decoration: none;
    transition: all 0.3s;
    width: 100%;
    position: relative;
    padding:2px;

    ${device.MobToSL} {
      flex-direction: column;
      height: auto;
    }
  }
  a:hover {
    //background: ${themeGet('colors.largegamecardbackground')};
    //border: 1.5px solid ${themeGet('colors.btnfontcolor')};
    padding:2px;
    span {
      svg path {
        fill: ${themeGet('colors.pagefontcolor')};
      }
    }
    .hoverview {
      opacity: 1;
      ${device.MobToSL} {
        height: calc(100% - 45px);
      }
      ${device.TabToXl} {
        height: calc(100% - 45px);
      }
    }
  }
  ${device.MobToSl} {
   
  
  }
 ${device.MobToSm}{
   height: auto;
   width: auto;
 }
 ${device.MobToSd}{
   height: auto;
 }
`;

export const StyledEvlCardContainer = styled.div`
  align-items: center;
  //background: ${themeGet('colors.largegamecardbackground')};
  border: 1.5px solid ${themeGet('colors.largegamecardborder')};
  border-radius: 10px;
  color: ${themeGet('colors.white')};
  display: flex;
  flex-direction: column;
  text-decoration: none;
  transition: all 0.3s;
  width:191px;
  height:190px;
  position: relative;
  cursor: pointer;
  //padding:2px;
  justify-content: center;
  &:hover {
    //background: ${themeGet('colors.largegamecardbackground')};
    border: 1.5px solid ${themeGet('colors.btnfontcolor')};
    span {
      svg path {
        fill: ${themeGet('colors.pagefontcolor')};
      }
    }
    .hoverview {
      opacity: 1;
      ${device.MobToSL} {
        height: calc(100% - 45px);
      }
      ${device.TabToXl} {
        height: calc(100% - 45px);
      }
    }
  }
  ${device.MobToSl} {
    width:160px;
    a{
      width:160px;
      height: 184px;
    }
  }
  ${device.MobToSm}{
    width:140px;
    height: 140px;
    h6{
      font-size:13px;
      width: 96px;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    a{
      width:140px;
      //height: 170px;
    }
  }
  ${device.MobToSd}{
    width:120px;
    height: 120px;
    a{
      width:120px;
      height: 170px;
    }
  }
`;

export const StyledEvlCardImg = styled.div`
 //height:150px;
  ${device.MobToSL} {
    width: 100% !important;
  }
  ${device.TabToXl} {
    width: 100%;
  }
  ${device.MobToMd}{
    height: auto;
    
  }
  img {
    height: 100%;
    object-fit: contain;
    width: 100%;
    border-radius: 10px;
  }
`;

export const StyledEvlText = styled.div`
  padding-left: 0;
  width: 100%;
  display: flex;
  padding: 12px 10px;
  position: relative;
  h6 {
    color: #aec1bb;
    font-size: 14px;
    line-height: 22px;
    text-align: left;
    margin-bottom: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 86%;
    font-family: ${themeGet('fonts.googleSansMedium')};
  }
  .game_info_btn {
    background: none;
    border: none;
    position: absolute;
    right: 10px;
    top: 10px;
    z-index: 1;
  }
`;

export const StyledEvlHoverContainer = styled.div`
  span {
    align-items: center;
    display: flex;
    justify-content: center;
    background: rgba(2, 28, 20, 0.9);
    border-radius: 7px;
    flex-direction: column;
    height: 100%;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    transition: opacity 0.3s;
    width: 100%;
    &:hover {
      opacity: 1;
    }
    .playnow_btn {
      background: ${themeGet('colors.btnfontcolor')};
      border: 1px solid ${themeGet('colors.btnfontcolor')};
      border-radius: 6px;
      color: ${themeGet('colors.pagefontcolor')};
      font-family: ${themeGet('fonts.googleSansBold')} !important;
      font-size: 14px;
      gap: 6px;
      height: 34px;
      min-width: 98px;
      padding: 6px 10px;
      transition: all 0.3s;
      width: 111px;
      
    }
  }
  .tryit_btn {
    align-items: flex-end;
    background: none;
    border: 1.2px solid ${themeGet('colors.btnfontcolor')};
    border-radius: 6px;
    color: ${themeGet('colors.btnfontcolor')};
    display: inline-flex;
    font-size: 13px;
    gap: 6px;
    margin-top: 20px;
    padding: 7px 16px 7px 15px;
    font-family: ${themeGet('fonts.googleSansBold')} !important;
  }
`;

export const StyledEvlPopup = styled.div`
  width: 100%;
`;

export const StyledEvlPopupOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0px;
  left: 0px;
  z-index: 100;
  background-color: #000;
  opacity: 0.8;
`;

export const StyledEvlPopupContainer = styled.div`
  width: 90%;
  height: calc(100vh - 60px);
  position: fixed;
  z-index: 200;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: rgb(38, 51, 77);
  border-radius: 30px;
  max-width: 700px;
  ${device.MobToMd} {
    width: 100%;
    height: 100%;
    border-radius: 0px;
  }
`;

export const StyledEvlPopupHeading = styled.div`
  width: 100%;
  padding: 20px 40px 10px 40px;
  float: left;
  ${device.MobToMd} {
    padding: 20px 16px;
  }
  h4 {
    color: ${themeGet('colors.white')};
    font-family: ${themeGet('fonts.googleSansBold')} !important;
    font-size: 24px;
    line-height: 24px;
    float: left;
    margin-top: 7px;
  }
  button {
    width: 32px;
    height: 32px;
    float: right;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    svg {
      width: 16px;
      height: 16px;
      line {
        stroke: #fff;
      }
    }
  }
`;

export const StyledEvlPopupContent = styled.div`
  width: 100%;
  float: left;
  padding: 20px 40px;
  ${device.MobToMd} {
    padding: 0px 16px;
  }
  iframe {
    width: 100%;
    height: calc(100vh - 192px);
    float: left;
    border: 1px solid #47597e;
    border-radius: 8px;
    ${device.MobToMd} {
      height: calc(100vh - 100px);
    }
  }
`;

export const StyledOverMainContainer = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${themeGet('colors.overlaybg')};
    background-clip: padding-box;
    border: 1px solid ${themeGet('colors.border')};
    outline: 0;
    border-radius: 30px;
    left: 50%;
    position: fixed;
    top: 50%;
    transform: translate(-50%, -50%);
    z-index: 200;
    cursor: auto;
    //min-height: calc(100% - 3.5rem);
    max-width: 700px;

`;

export const StyledOverLayBody = styled.div`
  position: relative;
  flex: 1 1 auto;
  padding: 1rem;
  min-height: 300px;
  padding: 30px 35px 25px 25px;
 .close_popup{
    background: none;
    height: 16px;
    width: 16px;
    position: absolute;
    right: 37px;
    top: 20px;
    svg{
      width: 18px;
      height: 18px;
      line{
        stroke: #6F8CAA;
      }
    }
 }
`;

export const StyledTitle = styled.h3`
  color: #c2c9d6;
  text-align: center;
  font-family: ${themeGet('fonts.googleSansMedium')};
  font-size: 24px;
  line-height: 24px;
  margin-bottom: 4px;
  p {
    font-size: 15px;
    color: ${themeGet('colors.eventnumberdefaulttxtcolor')};
    a {
      color: ${themeGet('colors.mainborder')};
    }
  }
  ${device.MobToMd} {
    margin-bottom: 15px;
  }
`;

export const StyledAllVenu = styled.div`
 .nav-tabs{
   padding-left:20px;
   border: 0px;
   li.nav-item{
    flex:0 1 auto !important;
   }
   .nav-link{
    color:${themeGet('colors.eventnumberdefaulttxtcolor')};
    font-size: 15px;
    font-family: ${themeGet('fonts.googleSansMedium')};
    border:0px;
    position: relative;
    padding: 20px 10px;
  }
  .nav-link:hover, .nav-link:focus{
    border:0px;
  }
   .nav-link.active{
    background: none;
    border: 0px;
    font-size: 15px;
    color: #FFF;
    font-family: ${themeGet('fonts.googleSansMedium')};
   }
   .nav-link.active:before {
    content: '';
    width: 100%;
    position: absolute;
    left: 0px;
    bottom:1px;
    border-bottom:4px solid ${themeGet('colors.mainbackground')};
}
 }
 .tab-content{
  border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  padding: 20px 16px 20px 16px;
  margin-top: 0px;
  font-family: 'google_sansbold',sans-serif;
  //height:390px;
  overflow: auto;
 }
 h6{
  color: #95A1B9;
  font-size: 18px;
  font-family: ${themeGet('fonts.googleSansRegular')};
  margin-top:20px;
  align-items: center;
  justify-content: center;
  display: flex;
 }
`;

export const StyledCommingBody = styled.div`
 padding-top:20px;
  h4{
  color:${themeGet('colors.eventnumberdefaulttxtcolor')};
  font-size: 16px;
  font-family: ${themeGet('fonts.googleSansBold')};
  margin-bottom:10px;
  }
  p{
    color:${themeGet('colors.GameTextcolor')};
    font-size: 13px;
    font-family: ${themeGet('fonts.googleSansRegular')};
    margin-bottom:14px;
  }
  ul{
    padding-left: 14px;
    li{
      color:${themeGet('colors.GameTextcolor')};
      font-size: 13px;
      font-family: ${themeGet('fonts.googleSansRegular')};
      line-height: 24px;
    }
  }
`;
export const StyledComminView = styled.div`
border-radius: 16px;
  background: rgba(255, 255, 255, 0.05);
  padding: 20px 20px;
  font-family: 'google_sansbold',sans-serif;
  //height:390px;
  overflow: auto;
  margin-top:20px;
  `;

  export const StyledBannerImg = styled.div``;

  export const StyledOvelayContainer = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 1050;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    outline: 0;
    opacity: 0;
    visibility: hidden;
    opacity: 1;
    visibility: visible;
`;