import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
  ArrowLeftSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledTextArea,
  StyledpageViewMain,
  StyledpagHeader,
  StyledpagBody,

  
} from './style';
import Accordion from 'react-bootstrap/esm/Accordion';


const FaqSportsbetsEnquiries = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  // const redirectToContact = async () => {
  //   const token = await CookieStorageService.getItem(AuthService.getAuthKey());
  //   if (token === undefined || token == '') {
  //     dispatch(changeModule('login'));
  //     return false;
  //   }
  //   history(`/contactus`);
  // };
  return (
    <>
      <StyledContainerFluid>
      <h3>FAQs &amp; Help</h3>
        <StyledContainerPageView>
        <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus">
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>{process.env.REACT_APP_SUPPORT_EMAIL}</Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledpageViewMain>
                <StyledpagHeader>
                 <Link to='/faqs'><ArrowLeftSvg/></Link>  <h4>Sports Bets Enquiries</h4>
                </StyledpagHeader>
                <StyledpagBody>
                  <Accordion defaultActiveKey="0">
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>How do I place a bet on Arionplay.com?</Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>Log in to your Arionplay account using your credentials.</li>
                          <li>Select the desired date for the event you wish to bet on.</li>
                          <li>Choose the specific event or game you are interested in betting on.</li>
                          <li>Review the available bets along with their respective odds.</li>
                          <li>Enter the amount you want to wager as your stake.</li>
                          <li>Click on the "Confirm" or "Place a Bet" button to finalize your bet.</li>
                        </ol>
                        <p>Always double-check your bet details before confirming to ensure accuracy. If you have any queries
                        or encounter any issues while placing a bet, don't hesitate to reach out to our support team for
                        assistance. Happy betting!</p>
                      </Accordion.Body>
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>What are the various types of bets in horse racing?</Accordion.Header>
                      <Accordion.Body>
                        <ol>
                          <li>Win: You bet on a horse to finish first in the race. If your chosen horse wins, you win the bet.</li>
                          <li>Place: You bet on a horse to finish in either first, second or third place. If your chosen horse comes in first, second or third, you win the bet.</li>
                          <li>SHP (Second Horse Pool): You pick a horse to finish in the second position in the race. If your selected horse comes in second, you win the bet.</li>
                          <li>THP (Third Horse Pool): You predict which horse will finish in the third position in the race. If your chosen horse finishes third, you win the bet.</li>
                          <li>Show: You bet on a horse to finish in the top three positions (first, second, or third). If your selected horse finishes in any of these positions, you win the bet.</li>
                          <li>Quinella: You choose two horses to finish in the top two positions, regardless of the order. If your chosen horses finish first and second, you win the bet.</li>
                          <li>Tanala: You choose three horses to finish in the exact order. If your chosen horses finish first second, third in exact order, you win the bet.</li>
                          <li>Exacta: You predict the first four horses in the exact order they finish. If your selected horses finish in the correct order in first, second, third and fourth, you win the bet.</li>
                          <li>Forecast: You predict the first two horses in the exact order they finish. If your selected horses finish in the correct order, you win the bet.</li>
                          <li>Treble: You select the winners of three selected races. All three selections must win for you to win the bet.</li>
                          <li>Mini Jackpot: Similar to the Jackpot, but you pick the winners of four selected races. If all your selections win, you win the Mini Jackpot.</li>
                          <li>Jackpot: You choose the winners of a five selected races. If all your selections win, you win the Jackpot.</li>
                          <li>Super Jackpot: You choose the winners of a six selected races. If all your selections win, you win the Super Jackpot.</li>
                        </ol>
                      </Accordion.Body>
                    </Accordion.Item>
                  </Accordion>
                </StyledpagBody>
              </StyledpageViewMain>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default FaqSportsbetsEnquiries;
