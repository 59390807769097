import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CookieStorageService from '@/services/CookieStorageService';
import { useAppDispatch } from '@/hooks/useAppDispatch';
import { changeModule } from '@/redux/slices/module';
import AuthService from '@/services/AuthService';
import {
  ArrowRightSvg,
  MessageSvg,
  QuestionSvg,
  HeadPhoneSvg,
} from '@/shared/svg';
import {
  StyledContainerFluid,
  StyledContainerPageView,
  StyledPageLeft,
  StyledPageRight,
  StyledInfoBox,
  StyledPageTextContainer,
  StyledPageBanner,
  StyledPageTextView,
  StyledTextArea,
  StyledPageTabContainer,
} from './style';

const Betrules = () => {
  const dispatch = useAppDispatch();
  const history = useNavigate();
  const [tab, setTab] = useState('livegames');
  const redirectToContact = async () => {
    const token = await CookieStorageService.getItem(AuthService.getAuthKey());
    if (token === undefined || token == '') {
      dispatch(changeModule('login'));
      return false;
    }
    history(`/contactus`);
  };

  const selectTab = async (val: any) => {
    setTab(val);
  };

  return (
    <>
      <StyledContainerFluid>
        <h3>Bet Rules</h3>
        <StyledContainerPageView>
          <StyledPageLeft>
            <StyledInfoBox>
              <span>
                <QuestionSvg />
              </span>
              <StyledTextArea>
                <p>
                  Everything you need to know about the{' '}
                  {process.env.REACT_APP_SITE_TITLE} Gaming. Can&apos;t find the
                  answer you&apos;re looking for?
                </p>
                <Link to="/contactus" onClick={() => redirectToContact()}>
                  <span>Contact Us</span>
                  <ArrowRightSvg />
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <HeadPhoneSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Customer support</p>
                <Link to='mailto: {process.env.REACT_APP_SUPPORT_EMAIL}'>
                  {process.env.REACT_APP_SUPPORT_EMAIL}
                </Link>
                {/* <strong>+63 999 991 2345</strong> */}
                <strong>(+63) 995 834 9274</strong>
                <small>Available: 24x7</small>
              </StyledTextArea>
            </StyledInfoBox>
            <StyledInfoBox>
              <span>
                <MessageSvg />
              </span>
              <StyledTextArea>
                <p className='chat_with_text'>Chat with us</p>
                <Link to=''>
                  <span>Coming Soon!</span>
                  {/* <ArrowRightSvg /> */}
                </Link>
              </StyledTextArea>
            </StyledInfoBox>
          </StyledPageLeft>
          <StyledPageRight>
            <StyledPageTextContainer>
              <StyledPageTabContainer>
                <ul>
                  <li className={`${tab === 'livegames' ? 'active' : ''}`}>
                    <Link to={''} onClick={() => selectTab('livegames')}>
                      livegames
                    </Link>
                  </li>
                  <li className={`${tab === 'evolutiongames' ? 'active' : ''}`}>
                    <Link to={''} onClick={() => selectTab('evolutiongames')}>
                      evolutiongames
                    </Link>
                  </li>
                  <li className={`${tab === 'toteodds' ? 'active' : ''}`}>
                    <Link to={''} onClick={() => selectTab('toteodds')}>
                      Sports Betting - Tote Odds
                    </Link>
                  </li>
                  <li className={`${tab === 'fixedodds' ? 'active' : ''}`}>
                    <Link to={''} onClick={() => selectTab('fixedodds')}>
                      Sports Betting - Fixed Odds
                    </Link>
                  </li>
                </ul>

                {tab == 'livegames' && (
                  <div className='livegames' title='Live Games'>
                    <StyledPageBanner>
                      <img
                        src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/Live Games-banner.png`}
                        alt=''
                      />
                    </StyledPageBanner>
                    <StyledPageTextView>
                      <div className=''>
                        <div className='static_content'>
                          <h2 className='capitalize'>
                            Live Games Odds Bet Rules
                          </h2>
                          <div className='betruletext'></div>
                        </div>
                      </div>
                    </StyledPageTextView>
                  </div>
                )}
                {tab == 'evolutiongames' && (
                  <div className='evolutiongames' title='Evolution Games'>
                    <StyledPageBanner>
                      <img
                        src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/E-Games-banner.png`}
                        alt=''
                      />
                    </StyledPageBanner>
                    <StyledPageTextView>
                      <div className=''>
                        <div className='static_content'>
                          <h2 className='capitalize'>
                            Evolution Games Bet Rules
                          </h2>
                          <div className='betruletext'></div>
                        </div>
                      </div>
                    </StyledPageTextView>
                  </div>
                )}
                {tab == 'toteodds' && (
                  <div className='toteodds' title='Sports Betting - Tote Odds'>
                    <StyledPageBanner>
                      <img
                        src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/SportsBetting-ToteOdds-Banner.png`}
                        alt=''
                      />
                    </StyledPageBanner>
                    <StyledPageTextView>
                      <div className=''>
                        <div className='static_content'>
                          <h2 className='capitalize'>
                            Sports Betting - Tote Odds Bet Rules
                          </h2>
                          <div className='betruletext'>
                            <p>
                              <strong>
                                BOOKMAKERS/ TOTE TRANSACTIONS&nbsp;&nbsp; R U L
                                E S
                              </strong>
                            </p>
                            <p>
                              No transactions are permitted other than at the
                              Totalizators (on course or through online
                              web/mobile app transactions on the HRC’s
                              Totalizators only) or with Bookmakers.
                            </p>
                            <p>
                              Any one contravening this rule will be severely
                              dealt with and shall be liable for being ejected
                              from the HRC’s premises.
                            </p>
                            <p>
                              a)&nbsp; No transactions for less than Rs.100/-
                              win or place on races conducted at Race Course,
                              Malakpet, shall be accepted by Bookmakers.
                            </p>
                            <p>
                              b)&nbsp; No transactions for less than Rs.60/- win
                              or place shall be accepted by Bookmakers on
                              Intervenue Transactions conducted at the Race
                              Course, Malakpet.
                            </p>
                            <p>
                              A&nbsp;&nbsp; Bookmaker shall be bound to accept a
                              liability of Rs.2,500/- at the odds shown on his
                              board for Win.&nbsp; Persons making book
                              transactions are warned that as far as possible
                              they should confirm their transactions or
                              otherwise make sure that they have been
                              accepted.&nbsp; Neglect of this, in the event of a
                              dispute, may invalidate their claim.
                            </p>
                            <p>
                              <strong>
                                Transactions on objections is not permitted.
                              </strong>
                            </p>
                            <p>
                              Persons making cash transactions of Rs.2,000/- or
                              over are warned that Bookmakers are forbidden to
                              accept such transactions unless the name of the
                              backer is given when the transaction/s is
                              made.&nbsp; Bookmakers are also required to record
                              the names and addresses of backers making
                              transactions of Rs.5,000/- or more and of those
                              winning Rs.10,000/- or more.
                            </p>
                            <p>
                              All transaction/s complaints must be reported
                              immediately in writing to the Chief Operating
                              Officer/Secretary, at the Hyderabad Race Club
                              office.
                            </p>
                            <p>
                              Bookmakers will pay on 3 placed horses when there
                              are 8 or more starters in a race and on 2 placed
                              horses when there are 4 and less than 8 starters
                              in a race.
                            </p>
                            <p>
                              In the event of the requisite number of starters
                              as above being left in at the commencement of
                              transactions and one or more not coming under the
                              Starter’s Orders,&nbsp; Place&nbsp;
                              transactions&nbsp; shall&nbsp; still&nbsp; be 1,
                              2, 3 or 1, 2 as the case may be.
                            </p>
                            <p>
                              In the case of transactions struck before it has
                              been officially notified that a horse has been
                              withdrawn before coming under Starter’s Orders.,
                              or the Stewards have declared a horse a
                              non-starter, if the Stewards consider that such
                              withdrawal of the horse from the race, would have
                              any material effect on the odds of the remaining
                              horses, the liability of the layer on the win and
                              place transactions on the race may be reduced in
                              accordance with the following scale depending on
                              the current odds against the withdrawn&nbsp; horse
                              at the time of such official notification.
                            </p>
                            <p>
                              a)&nbsp; If the current odds are evens or odds on,
                              by 50 ps. in the Rupee.
                            </p>
                            <p>
                              b)&nbsp; If over evens and under 4 to 1 by 25 ps.
                              in the Rupee.
                            </p>
                            <p>
                              c)&nbsp; If 4 to 1 and under 10 to 1, by 12 ps. in
                              the Rupee.
                            </p>
                            <p>
                              d)&nbsp; If 10 to 1 or over, the liability will
                              remain unchanged.
                            </p>
                            <p>
                              “However, in case where there is/are more than one
                              horse being declared a non-starter/withdrawn, the
                              deduction would be calculated on the
                              shortest/lowest odds prevailing on the withdrawn
                              horses”.
                            </p>
                            <p>
                              “In the event of a Dead-Heat, the Bookmakers
                              liability (including investment) will be reduced
                              by 50% e.g., for an effective transaction of
                              Rs.100 for win/place, say at 5-1 i.e., Rs.500/100,
                              a punter will receive Rs.300/- if the horse he/she
                              has backed dead-heats with another horse for Win
                              or Place”.
                            </p>
                            <p>
                              “Persons making cash transactions with the
                              Bookmakers are informed that the time limit for
                              encashment of winning cash cards has been fixed at
                              15 days from the day of issue of such cards.&nbsp;
                              In case the holder of a winning cash card is
                              unable to contact the Bookmaker concerned, he
                              should arrange to submit such winning card during
                              working hours to the office of the Hyderabad Race
                              Club within the stipulated time limit, when the
                              same will be passed on to the Bookmaker concerned
                              for payment if otherwise in order”.
                            </p>
                            <p>
                              Bookmakers will collect GST @ 28% on all
                              transactions laid or agreed to be laid on all
                              Races and are authorized to collect 3% inclusive
                              of tax towards administration charges.
                            </p>
                            <p>
                              A list of Backers who fail to settle their
                              accounts when due must be immediately circulated
                              among the other Bookmakers operating at the
                              Meeting. No further credits must be given to
                              persons whose names appear in the list until such
                              accounts are settled in full.&nbsp; When such
                              accounts are settled, the Bookmaker concerned must
                              at once report the matter to the Chief Operating
                              Officer/Secretary.
                            </p>
                            <p>
                              Settling with Bookmakers will be in their
                              respective stalls between 11.00 A.M. and 12 Noon
                              on the day following each race day. In the case of
                              consecutive race days, settling for the first day
                              will be at the Race Course on the following day
                              one hour before the first race.
                            </p>
                            <p>
                              <strong>GENERAL TOTALIZATOR RULES</strong>
                            </p>
                            <p>
                              The Rules governing the Totalizators are exhibited
                              in all the Enclosures at the Race Course,
                              Malakpet, HRC’s Off-Course Tote Centres and on
                              HRC’s website&nbsp;
                              <a href='http://www.hydraces.com/'>
                                www.hydraces.com
                              </a>
                              .&nbsp; All investors and holders of the offline
                              ticket or online web/mobile transaction
                              receipt/transaction number shall be deemed to have
                              made themselves acquainted with the rules and to
                              have agreed to be bound thereby.
                            </p>
                            <p>
                              1.Every investor must satisfy himself or herself
                              before leaving the counter/window the
                              correctness/details of tickets received or the
                              amount of dividend or refund payable to him or to
                              her as the case may be.&nbsp; No claim in respect
                              of the issue of tickets or in respect of short
                              payments of dividend or refunds will be
                              entertained after the investor has left the
                              counter/window. In case of online web/mobile
                              transactions the investors must satisfy themselves
                              before confirming the transactions.
                            </p>
                            <p>
                              Patrons are requested to note that no ticket can
                              be cancelled once the investor has left the
                              counter/window or the next ticket from that
                              counter/window is issued in case of offline
                              transactions. In case of online web/mobile app
                              transactions once the transaction/s is/are done
                              the same cannot be cancelled under any
                              circumstances, as such patrons are requested to be
                              doubly sure before placing transactions.
                            </p>
                            <p>
                              2.Any investor may invest an unlimited amount, Win
                              or Place on any horse.
                            </p>
                            <p>
                              3. (a) The sale of tickets/transactions will be
                              closed at the actual start of each race
                              simultaneously at Race Course, Malakpet, HRC’s
                              Off-Course Tote Centres and on online web/mobile
                              apps and no investment shall be received under any
                              circumstances whatsoever after the race start bell
                              is given.
                            </p>
                            <p>
                              &nbsp;(b) All Transactions operations will be
                              closed during the course of loading operations at
                              the time when a last horse is remaining for
                              loading into the starting gates for all races of
                              all Off-Course Racing centres.
                            </p>
                            <p>
                              4.Cheques or Foreign currency will not be
                              accepted.&nbsp; Coins, One Rupee and Two Rupee
                              notes will also not be accepted at the selling
                              counters/windows.
                            </p>
                            <p>
                              5.Torn or disfigured or mutilated tickets will not
                              be paid.&nbsp; The Stewards may however pay torn
                              tickets at their discretion.&nbsp; Under no
                              circumstances will any dividend be paid unless the
                              correct tickets are surrendered.
                            </p>
                            <p>
                              6.In the event of a race being ordered to be run
                              again or in case of a false start or breakaway
                              after which the Stewards give permission for any
                              horse/s not to return to the Starting point,
                              transactions in favour of such horse/s shall be
                              refunded.
                            </p>
                            <p>
                              7.In calculating the dividends by the number of
                              tickets/transactions based on the winning/ winning
                              combinations of all the totalizator pools, Club’s
                              Commission/Operation costs and Government Taxes
                              applicable from time to time will be&nbsp;
                              deducted and refunds if any and fractions of Rupee
                              will not be paid in all the Pools.
                            </p>
                            <p>
                              8.In respect of any race or races in connection
                              with which the recording machinery is found by the
                              Stewards to have been or to be at fault, the
                              Stewards are empowered to make such adjustments
                              they consider fair and equitable for the purpose
                              of calculating dividends and the dividends for
                              such race or races will be calculated in
                              accordance with such adjustments and declared
                              accordingly.
                            </p>
                            <p>
                              9.No payment will be made on any
                              ticket/transaction &nbsp;which does not tally with
                              the totalizator recording.
                            </p>
                            <p>
                              10.In the case of any objection at the scales,
                              transactions on the Totalizator will be paid on
                              the decision of the Stewards, Hyderabad Race Club.
                            </p>
                            <p>
                              11.Payment will be made against production of a
                              Winning Ticket with the correct code/ Sl.No., and
                              in case of online web/mobile app transactions will
                              be as per the transaction/receipt numbers to the
                              account holders. However, subsequently should
                              another similar ticket with the same code/computer
                              Sl. No. be presented, such ticket will not be paid
                              as the totalizator issues only one ticket of any
                              particular code or Sl. No.
                            </p>
                            <p>
                              12.All disputes shall be referred to the sole
                              arbitration of the Stewards of the Hyderabad Race
                              Club whose decision shall be final and binding on
                              both parties.
                            </p>
                            <p>
                              13.If there is any matter not provided for under
                              the Rules of any of the transaction events at
                              Hyderabad Race Club the decision of the Stewards
                              on such matter shall be final.
                            </p>
                            <p>
                              14.The decision of the Stewards of the Club as to
                              the amount payable to the successful investors
                              shall apart from any manifest error appearing in
                              the calculation thereof or in sorting out the
                              maximum winners be conclusive.
                            </p>
                            <p>
                              15.Totalizator Pools as referred to in these rules
                              would be only in respect of the taking at this
                              centre under the auspices of Hyderabad Race Club.
                            </p>
                            <p>
                              16.The Stewards of the Hyderabad Race Club may
                              decide to conduct transactions at the Race Course,
                              Malakpet and/or at any other place/s as may be
                              decided by them from time to time on races run at
                              Hyderabad or other Racing Centre/s.
                            </p>
                            <p>
                              17.(a) “Intervenue Transactions Day” shall mean
                              the day on which the Hyderabad Race Club is
                              accepting transactions and operating the
                              Totalizators for races held at any other racing
                              centre.
                            </p>
                            <p>
                              (b)“Racing Centre” shall be any CENTRE at which
                              races are conducted and on which the Hyderabad
                              Race Club operates inter-venue transactions at the
                              Race Course, Malakpet&nbsp; and/or any Off-Course
                              Tote Centres and on online web/mobile app of
                              Hyderabad Race Club, Turf Winners and Paytm First
                              Games linked to the Hyderabad Race Club’s
                              Totalizator System.
                            </p>
                            <p>
                              (c)“Season” for the Intervenue transactions at
                              Hyderabad on races run at any other racing centre,
                              shall mean the race days originally fixed in the
                              prospectus of the concerned racing centre for
                              which the Hyderabad Race Club decides to operate
                              the Totalizators at Hyderabad and shall also mean
                              to include any extra days in addition to the days
                              originally fixed in the prospectus of the
                              concerned racing centre, for which the Hyderabad
                              Race Club decides to operate the Totalizators.
                            </p>
                            <p>
                              18.The Totalizator at Race Course, Malakpet will
                              operate both online/ offline transactions on
                              Jackpot, Treble, Tanala, Forecast, Quinella, Win,
                              Place, Second Horse Pool, Third Horse Pool and
                              Mini Jackpot Pool on a race day and on an
                              Intervenue Transactions day and any or all of
                              these pools at Off course Tote Centre(s) in
                              respect of races run at Hyderabad Race Club or at
                              any racing centre as may be decided by the
                              Stewards of the Hyderabad Race Club from time to
                              time.
                            </p>
                            <p>
                              19.(a) On an Intervenue transactions Day, in the
                              case of any objection at the scales, transactions
                              on the Totalizators will be paid on the decision
                              of the Stewards of the concerned “Racing Centre”.
                            </p>
                            <p>
                              (b) In the event of the Information regarding the
                              winning and placed horses of any of the races
                              being not available from the racing centre on
                              account of failure in the communication system
                              within one hour from the advertised time of the
                              last race for the day at the racing centre, then
                              the declaration and payment of the dividend will
                              be made on the following day.
                            </p>
                            <p>
                              (c) In the event of racing being abandoned on any
                              day at the concerned racing centre.
                            </p>
                            <p>
                              (i) Before the first selected race of Jackpot
                              and/or Treble event or the selected race/s for
                              Tanala, Forecast, Quinella, Win, Place, Second
                              Horse Pool, Third Horse Pool &amp; Mini Jackpot
                              Pool the purchase price of the ticket/ transaction
                              valid for the respective event for the day at
                              Hyderabad will be refunded.
                            </p>
                            <p>
                              (ii) After one or more selected race/s of the
                              concerned event have been run at the racing
                              centre, but before the last selected race of the
                              event, then the dividend will be calculated at
                              Hyderabad in conformity with the rules of the
                              respective pools on unit transaction nominating
                              the Winner/s of the selected race/s which has/have
                              been run at the racing centre.
                            </p>
                            <p>
                              20.Tickets will be sold at the Race Course,
                              Malakpet and Off Course Tote Centres operated by
                              the Hyderabad Race Club for all the pools from
                              9.00 AM onwards on the day of races or a day prior
                              timings as specified by the Stewards and in case
                              of online web/mobile application transactions can
                              be done from two days prior to start of each race
                              through online web/mobile app of Hyderabad Race
                              Club, Turf Winners and Paytm First Games linked to
                              the Hyderabad Race Club’s Totalizator System.
                            </p>
                            <p>
                              21.Payment on winning tickets purchased at any off
                              course Tote Centres operated by the Hyderabad Race
                              Club and at Race Course, Malakpet will be made at
                              any off course Tote Centres subject to the
                              availability of cash at that centre or at Race
                              Course, Malakpet upto half-an-hour after the ALL
                              CLEAR&nbsp; being given for the last race of the
                              day. In case of online transactions payments will
                              be cleared immediately after All Clear for each
                              race/event.
                            </p>
                            <p>
                              22.The un-redeemed tickets which have not been
                              presented for payment in the next 7 days from the
                              date of purchase will not be paid. However if the
                              last day for such payment happens to be a non-race
                              day such un-redeemed tickets will be paid on next
                              race day.
                            </p>
                            <p>
                              23.At the conclusion of each race if the riders of
                              the placed horses have passed the Scales and no
                              objection has been lodged at the scales against
                              any of the placed horse, a white cone is hoisted
                              and dividends for the tote pools will then be
                              exhibited on the respective indicators.&nbsp; If
                              an objection is lodged against any of the placed
                              horse, tickets will be paid in accordance with the
                              decision of the Stewards, which for the purposes
                              of these rules, shall be conclusive and final.
                            </p>
                            <p>
                              24.In the event of any net pool of any totalizator
                              pool being carried over there being no winning
                              (incl., combinations) tickets/transactions
                              &nbsp;on any race of the Hyderabad Races or on any
                              other racing centre/s, the same shall be carried
                              forward and the entire amount or split into two or
                              three parts as deemed fit will be added to the net
                              concerned pool of any Hyderabad Race Day or any
                              other racing centre races as nominated by the
                              Stewards of the Club. There shall not be any
                              further deductions in the net carried forward
                              amounts.
                            </p>
                            <p>
                              25. All Totalizator Pools are independent of each
                              other pools.
                            </p>
                            <p>
                              <strong>STARTER's&nbsp; ORDERS</strong>
                            </p>
                            <p>
                              Horses are deemed to have come under Starter’s
                              Orders on a signal being given by the Starter by
                              means of a White Flag when he has finally
                              dispatched the field.
                              <br /> <br /> <strong>NOTICES</strong>
                            </p>
                            <p>
                              &nbsp;1. The “Weighed-in” indicating “All Clear”
                              will be signalled by hoisting a White Cone near
                              the paddock and Course Jockey’s Name Boards and
                              displaying a Green Light near the Bookmakers Ring.
                              <br /> <br /> (a) On an objection being lodged, a
                              Red Cone will be hoisted at the said places and a
                              Red Light displayed in the Bookmakers Ring.
                              <br /> <br /> (b) Similarly on a Stewards Enquiry
                              being opened, a Royal Blue cone will be hoisted
                              and a Blue Light displayed in the Bookmakers Ring.
                              <br /> <br /> (c) On an objection being upheld
                              and/or the placings revised following a Stewards
                              Enquiry, the Red Cone/Royal Blue Cone will be
                              lowered and a half Red and half White Cone hoisted
                              and a Red and White Light displayed in the
                              Bookmakers Ring.
                              <br /> <br /> (d) In the event of an objection
                              being over ruled/placings remaining unaltered
                              consequent to a Stewards Enquiry, the Red
                              Cone/Royal Blue Cone will be lowered and a White
                              Cone hoisted.
                              <br /> <br /> (e)In all cases of
                              objections/Stewards Enquiries “Notices” will be
                              put up stating either objection over ruled/upheld
                              and the order of revised placings if any, as
                              ordered by the Stewards at the above-mentioned
                              places.
                              <br /> <br /> 2. No horse may carry more than 3 ½
                              kgs. Overweight in any race at the Meeting without
                              the special permission of the Stewards. The
                              permission must be applied for, before the
                              Starting Declaration Box for that race is opened.
                              <br /> <br /> 3. The weights given have been
                              carefully checked and are believed to be correct,
                              but the Management will not hold itself
                              responsible for any inaccuracy. Any alteration in
                              weights or colours will be posted on the Notice
                              Boards.
                              <br /> <br /> 4. The Mounting Bell will be rung 10
                              minutes before the time advertised for each race.
                              Owners and Trainers are particularly requested to
                              assist the Management in enforcing punctuality.
                              <br /> <br /> 5. Trainers are advised to make sure
                              that the number clothes on their horses are
                              clearly visible.
                            </p>
                            <p>
                              <strong>STARTING DECLARATIONS</strong>
                            </p>
                            <p>
                              Starting Declaration for all the races must be in
                              the respective Boxes set apart for each race not
                              later than 09-30 a.m. on the day previous to the
                              races.
                              <br /> <br /> <strong>PHOTO FINISH</strong>
                            </p>
                            <p>
                              Should there be no photograph available when
                              called for by the Judge, the Judge will forth with
                              announce his decision.
                              <br /> <br />{' '}
                              <strong>EMERGENCY COLOURS ALL BLACK</strong>
                            </p>
                            <p>
                              In the event of a person being unable to run a
                              horse in Colours as declared in the OFFICIAL RACE
                              CARD, the JOCKEY will wear “ALL BLACK”. A set of
                              these Colours may be obtained from the Clerk of
                              the Scales.
                            </p>
                          </div>
                        </div>
                      </div>
                    </StyledPageTextView>
                  </div>
                )}
                {tab == 'fixedodds' && (
                  <div
                    className='fixedodds'
                    title='Sports Betting - Fixed Odds'
                  >
                    <StyledPageBanner>
                      <img
                        src={`${process.env.REACT_APP_CDN_IMAGES_PATH}/SportsBetting-FixedOdds-Banner.png`}
                        alt=''
                      />
                    </StyledPageBanner>
                    <StyledPageTextView>
                      <div className=''>
                        <div className='static_content'>
                          <h2 className='capitalize'>
                            Sports Betting - Fixed Odds Bet Rules
                          </h2>
                          <div className='betruletext'></div>
                        </div>
                      </div>
                    </StyledPageTextView>
                  </div>
                )}
              </StyledPageTabContainer>
            </StyledPageTextContainer>
          </StyledPageRight>
        </StyledContainerPageView>
      </StyledContainerFluid>
    </>
  );
};

export default Betrules;
