import { toast } from 'react-toastify';
export default class ToastifyService {
    public static notify = async (message: any) => {
        if (message !== '') {
            toast(message, {
                position: "top-center",
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "dark",
                type: "info"
            })
        }
    }
}